import { pureCloudLogout } from '../session/session';
import config from '../../config';
const backEndURL = config.apiEndpoint;

// <queues>
export const getRoutingGetQueues = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await routingGetQueues(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const routingGetQueues = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/routing/queues?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const routingPostQueue = (env, token, selectedDivisionId ='', name, acwWrapupPrompt, acwTimeoutSec, skillEvaluationMethod, alertingTimeoutSeconds, slPercentage, slDurationSec, callingPartyName, callingPartyNumber) => {

  var body = {
    "name": name
  }

  if (selectedDivisionId != ''){
    body.division = {"id":selectedDivisionId}
  }

  if (acwWrapupPrompt) {
    if (!body.acwSettings) { body.acwSettings = {}; }
    body.acwSettings.wrapupPrompt = acwWrapupPrompt;
  }

  if (acwTimeoutSec) {
    if (!body.acwSettings) { body.acwSettings = {}; }
    body.acwSettings.timeoutMs = acwTimeoutSec * 1000;
  }

  if (skillEvaluationMethod) {
    body.skillEvaluationMethod = skillEvaluationMethod;
  }

  if (callingPartyName) {
    body.callingPartyName = callingPartyName;
  }

  if (callingPartyNumber) {
    body.callingPartyNumber = callingPartyNumber;
  }

  if (alertingTimeoutSeconds) {
    if (!body.mediaSettings) { body.mediaSettings = {}; }
    if (!body.mediaSettings.call) { body.mediaSettings.call = {}; }
    body.mediaSettings.call.alertingTimeoutSeconds = alertingTimeoutSeconds;
  }

  if (slPercentage) {
    if (!body.mediaSettings) { body.mediaSettings = {}; }
    if (!body.mediaSettings.call) { body.mediaSettings.call = {}; }
    if (!body.mediaSettings.call.serviceLevel) { body.mediaSettings.call.serviceLevel = {}; }
    body.mediaSettings.call.serviceLevel.percentage = slPercentage / 100;
  }

  if (slDurationSec) {
    if (!body.mediaSettings) { body.mediaSettings = {}; }
    if (!body.mediaSettings.call) { body.mediaSettings.call = {}; }
    if (!body.mediaSettings.call.serviceLevel) { body.mediaSettings.call.serviceLevel = {}; }
    body.mediaSettings.call.serviceLevel.durationMs = slDurationSec * 1000;
  }

  console.log(JSON.stringify(body));

  return fetch(`https://api.${env}/api/v2/routing/queues`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}

export const routingDeleteQueue = (env, token, id) => {
  return fetch(`https://api.${env}/api/v2/routing/queues/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
}

export const routingPostQueuesUsers = (env, token, userId, queueId) => {
  return fetch(`https://api.${env}/api/v2/routing/queues/${queueId}/users`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify([{
      "id": userId
    }])
  })
}

export const routingPostQueuesWrapUpCodes = (env, token, queueId, queueWrapUpCodeIds) => {
  return fetch(`https://api.${env}/api/v2/routing/queues/${queueId}/wrapupcodes`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(queueWrapUpCodeIds)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}
// </queues>

export const postRoleDivisionGrants = (body, userId, env, userMail, authToken, orgName) => {
  return fetch(backEndURL + `/authorization/subjects/${userId}/bulkadd`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    },
    body: JSON.stringify({"grants": body})
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) return response;
        return response;
      }
      return response;
    })
}

// <skills>
export const routingPatchRoutingSkillsBulk = (env, token, userId, skillList) => {
  return fetch(`https://api.${env}/api/v2/users/${userId}/routingskills/bulk`, {
    method: 'PATCH',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(skillList)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}

export const getRoutingGetSkills = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await routingGetSkills(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const routingGetSkills = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/routing/skills?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const routingPostSkill = (env, token, name) => {
  return fetch(`https://api.${env}/api/v2/routing/skills`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const routingDeleteSkill = (env, token, id) => {
  return fetch(`https://api.${env}/api/v2/routing/skills/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
}
// </skills>

// <wrap-up codes>
export const getRoutingWrapupCodes = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await routingGetWrapUpCodes(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const routingGetWrapUpCodes = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/routing/wrapupcodes?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const getRoutingGetQueueUsers = async (env, token, queueId) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await routingGetQueueUsers(env, token, queueId, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const routingGetQueueUsers = (env, token, queueId, index) => {
  return fetch(`https://api.${env}/api/v2/routing/queues/${queueId}/users?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
      .then(response => response.json())
      .then(response => {
        if (response.status === 401) {
          pureCloudLogout();
          return;
        }
        if (response.status < 200 || response.status > 299) throw response;
        if (response.entities && response.entities.length > 0) {
          return response.entities;
        }
      })
};

export const routingGetQueueWrapUpCode = (env, token, queueId) => {
  return fetch(`https://api.${env}/api/v2/routing/queues/${queueId}/wrapupcodes`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
      .then(response => response.json())
      .then(response => {
        if (response.status === 401) {
          pureCloudLogout();
          return;
        }
        if (response.status < 200 || response.status > 299) throw response;
        if (response.entities && response.entities.length > 0) {
          return response.entities;
        }
      })
};

export const routingPostWrapUpCode = (env, token, name) => {
  return fetch(`https://api.${env}/api/v2/routing/wrapupcodes`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const routingDeleteWrapUpCode = (env, token, id) => {
  return fetch(`https://api.${env}/api/v2/routing/wrapupcodes/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
}
// </wrap-up codes>
