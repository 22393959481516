import './orgProperties.css'
import React from 'react'
import { Card, CardBody, CardTitle, Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactTable from "react-table";

export default class OrgProperties extends React.Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="Orgprops">
                    <ModalHeader>Organization Properties</ModalHeader>
                    <ModalBody>
                        <Row>

                            {/* <properties> */}
                            <Col lg="6" sm="12">
                                <Card className="mb-4">
                                    <CardBody className="p-3">
                                        <CardTitle className="m-0">Properties</CardTitle>
                                    </CardBody>
                                    <CardBody className="p-3">
                                        <Row className="mb-3">
                                            <Col>
                                                <ReactTable
                                                    data={this.props.orgProperties}
                                                    pageSize={this.props.orgProperties.length}
                                                    columns={[
                                                        {
                                                            // minWidth: 200,
                                                            Header: "Key",
                                                            accessor: "key",                                                            
                                                        },
                                                        {
                                                            // minWidth: 200,
                                                            Header: "Value",
                                                            accessor: "value",
                                                        }
                                                    ]}
                                                    showPagination={false}
                                                    className="-striped -highlight"
                                                />
                                            </Col>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>
                            {/* </properties> */}

                            {/* <features> */}
                            <Col lg="6" sm="12">
                                <Card className="mb-4">
                                    <CardBody className="p-3">
                                        <CardTitle className="m-0">Features</CardTitle>
                                    </CardBody>
                                    <CardBody className="p-3">
                                        <ReactTable
                                            data={this.props.orgFeatures}
                                            pageSize={this.props.orgFeatures.length}
                                            columns={[
                                                {
                                                    // minWidth: 200,
                                                    Header: "Key",
                                                    accessor: "key",
                                                },
                                                {
                                                    // minWidth: 200,
                                                    Header: "Value",
                                                    accessor: "value",
                                                }
                                            ]}
                                            showPagination={false}
                                            className="-striped -highlight"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                            {/* </features> */}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}