export const defaultPrompts = [
    {
        rowId: '1',
        promptId: '',
        description: "Main Greeting Message",
        promptText: "Thank you for calling <Company Name>",
        promptName: "MainGreeting",
        language: "en-us",
        promptFile: "n/a",
        promptContent: null,
        useTTS: false
    },
    {
        rowId: '2',
        promptId: '',
        description: "Main Menu Message",
        promptText: "If you are a new customer and looking for more information about out product, press 1.  If you have purchased our product and currenting have any issues or inquiries, press 2.  If you are a 3rd party vendor seeking marketing material, press 3.  For all other inquiries press 4.  To repeat this options press star.",
        promptName: "MainMenu",
        language: "en-us",
        promptFile: "n/a",
        promptContent: null,
        useTTS: false
    },
    {
        rowId: '3',
        promptId: '',
        description: "Closed Message",
        promptText: "Our offices are closed at present; please call back during our normal opening hours which are 8 AM to 5 PM, Monday through Friday.",
        promptName: "ClosedMessage",
        language: "en-us",
        promptFile: "n/a",
        promptContent: null,
        useTTS: false
    },
    {
        rowId: '4',
        promptId: '',
        description: "Holiday Message",
        promptText: "We are closed for holiday.  Please see our website at www.company.com/businesshours for when we will return to normal operating hours.  Have a great day.",
        promptName: "HolidayMessage",
        language: "en-us",
        promptFile: "n/a",
        promptContent: null,
        useTTS: false
    },
    {
        rowId: '5',
        promptId: '',
        description: "Emergency Message",
        promptText: "We are experiencing interruption in our service.  You can find out more information at www.company.com/events for next steps and when we will return to normal operations.  We apologize for any inconvenience this may have caused.",
        promptName: "EmergencyMessage",
        language: "en-us",
        promptFile: "n/a",
        promptContent: null,
        useTTS: false
    }
];

export const defaultLanguages = [
    "af-na",
    "af-za",
    "agq-cm",
    "ak-gh",
    "am-et",
    "ar-001",
    "ar-ae",
    "ar-bh",
    "ar-dj",
    "ar-dz",
    "ar-eg",
    "ar-eh",
    "ar-er",
    "ar-il",
    "ar-iq",
    "ar-jo",
    "ar-km",
    "ar-kw",
    "ar-lb",
    "ar-ly",
    "ar-ma",
    "ar-mr",
    "ar-om",
    "ar-ps",
    "ar-qa",
    "ar-sa",
    "ar-sd",
    "ar-so",
    "ar-sy",
    "ar-td",
    "ar-tn",
    "ar-ye",
    "arn-cl",
    "as-in",
    "asa-tz",
    "az-cyrl",
    "az-cyrl-az",
    "az-latn",
    "az-latn-az",
    "ba-ru",
    "bas-cm",
    "be-by",
    "bem-zm",
    "bez-tz",
    "bg-bg",
    "bm-ml",
    "bn-bd",
    "bn-in",
    "bo-cn",
    "bo-in",
    "br-fr",
    "bs-cyrl",
    "bs-cyrl-ba",
    "bs-latn",
    "bs-latn-ba",
    "ca-ad",
    "ca-es",
    "cgg-ug",
    "chr-us",
    "co-fr",
    "cs-cz",
    "cy-gb",
    "da-dk",
    "dav-ke",
    "de-at",
    "de-be",
    "de-ch",
    "de-de",
    "de-li",
    "de-lu",
    "dje-ne",
    "dsb-de",
    "dua-cm",
    "dv-mv",
    "dyo-sn",
    "dz-bt",
    "ebu-ke",
    "ee-gh",
    "ee-tg",
    "el-cy",
    "el-gr",
    "en-029",
    "en-150",
    "en-ag",
    "en-as",
    "en-au",
    "en-bb",
    "en-be",
    "en-bm",
    "en-bs",
    "en-bw",
    "en-bz",
    "en-ca",
    "en-cm",
    "en-dm",
    "en-fj",
    "en-fm",
    "en-gb",
    "en-gd",
    "en-gg",
    "en-gh",
    "en-gi",
    "en-gm",
    "en-gu",
    "en-gy",
    "en-hk",
    "en-ie",
    "en-im",
    "en-in",
    "en-je",
    "en-jm",
    "en-ke",
    "en-ki",
    "en-kn",
    "en-ky",
    "en-lc",
    "en-lr",
    "en-ls",
    "en-mg",
    "en-mh",
    "en-mp",
    "en-mt",
    "en-mu",
    "en-mw",
    "en-my",
    "en-na",
    "en-ng",
    "en-nz",
    "en-pg",
    "en-ph",
    "en-pk",
    "en-pr",
    "en-pw",
    "en-sb",
    "en-sc",
    "en-sg",
    "en-sl",
    "en-ss",
    "en-sz",
    "en-tc",
    "en-to",
    "en-tt",
    "en-tz",
    "en-ug",
    "en-um",
    "en-us",
    "en-vc",
    "en-vg",
    "en-vi",
    "en-vu",
    "en-ws",
    "en-za",
    "en-zm",
    "en-zw",
    "es-419",
    "es-ar",
    "es-bo",
    "es-cl",
    "es-co",
    "es-cr",
    "es-cu",
    "es-do",
    "es-ea",
    "es-ec",
    "es-es",
    "es-gq",
    "es-gt",
    "es-hn",
    "es-ic",
    "es-mx",
    "es-ni",
    "es-pa",
    "es-pe",
    "es-ph",
    "es-pr",
    "es-py",
    "es-sv",
    "es-us",
    "es-uy",
    "es-ve",
    "et-ee",
    "eu-es",
    "ewo-cm",
    "fa-af",
    "fa-ir",
    "ff-sn",
    "fi-fi",
    "fil-ph",
    "fo-fo",
    "fr-be",
    "fr-bf",
    "fr-bi",
    "fr-bj",
    "fr-bl",
    "fr-ca",
    "fr-cd",
    "fr-cf",
    "fr-cg",
    "fr-ch",
    "fr-ci",
    "fr-cm",
    "fr-dj",
    "fr-dz",
    "fr-fr",
    "fr-ga",
    "fr-gf",
    "fr-gn",
    "fr-gp",
    "fr-gq",
    "fr-ht",
    "fr-km",
    "fr-lu",
    "fr-ma",
    "fr-mc",
    "fr-mf",
    "fr-mg",
    "fr-ml",
    "fr-mq",
    "fr-mr",
    "fr-mu",
    "fr-nc",
    "fr-ne",
    "fr-pf",
    "fr-re",
    "fr-rw",
    "fr-sc",
    "fr-sn",
    "fr-sy",
    "fr-td",
    "fr-tg",
    "fr-tn",
    "fr-vu",
    "fr-yt",
    "fy-nl",
    "ga-ie",
    "gd-gb",
    "gl-es",
    "gsw-ch",
    "gsw-fr",
    "gu-in",
    "guz-ke",
    "gv-gb",
    "ha-latn",
    "ha-latn-gh",
    "ha-latn-ne",
    "ha-latn-ng",
    "ha-ng",
    "haw-us",
    "he-il",
    "hi-in",
    "hr-ba",
    "hr-hr",
    "hsb-de",
    "hu-hu",
    "hy-am",
    "id-id",
    "ig-ng",
    "ii-cn",
    "is-is",
    "it-ch",
    "it-it",
    "it-sm",
    "ja-jp",
    "jgo-cm",
    "jmc-tz",
    "ka-ge",
    "kab-dz",
    "kam-ke",
    "kde-tz",
    "kea-cv",
    "khq-ml",
    "ki-ke",
    "kk-cyrl",
    "kk-cyrl-kz",
    "kk-kz",
    "kl-gl",
    "kln-ke",
    "km-kh",
    "kn-in",
    "ko-kp",
    "ko-kr",
    "kok-in",
    "ks-arab",
    "ks-arab-in",
    "ksb-tz",
    "ksf-cm",
    "kw-gb",
    "ky-kg",
    "lag-tz",
    "lb-lu",
    "lg-ug",
    "ln-ao",
    "ln-cd",
    "ln-cf",
    "ln-cg",
    "lo-la",
    "lt-lt",
    "lu-cd",
    "luo-ke",
    "luy-ke",
    "lv-lv",
    "mas-ke",
    "mas-tz",
    "mer-ke",
    "mfe-mu",
    "mg-mg",
    "mgh-mz",
    "mgo-cm",
    "mi-nz",
    "mk-mk",
    "ml-in",
    "mn-cn",
    "mn-mn",
    "moh-ca",
    "mr-in",
    "ms-bn",
    "ms-my",
    "ms-sg",
    "mt-mt",
    "mua-cm",
    "my-mm",
    "naq-na",
    "nb-no",
    "nd-zw",
    "ne-in",
    "ne-np",
    "nl-aw",
    "nl-be",
    "nl-cw",
    "nl-nl",
    "nl-sr",
    "nmg-cm",
    "nn-no",
    "nso-za",
    "nus-sd",
    "nyn-ug",
    "oc-fr",
    "om-et",
    "om-ke",
    "or-in",
    "pa-arab",
    "pa-arab-pk",
    "pa-guru",
    "pa-guru-in",
    "pa-in",
    "pl-pl",
    "prs-af",
    "ps-af",
    "pt-ao",
    "pt-br",
    "pt-cv",
    "pt-gw",
    "pt-mo",
    "pt-mz",
    "pt-pt",
    "pt-st",
    "pt-tl",
    "qut-gt",
    "quz-bo",
    "quz-ec",
    "quz-pe",
    "rm-ch",
    "rn-bi",
    "ro-md",
    "ro-ro",
    "rof-tz",
    "ru-by",
    "ru-kg",
    "ru-kz",
    "ru-md",
    "ru-ru",
    "ru-ua",
    "rw-rw",
    "rwk-tz",
    "sa-in",
    "sah-ru",
    "saq-ke",
    "sbp-tz",
    "se-fi",
    "se-no",
    "se-se",
    "seh-mz",
    "ses-ml",
    "sg-cf",
    "shi-latn",
    "shi-latn-ma",
    "shi-tfng",
    "shi-tfng-ma",
    "si-lk",
    "sk-sk",
    "sl-si",
    "sma-no",
    "sma-se",
    "smj-no",
    "smj-se",
    "smn-fi",
    "sms-fi",
    "sn-zw",
    "so-dj",
    "so-et",
    "so-ke",
    "so-so",
    "sq-al",
    "sq-mk",
    "sr-cyrl",
    "sr-cyrl-ba",
    "sr-cyrl-cs",
    "sr-cyrl-me",
    "sr-cyrl-rs",
    "sr-latn",
    "sr-latn-ba",
    "sr-latn-cs",
    "sr-latn-me",
    "sr-latn-rs",
    "sv-fi",
    "sv-se",
    "sw-ke",
    "sw-tz",
    "sw-ug",
    "swc-cd",
    "syr-sy",
    "ta-in",
    "ta-lk",
    "ta-my",
    "ta-sg",
    "te-in",
    "teo-ke",
    "teo-ug",
    "tg-tj",
    "th-th",
    "ti-er",
    "ti-et",
    "tk-tm",
    "tn-za",
    "to-to",
    "tr-cy",
    "tr-tr",
    "tt-ru",
    "twq-ne",
    "tzm-dz",
    "tzm-latn",
    "tzm-latn-ma",
    "ug-cn",
    "uk-ua",
    "ur-in",
    "ur-pk",
    "uz-arab",
    "uz-arab-af",
    "uz-cyrl",
    "uz-cyrl-uz",
    "uz-latn",
    "uz-latn-uz",
    "vai-latn",
    "vai-latn-lr",
    "vai-vaii",
    "vai-vaii-lr",
    "vi-vn",
    "vun-tz",
    "wo-sn",
    "yav-cm",
    "yo-ng",
    "zh-cn",
    "zh-hans",
    "zh-hans-cn",
    "zh-hans-hk",
    "zh-hans-mo",
    "zh-hans-sg",
    "zh-hant",
    "zh-hant-hk",
    "zh-hant-mo",
    "zh-hant-tw",
    "zh-hk",
    "zh-mo",
    "zh-sg",
    "zh-tw",
    "zu-za",
];

export default {
    defaultPrompts,
    defaultLanguages
}