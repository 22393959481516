import './importLibraryCallFlows.css'
import moment from "moment";
import React from 'react'
import { Form, Row, Col, Card, CardBody, Button, CardTitle, FormGroup, Input, Label, Badge } from 'reactstrap';
import { FaTasks, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, getOrgMe, getUserMe, logActionData } from '../../../../services/session/session';
import { importInBoundCallFlow, importInQueueCallFlow, importInboundChatFlow, importInboundEmailFlow, importOutboundFlow, archyResults } from '../../../../services/purecloud/architect';
import { selectedCallFlows, publishCallFlows, checkProgress, downloadSubsFile, getI3FlowFileData } from '../../../../services/purecloud/flows';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import Misc from '../../../../services/misc/misc';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import ArchyModal from '../../../Misc/archyModal/archyModal';
import EventListViewer from "../../../Misc/eventListViewer/eventListViewer";
import exportfile from '../../../../services/misc/fileDownload';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import ImageMapper from 'react-image-mapper';

export default class ImportLibraryCallFlows extends React.Component {

    state = {
        env: '',
        token: '',
        orgMe: {},
        userMe: {},
        preloaderText: '',
        preloaderShow: false,
        eventList: [],
        eventListIsOpen: false,
        callFlow: [],
        displayLabel: false,
        selectedFlowName: '',
        selectedFlowPath: '',
        selectedSubsFile: '',
        subsFileName: '',
        subsFileContent: {},
        isArchyOpen: false,
        extensionFile: '',
        selectedFlowType: '',
        isInfoOpen: false,
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/, isWarning = false) => {
        const event = {
            isError: isError,
            isWarning: isWarning,
            message: message,
            time: moment().format('HH:mm:ss'),
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: this.eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    async componentDidMount() {
        await this.loadOrg();
        await this.getSelectedCallFlows();
    }

    loadOrg = async () => {
        try {
            const orgMe = await getOrgMe();
            const userMe = await getUserMe();
            await this.setState({ orgMe: orgMe, userMe: userMe });
        } catch (error) {
            throw error;
        }
    }

    getSelectedCallFlows = async () => {
        try {
            await this.setState({ preloaderShow: true, preloaderText: 'Loading' });
            this.state.callFlow = await selectedCallFlows(this.state.userMe.email, this.state.orgMe.name);
            await this.setState({ preloaderShow: false, preloaderText: '' });
        } catch (err) {
            await this.setState({ preloaderShow: false, preloaderText: '' });
            throw err;
        }
    }

    handleSelectedCallFlow = async (flowPath, flowName, substitutionTemplateFilename, flowType) => {
        if (substitutionTemplateFilename === null) {
            this.setState({
                displayLabel: false,
                selectedFlowName: flowName,
                selectedFlowPath: flowPath,
                selectedSubsFile: substitutionTemplateFilename,
                selectedFlowType: flowType,
            });
        } else {
            let extensionFile = substitutionTemplateFilename.substr(substitutionTemplateFilename.indexOf('.') + 1, substitutionTemplateFilename.length);
            if (flowType === 'i3') {
                this.setState({
                    displayLabel: false,
                    selectedFlowName: flowName,
                    selectedFlowPath: flowPath,
                    selectedSubsFile: null,
                    extensionFile: extensionFile,
                    selectedFlowType: flowType,
                });
            } else {
                this.setState({
                    displayLabel: true,
                    selectedFlowName: flowName,
                    selectedFlowPath: flowPath,
                    selectedSubsFile: substitutionTemplateFilename,
                    selectedFlowType: flowType,
                });
            }
        }
    }

    createDocReaderPromise = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file,
                };
                resolve(fileInfo.base64.split(',')[1]);
            };
            reader.onerror = () => {
                reject();
            };
        });
    }

    handleSubsFile = async (e) => {
        try {
            e.persist();
            this.setState({
                subsFileName: e.target.files[0].name,
                subsFileContent: e.target.files[0],
            });
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.yaml')) {
                console.error('Only YAML files are allowed');
                return;
            }
            this.setState({
                subsFileName: file.name,
                subsFileContent: file,
            });
        } catch (err) {
            console.log(err);
        }
    }

    clearState = async () => {
        this.setState({
            displayLabel: false,
            selectedFlowName: '',
            selectedFlowPath: '',
            selectedFlowType: '',
            extensionFile: '',
            selectedSubsFile: '',
            subsFileName: '',
            subsFileContent: {},
        });
    }

    PublishButtonClicked = async () => {
        this.eventList = [];
        if (this.state.selectedFlowType === 'i3') {
            await this.setState({ preloaderShow: true, preloaderText: 'Retrieving I3 Call Flow Data' });
            try {
                let b64fileData = await getI3FlowFileData(this.state.userMe.email, this.state.selectedFlowPath, this.state.orgMe.name);
                let fileData = await this.base64ToUTF8(b64fileData);
                if (this.state.extensionFile === 'i3InboundFlow') {
                    await this.setState({ preloaderShow: true, preloaderText: 'Importing i3 Inbound Call Flow Data' });
                    await importInBoundCallFlow(this.state.env, this.state.token, fileData, this.state.selectedFlowPath);
                    this.logEvent(`File [${this.state.selectedFlowPath}] successfully processed`);
                } else if (this.state.extensionFile === 'i3InQueueFlow') {
                    await this.setState({ preloaderShow: true, preloaderText: 'Importing i3 In-Queue Call Flow Data' });
                    await importInQueueCallFlow(this.state.env, this.state.token, fileData, this.state.selectedFlowPath);
                    this.logEvent(`File [${this.state.selectedFlowPath}] successfully processed`);
                } else if (this.state.extensionFile === 'i3InboundChatFlow') {
                    await this.setState({ preloaderShow: true, preloaderText: 'Importing i3 Inbound Chat Flow Data' });
                    await importInboundChatFlow(this.state.env, this.state.token, fileData, this.state.selectedFlowPath);
                    this.logEvent(`File [${this.state.selectedFlowPath}] successfully processed`);
                } else if (this.state.extensionFile === 'i3InboundEmailFlow') {
                    await this.setState({ preloaderShow: true, preloaderText: 'Importing i3 Inbound Email Flow Data' });
                    await importInboundEmailFlow(this.state.env, this.state.token, fileData, this.state.selectedFlowPath);
                    this.logEvent(`File [${this.state.selectedFlowPath}] successfully processed`);
                } else if (this.state.extensionFile === 'i3OutboundFlow') {
                    await this.setState({ preloaderShow: true, preloaderText: 'Importing i3 Outbound Flow Data' });
                    await importOutboundFlow(this.state.env, this.state.token, fileData, this.state.selectedFlowPath);
                    this.logEvent(`File [${this.state.selectedFlowPath}] successfully processed`);
                }
                await logActionData('Import', `Importing ${this.state.selectedFlowPath}`, 1, this.state.selectedFlowPath);
                this.showEventList();
            } catch (err) {
                this.displayArchyResults(archyResults);
            }
            await Misc.sleep(500); // just for better user expirience - to showw 100% completed progressbar
            await this.clearState();
            await this.setState({ preloaderShow: false, preloaderText: '' });
        } else {
            if (this.state.subsFileName === '') {
                this.setState({ isArchyOpen: true });
            } else {
                const subContent = await this.createDocReaderPromise(this.state.subsFileContent);
                let token = await publishCallFlows(this.state.userMe.email, this.state.orgMe.name, this.state.selectedFlowPath, subContent, this.state.subsFileName, this.state.token, this.state.env);
                let response = await this.callFlowProgress(token);
                await Misc.sleep(500); // just for better user expirience - to showw 100% completed progressbar
                await this.clearState();
                this.displayEventList(response);
            }
        }
    }

    publishWithoutSubs = async () => {
        await this.setState({ preloaderShow: true, preloaderText: 'Publishing Call Flow without substitution file' });
        this.setState({ isArchyOpen: false });
        let token = await publishCallFlows(this.state.userMe.email, this.state.orgMe.name, this.state.selectedFlowPath, undefined, undefined, this.state.token, this.state.env);
        let response = await this.callFlowProgress(token);
        await Misc.sleep(500); // just for better user expirience - to showw 100% completed progressbar
        await this.clearState();
        this.displayEventList(response);
    }

    handleDownloadSubs = async () => {
        this.eventList = [];
        await this.setState({ preloaderShow: true, preloaderText: 'Downloading Subs File' });
        let response = await downloadSubsFile(this.state.userMe.email, this.state.orgMe.name, this.state.selectedSubsFile);
        if (response) {
            if (response.isSuccess) {
                this.base64ToBlob(response.response);
            } else {
                this.logEvent(response.response, true, false);
                this.showEventList();
            }
        }

        await this.setState({ preloaderShow: false, preloaderText: '' });
    }

    base64ToBlob = async (response) => {
        var b64toBlob = require('b64-to-blob');
        var contentType = 'application/json';
        var b64Data = response;
        var blob = b64toBlob(b64Data, contentType);
        exportfile(blob, this.state.selectedSubsFile);
    }

    base64ToUTF8 = async (str) => {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    displayArchyResults = async (response) => {
        this.eventList = [];
        response.forEach(element => {
            this.logEvent(element, true, false);
        });
        this.showEventList();
    }

    displayEventList = async (response) => {
        this.eventList = [];
        if (response) {
            if (response.status === undefined) {
                if (response === 'Unauthorized') {
                    this.logEvent(response, true, false);
                } else {
                    this.logEvent("Something went wrong with archy command. Verify callflow/substitution file.", true, false);
                }
            } else {
                if (response.status === 'success') {
                    this.logEvent(response.status, false, false);
                    if (response.output) {
                        this.logEvent(response.output.flowName, false, false);
                    }
                } else {
                    this.logEvent(response.status, true, false);
                    if (response.output) {
                        this.logEvent(response.output.flowName, false, false);
                        if (response.output.traces && response.output.traces.length > 0) {
                            response.output.traces.map((trace) => {
                                this.logEvent(trace.text, true, false);
                            });
                        }
                    }
                }
            }
        }
        this.showEventList();
    }

    callFlowProgress = async (token) => {
        if (token) {
            while (true) {
                let response = await checkProgress(this.state.userMe.email, this.state.orgMe.name, token);
                if (response.progress !== undefined) {
                    await this.setState({ preloaderShow: true, preloaderText: response.progress + '% ' + response.text });
                    await this.sleep(3000);
                } else {
                    await this.setState({ preloaderShow: false, preloaderText: '' });
                    return response;
                }
            }
        }
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            }
        }
    }

    render() {

        let MAP = {
            name: "callflow-map",
            areas: [
                { name: "0", shape: "circle", coords: [31, 16, 16] },
                // { name: "1", shape: "circle", coords: [112, 16, 16] },
            ]
        }

        let importButton = 'Import';
        if (this.state.selectedFlowType === 'yaml') {
            importButton = 'Publish';
        } else if (this.state.selectedFlowType === 'i3') {
            importButton = 'Import';
        }

        return (
            <div className="libraryCard">

                {/* <event list viewer> */}
                <EventListViewer title="Events of Call Flow" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                {/* <archy modal> */}
                <ArchyModal isOpen={this.state.isArchyOpen} callFlow={this.state.selectedFlowName} orgName={this.state.orgMe} publishFunction={this.publishWithoutSubs} closeFunction={() => { this.setState({ isArchyOpen: false }) }} />
                {/* </archy modal> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 libraryCardBody">
                        <CardTitle className="m-0 libraryTitle">
                            <div className="divLibFlows">
                                <Badge className="titleBadgeDesignFlows">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pCallFlows.png" map={MAP} width={150} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Import/Publish callflows from the standard Library of callflows.
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyLibFlows">
                        <PreloaderLocal show={this.state.preloaderShow} text={this.state.preloaderText} />
                        <Form>
                            <FormGroup className="FieldSet">
                                {this.state.displayLabel ? <legend className="LegendClass">Click to download <a onClick={this.handleDownloadSubs}><u style={{ cursor: "pointer", color: "blue" }}>substitution Template file</u></a></legend> : <legend className="LegendClass"></legend>}
                                <FormGroup style={{ height: "25vmin", overflowY: "scroll", marginLeft: "5px" }}>
                                    {
                                        (this.state.callFlow && this.state.callFlow.length > 0) && this.state.callFlow.map((flow, index) => {
                                            return (
                                                <div key={index}>
                                                    <Label key={index} title={flow.description} style={{ cursor: "pointer", backgroundColor: flow.displayName === this.state.selectedFlowName && "Yellow" }} onClick={() => this.handleSelectedCallFlow(flow.flowName, flow.displayName, flow.substitutionTemplateFilename, flow.type)}><strong>{flow.displayName}</strong></Label>
                                                </div>
                                            )
                                        })
                                    }
                                </FormGroup>
                            </FormGroup>
                            {
                                this.state.displayLabel &&
                                <div>
                                    <FormGroup>
                                        <Label style={{ fontSize: "2.5vmin", marginTop:"-15px" }}>Selected Call Flow: </Label>
                                        <Label style={{ margin: "5px" }}><strong>{this.state.selectedFlowName}</strong></Label>
                                    </FormGroup>
                                    <FormGroup style={{ display: "flex" }}>
                                        <div onDrop={this.handleUploadFile}>
                                            <Label style={{ fontSize: "2.5vmin" , marginTop:"-15px"  }}>Substitution File: </Label>
                                        </div>
                                        <div style={{ width: "50%", marginTop:"-15px", marginLeft: "10px"  }}>
                                            <DragAndDrop handleDrop={this.handleDropFile}>
                                                <Input type="text" style={{ fontWeight: "900" }} placeholder="Browse to; or Drop a substitution file here" disabled value={this.state.subsFileName} />
                                            </DragAndDrop>
                                        </div>
                                        <div style={{ marginTop:"-15px"  }}>
                                            <label className="custom-file-upload">
                                                <input style={{ display: "none" }} type="file" id="subsFile" accept=".yml, .yaml" onChange={this.handleSubsFile} />
                                                <FaCloudUploadAlt /> Browse
                                            </label>
                                        </div>
                                    </FormGroup>
                                </div>
                            }
                        </Form>
                        <div className="single-field-editor-card-wrap">
                            <Row className="mt-3">
                                <Col>
                                    <div style = {{marginTop:"0px"}}>
                                        <Button className="SubmitButtonDesignFlows" disabled={this.state.selectedFlowName === ''} onClick={this.PublishButtonClicked}><FaCloudUploadAlt /> {importButton}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}