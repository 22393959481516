import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap'

export default class BulkDelete extends React.Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isBulkDeleteModalOpen}>
                    <ModalHeader>Bulk Delete </ModalHeader>
                    <ModalBody>
                        Do you want to Delete all Selected Items?
                    </ModalBody>
                    <ModalFooter>
                    <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.deleteAllFunction}>Ok</Button>
                        <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}