import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row, Table } from 'reactstrap'

export default class DivisionsInfo extends React.Component {
    render() {
        let isButtonEnabled = false;
        if (this.props.selectedRole != '' && this.props.divisionArray.length > 0){
            isButtonEnabled = true;
        }
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="usersModal">
                    <ModalHeader>Roles Configuration</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Label>Role:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.selectedRole} onChange={this.props.handleInputChange('selectedRole')}>
                                            <option key="D1" hidden>Select a Role</option>
                                            {
                                                this.props.roleList.map((role) => (
                                                    <option key={role.id}>{role.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        {/* <Label md={4} title="Select entry & Cntrl key for multiple selections">Select Divisions for {this.props.selectedRole}:<span className="text-danger font-weight-bold">*</span></Label> */}
                                        <select md={6} multiple title="Select entry & Cntrl key for multiple selections" className="form-control selectpicker" value={this.props.divisionArray} onChange={this.props.handleRoleConfig()} >
                                            <option key="D1" selected disabled>Select Divisions</option>
                                            {
                                                this.props.divisionList.map((division) => (
                                                    <option key={division.id}>{division.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isRolesSaveLocale
                                ?
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.updateRolesConfigFunction}>Update</Button>
                                :
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.submitRolesConfigFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.closeRolesConfigFunction}>Close</Button>
                    </ModalFooter>
                </Modal>

            </div>
        )
    }
}