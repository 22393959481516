const uuidv1 = require('uuid/v1');
const version = require('../../version.json');

export const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getUuid = () => {
  return uuidv1();
}

export const isNumeric = (value) => {
  return /^-{0,1}\d+$/.test(value);
}

export const readFile = (file) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject('reader error')
    }
    reader.onload = () => { resolve(reader.result) }
    reader.readAsText(file, "UTF-8")
  })
}

export const normalizeLineBrakes = (inputString) => {
  const temp = inputString.replace(/(?:\r\n|\r|\n)/g, '|');
  const tempArray = temp.split('|');
  var outputArray = [];
  for (const item of tempArray) {
    outputArray.push(item.trim());
  }
  const result = outputArray.join('<br />');
  return result;
}

export const displayListToArray = (displayList) => {
  if (!displayList) return [];
  const arrayTemp = displayList.split('<br />');
  var arrayResult = [];
  for (const item of arrayTemp) {
    if (item.trim()) {
      arrayResult.push(item.trim());
    }
  }
  return arrayResult;
}

export const getVersion = () => { return version; }

export default {
  sleep,
  getUuid,
  isNumeric,
  normalizeLineBrakes,
  displayListToArray,
  readFile,
  getVersion
}