import React from 'react';
import './addNewModal.css';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Input, Label, FormGroup, Row, Col } from 'reactstrap'

export default class AddNewModal extends React.Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="BulkModal">
                    <ModalHeader>Add New {this.props.Title}</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Row>
                                <Col md={4}>
                                <Label style={{ marginLeft: "5px", float: "right" }}>Name<span className="text-danger font-weight-bold">*</span></Label>
                                </Col>
                                <Col md={6}>
                                <Input type="text" onChange={this.props.handleInputChange} value={this.props.newItemValue} />
                                </Col>
                            </Row>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ marginLeft: "5px", float: "right", backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Cancel</Button>
                        <Button disabled={this.props.newItemValue === ''} style={{backgroundColor:"#061e45" }} onClick={this.props.AddNewItem}>Add</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

}