import './orgSettings.css'

import React from 'react'
import { Badge, Card, CardBody, CardTitle, Table, Input, InputGroup, Row, Col } from 'reactstrap';
import { FaTrash, FaCloudUploadAlt, FaInfoCircle, FaPlus } from 'react-icons/fa';
import AddNewModal from '../../Misc/addNewModal/addNewModal'
import moment from 'moment';

import { getSelectedRegion } from '../../../global/localStorage';
import { getToken, logActionData } from '../../../services/session/session';
import PreloaderLocal from '../../Misc/preloaderLocal/preloaderLocal';
import DragAndDrop from '../../Misc/dragAndDrop/dragAndDrop';
import EventListViewer from '../../Misc/eventListViewer/eventListViewer';
import CsvReader from '../../../services/misc/csvReader';
import InformationMessage from '../../Misc/informationMessage/informationMessage';
import { Button } from 'reactstrap';
import ImageMapper from 'react-image-mapper';
import BulkDelete from '../../Misc/bulkDeleteModal/bulkDelete'

export default class OrgSettings extends React.Component {

  state = {
    env: '',
    token: '',
    enteredNewItemName: '',
    itemList: [],
    preloaderLocalShow: false,
    text: 'Loading',
    eventList: [],
    eventListIsOpen: false,
    isInfoOpen: false,
    isaddNewModalOpen: false,
    newValue: '',
    selected: {},
    selectAll: 0,
    isBulkDeleteModalOpen: false
  }

  eventList = [];

  logEvent = (message, isError = false /*bool*/) => {
    const event = {
      isError: isError,
      time: moment().format('HH:mm:ss'),
      message: message
    }
    this.eventList.push(event);
  }

  showEventList = (eventList) => {
    this.setState({
      eventList: eventList,
      eventListIsOpen: true
    });
  }

  constructor(props) {
    super(props);
    this.state.env = getSelectedRegion();
    this.state.token = getToken();
    if (!this.state.token) {
      window.location.href = '/authorization';
      return;
    }
  }

  async componentDidMount() {
    this.loadItems();
  }

  loadItems = async () => {
    if (!this.props.getListMethod) {
      console.error('getListMethod doesn\'t exist');
      return;
    }
    await this.setState({ preloaderLocalShow: true });
    try {
      let itemList = await this.props.getListMethod(this.state.env, this.state.token);
      if (itemList === undefined) {
        itemList = [];
      }
      await this.setState({ itemList: itemList });
    } catch (err) {
      console.error(err);
    }
    await this.setState({ preloaderLocalShow: false });
  }

  addItem = async (newItemName) => {
    this.eventList = [];
    if (!this.props.addItemMethod) {
      console.error('addItemMethod doesn\'t exist');
      return;
    }
    await this.setState({ preloaderLocalShow: true });
    try {
      if (this.isItemAlreadyOnThelist(newItemName)) {
        this.logEvent(`[${newItemName}] already exists`, true);
        this.showEventList(this.eventList);
      } else {
        await this.props.addItemMethod(this.state.env, this.state.token, newItemName);
        let entity = (this.props.cardTitle !== 'Wrap-Up Codes') ? this.props.cardTitle : 'WrapUpCodes';
        await logActionData('Import', `Importing ${entity}`, 1, entity);
      }
    } catch (err) {
      console.error(err);
    }
    await this.setState({ preloaderLocalShow: false });
  }

  isItemAlreadyOnThelist = (name) => {
    for (const item of this.state.itemList) {
      if (item.name.toLowerCase() === name.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  addNewItemKeyPressed = async (e) => {
    if (e.key === 'Enter' && this.state.enteredNewItemName.trim() !== '') {
      const newItemName = this.state.enteredNewItemName.trim();
      if (!newItemName) { return; }
      await this.addItem(newItemName);
      await this.setState({ enteredNewItemName: '' });
      await this.loadItems();
    }
  }

  addNewItemChanged = async (data) => {
    await this.setState({ enteredNewItemName: data.target.value });
  }

  deleteItem = async (id) => {
    if (!this.props.deleteItemMethod) {
      console.error('deleteItemMethod doesn\'t exist');
      return;
    }
    var selectedList = this.state.selected;
    selectedList[id] = false;
    try {
      let resp = await this.props.deleteItemMethod(this.state.env, this.state.token, id);
      if (resp != null && resp.status != 200 && resp.contains('Scope')) {
        this.state.itemList.forEach(x => {
          if (x.id == id) {
            this.logEvent(`The Division - [${x.name}] cannot be deleted because there are objects associated with it; this item will be skipped`, true);
          }
        });
      }
    } catch (err) {
      console.error(err);
      selectedList[id] = false;
    }
    if ((Object.values(selectedList).find(el => el == true)) == undefined) {
      await this.setState({ selectAll: 0 })
    }
    await this.setState({ selected: selectedList });
  }

  deleteItemButtonClicked = async (id) => {
    await this.setState({ preloaderLocalShow: true });
    this.eventList = [];
    await this.deleteItem(id);
    await this.loadItems();
    if (this.eventList.length > 0) {
      this.showEventList(this.eventList);
    }
    await this.setState({ preloaderLocalShow: false });
  }

  importCsv = async (file) => {
    this.eventList = [];
    let aloneItems = [];
    let importCount = 0;
    if (!this.props.addItemMethod) {
      console.error('addItemMethod doesn\'t exist');
      return;
    }
    await this.setState({ preloaderLocalShow: true });
    const fileContent = await CsvReader.readFile(file);
    const fileListViewerItems = await CsvReader.parseCsvFileToSingleFieldData(fileContent);
    for (const item of fileListViewerItems) {
      this.logEvent(`Processing item [${item.item}]`);
      try {
        if (this.isItemAlreadyOnThelist(item.item)) {
          this.logEvent(`[${item.item}] already exists`, true);
        } else {
          await this.props.addItemMethod(this.state.env, this.state.token, item.item);
          importCount = importCount + 1;
          this.logEvent(`Item [${item.item}] successfully processed`);
        }
      } catch (err) {
        if (err.message.includes("Rate limit exceeded the maximum")) {
          this.logEvent(`Rate limit exceeded the maximum, will retry after few seconds `, true);
          await this.setState({ preloaderLocalShow: true });
          aloneItems.push(item);
          await this.sleep(25000);
        } else {
          this.logEvent(`${err.message}`, true);
        }
        console.log(err.message);
      }
    }

    if (aloneItems.length > 0) {
      console.log('Importing failed Items');
      await this.importfailedItems(this.eventList, aloneItems);
    } else {
      if (importCount > 0) {
        let entity = (this.props.cardTitle !== 'Wrap-Up Codes') ? this.props.cardTitle : 'WrapUpCodes';
        await logActionData('Import', `Importing ${entity}`, importCount, entity);
      }
      await this.loadItems();
      this.showEventList(this.eventList);
    }
  }

  importfailedItems = async (eventList, ListItems) => {
    let importCount = 0;
    for (const item of ListItems) {
      this.logEvent(`Processing item [${item.item}]`);
      try {
        if (this.isItemAlreadyOnThelist(item.item)) {
          this.logEvent(`[${item.item}] already exists`, true);
        } else {
          await this.props.addItemMethod(this.state.env, this.state.token, item.item);
          importCount = importCount + 1;
          this.logEvent(`Item [${item.item}] successfully processed`);
        }
      } catch (err) {
        this.logEvent(`${err.message}`, true);
      }
    }
    if (importCount > 0) {
      let entity = (this.props.cardTitle !== 'Wrap-Up Codes') ? this.props.cardTitle : 'WrapUpCodes';
      await logActionData('Import', `Importing ${entity}`, importCount, entity);
    }
    await this.loadItems();
    this.showEventList(eventList);
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  handleImportFile = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        e.persist();
        this.importCsv(e.target.files[0]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleDropFile = async (e) => {
    try {
      const file = e[0];
      if (!file || !file.name.toLowerCase().endsWith('.csv')) {
        console.error('Only CSV files are allowed');
        return;
      }
      if (file) {
        this.importCsv(file);
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleInformation = () => {
    this.setState({
      isInfoOpen: true,
    });
  }

  handleAddNew = () => {
    this.setState({ isaddNewModalOpen: true });
  }

  handleInputChange = () => (event) => {
    this.setState({ newValue: event.target.value })
  }

  AddNewItem = async () => {
    const newItemName = this.state.newValue.trim();
    if (!newItemName) { return; }
    await this.setState({ newValue: '', isaddNewModalOpen: false });
    await this.addItem(newItemName);
    await this.loadItems();
  }

  toggleRow = (id, e) => {
    if (e) { e.stopPropagation(); }
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[id] = !this.state.selected[id];
    let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
    this.setState({ selected: newSelected, selectAll: selectAllValue });
    console.log(newSelected);
  }

  deleteAllFunction = async () => {
    await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, text: `Removing Selected ${this.props.cardTitle} ....` });
    this.eventList = [];
    let selected = this.state.selected;
    for (let id of Object.keys(selected)) {
      if (selected[id] === true) {
        await this.deleteItem(id);
        await this.sleep(3000);
      }
    }
    await this.loadItems();
    if (this.eventList.length > 0) {
      this.showEventList(this.eventList);
    }
    await this.setState({ selectAll: 0, preloaderLocalShow: false })
  }

  removeAllClicked = async (e) => {
    if (e) { e.stopPropagation(); }
    if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
      this.setState({ isBulkDeleteModalOpen: true })
    }
  }

  toggleSelectAll = () => {
    let newSelected = {};
    if (this.state.selectAll === 0) {
      this.state.itemList.forEach(x => {
        newSelected[x.id] = true;
      });
    }
    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0
    });
  }

  clickedAreaDiv = (area) => {
    if (area != null) {
      if (area.name === '3') {
        this.props.navigateToHorizontalSlide('3');
      } else if (area.name === '1') {
        this.props.navigateToHorizontalSlide('1');
      } else if (area.name === '2') {
        this.props.navigateToHorizontalSlide('2');
      }
    }
  }

  clickedAreaSkills = (area) => {
    if (area != null) {
      if (area.name === '0') {
        this.props.navigateToHorizontalSlide('0');
      } else if (area.name === '2') {
        this.props.navigateToHorizontalSlide('2');
      } else if (area.name === '3') {
        this.props.navigateToHorizontalSlide('3');
      }
    }
  }

  clickedAreaWrapCodes = (area) => {
    if (area != null) {
      if (area.name === '0') {
        this.props.navigateToHorizontalSlide('0');
      } else if (area.name === '1') {
        this.props.navigateToHorizontalSlide('1');
      } else if (area.name === '3') {
        this.props.navigateToHorizontalSlide('3');
      }
    }
  }

  clickedAreaRoles = (area) => {
    if (area != null) {
      if (area.name === '0') {
        this.props.navigateToHorizontalSlide('0');
      } else if (area.name === '1') {
        this.props.navigateToHorizontalSlide('1');
      } else if (area.name === '2') {
        this.props.navigateToHorizontalSlide('2');
      }
    }
  }

  render() {
    let downloadTemplateLink = "";
    if (this.props.cardTitle === 'Divisions') {
      downloadTemplateLink = './templates/divisions-import-template.csv'
    } else if (this.props.cardTitle === 'Skills') {
      downloadTemplateLink = './templates/skills-import-template.csv'
    } else if (this.props.cardTitle === 'Wrap-Up Codes') {
      downloadTemplateLink = './templates/wrapup-codes-import-template.csv'
    } else if (this.props.cardTitle === 'Roles') {
      downloadTemplateLink = './templates/roles-import-template.csv'
    }

    let DIVMAP = {
      name: "div-map",
      areas: [
        // { name: "0", shape: "circle", coords: [29, 16, 15] },
        { name: "1", shape: "circle", coords: [107, 16, 15] },
        { name: "2", shape: "circle", coords: [184, 16, 15] },
        { name: "3", shape: "circle", coords: [264, 16, 15] }
      ]
    }

    let SKILLMAP = {
      name: "ski-map",
      areas: [
        { name: "0", shape: "circle", coords: [29, 16, 15] },
        // { name: "1", shape: "circle", coords: [107, 16, 15] },
        { name: "2", shape: "circle", coords: [184, 16, 15] },
        { name: "3", shape: "circle", coords: [264, 16, 15] }
      ]
    }
    let WrapMAP = {
      name: "wrap-map",
      areas: [
        { name: "0", shape: "circle", coords: [29, 16, 15] },
        { name: "1", shape: "circle", coords: [107, 16, 15] },
        // { name: "2", shape: "circle", coords: [184, 16, 15] },
        { name: "3", shape: "circle", coords: [264, 16, 15] }
      ]
    }
    let RoleMAP = {
      name: "role-map",
      areas: [
        { name: "0", shape: "circle", coords: [29, 16, 15] },
        { name: "1", shape: "circle", coords: [107, 16, 15] },
        { name: "2", shape: "circle", coords: [184, 16, 15] },
        // { name: "3", shape: "circle", coords: [264, 16, 15] }
      ]
    }

    return (
      <div className="orgCard">

        {/* <event list viewer> */}
        <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
        {/* </event list viewer>  */}

        {/* <Information viewer> */}
        <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
        {/* <Information viewer> */}

        <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

        {<AddNewModal isOpen={this.state.isaddNewModalOpen} newItemValue={this.state.newValue} Title={this.props.cardTitle}
          handleInputChange={this.handleInputChange()}
          AddNewItem={this.AddNewItem}
          closeFunction={() => { this.setState({ isaddNewModalOpen: false }) }} />}

        <Card className="mb-4 cardDesign">
          <CardBody className="p-3 cardBody1">
            <CardTitle className="m-0 OrgTitle">
              <div className="divOrg">
                <Badge className="titleBadge">{this.props.cardTitle}</Badge>
                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "2px" }} className="ml-1" title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                <sup className="supText">  Learn More</sup>
                <div className="ImageMapper">
                  {
                    this.props.cardTitle === 'Divisions' && <ImageMapper src="/images/pd.png" map={DIVMAP} width={300} onClick={area => this.clickedAreaDiv(area)} />
                  }
                  {
                    this.props.cardTitle === 'Skills' && <ImageMapper src="/images/ps.png" map={SKILLMAP} width={300} onClick={area => this.clickedAreaSkills(area)} />
                  }
                  {
                    this.props.cardTitle === 'Wrap-Up Codes' && <ImageMapper src="/images/pw.png" map={WrapMAP} width={300} onClick={area => this.clickedAreaWrapCodes(area)} />
                  }
                  {
                    this.props.cardTitle === 'Roles' && <ImageMapper src="/images/pr.png" map={RoleMAP} width={300} onClick={area => this.clickedAreaRoles(area)} />
                  }
                </div>
              </div>
            </CardTitle>
            <div style={{ paddingLeft: "12px", marginLeft: "-20px" }}>
              <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                A template .csv file can be downloaded <a style={{ color: "orangered" }} href={downloadTemplateLink} ><u>Here</u></a>
              </p>
            </div>
          </CardBody>
          <CardBody className="p-3 CardBody2">
            <div className="single-field-editor-card-wrap">
              <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.text} />
              <Row className="mt-3">
                <Col className="fileDropCol">
                  <div style={{ width: "20%" }}>
                    <Button className="AddButton" onClick={this.handleAddNew}><FaPlus />  Add New </Button>
                  </div>
                  <div style={{ width: "60%", paddingLeft: "25px" }}>
                    <DragAndDrop handleDrop={this.handleDropFile}>
                      <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                    </DragAndDrop>
                  </div>
                  <div className="BrowseButton">
                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                      <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                      <FaCloudUploadAlt /> Browse
                    </label>
                  </div>
                </Col>
              </Row>
              <div className="single-field-editor-table-wrap">
                <Table striped bordered className="table-condensed single-field-editor-table ">
                  <thead>
                    <tr className="rowHeaderOrg">
                      <th className="checkboxHeader"><input
                        type="checkbox"
                        checked={this.state.selectAll === 1}
                        disabled={this.state.itemList.length === 0 ? "disabled" : ""}
                        ref={input => {
                          if (input) {
                            input.indeterminate = this.state.selectAll === 2;
                          }
                        }}
                        onChange={() => this.toggleSelectAll()}
                      /></th>
                      <th style={{}}>Name</th>
                      {
                        this.state.selectAll != 0 ?
                          <th className="buttonRemove" onClick={(e) => { this.removeAllClicked() }}>Remove</th>
                          :
                          <th className="buttonRemoveDefault" onClick={(e) => { this.removeAllClicked() }}>Remove</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.itemList.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td> <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selected[item.id] === true}
                            onChange={(e) => this.toggleRow(item.id, e)}
                          /></td>
                          <td className="p-1">{item.name}</td>
                          <td className="p-1 single-field-editor-button-cell"><FaTrash onClick={() => { this.deleteItemButtonClicked(item.id) }} /></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    )
  }
}
