import { pureCloudLogout } from '../session/session';
import config from '../../config';
const backEndURL = config.apiEndpoint;
const architectScripting = window.__architectScripting__;
const archEnums = architectScripting.enums.archEnums;
const archSession = architectScripting.environment.archSession;
const archFactoryFlows = architectScripting.factories.archFactoryFlows;
const archLanguages = architectScripting.languages.archLanguages;
const menuFactory = architectScripting.factories.archFactoryMenus;
const scriptingLogger = architectScripting.services.archLogging;

export let archyResults = [];
scriptingLogger.setLoggingCallback((logInfo) => {
  switch (logInfo.logType) {
    case archEnums.LOG_TYPES.error:
      archyResults.push(logInfo.messageFull);
      break;
  }
  return true;
});

scriptingLogger.logNotesVerbose = true;


export const getArchitects = async (env, token, objectType) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = undefined;
    if (objectType === 'prompts') {
      respond = await architectGetPrompts(env, token, index);
    } else if (objectType === 'schedules') {
      respond = await architectGetSchedules(env, token, index);
    } else if (objectType === 'scheduleGroups') {
      respond = await architectGetScheduleGroups(env, token, index);
    } else if (objectType === 'ivrs') {
      respond = await architectGetIvrs(env, token, index);
    }
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

// <prompts>
export const architectGetPrompts = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/architect/prompts?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const architectGetPromptByName = (env, token, name) => {
  return fetch(`https://api.${env}/api/v2/architect/prompts?name=${name}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities[0];
      }
    })
}

export const architectPostPrompt = (env, token, name) => {
  return fetch(`https://api.${env}/api/v2/architect/prompts`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}

export const architectPostPromptResource = (env, token, lang, id) => {
  return fetch(`https://apps.${env}/platform/api/v2/architect/prompts/${id}/resources`, {
    method: 'POST',
    body: JSON.stringify({ language: lang }),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `bearer ${token}`
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}

export const architectUploadPromptAudio = (env, token, uri, file) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  return fetch(uri, {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': `bearer ${token}`
    }
  })
    .then(response => response.json())
}

export const uploadPrompts = (prompts, authToken, regionName, orgName, userMail) => {
  return fetch(backEndURL + `/questionaire/prompt`, {
    // content-type header should not be specified!
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-env': regionName,
      'authToken': authToken,
      'x-accel-org': orgName
    },
    body: JSON.stringify(prompts)
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response;
    })
}
// </prompts>

// <schedules>
export const architectGetSchedules = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/architect/schedules?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const architectPostSchedule = (env, token, name, start, end, rrule) => {

  var body = {
    'name': name,
    'start': start,
    'end': end,
    'rrule': rrule
  }

  return fetch(`https://api.${env}/api/v2/architect/schedules`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}
// </schedules>

// <schedule groups>

export const architectGetScheduleGroups = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/architect/schedulegroups?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}


export const architectPostScheduleGroup = (env, token, name, timeZone, openSchedules, closedSchedules, holidaySchedules) => {

  var body = {
    'name': name,
    'timeZone': timeZone,
    'openSchedules': openSchedules,
    'closedSchedules': closedSchedules,
    'holidaySchedules': holidaySchedules
  }

  return fetch(`https://api.${env}/api/v2/architect/schedulegroups`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}
// <schedule groups>

// <IVRs routing>
export const architectPostIvrs = (env, token, name, addresses, scheduleGroup, openHoursFlow, closedHoursFlow, holidayHoursFlow) => {

  var body = {
    'name': name,
    'dnis': addresses
  }

  if (scheduleGroup) { body.scheduleGroup = scheduleGroup; }
  if (openHoursFlow) { body.openHoursFlow = openHoursFlow; }
  if (closedHoursFlow) { body.closedHoursFlow = closedHoursFlow; }
  if (holidayHoursFlow) { body.holidayHoursFlow = holidayHoursFlow; }

  return fetch(`https://api.${env}/api/v2/architect/ivrs`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}

export const architectGetIvrs = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/architect/ivrs?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}
// </IVRs routing>

// <README - SCRIPTING SDK>
// Documentation:
// https://www.npmjs.com/package/purecloud-flow-scripting-api-sdk-javascript
// https://mypurecloud.github.io/purecloud-flow-scripting-api-sdk-javascript/
// </README - SCRIPTING SDK>

// <inbound flows>

const findLocation = (env) => {
  switch (env) {
    case 'mypurecloud.com': return archEnums.LOCATIONS.prod_us_east_1
    case 'mypurecloud.ie': return archEnums.LOCATIONS.prod_eu_west_1
    case 'mypurecloud.com.au': return archEnums.LOCATIONS.prod_ap_southeast_2
    case 'mypurecloud.jp': return archEnums.LOCATIONS.prod_ap_northeast_1
    case 'mypurecloud.de': return archEnums.LOCATIONS.prod_eu_central_1
    case 'apne2.pure.cloud': return archEnums.LOCATIONS.prod_ap_northeast_2
    case 'cac1.pure.cloud': return archEnums.LOCATIONS.prod_ca_central_1
    case 'euw2.pure.cloud': return archEnums.LOCATIONS.prod_eu_west_2
    case 'usw2.pure.cloud': return archEnums.LOCATIONS.prod_us_west_2
    case 'aps1.pure.cloud': return archEnums.LOCATIONS.prod_ap_south_1
    case 'sae1.pure.cloud': return archEnums.LOCATIONS.prod_sa_east_1
    case 'use2.us-gov-pure.cloud': return archEnums.LOCATIONS.prod_us_east_2
    default: return ''
  }
}
export const importInBoundCallFlow = (env, token, file, name) => {
  archyResults = [];
  let location = findLocation(env);
  console.log('importInBoundCallFlow.location:', location);
  return new Promise((resolve, reject) => {
    archSession.startWithAuthToken(location, () => {
      console.log('Session connected')
      return archFactoryFlows.createFlowInboundCallAsync(name, undefined, archLanguages.englishUnitedStates, (flow) => {
        return flow.importFromContentAsync(file)
          .then(() => { return flow.saveAsync() })
      })
    }, token, () => {
      console.log('Session ending')
      if (archSession.endExitCode === 0) resolve('Success')
      else reject('Error')
    })
  })
}
// </inbound flows>


// <in-queue flows>
export const importInQueueCallFlow = (env, token, file, name) => {
  archyResults = [];
  let location = findLocation(env);
  console.log('importInQueueCallFlow.location:', location);
  return new Promise((resolve, reject) => {
    archSession.startWithAuthToken(location, () => {
      console.log('Session connected')
      return archFactoryFlows.createFlowInQueueCallAsync(name, undefined, archLanguages.englishUnitedStates, (flow) => {
        return flow.importFromContentAsync(file)
          .then(() => { return flow.saveAsync() })
      })
    }, token, () => {
      console.log('Session ending')
      if (archSession.endExitCode === 0) resolve('Success')
      else reject('Error')
    })
  })
}

// </in-queue flows>

// <inboundChat flows>
export const importInboundChatFlow = (env, token, file, name) => {
  archyResults = [];
  let location = findLocation(env);
  console.log('importInboundChatFlow.location:', location);
  return new Promise((resolve, reject) => {
    archSession.startWithAuthToken(location, () => {
      console.log('Session connected')
      return archFactoryFlows.createFlowInboundChatAsync(name, undefined, archLanguages.englishUnitedStates, (flow) => {
        return flow.importFromContentAsync(file)
          .then(() => { return flow.saveAsync() })
      })
    }, token, () => {
      console.log('Session ending')
      if (archSession.endExitCode === 0) resolve('Success')
      else reject('Error')
    })
  })
}
// </inboundChat flows>

// <inboundEmail flows>
export const importInboundEmailFlow = (env, token, file, name) => {
  archyResults = [];
  let location = findLocation(env);
  console.log('importInboundEmailFlow.location:', location);
  return new Promise((resolve, reject) => {
    archSession.startWithAuthToken(location, () => {
      console.log('Session connected')
      return archFactoryFlows.createFlowInboundEmailAsync(name, undefined, archLanguages.englishUnitedStates, (flow) => {
        return flow.importFromContentAsync(file)
          .then(() => { return flow.saveAsync() })
      })
    }, token, () => {
      console.log('Session ending')
      if (archSession.endExitCode === 0) resolve('Success')
      else reject('Error')
    })
  })
}
// </inboundEmail flows>

// <outbound flows>
export const importOutboundFlow = (env, token, file, name) => {
  archyResults = [];
  let location = findLocation(env);
  console.log('importOutboundFlow.location:', location);
  return new Promise((resolve, reject) => {
    archSession.startWithAuthToken(location, () => {
      console.log('Session connected')
      return archFactoryFlows.createFlowOutboundCallAsync(name, undefined, archLanguages.englishUnitedStates, (flow) => {
        return flow.importFromContentAsync(file)
          .then(() => { return flow.saveAsync() })
      })
    }, token, () => {
      console.log('Session ending')
      if (archSession.endExitCode === 0) resolve('Success')
      else reject('Error')
    })
  })
}
// </outbound flows>

// <import from YAML>

export const createInboundCallFlow = async (env, token, flowName, nodes) => {
  archyResults = [];
  let location = findLocation(env);
  console.log('createInboundCallFlow.location:', location);
  return new Promise((resolve, reject) => {
    archSession.startWithAuthToken(location, () => {
      console.log('Session connected')
      return archFactoryFlows.createFlowInboundCallAsync(flowName, undefined, archLanguages.englishUnitedStates, (flow) => {
        var mainMenu = menuFactory.addMenu(flow, 'Main menu', '', true);
        nodes.map((node) => {
          if (node.behaviour === 'Transfer to Queue') {
            var acdMenu = menuFactory.addMenuTransferToAcd(mainMenu, node.behaviour, node.dtmf, '');
            acdMenu.actionTransferToAcd.setLiteralByQueueNameAsync(node.queueName);
            if (node.skillName && node.skillName !== '') {
              acdMenu.actionTransferToAcd.skills.addLiteralBySkillNameAsync(node.skillName);
            }              
          } else if (node.behaviour === 'Repeat Menu') {
            menuFactory.addMenuRepeatMenu(mainMenu, node.behaviour, node.dtmf);
          } else if (node.behaviour === 'Play a Message and Disconnect') {
            var subMenu = menuFactory.addMenuSubMenu(mainMenu, node.behaviour, node.dtmf, '');
            subMenu.audio.setDefaultCaseLiteralTTS(node.prompt);
          }
        })
        flow.saveAsync();
      })
    }, token, () => {
      console.log('Session ending')
      if (archSession.endExitCode === 0) resolve('Success')
      else reject('Error')
    })
  })
}
// </import from YAML>