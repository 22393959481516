import './orgInformation.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Row, Col } from 'reactstrap'

export default class OrgInformation extends React.Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen}>
                    <ModalHeader>Organization Information</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <label>User Name:</label>
                            </Col>
                            <Col>
                                <label>{this.props.userName}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Region:</label>
                            </Col>
                            <Col>
                                <label>{this.props.regionName}</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label>Organization:</label>
                            </Col>
                            <Col>
                                <label>{this.props.orgName}</label>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.moreFunction}>More</Button>
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}