import { pureCloudLogout } from '../session/session'

export const getlocations = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await locationsGetLocations(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const locationsGetLocations = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/locations?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const locationsPostLocations = (env, token, name, street1, street2, city, state, zip, countryAbbreviation, country, notes, emergencyNumber) => {

    var body = {
        "name": name,
        "notes": notes,
        "address": {
            "city": city,
            "country": countryAbbreviation,
            "countryName": country,
            "state": state,
            "street1": street1,
            "street2": street2,
            "zipcode": zip
        },
        "addressVerified": true,
        "emergencyNumber": {
            "e164": "",
            "number": emergencyNumber,
            "type": "default"
        },
        "state": "",
        "version": 1,
        "path": []
    }

    return fetch(`https://api.${env}/api/v2/locations`, {
        method: 'POST',
        headers: {
            'Authorization': `bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    })
        .then(response => response.json())
        .then(response => {
            if (response.status < 200 || response.status > 299) throw new Error(response.message)
            return response;
        })
}

