import { pureCloudLogout } from '../session/session';
import config from '../../config';
const backEndURL = config.apiEndpoint;

export const createContactLists = (lists, env, userMail, authToken, url, orgName) => {
  return fetch(backEndURL + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    },
    body: lists
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response;
    })
}

export const uploadContactLists = (file, env, userMail, authToken, url, orgName) => {
  return fetch(backEndURL + url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    },
    body: file
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response;
    })
}

export const postWrapUpCode = (env, token, name) => {
  return fetch(`https://api.${env}/api/v2/routing/wrapupcodes`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response;
    })
}

export const getQueueWrapUpCodeMappings = (env, token) => {
  return fetch(`https://api.${env}/api/v2/outbound/wrapupcodemappings`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response
    })
};

export const putWrapUpCodeMappings = (env, token, mappings) => {
  return fetch(`https://api.${env}/api/v2/outbound/wrapupcodemappings`, {
    method: 'PUT',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(mappings)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status < 200 || response.status > 299) return response;
      return response;
    })
}

export const createCampaign = (campaign, env, userMail, authToken, orgName) => {
  return fetch(backEndURL + `/outbound/campaigns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    },
    body: JSON.stringify(campaign)
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) return response;
        return response;
      }
      return response;
    })
}

export const createMessagingCampaign = (campaign, env, userMail, authToken, orgName) => {
  return fetch(backEndURL + `/outbound/messagecampaigns`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    },
    body: JSON.stringify(campaign)
  })
  .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) return response;
        return response;
      }
      return response;
    })
}

export const getEdgeGroups = (env, userMail, authToken, orgName) => {
  return fetch(backEndURL + `/outbound/edgegroups`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response
    })
};

export const getSmsPhoneNumbers = (env, userMail, authToken, orgName) => {
  return fetch(backEndURL + `/routing/sms/phonenumbers`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response
    })
};

export const getOutboundFlows = (env, userMail, authToken, orgName) => {
  return fetch(backEndURL + `/routing/flows/divisionviews`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response
    })
};

export const getAgentScripts = (env , userMail, authToken, orgName) => {
  return fetch(backEndURL + `/outbound/scripts`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response
    })
};

export const getCallResponseSets = (env , userMail, authToken, orgName) => {
  return fetch(backEndURL + `/outbound/callanalysisresponsesets`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        return response;
      }
      return response
    })
};

export const postAgentScripts = (scriptName, env, userMail, authToken, orgName) => {
  return fetch(backEndURL + `/outbound/scripts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
      'x-accel-org': orgName,
      'authToken': authToken,
      'x-accel-env': env,
    },
    body: JSON.stringify(scriptName)
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) return response;
        return response;
      }
      return response;
    })
}

export const publishAgentScripts = (scriptId, env, userMail, authToken, orgName) => {
    return fetch(backEndURL + `/outbound/publish/scripts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-accel-user': userMail,
        'x-accel-org': orgName,
        'authToken': authToken,
        'x-accel-env': env,
      },
      body: JSON.stringify({id : scriptId})
    })
      .then(response => response.json())
      .then(response => {
        if (response) {
          if (response.status < 200 || response.status > 299) return response;
          return response;
        }
        return response;
      })
  }


  export const postCallResponseAnalysisSets = (body, env, userMail, authToken, orgName) => {
    return fetch(backEndURL + `/outbound/callanalysisresponsesets`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-accel-user': userMail,
        'x-accel-org': orgName,
        'authToken': authToken,
        'x-accel-env': env,
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        if (response) {
          if (response.status < 200 || response.status > 299) return response;
          return response;
        }
        return response;
      })
  }

  export const postOutboundFlow = (body, env, userMail, authToken, orgName) => {
    return fetch(backEndURL + `/routing/flows`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-accel-user': userMail,
        'x-accel-org': orgName,
        'authToken': authToken,
        'x-accel-env': env,
      },
      body: JSON.stringify(body)
    })
      .then(response => response.json())
      .then(response => {
        if (response) {
          if (response.status < 200 || response.status > 299) return response;
          return response;
        }
        return response;
      })
  }

  export const createWidgets = (body, env, userMail, authToken, orgName) => {
    return fetch(backEndURL + `/outbound/widgets/deployments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-accel-user': userMail,
        'x-accel-org': orgName,
        'authToken': authToken,
        'x-accel-env': env,
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
      .then(response => {
        if (response) {
          if (response.status < 200 || response.status > 299) return response;
          return response;
        }
        return response;
      })
  }