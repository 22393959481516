import './archyModal.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap'
import { FaExclamation } from 'react-icons/fa';

export default class ArchyModal extends React.Component {
  render() {
    return (
      <div>        
        <Modal isOpen={this.props.isOpen}>
          <ModalHeader><FaExclamation/> Warning</ModalHeader>
          <ModalBody>
            Are you sure you are ready to import the <strong>{this.props.callFlow}</strong> into the <strong>{this.props.orgName.name}</strong> without a designated substitution file?
          </ModalBody>
          <ModalFooter>            
            <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
            <Button style={{backgroundColor:"#061e45" }} onClick={this.props.publishFunction}>Publish</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}