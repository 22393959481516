import './edgeGroups.css'
import React from 'react'
import { FaObjectGroup, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import { telephonyGetTrunkBaseSettings, telephonyPostEdgeGroups } from '../../../../services/purecloud/telephony';
import EdgeGroupsInfo from '../EdgeGroups/edgeGroupsInfo';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const edgeGroupsDataFields = [
    'name',
    'description',
    'managed',
    'phoneTrunkBaseName'
];

export default class EdgeGroups extends React.Component {

    state = {
        env: '',
        token: '',
        edgeGroupsList: [],
        isEdgeGroupsInfoOpen: false,
        edgeGroupName: '',
        description: '',
        managed: '',
        phoneTrunkBaseName: '',
        rowID: null,
        trunkBaseSettingsList: [],
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.state.trunkBaseSettingsList = await telephonyGetTrunkBaseSettings(this.state.env, this.state.token);
        await this.setState({ preloaderLocalShow: false });
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var edgeGroupsList = [...this.state.edgeGroupsList];
        var selectedList = this.state.selected;
        for (const i in edgeGroupsList) {
            if (edgeGroupsList[i].rowid === rowid) {
                edgeGroupsList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ edgeGroupsList: edgeGroupsList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isEdgeGroupsInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            edgeGroupName: '',
            description: '',
            managed: '',
            phoneTrunkBaseName: '',
            rowID: null,
        })
    }

    handleSubmitFunction = () => {
        let edgeGroupsList = [...this.state.edgeGroupsList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in edgeGroupsDataFields) {
            if (edgeGroupsDataFields[i] === 'name') {
                newRow[edgeGroupsDataFields[i]] = this.state.edgeGroupName;
            } else if (edgeGroupsDataFields[i] === 'description') {
                newRow[edgeGroupsDataFields[i]] = this.state.description;
            } else if (edgeGroupsDataFields[i] === 'managed') {
                newRow[edgeGroupsDataFields[i]] = this.state.managed;
            } else if (edgeGroupsDataFields[i] === 'phoneTrunkBaseName') {
                newRow[edgeGroupsDataFields[i]] = this.state.phoneTrunkBaseName;
            }
        }

        edgeGroupsList.push(newRow);

        this.setState({
            isEdgeGroupsInfoOpen: false,
            edgeGroupsList: edgeGroupsList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        this.setState({
            [fieldName]: event.target.value
        });
    }

    updateState = (rowInfo) => {
        this.setState({
            edgeGroupName: rowInfo.name,
            description: rowInfo.description,
            managed: rowInfo.managed.toLowerCase(),
            phoneTrunkBaseName: rowInfo.phoneTrunkBaseName,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isEdgeGroupsInfoOpen: true,
        });
    }

    updateFunction = () => {
        let edgeGroupsList = [...this.state.edgeGroupsList];
        for (let edge of edgeGroupsList) {
            if (edge.rowid === this.state.rowID) {
                edge.name = this.state.edgeGroupName;
                edge.description = this.state.description;
                edge.managed = this.state.managed.toLowerCase();
                edge.phoneTrunkBaseName = this.state.phoneTrunkBaseName;
            }
        }

        this.setState({
            edgeGroupsList: edgeGroupsList,
            isEdgeGroupsInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const edgeGroupData = await CsvReader.parseCsvFileToEdgeGroupsData(fileContent);
        await this.setState({ edgeGroupsList: [...this.state.edgeGroupsList, ...edgeGroupData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importEdgeGroups = async () => {
        this.eventList = [];
        const edgeGroupsList = this.state.edgeGroupsList;
        for (const i in edgeGroupsList) {
            const edgeGroupData = edgeGroupsList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing edge group [${edgeGroupData.name}]`);
            if (await this.dataValidated(edgeGroupData, i, edgeGroupsList)) {
                try {
                    // <create edge group>
                    const phoneTrunk = this.getTrunkBaseSettingsByName(edgeGroupData.phoneTrunkBaseName);
                    const edgeGroup = await telephonyPostEdgeGroups(this.state.env, this.state.token, edgeGroupData.name.toLowerCase(), edgeGroupData.description, edgeGroupData.managed.toLowerCase(), phoneTrunk.id, edgeGroupData.phoneTrunkBaseName);
                    console.log(`edge group created: ${JSON.stringify(edgeGroup)}`);
                    // </create edge group>
                    this.logEvent(`Edge group [${edgeGroupData.name}] successfully processed`);
                    this.removeRowClicked(edgeGroupData.rowid);
                } catch (err) {
                    console.log(`${err.message}`);
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Edge Groups`, count, 'EdgeGroups');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    getTrunkBaseSettingsByName = (name) => {
        const trunkBaseSettings = this.state.trunkBaseSettingsList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (trunkBaseSettings) { return trunkBaseSettings; }
    }

    dataValidated = async (edgeGroup, i, edgeGroupsData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!edgeGroup.name || !edgeGroup.name.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!edgeGroup.description || !edgeGroup.description.trim()) { this.logEvent(`[Index ${i}] Description can't be empty.`, true); isValidated = false; }
        if (!edgeGroup.phoneTrunkBaseName || !edgeGroup.phoneTrunkBaseName.trim()) { this.logEvent(`[Index ${i}] Phone Trunk Base Name can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if trunk base settings exist>
        var trunkBaseSettingsValid = false;
        for (const trunkBaseSettings of this.state.trunkBaseSettingsList) {
            if (edgeGroup.phoneTrunkBaseName.toLowerCase() === trunkBaseSettings.name.toLowerCase()) {
                trunkBaseSettingsValid = true;
            }
        }
        if (!trunkBaseSettingsValid) { this.logEvent(`[Index ${i}] Phone Trunk '${edgeGroup.phoneTrunkBaseName}' doesn't exist`, true); isValidated = false; }
        // <//validate if trunk base settings exist>

        // <validate if edge group anem is unique>
        for (const j in edgeGroupsData) {
            const edgeGroup1 = edgeGroupsData[j];
            if (i === j) { continue; }
            if (edgeGroup.name.trim().toLowerCase() === edgeGroup1.name.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if edge group name is unique>

        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.edgeGroupsList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Edge Groups...."  });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            } else if (area.name === '1') {
                this.props.navigateToHorizontalSlide('1');
            } else if (area.name === '3') {
                this.props.navigateToHorizontalSlide('3');
            } else if (area.name === '4') {
                this.props.navigateToHorizontalSlide('4');
            }
        }
    }

    render() {
        let MAP = {
            name: "eg-map",
            areas: [
                { name: "0", shape: "circle", coords: [23, 13, 12] },
                { name: "1", shape: "circle", coords: [85.5, 13, 12] },
                // { name: "2", shape: "circle", coords: [148, 13, 12] },
                { name: "3", shape: "circle", coords: [210, 13, 12] },
                { name: "4", shape: "circle", coords: [273, 13, 12] }
            ]
        }
        return (
            <div className="edgeGroupsCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <EdgeGroupsInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isEdgeGroupsInfoOpen}
                    edgeGroupName={this.state.edgeGroupName}
                    description={this.state.description}
                    managed={this.state.managed}
                    phoneTrunkBaseName={this.state.phoneTrunkBaseName}
                    isSaveLocale={this.state.isSaveLocale}
                    trunkBaseSettingsList={this.state.trunkBaseSettingsList}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isEdgeGroupsInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 edgeGroupsCardBody">
                        <CardTitle className="m-0 edgeGroupsTitle">
                            <div className="divEdges">
                                <Badge className="titleBadgeEdgeGroups">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pEdge.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/edgegroups-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyEdgeGroups">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonEdgeGroups" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonEdgeGroups">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage}/>
                            <div className="edgeGroups-wrap">
                                <ReactTable
                                    data={this.state.edgeGroupsList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.edgeGroupsList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No EdgeGroups loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonEdgeGroups" onClick={this.importEdgeGroups} disabled={this.state.edgeGroupsList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
