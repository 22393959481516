import './queuesInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

export default class QueuesInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        let isacwinValid = false;
        if ((this.props.acwType === 'MANDATORY_TIMEOUT' || this.props.acwType === 'MANDATORY_FORCED_TIMEOUT') && this.props.acwTimeout === '') {
            isacwinValid = true;
        }
        if (this.props.queueName !== '' && !isacwinValid) {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="queuesModal">
                    <ModalHeader>Queue Setup</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="queueName">Queue Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.queueName === '' ? true : false} value={this.props.queueName} onChange={this.props.handleInputChange('queueName')} id="queueName" type="text" placeholder="Enter the name of the Queue" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="divisons">Divisions:</Label>
                                <Col sm={10}>
                                    <select className="form-control selectpicker" value={this.props.selectedDivision} onChange={this.props.handleInputChange('selectedDivision')}>
                                        <option key="D1" disabled>Select a Division</option>
                                        {
                                            this.props.divisionList.map((division) => (
                                                <option key={division.id}>{division.name}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>ACW Type:</Label>
                                        <select className="form-control selectpicker" value={this.props.acwType} onChange={this.props.handleInputChange('acwType')}>
                                            <option key="L1">None Selected</option>
                                            <option key="L2">MANDATORY</option>
                                            <option key="L3">OPTIONAL</option>
                                            <option key="L4">MANDATORY_TIMEOUT</option>
                                            <option key="L5">MANDATORY_FORCED_TIMEOUT</option>
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="acwTimeout">ACW Timeout:</Label>
                                        <Input disabled={this.props.isacwTimeOut} invalid={isacwinValid} value={this.props.acwTimeout} onChange={this.props.handleInputChange('acwTimeout')} type="number" id="acwTimeout" placeholder="ACW Timeout" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Evaluation Method:</Label>
                                        <select className="form-control selectpicker" value={this.props.evaluationMethod} onChange={this.props.handleInputChange('evaluationMethod')}>
                                            <option key="E1">None Selected</option>
                                            <option key="E2">NONE</option>
                                            <option key="E3">BEST</option>
                                            <option key="E4">ALL</option>
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="acwTimeout">Alerting Timeout:</Label>
                                        <Input value={this.props.alertingTimeout} onChange={this.props.handleInputChange('alertingTimeout')} type="number" id="alertingTimeout" placeholder="Alerting Timeout" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="slPercentage">Service Level Percentage:</Label>
                                        <Input value={this.props.slPercentage} onChange={this.props.handleInputChange('slPercentage')} type="number" id="slPercentage" placeholder="SL Timeout" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="slDuration">Service Level Target:</Label>
                                        <Input value={this.props.slDuration} onChange={this.props.handleInputChange('slDuration')} type="number" id="slDuration" placeholder="SL Duration in Seconds" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="callingPartyName">Calling Party Name:</Label>
                                        <Input value={this.props.callingPartyName} onChange={this.props.handleInputChange('callingPartyName')} type="text" id="callingPartyName" placeholder="Calling Party Name" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="callingPartyNumber">Calling Party Number:</Label>
                                        <Input value={this.props.callingPartyNumber} onChange={this.props.handleInputChange('callingPartyNumber')} type="text" id="callingPartyNumber" placeholder="Calling Party Number" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup row>
                                <Label title="Select entry & Cntrl key for multiple selections" sm={2} for="wrapUpCodes">WrapUp Code:</Label>
                                <Col sm={10}>
                                    <select multiple title="Select entry & Cntrl key for multiple selections" className="form-control selectpicker" value={this.props.wrapUpCodes} onChange={this.props.handleInputChange('wrapUpCodes')}>
                                        <option key="W1" selected disabled>None Selected</option>
                                        {
                                            this.props.wrapUpCodeList.map((wrapUp) => (
                                                <option key={wrapUp.id}>{wrapUp.name}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
