import './logout.css'
import React from 'react'
import AppHeader from '../header/appHeader';
import { getSelectedRegion } from '../../global/localStorage'

import {
    Container,
    Card,
    CardHeader,
    CardBody
} from 'reactstrap'

class Logout extends React.Component {
    state = {
        logoutPageUrl: 'about:blank',        
    }

    constructor(props) {
        super(props);
        this.setLogoutUrl();
    }
    
    setLogoutUrl = async () => {
        sessionStorage.removeItem('purecloud_token');
        sessionStorage.removeItem('purecloud_user');
        sessionStorage.removeItem('purecloud_org');  
        sessionStorage.removeItem('session_id');  
        const env = getSelectedRegion();
        this.state.logoutPageUrl = `https://login.${env}/logout`;
        console.log(this.state.logoutPageUrl);
    }

    iframeOnLoad = (data) => {
        window.location.href = '/authorization';
    }

    render() {        
        return (
            <div>
                <AppHeader />                 
                <Container>
                    <Card className="border-secondary mb-3">
                        <CardHeader>Logout from GenesysCloud</CardHeader>
                        <CardBody className="text-secondary purecloud-login-card">
                            <iframe title="GenesysCloud" className="purecloud-login-iframe" src={this.state.logoutPageUrl} onLoad={this.iframeOnLoad}>
                            </iframe>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Logout