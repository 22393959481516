import styles from './Home.css';
import React from 'react';
import ReactPageScroller from 'react-page-scroller';

import AppHeader from '../header/appHeader';
import AppBottom from '../header/appBottom';
import { getSelectedRegion } from '../../global/localStorage';
import { getToken, addLoginSession } from '../../services/session/session';
import Cardbar from '../cardbar/cardbar';
import ItemsPage from '../itemsPage/itemsPage';

class Home extends React.Component {

  state = {
    env: '',
    token: '',
    isLogged: false,
    selectedPage: 'org-info',
    currentPage: 0,
    selectedCardItem: null,
    currentSlide: 0,
    finishedCardList: [],
    active: {
      Fullpage: 0,
      horizontalSlider1: 0
    },
    blockIt: true,
  }

  constructor(props) {
    super(props);
    try {
      let sessionId = sessionStorage.getItem('session_id');
      this.state.env = getSelectedRegion();
      this.state.token = getToken();
      if (!this.state.token) {
        window.location.href = '/authorization';
        return;
      }
      this.state.isLogged = true;
      if(this.state.isLogged && sessionId === null){
        addLoginSession('Login','User has logged in');
      }
    } catch (error) {
      console.error(error);
      window.location.href = '/authorization';
    }
  }

  handlePageChange = number => {
    this.setState({ currentPage: number });
  };

  handleOnCardChange = (cardItem) => {
    this.handleScrollBlock(false);
    this.setState({ currentPage: 1, selectedCardItem: cardItem });
    this.handleScrollBlock(true);
  }

  handleUpdateSliderState = (updatedState) => {
    this.setState({ active: updatedState });
  }

  navigateToCards = async (cardName) => {
    this.handleScrollBlock(false);
    let cardList = this.state.finishedCardList;
    if (!cardList.includes(cardName)) {
      cardList.push(cardName);
    }
    this.setState({ currentPage: 0, finishedCardList: cardList });
    this.handleScrollBlock(true);
  }

  handleScrollBlock = (value) => {
    this.setState({
      blockIt: value,
    });
  }

  render() {

    if (this.state.isLogged) {
      return (
        <div className={styles}>
          <AppHeader modeProp="authorized" history={this.props.history} currPage={this.state.currentPage} cardItem={this.state.selectedCardItem} currentSlide={this.state.active['horizontalSlider1']} />
          <ReactPageScroller
            onBeforePageScroll={this.handleBeforePageScroll}
            customPageNumber={this.state.currentPage}
            pageOnChange={this.handlePageChange}
            blockScrollUp={this.state.blockIt}
            blockScrollDown={this.state.blockIt}
            animationTimer={1000}
          >
            <Cardbar cardOnSelect={this.handleOnCardChange} finishedCards={this.state.finishedCardList} />
            <ItemsPage cardItem={this.state.selectedCardItem} active={this.state.active} updateSliderState={this.handleUpdateSliderState} navigateToCards={this.navigateToCards} />
          </ReactPageScroller>
            {/* Remove AppBottom Contents */}
            {/* <AppBottom currPage={this.state.currentPage} cardItem={this.state.selectedCardItem} currentSlide={this.state.active['horizontalSlider1']} /> */}
        </div>
      )
    } else {
      return (
        <div>
          <AppHeader />
        </div>
      )
    }
  }
}

export default Home