import './widgets.css'
import React from 'react'
import { FaPlus, FaTrash, FaInfoCircle, FaCloudUploadAlt } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData, getUserMe, getOrgMe } from '../../../../services/session/session';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import ImageMapper from 'react-image-mapper';
import WidgetsInfo from './widgetsInfo';
import DomainInfo from './domainInfo';
import { getInboundChatflows } from '../../../../services/purecloud/flows'
import { createWidgets } from '../../../../services/purecloud/outbound'
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';

const uuidv1 = require('uuid/v1');
const widgetDataFields = [
    'Name',
    'Description',
    'AuthenticationRequired',
    'WidgetType',
    'Flow',
    'AllowedDomains',
    'WebChatSkin',
    'AuthenticationUrl'
];

export default class Widgets extends React.Component {

    state = {
        env: '',
        token: '',
        userMe: {},
        orgMe: {},
        preloaderLocalShow: false,
        preloaderMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false,
        isInfoOpen: false,
        widgetList: [],
        isWidgetsInfoOpen: false,
        widgetsName: '',
        description: '',
        selectedWidgetsType: '',
        domainInfoOpen: false,
        domainName: '',
        isSaveLocale: false,
        lastUsedDomain: '',
        domains: [],
        widgetList: [],
        rowID: null,
        isDomainSaveLocale: false,
        inboundChatFlowList: [],
        selectedFlow: '',
        requireAuth: false,
        authUrl: '',
        selectedOption: 'Modern'
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount = async () => {
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Loading' });
        await this.loadUser();
        await this.loadItems();
        await this.setState({ preloaderLocalShow: false });
    }

    loadUser = async () => {
        try {
            const userMe = await getUserMe();
            const orgMe = await getOrgMe();
            await this.setState({ userMe: userMe });
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            throw error;
        }
    }

    loadItems = async () => {
        this.state.inboundChatFlowList = await getInboundChatflows(this.state.env, this.state.token);
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }


    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            } else if (area.name === '1') {
                this.props.navigateToHorizontalSlide('1');
            } else if (area.name === '2') {
                this.props.navigateToHorizontalSlide('2');
            }
        }
    }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({ isWidgetsInfoOpen: true })
    }

    clearLocaleInfo = () => {
        this.setState({
            widgetsName: '',
            description: '',
            selectedWidgetsType: '',
            lastUsedDomain: '',
            domains: [],
            rowID: null,
            domainName: '',
            selectedFlow: '',
            authUrl: '',
            requireAuth: false,
            selectedOption: 'Modern'
        })
    }

    handleInputChange = (fieldName) => (event) => {
        this.setState({
            [fieldName]: event.target.value
        });
    }

    addDomains = () => {
        this.setState({
            domainInfoOpen: true,
            isSaveLocale: false,
            domainName: '',
            lastUsedDomain: '',
        })
    }

    handleRowClick = (domain) => {
        this.setState({
            domainName: domain,
            lastUsedDomain: domain,
            domainInfoOpen: true,
            isSaveLocale: true,
        })
    }

    addDomainFunction = async () => {
        let domainList = this.state.domains;
        domainList.push(this.state.domainName);
        this.setState({
            domainInfoOpen: false,
            domains: domainList
        });
    }

    updateDomainFunction = async () => {
        let result = this.state.domains.filter(item => item !== this.state.lastUsedDomain);
        result.push(this.state.domainName);
        this.setState({
            domainInfoOpen: false,
            domains: result
        });
    }

    deleteButtonClicked = async (domain, e) => {
        if (e) {
            e.stopPropagation();
        }
        let result = this.state.domains.filter(item => item !== domain);
        this.setState({
            domains: result
        });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const widgetData = await CsvReader.parseCsvFileToWidgetsData(fileContent);
        await this.setState({ widgetList: [...this.state.widgetList, ...widgetData], preloaderLocalShow: false });
    }


    submitFunction = () => {
        let widgetList = [...this.state.widgetList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in widgetDataFields) {
            if (widgetDataFields[i] === 'Name') {
                newRow[widgetDataFields[i]] = this.state.widgetsName;
            } else if (widgetDataFields[i] === 'Description') {
                newRow[widgetDataFields[i]] = this.state.description;
            } else if (widgetDataFields[i] === 'WidgetType') {
                newRow[widgetDataFields[i]] = this.state.selectedWidgetsType;
            } else if (widgetDataFields[i] === 'AllowedDomains') {
                newRow[widgetDataFields[i]] = this.state.domains;
            } else if (widgetDataFields[i] === 'Flow') {
                newRow[widgetDataFields[i]] = this.state.selectedFlow;
            } else if (widgetDataFields[i] === 'AuthenticationRequired') {
                newRow[widgetDataFields[i]] = this.state.requireAuth;
            } else if (widgetDataFields[i] === 'WebChatSkin') {
                newRow[widgetDataFields[i]] = this.state.selectedOption;
            } else if (widgetDataFields[i] === 'AuthenticationUrl') {
                newRow[widgetDataFields[i]] = this.state.authUrl;
            }
        }

        widgetList.push(newRow);

        this.setState({
            isWidgetsInfoOpen: false,
            widgetList: widgetList,
        });
    }

    updateFunction = () => {
        let widgetList = [...this.state.widgetList];
        for (let widget of widgetList) {
            if (widget.rowid === this.state.rowID) {
                widget.Name = this.state.widgetsName;
                widget.Description = this.state.description;
                widget.WidgetType = this.state.selectedWidgetsType;
                widget.AllowedDomains = this.state.domains;
                widget.Flow = this.state.selectedFlow;
                widget.AuthenticationRequired = this.state.requireAuth;
                widget.WebChatSkin = this.state.selectedOption;
                widget.AuthenticationUrl = this.state.authUrl;
            }
        }
        this.setState({
            widgetList: widgetList,
            isWidgetsInfoOpen: false,
        })
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    updateState = async (rowInfo) => {
        await this.setState({
            widgetsName: rowInfo.Name,
            description: rowInfo.Description,
            selectedWidgetsType: rowInfo.WidgetType,
            domains: rowInfo.AllowedDomains,
            selectedFlow: rowInfo.Flow,
            requireAuth: rowInfo.AuthenticationRequired,
            selectedOption: rowInfo.WebChatSkin,
            authUrl: rowInfo.AuthenticationUrl,
            rowID: rowInfo.rowid,
            isDomainSaveLocale: true,
            isWidgetsInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.widgetList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderMessage: "Deleting Selected Widgets...." });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderMessage: 'Loading' })
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var widgetList = [...this.state.widgetList];
        var selectedList = this.state.selected;
        for (const i in widgetList) {
            if (widgetList[i].rowid === rowid) {
                widgetList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ widgetList: widgetList, selected: selectedList });
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    importWidgets = async () => {
        this.eventList = [];
        const widget = this.state.widgetList;
        for (const i in widget) {
            const widgetData = widget[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing Widget [${widgetData.Name}]`);

            let body = {};
            body.name = widgetData.Name;
            body.description = widgetData.Description;
            body.authenticationRequired = widgetData.AuthenticationRequired;
            body.disabled = false;
            body.enabled = true;
            body.isDefaultDeployment = false;

            body.isV1ClientType = widgetData.WidgetType == 'Version 1.0' ? true : false;
            body.isV1HttpClientType = widgetData.WidgetType == 'Version 1.1' ? true : false;
            body.isV2ClientType = widgetData.WidgetType == 'Version 2' ? true : false;
            body.isThirdPartyClientType = widgetData.WidgetType == 'Third Party' ? true : false;

            if (widgetData.Flow != '') {
                let flow = this.state.inboundChatFlowList.find(el => el.name == widgetData.Flow);
                if (flow != undefined) {
                    body.flow = { "id": flow.id, "name": flow.name }
                }
            }

            if (widgetData.WidgetType == 'Version 1.0') {
                body.clientType = 'v1';
                body.clientConfig = { "v1": { "webChatSkin": widgetData.WebChatSkin == 'Modern' ? "modern-caret-skin" : "basic", "authenticationUrl": widgetData.AuthenticationUrl }, "v1Http": null }
            } else if (widgetData.WidgetType == 'Version 1.1') {
                body.clientType = 'v1-http';
                body.clientConfig = { "v1": null, "v1Http": { "webChatSkin": widgetData.WebChatSkin == 'Modern' ? "modern-caret-skin" : "basic", "authenticationUrl": widgetData.AuthenticationUrl } }
            } else {
                body.clientType = widgetData.WidgetType == 'Version 2' ? 'v2' : 'third-party';
                body.clientConfig = { "v1": null, "v1Http": null }
            }
            body.allowedDomains = widgetData.AllowedDomains;

            console.log(widgetData.WidgetType);
            console.log(JSON.stringify(body));
            try {
                const response = await createWidgets(body, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
                console.log(`Widget Creation ${JSON.stringify(response)}`);
                this.logEvent(`Widget [${widgetData.Name}] successfully processed`);
                this.removeRowClicked(widgetData.rowid);
            } catch (err) {
                console.log(`${err.message}`);
                this.logEvent(`${err.message}`, true);
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Widgets`, count, 'Widgets');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    handleOnChange = (name) => (event) => {
        if (name === 'requireAuth') {
            this.setState({
                requireAuth: event
            });
        }
    }

    render() {
        let MAP = {
            name: "wid-map",
            areas: [
                { name: "0", shape: "circle", coords: [24, 13, 12] },
                { name: "1", shape: "circle", coords: [83, 13, 12] },
                { name: "2", shape: "circle", coords: [140, 13, 12] },
                //{ name: "3", shape: "circle", coords: [198, 13, 12] }
            ]
        }

        let widgetsType = ['Version 2', 'Version 1.1', 'Version 1.0', 'Third Party']

        return (
            <div className="widgetsCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                <WidgetsInfo isOpen={this.state.isWidgetsInfoOpen}
                    widgetsName={this.state.widgetsName}
                    description={this.state.description}
                    handleInputChange={this.handleInputChange}
                    widgetsType={widgetsType}
                    selectedWidgetsType={this.state.selectedWidgetsType}
                    inboundChatFlowList={this.state.inboundChatFlowList}
                    selectedFlow={this.state.selectedFlow}
                    domains={this.state.domains}
                    isDomainSaveLocale={this.state.isDomainSaveLocale}
                    requireAuth={this.state.requireAuth}
                    authUrl={this.state.authUrl}
                    selectedOption={this.state.selectedOption}
                    handleOnChange={this.handleOnChange}
                    addDomains={this.addDomains}
                    handleRowClick={this.handleRowClick}
                    deleteButtonClicked={this.deleteButtonClicked}
                    updateFunction={this.updateFunction}
                    submitFunction={this.submitFunction}
                    closeFunction={() => { this.setState({ isWidgetsInfoOpen: false }) }} />

                <DomainInfo isOpen={this.state.domainInfoOpen}
                    domainName={this.state.domainName}
                    isSaveLocale={this.state.isSaveLocale}
                    handleInputChange={this.handleInputChange}
                    addDomainFunction={this.addDomainFunction}
                    updateDomainFunction={this.updateDomainFunction}
                    closeDomainFunction={() => { this.setState({ domainInfoOpen: false }) }}
                />

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 widgetsCardBody">
                        <CardTitle className="m-0 widgetsTitle">
                            <div className="divWidgets">
                                <Badge className="titleBadgeWidgets">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pWidgets.png" map={MAP} width={225} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px" }}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually  or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/widgets-import-template.csv" ><u>Here</u></a> </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 cardBodyWidgets">
                        <FormGroup row>
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonWidgets" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonWidgets">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </FormGroup>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderMessage} />
                            <div className="widgets-wrap">
                                <ReactTable
                                    data={this.state.widgetList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.widgetList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "Name",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{
                                                width: "400px", height: "25px", display: "block", cursor: "pointer"
                                                , backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius: (this.state.selectAll != 0) ? "25px" : "0px"
                                            }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Widgets loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonWidgets" onClick={this.importWidgets} disabled={this.state.widgetList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>


            </div>)
    }

}