import './telephonyInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input } from 'reactstrap'

export default class TelephonyInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.didStart !== '' && this.props.didEnd !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="TelephonyModal">
                    <ModalHeader>DID Number Configuration</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="didStart">DID Start:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.didStart === '' ? true : false} value={this.props.didStart} onChange={this.props.handleInputChange('didStart')} type="text" id="didStart" placeholder="Enter the DID start range" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="didEnd">DID End:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.didEnd === '' ? true : false} value={this.props.didEnd} onChange={this.props.handleInputChange('didEnd')} type="text" id="didEnd" placeholder="Enter the DID end range" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="provider">Provider:</Label>
                                <Col sm={10}>
                                    <Input value={this.props.provider} onChange={this.props.handleInputChange('provider')} type="text" id="provider" placeholder="Enter the Provider" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="comments">Comments:</Label>
                                <Col sm={10}>
                                    <Input value={this.props.comments} onChange={this.props.handleInputChange('comments')} type="text" id="comments" placeholder="Enter your comment" />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
