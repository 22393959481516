import React from 'react';
import './bulkExport.css';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Input, Label, FormGroup } from 'reactstrap'

export default class BulkExport extends React.Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="BulkModalExport">
                    <ModalHeader>Bulk Export</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                        <Label for="cb"  className = "label"  check>Include Extended Attributes </Label>
                        <Input className="chkbox" type="checkbox" id="cb" checked={this.props.isExtendedExport} onChange={this.props.handleInputChange} />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Cancel</Button>
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.exportFunction}>OK</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }

}