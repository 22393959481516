import misc from '../misc/misc';
import { pureCloudLogout } from '../session/session'

export const telephonyPostSites = (env, token, name, description, locationId, rrule, timeZone, start, end) => {
  var body = {
    "name": name,
    "description": description,
    "location": {
      "id": locationId
    },
    "edgeAutoUpdateConfig": {
      "start": start,
      "end": end,
      "rrule": rrule,
      "timeZone": timeZone
    },
    "primarySites": [],
    "secondarySites": [], "edges": [],
    "primaryEdges": [],
    "secondaryEdges": [],
    "managed": false,
    "mediaModel": "Cloud"
  }

  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/sites`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}

export const telephonyPostDidPools = (env, token, startPhoneNumber, endPhoneNumber, provider, comments) => {
  var body = {
    "startPhoneNumber": startPhoneNumber,
    "endPhoneNumber": endPhoneNumber,
    "description": provider,
    "comments": comments
  }

  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/didpools`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}

export const telephonyPostExtensionPools = (env, token, startNumber, endNumber) => {
  var body = {
    "startNumber": startNumber,
    "endNumber": endNumber
  }

  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/extensionPools`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}

export const telephonyGetListOfExtensionPools = async (env, token) => {
  let telephonyGetExPoolsContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyExtensionPoolsWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyGetExPoolsContainer.push(respond)
    }
  }
  return [].concat(...telephonyGetExPoolsContainer);
}

export const telephonyExtensionPoolsWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/extensionpools?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetTrunkBaseSettings = (env, token) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/trunkbasesettings?pageSize=255&pageNumber=1`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetEdgeGroups = async (env, token) => {
  let telephonyEdgesContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyEdgeGroupsWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyEdgesContainer.push(respond)
    }
  }
  return [].concat(...telephonyEdgesContainer);
}

export const telephonyEdgeGroupsWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/edgegroups?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const getWidgetsList = (env, token) => {
  return fetch(`https://api.${env}/api/v2/widgets/deployments`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetDids = async (env, token) => {
  let telephonyGetDidsContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetDidsWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyGetDidsContainer.push(respond)
    }
  }
  return [].concat(...telephonyGetDidsContainer);
}

export const telephonyGetDidsWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/didpools?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetDidExtensions = async (env, token, id) => {
  let telephonyGetDidExtensions = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetDidExtensionsWithPagination(env, token, index, id);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyGetDidExtensions.push(respond)
    }
  }
  return [].concat(...telephonyGetDidExtensions);
}

export const telephonyGetDidExtensionsWithPagination = (env, token, index, id) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/didpools/dids?type=ASSIGNED_AND_UNASSIGNED&id=${id}&pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetTimeZones = async (env, token) => {
  let telephonyTimeZones = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetTimeZonesWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyTimeZones.push(respond)
    }
  }
  return [].concat(...telephonyTimeZones);
}

export const telephonyGetTimeZonesWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/timezones?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetBaseSettings = async (env, token) => {
  let telephonyBaseSettingsContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetBaseSettingsWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyBaseSettingsContainer.push(respond)
    }
  }
  return [].concat(...telephonyBaseSettingsContainer);
}

export const telephonyGetBaseSettingsWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/phonebasesettings?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetSites = async (env, token) => {
  let telephonySitesContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetSitesWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonySitesContainer.push(respond)
    }
  }
  return [].concat(...telephonySitesContainer);
}

export const telephonyGetSitesWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/sites?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetAvailableMetaBases = (env, token) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/phonebasesettings/availablemetabases?pageSize=255&pageNumber=1`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetPhoneBaseSettings = async (env, token) => {
  let phoneBaseSettingsContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetPhoneBaseSettingsWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      phoneBaseSettingsContainer.push(respond)
    }
  }
  return [].concat(...phoneBaseSettingsContainer);
}

export const telephonyGetPhoneBaseSettingsWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/phonebasesettings?pageSize=255&pageNumber=${index}&sortOrder=asc&sortBy=name`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const telephonyGetLineBaseSettings = async (env, token) => {
  let telephonyLineBaseSettingsContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetLineBaseSettingsWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyLineBaseSettingsContainer.push(respond)
    }
  }
  return [].concat(...telephonyLineBaseSettingsContainer);
}

export const telephonyGetLineBaseSettingsWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/linebasesettings?pageSize=100&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    }).catch(function(error){
      console.log(error);
      misc.sleep(3000)
      //Retry after sometime as per Genesys CARE - Case #0003171972
      telephonyGetLineBaseSettingsWithPagination(env, token, index)
    })
}

export const telephonyGetPhonesList = async (env, token) => {
  let telephonyPhoneListContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await telephonyGetPhonesListWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      telephonyPhoneListContainer.push(respond)
    }
  }
  return [].concat(...telephonyPhoneListContainer);
}

export const telephonyGetPhonesListWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/phones?pageSize=255&pageNumber=${index}&expand=lines&expand=phoneBaseSettings&expand=site`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
};

export const telephonyGetPhonesById = (env, token, phoneId) => {
  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/phones/${phoneId}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}

export const telephonyPostPhones = (env, token, phoneData, phoneBaseSettings, site, lineBaseSettings, user) => {

  var body = {
    "name": phoneData.name,
    "edgeGroup": {
      "id": null
    },
    "site": {
      "id": site !== undefined ? site.id : ""
    },
    "phoneBaseSettings": {
      "id": phoneBaseSettings !== undefined ? phoneBaseSettings.id : ""
    },
    "lines": [
      {
        "name": lineBaseSettings !== undefined ? lineBaseSettings.name.replace(' ', '') : "",
        "state": "active",
        "properties": {
          "station_label": {
            "type": "string",
            "value": {
              "default": null
            }
          },
          "station_numCallAppearances": {
            "type": "integer",
            "value": {
              "default": 1
            }
          },
          "station_remote_address": {
            "value": {
              "instance": phoneData.remoteAddress,
              "default": null
            }
          },
          "station_persistent_enabled": {
            "type": "boolean",
            "value": {
              "default": false
            }
          },
          "station_persistent_timeout": {
            "type": "integer",
            "minimum": 1,
            "maximum": 604800,
            "value": {
              "default": 600
            }
          },
          "station_use_originating_address_on_connection_call": {
            "type": "boolean",
            "value": {
              "default": false
            }
          }
        },
        "lineBaseSettings": {
          "id": lineBaseSettings !== undefined ? lineBaseSettings.id : "",
          "name": lineBaseSettings !== undefined ? lineBaseSettings.name : ""
        }
      }
    ],
    "properties": {
      "phone_hardwareId": {
        "value": {
          "instance": null
        }
      }
    },
    "webRtcUser": {
      "id": user !== null ? user.id : null,
      "name": user !== null ? user.name : null,
      "selfUri": user !== null ? user.selfUri : null,
    },
  }


  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/phones`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}

export const telephonyPostEdgeGroups = (env, token, name, description, managed, phoneTrunkID, phoneTrunkName) => {
  var body = {
    "name": name,
    "description": description,
    "managed": managed,
    "phoneTrunkBases": [
      {
        "id": phoneTrunkID,
        "name": phoneTrunkName
      }
    ]
  }

  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/edgegroups`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}

export const telephonyPostPhoneBaseSettings = (env, token, name, metaBaseSetting) => {
  var body = {
    "name": name,
    "phoneMetaBase": {
      "id": metaBaseSetting.id,
      "name": metaBaseSetting.name
    },
    "lines": [
      {
        "name": name,
        "lineMetaBase": {
          "id": metaBaseSetting.id
        }
      }
    ]
  }

  return fetch(`https://api.${env}/api/v2/telephony/providers/edges/phonebasesettings`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}

// export const telephonyProvidersGetEdges = (env, token) => {
//   return fetch(`https://api.${env}/api/v2/telephony/providers/edges?pageSize=255&pageNumber=1`, {
//     method: 'GET',
//     headers: {
//       'Authorization': `bearer ${token}`,
//       'Content-Type': 'application/json'
//     }
//   })
//     .then(response => response.json())
//     .then(response => {
//       if (response.status === 401) {
//         pureCloudLogout();
//         return;
//       }
//       if (response.status < 200 || response.status > 299) throw response;
//       if (response.entities && response.entities.length > 0) {
//         return response.entities;
//       }
//     })
// }