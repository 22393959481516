import './phoneBaseSettingsInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

export default class PhoneBaseSettingsInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.phoneBaseSettingsName !== '' && this.props.phoneMetaBase !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="phoneBaseSettingsModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Phone Base Settings Name:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.phoneBaseSettingsName === '' ? true : false} value={this.props.phoneBaseSettingsName} onChange={this.props.handleInputChange('phoneBaseSettingsName')} id="phoneBaseSettingsName" type="text" placeholder="Enter name of the Base Setting" />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Phone Make and Model:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.phoneMetaBase} onChange={this.props.handleInputChange('phoneMetaBase')}>
                                            <option key="p1">None Selected</option>
                                            {
                                                this.props.metaBaseSettings.map((metaBase) => (
                                                    <option key={metaBase.id}>{metaBase.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
