import './contactListInfo.css'
import React from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';

export default class ContactListInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.contactListName !== '' && this.props.division !== '' && this.props.clFileName !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="contactListInfoModal">
                    <ModalHeader>Contact List Information</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="contactListName">Name:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.contactListName === '' ? true : false} value={this.props.contactListName} onChange={this.props.handleInputChange('contactListName')} type="text" id="contactListName" placeholder="Enter the Contact List Name" />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Division:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.division} onChange={this.props.handleInputChange('division')}>
                                            <option key="D1">Select a Division</option>
                                            {
                                                this.props.divisionList.map((division) => (
                                                    <option key={division.id}>{division.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup check>
                                <Input type="checkbox" id="timezone" checked={this.props.timezone} onChange={this.props.handleInputChange('timezone')} />
                                <Label for="timezone" check>Enable automatic timezone mapping in North America​ </Label>
                            </FormGroup>
                            <FormGroup>
                                <Row className="mt-3">
                                    <Col className="fileDropCol">
                                        <div style={{ marginRight: "5px" }}>
                                            <label className="custom-file-upload">
                                                <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.props.handleImportFile} />
                                                <FaCloudUploadAlt /> Browse
                                    </label>
                                        </div>
                                        <div style={{ width: "70%" }}>
                                            <DragAndDrop handleDrop={this.props.handleDropFile}>
                                                <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" value={this.props.clFileName} />
                                            </DragAndDrop>
                                        </div>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.saveContactListsFunction} disabled={!isButtonEnabled}>Save</Button>
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
