import './edgeGroupsInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

export default class EdgeGroupsInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.edgeGroupName !== '' && this.props.description !== '' && this.props.description !== '' && this.props.phoneTrunkBaseName !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="edgeGroupsModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Edge Group Name:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.edgeGroupName === '' ? true : false} value={this.props.edgeGroupName} onChange={this.props.handleInputChange('edgeGroupName')} id="edgeGroupName" type="text" placeholder="Enter name of the Edge Group" />
                                    </Col>
                                    <Col md={6}>
                                        <Label>Description:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.description === '' ? true : false} value={this.props.description} onChange={this.props.handleInputChange('description')} id="description" type="text" placeholder="Enter Description" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Managed:</Label>
                                        <select className="form-control selectpicker" value={this.props.managed} onChange={this.props.handleInputChange('managed')}>
                                            <option key="M1">None Selected</option>
                                            <option key="M2">true</option>
                                            <option key="M3">false</option>
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="phoneTrunkBaseName">Phone Trunk BaseName:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.phoneTrunkBaseName} onChange={this.props.handleInputChange('phoneTrunkBaseName')}>
                                            <option key="T1" hidden>Select a Name</option>
                                            {
                                                this.props.trunkBaseSettingsList.map((base, index) => (
                                                    <option key={index}>{base.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
