
export const headerByAccesor = {
    'name': 'Name',
    'street1': 'Street 1',
    'street2': 'Street 2',
    'city': 'City',
    'state': 'State',
    'zip': 'Zip',
    'countryAbbreviation': 'Country Abbreviation',
    'country' :'Country',
    'notes': 'Notes',
    'emergencyNumber' : 'Emergency number',
    'phoneBaseSettings': 'Phone Base Settings',
    'site' : 'Site',
    'lineBaseSettings':'Line Base Settings',
    'person': 'Person',
    'remoteAddress' : 'Remote Address',
    'startPhoneNumber': 'Start Phone Number',
    'endPhoneNumber': 'End Phone Number',
    'provider' : 'Provider',
    'comments' : 'Comments',
    'startNumber' : 'Start Number',
    'endNumber' : 'End Number',
    'description': 'Description',
    'managed' : 'Managed',
    'phoneTrunkBaseName' : 'Phone Trunk Base Name',
    'start': 'Start',
    'end': 'End',
    'rrule' : 'Rrule',
    'timeZone' : 'Time Zone',
    'openSchedules': 'Open schedules',
    'closedSchedules': 'Closed schedules',
    'holidaySchedules': 'Holiday schedules',
    'location': 'Location name',
    'recurrenceType': 'Recurrence type',
    'startTime': 'Start Time',
    'endTime': 'End Time',
    'siteName': 'Site Name',
    'addresses': 'Addresses',
    'scheduleGroup' : 'Schedule Group',
    'openHoursFlow' : 'Open Hours Flow',
    'closedHoursflow' : 'Closed Hours flow',
    'holidayHoursflow': 'Holiday Hours flow',
    'queuename' : 'Queue name',
    'aCW' : 'ACW',
    'aCWtimeout' : 'ACW timeout',
    'evaluationmethod': 'Evaluation method',
    'alertingtimeout' : 'Alerting timeout',
    'sLpercentage' : 'SL percentage',
    'sLduration' : 'SL duration',
    'callingpartyname' : 'Calling party name',
    'callingpartynumber' : 'Calling party number',
    'wrapupcodes' : 'Wrapup codes',
    'email' : 'Email',
    'password': 'Password',
    'phone_work' : 'Phone Work',
    'title' : 'Title',
    'department': 'Department',
    'email_manager': 'Email Manager',
    'location_work': 'Location Work',
    'roles': 'Roles',
    'queues': 'Queues',
    'division': 'Division',
    'skills' : 'Skills',
    'proficiencies' : 'Proficiencies'
}

