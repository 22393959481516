import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import "react-table/react-table.css";
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './routers/AppRouter';

const jsx = (
    <AppRouter />
)

ReactDOM.render(jsx, document.getElementById('root'));
