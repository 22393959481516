import './appBottom.css';
import React from 'react';
import { Navbar, Nav } from 'reactstrap'
import Papa from 'papaparse';
import moment from "moment";

import { organizationsGetMe } from '../../services/purecloud/organizations';
import { getUserMe, getOrgMe, getToken } from '../../services/session/session';
import { getSelectedRegion, getSelectedRegionDisplay } from '../../global/localStorage';
import OrgInformation from '../Misc/orgInformation/orgInformation';
import OrgProperties from '../Misc/orgInformation/orgProperties';
import BulkImport from '../BulkImport/bulkImport';
import Preloader from '../Misc/preloader/preloader';
import zipper from '../../services/misc/zipper';
import {
    extractDidsData,
    extractDivisionsData,
    extractEdgeGroupsData,
    extractExtensionsData,
    extractIvrRoutingData,
    extractLocations,
    extractPhoneBaseSettingsData,
    extractPhonesData,
    extractQueuesData,
    extractRolesData,
    extractScheduleGroupsData,
    extractSchedulesData,
    extractSitesData,
    extractSkillsData,
    extractUsersData,
    extractWrapUpCodesData,
    extractSkillsMatrixData,
} from "../../services/misc/exportUtils";
import exportfile from '../../services/misc/fileDownload';

export default class AppBottom extends React.Component {

    state = {
        env: getSelectedRegion(),
        token: getToken(),
        isOrgOpen: false,
        isOrgPropsOpen: false,
        isBulkImportOpen: false,
        userMe: {},
        orgMe: {},
        regionName: getSelectedRegionDisplay(),
        orgProperties: [],
        orgFeatures: [],
        preloader: false,
        preloaderMessage: 'Loading',
    }

    constructor(props) {
        super(props);
        this.loadSessionData();
    }

    loadSessionData = async () => {
        try {
            const userMe = await getUserMe();
            const orgMe = await getOrgMe();
            await this.setState({ userMe: userMe });
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            console.error(error);
        }
    }

    handleOrgInfo = () => {
        this.setState({
            isOrgOpen: true,
        });
    }

    moreFunction = () => {
        this.setState({
            isOrgOpen: false,
            isOrgPropsOpen: true,
        });
    }

    moreFunction = async () => {
        await this.setState({ preloader: true });
        const orgMe = await organizationsGetMe(this.state.env, this.state.token);
        var orgProperties = [];
        var orgFeatures = [];
        const keys = Object.keys(orgMe);
        const values = Object.values(orgMe);

        // <parsing properties>
        for (const i in keys) {
            if (keys[i] === 'features') {
                // <parsing features>
                const featureKeys = Object.keys(values[i]);
                const featureValues = Object.values(values[i]);
                for (const j in featureKeys) {
                    const featureItem = {
                        key: featureKeys[j],
                        value: featureValues[j].toString()
                    }
                    orgFeatures.push(featureItem);
                }
                // </parsing features>
            } else {
                const item = {
                    key: keys[i],
                    value: values[i].toString()
                }
                orgProperties.push(item);
            }
        }
        // </parsing properties>      

        await this.setState({ orgProperties: orgProperties });
        await this.setState({ orgFeatures: orgFeatures });
        this.setState({ isOrgOpen: false, isOrgPropsOpen: true });
        await this.setState({ preloader: false });
    }

    handleManageGC = () => {
        if (this.props.currPage === 0) {
            window.open('https://apps.' + this.state.env + '/directory/#/admin/organization/details');
        } else if (this.props.currPage === 1) {
            if (this.props.cardItem === 'Org') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/people-and-permissions/divisions');
                } else if (this.props.currentSlide === 1) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/acdSkills');
                } else if (this.props.currentSlide === 2) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_wrapupCodesV2');
                } else if (this.props.currentSlide === 3) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/rolesV2');
                }
            } else if (this.props.cardItem === 'locale') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/locations');
                } else if (this.props.currentSlide === 1) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/sites');
                }
            } else if (this.props.cardItem === 'telephony') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/did/numbers');
                } else if (this.props.currentSlide === 1) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/extensions/pools');
                } else if (this.props.currentSlide === 2) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/edgeGroups');
                } else if (this.props.currentSlide === 3) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/phoneManagement/baseSettings');                    
                } else if (this.props.currentSlide === 4) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/phoneManagement/phones');
                }
            } else if (this.props.cardItem === 'people') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_queuesV2');
                } else if (this.props.currentSlide === 1) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/peopleV3');
                }
            } else if (this.props.cardItem === 'schedule') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/routing/scheduling/schedules');
                } else if (this.props.currentSlide === 1) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/routing/scheduling');
                } else if (this.props.currentSlide === 2) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/routing/ivrs');
                }
            } else if (this.props.cardItem === 'callFlows') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/architect/#/inboundcall/flows');
                } else if (this.props.currentSlide === 1) {
                    window.open('https://apps.' + this.state.env + '/architect/#/inboundcall/flows');
                }
            } else if (this.props.cardItem === 'announcements') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/architect/#/call/userprompts');
                }
            } else if (this.props.cardItem === 'outbound') {
                if (this.props.currentSlide === 0) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/campaignManagement/campaigns');
                } else if (this.props.currentSlide === 1) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/lists/contactLists');
                } else if (this.props.currentSlide === 2) {
                    window.open('https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_queuesV2');
                } else if (this.props.currentSlide === 3) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/wrapupMappings');
                } else if (this.props.currentSlide === 4) {
                    window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/campaignManagement/campaigns');
                }
            }
        }
    }

    handleDownloadOffTemplate = () => {
        window.open("./templates/bulk-import-template.zip");
    }

    handleImport = () => {
        this.setState({
            isBulkImportOpen: true,
        });
    }

    handleObjectTemplate = (objectType) => {
        if (objectType === 'Divisions') {
            window.open("./templates/divisions-import-template.csv");
        } else if (objectType === 'Skills') {
            window.open("./templates/skills-import-template.csv");
        } else if (objectType === 'Wrap-Up Codes') {
            window.open("./templates/wrapup-codes-import-template.csv");
        } else if (objectType === 'Roles') {
            window.open("./templates/roles-import-template.csv");
        } else if (objectType === 'Locations') {
            window.open("./templates/location-import-template.csv");
        } else if (objectType === 'Sites') {
            window.open("./templates/site-import-template.csv");
        } else if (objectType === 'DIDs') {
            window.open("./templates/dids-import-template.csv");
        } else if (objectType === 'Extensions') {
            window.open("./templates/extensions-import-template.csv");
        } else if (objectType === 'Edge Groups') {
            window.open("./templates/edgegroups-import-template.csv");
        } else if (objectType === 'Phones') {
            window.open("./templates/phones-import-template.csv");
        } else if (objectType === 'PhoneBase Settings') {
            window.open("./templates/phones-base-settings-import-template.csv");
        } else if (objectType === 'Queues') {
            window.open("./templates/queues-full-import-template.csv");
        } else if (objectType === 'Users') {
            window.open("./templates/user-import-template.csv");
        } else if (objectType === 'Schedules') {
            window.open("./templates/schedules-import-template.csv");
        } else if (objectType === 'Schedule Groups') {
            window.open("./templates/schedulegroups-import-template.csv");
        } else if (objectType === 'IVR Routing') {
            window.open("./templates/ivrsrouting-import-template.csv");
        }
    }

    handleExportObjects = async (objectType) => {
        if (objectType === 'Divisions') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Divisions' });
            let divisionsFileName = this.state.orgMe.name + `_` + `Divisions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const divisionsBlob = await extractDivisionsData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(divisionsBlob, divisionsFileName);
        } else if (objectType === 'Skills') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Skills' });
            let skillsFileName = this.state.orgMe.name + `_` + `Skills_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const skillsBlob = await extractSkillsData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(skillsBlob, skillsFileName);
        } else if (objectType === 'Wrap-Up Codes') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Wrap-up Codes' });
            let wrapUpCodesFileName = this.state.orgMe.name + `_` + `Wrap-Up Codes_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const codesBlob = await extractWrapUpCodesData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(codesBlob, wrapUpCodesFileName);
        } else if (objectType === 'Roles') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Roles' });
            let rolesFileName = this.state.orgMe.name + `_` + `Roles_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const rolesBlob = await extractRolesData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(rolesBlob, rolesFileName);
        } else if (objectType === 'Locations') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Locations' });
            let locationsFileName = this.state.orgMe.name + `_` + `Locations_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const locationsBlob = await extractLocations(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(locationsBlob, locationsFileName);
        } else if (objectType === 'Sites') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Sites' });
            let sitesFileName = this.state.orgMe.name + `_` + `Sites_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const sitesBlob = await extractSitesData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(sitesBlob, sitesFileName);
        } else if (objectType === 'DIDs') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting DIDs' });
            let didsFileName = this.state.orgMe.name + `_` + `DIDs_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const didsBlob = await extractDidsData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(didsBlob, didsFileName);
        } else if (objectType === 'Extensions') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Extensions' });
            let extensionsFileName = this.state.orgMe.name + `_` + `Extensions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const extensionsBlob = await extractExtensionsData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(extensionsBlob, extensionsFileName);
        } else if (objectType === 'Edge Groups') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Edge Groups' });
            let edgeGroupsFileName = this.state.orgMe.name + `_` + `EdgeGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const edgeGroupsBlob = await extractEdgeGroupsData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(edgeGroupsBlob, edgeGroupsFileName);
        } else if (objectType === 'Phones') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Phones' });
            let phonesFileName = this.state.orgMe.name + `_` + `Phones_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const phonesBlob = await extractPhonesData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(phonesBlob, phonesFileName);
        } else if (objectType === 'PhoneBase Settings') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Phone Base Settings' });
            let phoneBaseSettingsFileName = this.state.orgMe.name + `_` + `PhoneBaseSettings_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const phoneBaseSettingsBlob = await extractPhoneBaseSettingsData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(phoneBaseSettingsBlob, phoneBaseSettingsFileName);
        } else if (objectType === 'Queues') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Queues' });
            let queuesFileName = this.state.orgMe.name + `_` + `Queues_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const queuesBlob = await extractQueuesData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(queuesBlob, queuesFileName);
        } else if (objectType === 'Users') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Users' });
            let usersFileName = this.state.orgMe.name + `_` + `Users_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const usersBlob = await extractUsersData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(usersBlob, usersFileName);
        } else if (objectType === 'Schedules') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Schedules' });
            let schedulesFileName = this.state.orgMe.name + `_` + `Schedules_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const schedulesBlob = await extractSchedulesData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(schedulesBlob, schedulesFileName);
        } else if (objectType === 'Schedule Groups') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting Schedule Groups' });
            let scheduleGroupsFileName = this.state.orgMe.name + `_` + `ScheduleGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const scheduleGroupsBlob = await extractScheduleGroupsData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(scheduleGroupsBlob, scheduleGroupsFileName);
        } else if (objectType === 'IVR Routing') {
            this.setState({ preloader: true, preloaderMessage: 'Exporting IVR Routing' });
            let ivrRoutingFileName = this.state.orgMe.name + `_` + `IVR_Routing_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
            const ivrRoutingBlob = await extractIvrRoutingData(this.state.env, this.state.token);
            this.setState({ preloader: false, preloaderMessage: 'Loading' });
            exportfile(ivrRoutingBlob, ivrRoutingFileName);
        }
    }

    exportButtonClicked = async () => {
        this.setState({ preloader: true, preloaderMessage: 'Loading' });
        let zipFileName = this.state.orgMe.name + `_` + `bulk_export_configuration_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.zip`;
        zipper.initialize();

        //divisions
        this.setState({ preloader: true, preloaderMessage: 'Exporting Divisions' });
        let divisionsFileName = this.state.orgMe.name + `_` + `Divisions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const divisionsBlob = await extractDivisionsData(this.state.env, this.state.token, true);
        if (divisionsBlob) {
            zipper.addToZip(divisionsBlob, divisionsFileName);
        }

        //skills
        this.setState({ preloader: true, preloaderMessage: 'Exporting Skills' });
        let skillsFileName = this.state.orgMe.name + `_` + `Skills_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const skillsBlob = await extractSkillsData(this.state.env, this.state.token, true);
        if (skillsBlob) {
            zipper.addToZip(skillsBlob, skillsFileName);
        }

        //Wrap-up Codes
        this.setState({ preloader: true, preloaderMessage: 'Exporting Wrap-up Codes' });
        let wrapUpCodesFileName = this.state.orgMe.name + `_` + `Wrap-Up Codes_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const codesBlob = await extractWrapUpCodesData(this.state.env, this.state.token, true);
        if (codesBlob) {
            zipper.addToZip(codesBlob, wrapUpCodesFileName);
        }

        //Roles
        this.setState({ preloader: true, preloaderMessage: 'Exporting Roles' });
        let rolesFileName = this.state.orgMe.name + `_` + `Roles_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const rolesBlob = await extractRolesData(this.state.env, this.state.token, true);
        if (rolesBlob) {
            zipper.addToZip(rolesBlob, rolesFileName);
        }

        //skills matrix
        this.setState({ preloader: true, preloaderMessage: 'Exporting Skills Matrix' });
        let skillsMatrixFileName = this.state.orgMe.name + `_` + `SkillsMatrix_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const skillsMatrixBlob = await extractSkillsMatrixData(this.state.env, this.state.token, true);
        if (skillsMatrixBlob) {
            zipper.addToZip(skillsMatrixBlob, skillsMatrixFileName);
        }

        //sites
        this.setState({ preloader: true, preloaderMessage: 'Exporting Sites' });
        let sitesFileName = this.state.orgMe.name + `_` + `Sites_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const sitesBlob = await extractSitesData(this.state.env, this.state.token, true);
        if (sitesBlob) {
            zipper.addToZip(sitesBlob, sitesFileName);
        }

        //Schedules
        this.setState({ preloader: true, preloaderMessage: 'Exporting Schedules' });
        let schedulesFileName = this.state.orgMe.name + `_` + `Schedules_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const schedulesBlob = await extractSchedulesData(this.state.env, this.state.token, true);
        if (schedulesBlob) {
            zipper.addToZip(schedulesBlob, schedulesFileName);
        }

        // ScheduleGroups
        this.setState({ preloader: true, preloaderMessage: 'Exporting Schedule Groups' });
        let scheduleGroupsFileName = this.state.orgMe.name + `_` + `ScheduleGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const scheduleGroupsBlob = await extractScheduleGroupsData(this.state.env, this.state.token, true);
        if (scheduleGroupsBlob) {
            zipper.addToZip(scheduleGroupsBlob, scheduleGroupsFileName);
        }

        //PhoneBaseSettings
        this.setState({ preloader: true, preloaderMessage: 'Exporting Phone Base Settings' });
        let phoneBaseSettingsFileName = this.state.orgMe.name + `_` + `PhoneBaseSettings_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const phoneBaseSettingsBlob = await extractPhoneBaseSettingsData(this.state.env, this.state.token, true);
        if (phoneBaseSettingsBlob) {
            zipper.addToZip(phoneBaseSettingsBlob, phoneBaseSettingsFileName);
        }

        //Locations
        this.setState({ preloader: true, preloaderMessage: 'Exporting Locations' });
        let locationsFileName = this.state.orgMe.name + `_` + `Locations_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const locationsBlob = await extractLocations(this.state.env, this.state.token, true);
        if (locationsBlob) {
            zipper.addToZip(locationsBlob, locationsFileName);
        }

        //Dids
        this.setState({ preloader: true, preloaderMessage: 'Exporting DIDs' });
        let didsFileName = this.state.orgMe.name + `_` + `DIDs_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const didsBlob = await extractDidsData(this.state.env, this.state.token, true);
        if (didsBlob) {
            zipper.addToZip(didsBlob, didsFileName);
        }

        //Extensions
        this.setState({ preloader: true, preloaderMessage: 'Exporting Extensions' });
        let extensionsFileName = this.state.orgMe.name + `_` + `Extensions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const extensionsBlob = await extractExtensionsData(this.state.env, this.state.token, true);
        if (extensionsBlob) {
            zipper.addToZip(extensionsBlob, extensionsFileName);
        }

        //EdgeGroups
        this.setState({ preloader: true, preloaderMessage: 'Exporting Edge Groups' });
        let edgeGroupsFileName = this.state.orgMe.name + `_` + `EdgeGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const edgeGroupsBlob = await extractEdgeGroupsData(this.state.env, this.state.token, true);
        if (edgeGroupsBlob) {
            zipper.addToZip(edgeGroupsBlob, edgeGroupsFileName);
        }

        //IvrRouting
        this.setState({ preloader: true, preloaderMessage: 'Exporting IVR Routing' });
        let ivrRoutingFileName = this.state.orgMe.name + `_` + `IVR_Routing_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const ivrRoutingBlob = await extractIvrRoutingData(this.state.env, this.state.token, true);
        if (ivrRoutingBlob) {
            zipper.addToZip(ivrRoutingBlob, ivrRoutingFileName);
        }

        //phones
        this.setState({ preloader: true, preloaderMessage: 'Exporting Phones' });
        let phonesFileName = this.state.orgMe.name + `_` + `Phones_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const phonesBlob = await extractPhonesData(this.state.env, this.state.token, true);
        if (phonesBlob) {
            zipper.addToZip(phonesBlob, phonesFileName);
        }

        //queues
        this.setState({ preloader: true, preloaderMessage: 'Exporting Queues' });
        let queuesFileName = this.state.orgMe.name + `_` + `Queues_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const queuesBlob = await extractQueuesData(this.state.env, this.state.token, true);
        if (queuesBlob) {
            zipper.addToZip(queuesBlob, queuesFileName);
        }

        //users Rate limit exceeded the maximum [300] requests within [60] seconds"
        this.setState({ preloader: true, preloaderMessage: 'Exporting Users' });
        let usersFileName = this.state.orgMe.name + `_` + `Users_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
        const usersBlob = await extractUsersData(this.state.env, this.state.token, true);
        if (usersBlob) {
            zipper.addToZip(usersBlob, usersFileName);
        }

        await zipper.exportZip(zipFileName);
        this.setState({ preloader: false, preloaderMessage: 'Loading' });
    };

    render() {

        let manageGcTitle = '';
        let exportTitle = '';
        let templateTitle = '';
        let objectType = '';
        let notEligible = false;

        if (this.props.currPage === 0) {
            manageGcTitle = 'Navigate to Genesys Cloud for detailed Organization Information viewing and\\or Editing';
            exportTitle = 'Export all previously configured objects in a compressed .zip package';
            templateTitle = 'Download a .zip file containing templates for each ObjectType';
            objectType = '';
            notEligible = false;
        } else if (this.props.currPage === 1) {
            if (this.props.cardItem === 'Org') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Divisions information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Divisions in a  .csv file';
                    templateTitle = 'Download a .csv import template for Divisions';
                    objectType = 'Divisions';
                    notEligible = false;
                } else if (this.props.currentSlide === 1) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Skills information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Skills in a  .csv file';
                    templateTitle = 'Download a .csv import template for Skills';
                    objectType = 'Skills';
                    notEligible = false;
                } else if (this.props.currentSlide === 2) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Wrap-Up Codes information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Wrap-Up Codes in a  .csv file';
                    templateTitle = 'Download a .csv import template for Wrap-Up Codes';
                    objectType = 'Wrap-Up Codes';
                    notEligible = false;
                } else if (this.props.currentSlide === 3) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Roles information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Roles in a  .csv file';
                    templateTitle = 'Download a .csv import template for Roles';
                    objectType = 'Roles';
                    notEligible = false;
                }
            } else if (this.props.cardItem === 'locale') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Locations information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Locations in a  .csv file';
                    templateTitle = 'Download a .csv import template for Locations';
                    objectType = 'Locations';
                    notEligible = false;
                } else if (this.props.currentSlide === 1) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Sites information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Sites in a  .csv file';
                    templateTitle = 'Download a .csv import template for Sites';
                    objectType = 'Sites';
                    notEligible = false;
                }
            } else if (this.props.cardItem === 'telephony') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed DIDs information viewing and\\or Editing';
                    exportTitle = 'Export previously configured DIDs in a  .csv file';
                    templateTitle = 'Download a .csv import template for DIDs';
                    objectType = 'DIDs';
                    notEligible = false;
                } else if (this.props.currentSlide === 1) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Extensions information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Extensions in a  .csv file';
                    templateTitle = 'Download a .csv import template for Extensions';
                    objectType = 'Extensions';
                    notEligible = false;
                } else if (this.props.currentSlide === 2) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Edge Groups information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Edge Groups in a  .csv file';
                    templateTitle = 'Download a .csv import template for Edge Groups';
                    objectType = 'Edge Groups';
                    notEligible = false;
                } else if (this.props.currentSlide === 3) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed PhoneBase Settings information viewing and\\or Editing';
                    exportTitle = 'Export previously configured PhoneBase Settings in a  .csv file';
                    templateTitle = 'Download a .csv import template for PhoneBase Settings';
                    objectType = 'PhoneBase Settings';
                    notEligible = false;
                } else if (this.props.currentSlide === 4) {                    
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Phones information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Phones in a  .csv file';
                    templateTitle = 'Download a .csv import template for Phones';
                    objectType = 'Phones';
                    notEligible = false;
                }
            } else if (this.props.cardItem === 'people') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Queues information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Queues in a  .csv file';
                    templateTitle = 'Download a .csv import template for Queues';
                    objectType = 'Queues';
                    notEligible = false;
                } else if (this.props.currentSlide === 1) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Users information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Users in a  .csv file';
                    templateTitle = 'Download a .csv import template for Users';
                    objectType = 'Users';
                    notEligible = false;
                }
            } else if (this.props.cardItem === 'schedule') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Schedules information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Schedules in a  .csv file';
                    templateTitle = 'Download a .csv import template for Schedules';
                    objectType = 'Schedules';
                    notEligible = false;
                } else if (this.props.currentSlide === 1) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Schedule Groups information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Schedule Groups in a  .csv file';
                    templateTitle = 'Download a .csv import template for Schedule Groups';
                    objectType = 'Schedule Groups';
                    notEligible = false;
                } else if (this.props.currentSlide === 2) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed IVR Routing information viewing and\\or Editing';
                    exportTitle = 'Export previously configured IVR Routings in a  .csv file';
                    templateTitle = 'Download a .csv import template for IVR Routing';
                    objectType = 'IVR Routing';
                    notEligible = false;
                }
            } else if (this.props.cardItem === 'callFlows') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Design Flows information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Design Flows in a  .csv file';
                    templateTitle = 'Download a .csv import template for Design Flows';
                    objectType = 'Design Flows';
                    notEligible = true;
                } else if (this.props.currentSlide === 1) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Callflows information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Callflows in a  .csv file';
                    templateTitle = 'Download a .csv import template for Callflows';
                    objectType = 'Callflows';
                    notEligible = true;
                }
            } else if (this.props.cardItem === 'announcements') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Prompts information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Prompts in a  .csv file';
                    templateTitle = 'Download a .csv import template for Prompts';
                    objectType = 'Prompts';
                    notEligible = true;
                }
            } else if (this.props.cardItem === 'outbound') {
                if (this.props.currentSlide === 0) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Campaigns information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Campaigns in a  .csv file';
                    templateTitle = 'Download a .csv import template for Campagins';
                    objectType = 'Campagins';
                    notEligible = true;
                } else if (this.props.currentSlide === 1) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Contact Lists information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Contact Lists in a  .csv file';
                    templateTitle = 'Download a .csv import template for Contact Lists';
                    objectType = 'Contact Lists';
                    notEligible = true;
                } else if (this.props.currentSlide === 2) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Queues information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Queues in a  .csv file';
                    templateTitle = 'Download a .csv import template for Queues';
                    objectType = 'Queues';
                    notEligible = true;
                } else if (this.props.currentSlide === 3) {
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed WrapCode Mappings information viewing and\\or Editing';
                    exportTitle = 'Export previously configured WrapCode Mappings in a  .csv file';
                    templateTitle = 'Download a .csv import template for WrapCode Mappings';
                    objectType = 'WrapCode Mappings';
                    notEligible = true;
                } else if (this.props.currentSlide === 4) {                    
                    manageGcTitle = 'Navigate to Genesys Cloud for detailed Campaigns information viewing and\\or Editing';
                    exportTitle = 'Export previously configured Campaigns in a  .csv file';
                    templateTitle = 'Download a .csv import template for Campagins';
                    objectType = 'Campagins';
                    notEligible = true;
                }
            }
        }

        return (
            <div>

                {/* <preloader> */}
                <Preloader show={this.state.preloader} text={this.state.preloaderMessage} />
                {/* </preloader> */}

                {/* <Organization Information> */}
                <OrgInformation regionName={this.state.regionName} orgName={this.state.orgMe.name} userName={this.state.userMe.name} isOpen={this.state.isOrgOpen} moreFunction={this.moreFunction} closeFunction={() => { this.setState({ isOrgOpen: false }) }} />
                {/* <Organization Information> */}

                {/* <Organization Properties> */}
                <OrgProperties orgFeatures={this.state.orgFeatures} orgProperties={this.state.orgProperties} isOpen={this.state.isOrgPropsOpen} closeFunction={() => { this.setState({ isOrgPropsOpen: false }) }} />
                {/* <Organization Properties> */}

                {
                    this.state.isBulkImportOpen &&
                    <BulkImport isOpen={this.state.isBulkImportOpen} closeFunction={() => { this.setState({ isBulkImportOpen: false }) }} />
                }

                <Navbar className="navBottom" expand="md" fixed="bottom">
                    <Nav className="navItemsBottom">
                        <div className="infobuttons">
                            <button title="show the user the basic Org information in popup" className="bottomButtons" onClick={this.handleOrgInfo}>Organization Information</button>
                        </div>
                        <div className="infobuttons">
                            <button title={manageGcTitle} className="bottomButtons" onClick={this.handleManageGC}>Manage in Genesys Cloud</button>
                        </div>
                        {
                            this.props.currPage === 0
                                ?
                                <div className="infobuttons">
                                    <button title={exportTitle} className="bottomButtons" onClick={this.exportButtonClicked}>Export All</button>
                                </div>
                                :
                                <div className="infobuttons">
                                    <button disabled={notEligible} title={exportTitle} className={notEligible ? "btmButtons" : "bottomButtons"} onClick={() => { this.handleExportObjects(objectType) }}>Export {objectType}</button>
                                </div>
                        }
                        {
                            this.props.currPage === 0
                            &&
                            <div className="infobuttons">
                                <button title="Import multiple .csv import files packaged in a .zip file" className="bottomButtons" onClick={this.handleImport}>Import</button>
                            </div>
                        }
                        {
                            this.props.currPage === 0
                                ?
                                <div className="infobuttons">
                                    <button title={templateTitle} className="bottomButtons" onClick={this.handleDownloadOffTemplate}>Download Offline Template</button>
                                </div>
                                :
                                <div className="infobuttons">
                                    <button disabled={notEligible} title={templateTitle} className={notEligible ? "btmButtons" : "bottomButtons"} onClick={() => { this.handleObjectTemplate(objectType) }}>Download Offline Template</button>
                                </div>
                        }
                    </Nav>
                </Navbar>
            </div>
        )
    }
}