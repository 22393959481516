import './phoneBaseSettings.css'
import React from 'react'
import { FaObjectGroup, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, getOrgMe, logActionData } from '../../../../services/session/session';
import { telephonyGetAvailableMetaBases, telephonyPostPhoneBaseSettings } from '../../../../services/purecloud/telephony';
import PhoneBaseSettingsInfo from '../PhoneBaseSettings/phoneBaseSettingsInfo';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const telephonyPhonesBaseSettingsDataFields = [
    'name',
    'phoneMetaBase'
];

export default class PhoneBaseSettings extends React.Component {

    state = {
        env: '',
        token: '',
        orgMe: {},
        phoneBaseList: [],
        isPhoneBaseInfoOpen: false,
        phoneBaseSettingsName: '',
        phoneMetaBase: '',
        rowID: null,
        metaBaseSettings: [],
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    async componentDidMount() {
        await this.loadOrg();
        this.loadMetaBaseSettings();
    }

    loadOrg = async () => {
        try {
            const orgMe = await getOrgMe();
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            throw error;
        }
    }

    loadMetaBaseSettings = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.state.metaBaseSettings = await telephonyGetAvailableMetaBases(this.state.env, this.state.token);
        await this.setState({ preloaderLocalShow: false });
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var phoneBaseList = [...this.state.phoneBaseList];
        var selectedList = this.state.selected;
        for (const i in phoneBaseList) {
            if (phoneBaseList[i].rowid === rowid) {
                phoneBaseList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined){
            await this.setState({selectAll: 0})
        }
        await this.setState({ phoneBaseList: phoneBaseList, selected : selectedList});
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isPhoneBaseInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            phoneBaseSettingsName: '',
            phoneMetaBase: '',
            rowID: null,
        })
    }

    handleSubmitFunction = () => {
        let phoneBaseList = [...this.state.phoneBaseList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in telephonyPhonesBaseSettingsDataFields) {
            if (telephonyPhonesBaseSettingsDataFields[i] === 'name') {
                newRow[telephonyPhonesBaseSettingsDataFields[i]] = this.state.phoneBaseSettingsName;
            } else if (telephonyPhonesBaseSettingsDataFields[i] === 'phoneMetaBase') {
                newRow[telephonyPhonesBaseSettingsDataFields[i]] = this.state.phoneMetaBase;
            }
        }

        phoneBaseList.push(newRow);

        this.setState({
            isPhoneBaseInfoOpen: false,
            phoneBaseList: phoneBaseList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        this.setState({
            [fieldName]: event.target.value
        });
    }

    updateState = (rowInfo) => {
        this.setState({
            phoneBaseSettingsName: rowInfo.name,
            phoneMetaBase: rowInfo.phoneMetaBase,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isPhoneBaseInfoOpen: true,
        });
    }

    updateFunction = () => {
        let phoneBaseList = [...this.state.phoneBaseList];
        for (let base of phoneBaseList) {
            if (base.rowid === this.state.rowID) {
                base.name = this.state.phoneBaseSettingsName;
                base.phoneMetaBase = this.state.phoneMetaBase;
            }
        }

        this.setState({
            phoneBaseList: phoneBaseList,
            isPhoneBaseInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const phoneBaseData = await CsvReader.parseCsvFileToPhonesBaseSettingsData(fileContent);
        await this.setState({ phoneBaseList: [...this.state.phoneBaseList, ...phoneBaseData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importPhoneBase = async () => {
        this.eventList = [];
        const phoneBaseList = this.state.phoneBaseList;
        for (const i in phoneBaseList) {
            const phonesBaseSetting = phoneBaseList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing phone base setting [${phonesBaseSetting.name}]`);
            if (await this.dataValidated(phonesBaseSetting, i, phoneBaseList)) {
                try {
                    // <create phone Base>
                    const metaBaseSetting = this.getPhoneMetaBaseByPhoneName(phonesBaseSetting.phoneMetaBase);
                    const phoneSetting = await telephonyPostPhoneBaseSettings(this.state.env, this.state.token, phonesBaseSetting.name, metaBaseSetting);
                    console.log(`Phone base setting created: ${JSON.stringify(phoneSetting)}`);
                    // </create phone Base>
                    this.logEvent(`Phone base settings [${phonesBaseSetting.name}] successfully processed`);
                    this.removeRowClicked(phonesBaseSetting.rowid);
                } catch (err) {
                    if (err.message && err.message.indexOf(this.state.orgMe.id)) {
                        let message = err.message.replace(this.state.orgMe.id, this.state.orgMe.name);
                        this.logEvent(`${message}`, true);
                    } else {
                        this.logEvent(`${err.message}`, true);
                    }
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Phones-base-settings`, count, 'Phones-base-settings');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    getPhoneMetaBaseByPhoneName = (name) => {
        const sites = this.state.metaBaseSettings.filter(setting => setting.name.toLowerCase() === name.toLowerCase())[0];
        if (sites) { return sites; }
    }

    dataValidated = async (baseSetting, i, baseSettings) => {
        let isValidated = true;

        // <validate fields that can't be empty>
        if (!baseSetting.name || !baseSetting.name.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!baseSetting.phoneMetaBase || !baseSetting.phoneMetaBase.trim()) { this.logEvent(`[Index ${i}] Phone Make and Model can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if phone name is unique>
        for (const j in baseSettings) {
            const phone1 = baseSettings[j];
            if (i === j) { continue; }
            if (baseSetting.name.trim().toLowerCase() === phone1.name.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if phone name is unique>

        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.phoneBaseList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Phone Base Settings...."  });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            } else if (area.name === '1') {
                this.props.navigateToHorizontalSlide('1');
            } else if (area.name === '2') {
                this.props.navigateToHorizontalSlide('2');
            } else if (area.name === '4') {
                this.props.navigateToHorizontalSlide('4');
            }
        }
    }

    render() {
        let MAP = {
            name: "pb-map",
            areas: [
                { name: "0", shape: "circle", coords: [23, 13, 12] },
                { name: "1", shape: "circle", coords: [85.5, 13, 12] },
                { name: "2", shape: "circle", coords: [148, 13, 12] },
                //{ name: "3", shape: "circle", coords: [210, 13, 12] },
                { name: "4", shape: "circle", coords: [270, 13, 12] }
            ]
        }
        return (
            <div className="phoneBaseSettingsCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <PhoneBaseSettingsInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isPhoneBaseInfoOpen}
                    phoneBaseSettingsName={this.state.phoneBaseSettingsName}
                    phoneMetaBase={this.state.phoneMetaBase}
                    isSaveLocale={this.state.isSaveLocale}
                    metaBaseSettings={this.state.metaBaseSettings}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isPhoneBaseInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 phoneBaseSettingsCardBody">
                        <CardTitle className="m-0 phoneBaseSettingsTitle">
                            <div className="divPhoneBase">
                                <Badge className="titleBadgePhoneBaseSettings">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pPhoneBase.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/phones-base-settings-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyPhoneBaseSettings">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonPhoneBaseSettings" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonPhoneBaseSettings">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage}/>
                            <div className="phoneBaseSettings-wrap">
                                <ReactTable
                                    data={this.state.phoneBaseList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.phoneBaseList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Phone Base Settings loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonPhoneBaseSettings" onClick={this.importPhoneBase} disabled={this.state.phoneBaseList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
