import './locationInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

import { countries } from '../../../global/dictionary'

export default class LocationInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        let isStateInValid = false;
        if (this.props.locationName !== '' && this.props.streetAddress !== '' && this.props.cityLoc !== '' && this.props.zipLoc !== '' && this.props.countryLoc !== '' && this.props.emergencyLoc !== '') {
            isButtonEnabled = true;
        }

        if (this.props.countryLoc === 'United States' && this.props.stateLoc === '') {
            isStateInValid = true;
            isButtonEnabled = false;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="LocationModal">
                    <ModalHeader>{this.props.title + ' Information'} </ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="locationName">Location Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.locationName === '' ? true : false} value={this.props.locationName} onChange={this.props.handleInputChange('locationName')} type="text" id="locationName" placeholder="Enter the name of this location" />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="streetAddress">Street Address:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.streetAddress === '' ? true : false} value={this.props.streetAddress} onChange={this.props.handleInputChange('streetAddress')} type="text" id="streetAddress" placeholder="Enter the street address" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="address2">Street Address2:</Label>
                                        <Input value={this.props.address2} onChange={this.props.handleInputChange('address2')} type="text" id="address2" placeholder="(optional) Enter the address" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="cityLoc">City:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.cityLoc === '' ? true : false} value={this.props.cityLoc} onChange={this.props.handleInputChange('cityLoc')} type="text" id="cityLoc" placeholder="Enter the name of the city for this location" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="stateLoc">State:</Label>
                                        <Input invalid={isStateInValid} value={this.props.stateLoc} onChange={this.props.handleInputChange('stateLoc')} type="text" id="stateLoc" placeholder={isStateInValid ? "Enter the name of the state for this location" : "(optional) Enter the name of the state for this location"} />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="zipLoc">Zip Code:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.zipLoc === '' ? true : false} value={this.props.zipLoc} onChange={this.props.handleInputChange('zipLoc')} id="zipLoc" type="text" placeholder="Enter the zip code for this location" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="emergencyLoc">Emergency Number:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.emergencyLoc === '' ? true : false} value={this.props.emergencyLoc} onChange={this.props.handleInputChange('emergencyLoc')} id="emergencyLoc" type="text" placeholder="Enter the emergency phone number for this location" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Country:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.countryLoc} onChange={this.props.handleInputChange('countryLoc')}>
                                            <option key="1">Select a Country</option>
                                            {
                                                countries.map((country) => (
                                                    <option key={country.code}>{country.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="codeLoc">Country Code:</Label>
                                        <Input disabled value={this.props.codeLoc} id="codeLoc" type="text" placeholder="country code" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={1} for="notesLoc">Notes:</Label>
                                <Col sm={11}>
                                    <Input value={this.props.notesLoc} onChange={this.props.handleInputChange('notesLoc')} id="notesLoc" type="text" placeholder="(optional) Describe the location (e.g. Main HQ)" />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
