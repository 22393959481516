import config from '../../config';
const backEndURL = config.apiEndpoint;

export const uploadFile = (fileName, encodedFileContent, userMail, orgName) => {
    const zipArchive = {
        filePath: fileName,
        fileContent: encodedFileContent,
    };
    return fetch(backEndURL + `/ignite/zip/${orgName}`, {
        // content-type header should not be specified!
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-accel-user': userMail,
        },
        body: (typeof zipArchive === 'string') ? zipArchive : JSON.stringify(zipArchive)
    })
        .then(response => response.json())
        .then(response => {
            if (response.status < 200 || response.status > 299) throw new Error(response.message)
            return response;
        })
}

export const uploadPromptsFile = (fileName, encodedFileContent, userMail, orgName, region, token) => {
    const zipArchive = {
        fileName: fileName,
        fileContent: encodedFileContent
    };

    return fetch(backEndURL + '/questionaire/bulk/import/prompt', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-accel-user': userMail,
            'x-accel-org': orgName,
            'x-accel-env': region,
            'authToken': token
        },
        body: (typeof zipArchive === 'string') ? zipArchive : JSON.stringify(zipArchive)
    })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response;
        if (response.status.code === "OK") {
          if (response.result) {
            return response.result.token;
          }
        }
      }
      return response;
    })
}