import './preloader.css'
import React from 'react'

export default class Preloader extends React.Component {

  state = { text: 'Loading' }
    
  render() {
    return (
      <div hidden={this.props.show === false}>
        <div className="preloader-background" >
        </div>
        <div className="preloader-outer" >
          <div className="preloader-inner">
            {/* <img className="preLoader-logo" src="/logo-app.svg" alt="logo"></img> */}
            <h4>{this.props.text ? this.props.text : this.state.text}</h4>
            <img src="/loader.gif" alt="loading"></img>
          </div>
        </div>
      </div>
    )
  }
}
