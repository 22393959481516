const defaultRegion = 'mypurecloud.ie';
const defaultRegionDisplay = 'EMEA (Dublin)';
const regionStorageKeyDisplay = 'purecloud_region';
const regionStorageKey = 'purecloud_env';
const userAuthorizationkey = 'true';

export const getSelectedRegion = () => {
  var region = localStorage.getItem(regionStorageKey);
  if (!region) { 
    region = defaultRegion; 
    setSelectedRegion(region);
  }
  return region;
}

export const getSelectedRegionDisplay = () => {
  var region = localStorage.getItem(regionStorageKeyDisplay);
  if (!region) { 
    region = defaultRegionDisplay; 
    setSelectedRegionDisplay(region);
  }
  return region;
}

export const setSelectedRegion = (region) => {
  localStorage.setItem(regionStorageKey, region);
}

export const setSelectedRegionDisplay = (region) => {
  localStorage.setItem(regionStorageKeyDisplay, region);
}

export const getUserAuthorizationKey = () => {
  return localStorage.getItem(userAuthorizationkey);
}

export const setUserAuthorizationKey = (isAuth) => {
  localStorage.setItem(userAuthorizationkey, isAuth);
}
