import './peopleQueues.css'
import React from 'react'

import { FaCodeBranch, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getRoutingWrapupCodes, routingPostQueue, routingPostQueuesWrapUpCodes } from '../../../../services/purecloud/routing'
import QueuesInfo from '../peopleQueues/queuesInfo';
import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import ImageMapper from 'react-image-mapper';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import { getAuthorizationDivisions } from '../../../../services/purecloud/authorization'

const uuidv1 = require('uuid/v1');
const queuesDataFields = [
    'Queue name',
    'Division',
    'ACW',
    'ACW timeout',
    'Evaluation method',
    'Alerting timeout',
    'SL percentage',
    'SL duration',
    'Calling party name',
    'Calling party number',
    'Wrapup codes'
];

export default class PeopleQueues extends React.Component {

    state = {
        env: '',
        token: '',
        queuesList: [],
        isQueuesInfoOpen: false,
        queueName: '',
        acwType: '',
        acwTimeout: '',
        evaluationMethod: '',
        alertingTimeout: '15',
        slPercentage: '80',
        slDuration: '20',
        callingPartyName: '',
        callingPartyNumber: '',
        wrapUpCodes: [],
        rowID: null,
        isacwTimeOut: true,
        wrapUpCodeList: [],
        divisionList: [],
        selectedDivision: 'Home',
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.state.wrapUpCodeList = await getRoutingWrapupCodes(this.state.env, this.state.token);
        this.state.divisionList = await getAuthorizationDivisions(this.state.env, this.state.token);
        await this.setState({ preloaderLocalShow: false });
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var queuesList = [...this.state.queuesList];
        var selectedList = this.state.selected;
        for (const i in queuesList) {
            if (queuesList[i].rowid === rowid) {
                queuesList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ queuesList: queuesList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isQueuesInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            queueName: '',
            acwType: '',
            acwTimeout: '',
            evaluationMethod: '',
            alertingTimeout: '15',
            slPercentage: '80',
            slDuration: '20',
            callingPartyName: '',
            callingPartyNumber: '',
            wrapUpCodes: [],
            rowID: null,
            isacwTimeOut: true,
            selectedDivision: 'Home',
        })
    }

    handleSubmitFunction = () => {
        let queuesList = [...this.state.queuesList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in queuesDataFields) {
            if (queuesDataFields[i] === 'Queue name') {
                newRow[queuesDataFields[i]] = this.state.queueName;
            } else if (queuesDataFields[i] === 'ACW') {
                newRow[queuesDataFields[i]] = this.state.acwType;
            } else if (queuesDataFields[i] === 'ACW timeout') {
                newRow[queuesDataFields[i]] = this.state.acwTimeout;
            } else if (queuesDataFields[i] === 'Evaluation method') {
                newRow[queuesDataFields[i]] = this.state.evaluationMethod;
            } else if (queuesDataFields[i] === 'Alerting timeout') {
                newRow[queuesDataFields[i]] = this.state.alertingTimeout;
            } else if (queuesDataFields[i] === 'SL percentage') {
                newRow[queuesDataFields[i]] = this.state.slPercentage;
            } else if (queuesDataFields[i] === 'SL duration') {
                newRow[queuesDataFields[i]] = this.state.slDuration;
            } else if (queuesDataFields[i] === 'Calling party name') {
                newRow[queuesDataFields[i]] = this.state.callingPartyName;
            } else if (queuesDataFields[i] === 'Calling party number') {
                newRow[queuesDataFields[i]] = this.state.callingPartyNumber;
            } else if (queuesDataFields[i] === 'Wrapup codes') {
                newRow[queuesDataFields[i]] = this.state.wrapUpCodes;
            } else if (queuesDataFields[i] === 'Division') {
                newRow[queuesDataFields[i]] = this.state.selectedDivision;
            }
        }

        queuesList.push(newRow);

        this.setState({
            isQueuesInfoOpen: false,
            queuesList: queuesList,
        });
    }

    handleInputChange = (fieldName) => (e) => {
        if (fieldName === 'wrapUpCodes') {
            const options = e.target.selectedOptions;
            let value = [];
            for (let i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }
            this.setState({
                [fieldName]: value
            })
        } else if (fieldName === 'acwType') {
            if (e.target.value === 'MANDATORY' || e.target.value === 'OPTIONAL') {
                this.setState({
                    isacwTimeOut: true,
                    ['acwTimeout']: ''
                });
            } else {
                this.setState({
                    isacwTimeOut: false,
                });
            }
            this.setState({
                [fieldName]: e.target.value
            });
        } else {
            this.setState({
                [fieldName]: e.target.value
            });
        }

    }

    updateState = (rowInfo) => {
        this.setState({
            queueName: rowInfo['Queue name'],
            acwType: rowInfo['ACW'],
            acwTimeout: rowInfo['ACW timeout'],
            evaluationMethod: rowInfo['Evaluation method'],
            alertingTimeout: rowInfo['Alerting timeout'],
            slPercentage: rowInfo['SL percentage'],
            slDuration: rowInfo['SL duration'],
            callingPartyName: rowInfo['Calling party name'],
            callingPartyNumber: rowInfo['Calling party number'],
            wrapUpCodes: rowInfo['Wrapup codes'],
            selectedDivision: rowInfo['Division'],
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isQueuesInfoOpen: true,
        });
    }

    updateFunction = () => {
        let queuesList = [...this.state.queuesList];
        for (let queue of queuesList) {
            if (queue.rowid === this.state.rowID) {
                queue['Queue name'] = this.state.queueName;
                queue['ACW'] = this.state.acwType;
                queue['ACW timeout'] = this.state.acwTimeout;
                queue['Evaluation method'] = this.state.evaluationMethod;
                queue['Alerting timeout'] = this.state.alertingTimeout;
                queue['SL percentage'] = this.state.slPercentage;
                queue['SL duration'] = this.state.slDuration;
                queue['Calling party name'] = this.state.callingPartyName;
                queue['Calling party number'] = this.state.callingPartyNumber;
                queue['Wrapup codes'] = this.state.wrapUpCodes;
                queue['Division'] = this.state.selectedDivision;
            }
        }

        this.setState({
            queuesList: queuesList,
            isQueuesInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const queuesData = await CsvReader.parseCsvFileToQueuesData(fileContent);
        await this.setState({ queuesList: [...this.state.queuesList, ...queuesData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importQueues = async () => {
        this.eventList = [];
        const queuesList = this.state.queuesList;
        for (const i in queuesList) {
            const queueData = queuesList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing queue [${queueData["Queue name"]}]`);
            if (this.queueDataValidation(queueData, i, queuesList)) {
                try {
                    let selectedDivisionId = '';
                    let selectedDivision = this.state.divisionList.find(el => el.name === queueData['Division']);
                    if (selectedDivision != undefined) {
                        selectedDivisionId = selectedDivision.id;
                    }
                    // <create queue>          
                    const queue = await routingPostQueue(this.state.env, this.state.token, selectedDivisionId, queueData["Queue name"], queueData.ACW, queueData["ACW timeout"], queueData["Evaluation method"], queueData["Alerting timeout"], queueData["SL percentage"], queueData["SL duration"], queueData["Calling party name"], queueData["Calling party number"]);
                    console.log(`queue created: ${JSON.stringify(queue)}`);
                    // </create queue>
                    // <assign wrap-up codes>
                    const queueWrapUpCodes = queueData["Wrapup codes"];
                    const queueWrapUpCodeIds = this.getWrapUpIdList(queueWrapUpCodes);
                    routingPostQueuesWrapUpCodes(this.state.env, this.state.token, queue.id, queueWrapUpCodeIds)
                    // </assign wrap-up codes>
                    this.logEvent(`Queue [${queueData["Queue name"]}] successfully processed`);
                    this.removeRowClicked(queueData.rowid);
                } catch (err) {
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing ${this.props.cardTitle}`, count, this.props.cardTitle);
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    queueDataValidation = (queue, i, queuesData) => {
        let isqueueValidated = true;
        // <validate fields that can't be empty>
        if (!queue["Queue name"].trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isqueueValidated = false; }
        // </validate fields that can't be empty>

        // <validate if wrapup code exist>
        var wrapupsArray = queue["Wrapup codes"];
        for (const queueWrapup of wrapupsArray) {
            var queueWrapupValid = false;
            for (const wrapup of this.state.wrapUpCodeList) {
                if (queueWrapup.toLowerCase() === wrapup.name.toLowerCase()) {
                    queueWrapupValid = true;
                }
            }
            if (!queueWrapupValid) { this.logEvent(`[Index ${i}] Wrapup code '${queueWrapup}' doesn't exist`, true); isqueueValidated = false; }
        }
        // </validate if wrapup code exist>

        // <validate if site name is unique>
        for (const j in queuesData) {
            const site1 = queuesData[j];
            if (i === j) { continue; }
            if (queue["Queue name"].trim().toLowerCase() === site1["Queue name"].trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isqueueValidated = false; }
        }
        // </validate if site name is unique>

        return isqueueValidated;
    }

    getWrapUpIdList = (queueWrapUpCodes) => {
        var result = [];
        for (const i in queueWrapUpCodes) {
            const wrapUpCode = this.state.wrapUpCodeList.filter(s => s.name.toLowerCase() === queueWrapUpCodes[i].toLowerCase())[0];
            if (wrapUpCode) { result.push({ "id": wrapUpCode.id }); }
        }
        return result;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.queuesList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Queues...." });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('1');
            }
        }
    }


    render() {
        let MAP = {
            name: "queue-map",
            areas: [
                { name: "0", shape: "circle", coords: [115, 16, 15] }
            ]
        }
        return (
            <div className="queuesCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <QueuesInfo
                    isOpen={this.state.isQueuesInfoOpen}
                    isSaveLocale={this.state.isSaveLocale}
                    queueName={this.state.queueName}
                    acwType={this.state.acwType}
                    acwTimeout={this.state.acwTimeout}
                    evaluationMethod={this.state.evaluationMethod}
                    alertingTimeout={this.state.alertingTimeout}
                    slPercentage={this.state.slPercentage}
                    slDuration={this.state.slDuration}
                    callingPartyName={this.state.callingPartyName}
                    callingPartyNumber={this.state.callingPartyNumber}
                    wrapUpCodes={this.state.wrapUpCodes}
                    wrapUpCodeList={this.state.wrapUpCodeList}
                    divisionList={this.state.divisionList}
                    selectedDivision={this.state.selectedDivision}
                    isacwTimeOut={this.state.isacwTimeOut}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isQueuesInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 queueCardBody">
                        <CardTitle className="m-0 queueTitle">
                            <div className="QueuesTitleDiv">
                                <Badge className="titleBadgeQueues">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="QueuesImageMapper">
                                    <ImageMapper className="QueuesImageMapper" src="/images/pq.png" map={MAP} width={150} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px", marginTop: "-20px" }}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/queues-full-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyQueues">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonQueues" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonQueues">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage} />
                            <div className="PQueues-wrap">
                                <ReactTable
                                    data={this.state.queuesList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.queuesList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Queue Name",
                                            accessor: "Queue name",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{
                                                width: "400px", height: "25px", display: "block", cursor: "pointer"
                                                , backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius: (this.state.selectAll != 0) ? "25px" : "0px"
                                            }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "30vmin" }}
                                    noDataText="No Queues loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonQueues" onClick={this.importQueues} disabled={this.state.queuesList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
