import './extensionsInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input } from 'reactstrap'

export default class ExtensionsInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.startNumber !== '' && this.props.endNumber !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="extensionsModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="startNumber">Start Number:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.startNumber === '' ? true : false} value={this.props.startNumber} onChange={this.props.handleInputChange('startNumber')} type="text" id="startNumber" placeholder="Enter starting number" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="endNumber">End Number:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.endNumber === '' ? true : false} value={this.props.endNumber} onChange={this.props.handleInputChange('endNumber')} type="text" id="endNumber" placeholder="Enter ending number" />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
