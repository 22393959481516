import {
    telephonyGetDids,
    telephonyGetEdgeGroups,
    getWidgetsList,
    telephonyGetListOfExtensionPools,
    telephonyGetPhoneBaseSettings,
    telephonyGetPhonesById,
    telephonyGetPhonesList,
    telephonyGetSites
} from "../purecloud/telephony";
import Papa from "papaparse";
import { usersGetUsers } from "../purecloud/users";
import {
    getAuthorizationDivisions,
    authorizationGetListOfTheUsersInSpecifiedRole,
    getAuthorizationRoles
} from "../purecloud/authorization";
import { getRoutingGetQueues, getRoutingGetQueueUsers, routingGetQueueWrapUpCode, getRoutingGetSkills, getRoutingWrapupCodes } from "../purecloud/routing";
import { getArchitects } from "../purecloud/architect";
import moment from "moment";
import { getlocations } from "../purecloud/locations";
import Misc from "./misc";
import { logActionData } from '../session/session'

let entity_bulkExport = [];
let count_bulkExport = [];

export const extractPhoneBaseSettingsData = async (env, token, isBulkExport = false) => {
    const phoneBaseSettings = await telephonyGetPhoneBaseSettings(env, token);
    if (phoneBaseSettings) {
        const phonesForExport = phoneBaseSettings.map(phone => {
            let result = {};
            result = {
                ...result, ["name"]: phone.name,
                ["phoneMetaBase"]: phone.phoneMetaBase.name,
            };
            return result;
        });
        var phonesBaseSettingData = JSON.parse(JSON.stringify(phonesForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Phones-base-settings');
            count_bulkExport.push(phonesBaseSettingData.length);
        } else {
            await logActionData('Export', 'Exporting Phone Base Settings', phonesBaseSettingData.length, 'Phones-base-settings');
        }
        const csvString = Papa.unparse([...phonesBaseSettingData]);
        return new Blob([csvString]);
    }
};

export const extractDidsData = async (env, token, isBulkExport = false) => {
    const didsList = await telephonyGetDids(env, token);
    if (didsList) {
        var didsForExport = didsList.map(dids => {
            let result = {};
            result = {
                ...result, ["startPhoneNumber"]: dids.startPhoneNumber,
                ["endPhoneNumber"]: dids.endPhoneNumber,
                ["provider"]: dids.description ? dids.description : "",
                ["comments"]: dids.comments ? dids.comments : ""
            };
            return result;
        });
        var didData = JSON.parse(JSON.stringify(didsForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Dids');
            count_bulkExport.push(didData.length);
        } else {
            await logActionData('Export', 'Exporting Dids', didData.length, 'Dids');
        }
        const csvString = Papa.unparse([...didData]);
        return new Blob([csvString]);
    }
};

export const extractEdgeGroupsData = async (env, token, isBulkExport = false) => {
    const edgeGroupsList = await telephonyGetEdgeGroups(env, token);
    if (edgeGroupsList) {
        var edgesForExport = edgeGroupsList.map(edge => {
            let result = {};
            result = {
                ...result, ["name"]: edge.name,
                ["description"]: edge.description !== undefined ? edge.description : "",
                ["managed"]: edge.managed,
                ["phoneTrunkBaseName"]: edge.phoneTrunkBases[0].name
            };
            return result;
        });
        var edgeGroupData = JSON.parse(JSON.stringify(edgesForExport));
        if (isBulkExport) {
            entity_bulkExport.push('EdgeGroups');
            count_bulkExport.push(edgeGroupData.length);
        } else {
            await logActionData('Export', 'Exporting Edge Groups', edgeGroupData.length, 'EdgeGroups');
        }
        const csvString = Papa.unparse([...edgeGroupData]);
        return new Blob([csvString]);
    }
};


export const extractExtensionsData = async (env, token, isBulkExport = false) => {
    const extensionsList = await telephonyGetListOfExtensionPools(env, token);
    if (extensionsList) {
        var extensionsForExport = extensionsList.map(extension => {
            let result = {};
            result = {
                ...result, ["startNumber"]: extension.startNumber, ["endNumber"]: extension.endNumber
            }
            return result;
        });
        var extensionData = JSON.parse(JSON.stringify(extensionsForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Extensions');
            count_bulkExport.push(extensionData.length);
        } else {
            await logActionData('Export', 'Exporting Extensions', extensionData.length, 'Extensions');
        }
        const csvString = Papa.unparse([...extensionData]);
        return new Blob([csvString]);
    }
};

export const extractIvrRoutingData = async (env, token, isBulkExport = false) => {
    var ivrsRouting = await getArchitects(env, token, 'ivrs');
    if (ivrsRouting) {
        var ivrsForExport = ivrsRouting.map(ivr => {
            let result = {};
            result = {
                ...result, ["Name"]: ivr.name,
                ["Addresses"]: ivr.dnis ? ivr.dnis.map(item => `${item}`).join('|') : "",
                ["Schedule Group"]: ivr.scheduleGroup ? ivr.scheduleGroup.name : "",
                ["Open Hours Flow"]: ivr.openHoursFlow ? ivr.openHoursFlow.name : "",
                ["Closed Hours flow"]: ivr.closedHoursFlow ? ivr.closedHoursFlow.name : "",
                ["Holiday Hours flow"]: ivr.holidayHoursFlow ? ivr.holidayHoursFlow.name : ""
            };
            return result;
        });
        const ivrsRoutingData = JSON.parse(JSON.stringify(ivrsForExport));
        if (isBulkExport) {
            entity_bulkExport.push('IvrsRoutings');
            count_bulkExport.push(ivrsRoutingData.length);
        } else {
            await logActionData('Export', 'Exporting IVR Routings', ivrsRoutingData.length, 'IvrsRoutings');
        }
        const csvString = Papa.unparse([...ivrsRoutingData]);
        return new Blob([csvString]);
    }
};

export const extractWidgetsData = async (env, token, isBulkExport = false) => {
    var widgets = await getWidgetsList(env, token, 'ivrs');
    if (widgets) {
        var widgetsForExport = widgets.map(widget => {
            let result = {};
            result = {
                ...result, ["Name"]: widget.name,
                ["Description"]: widget.description,
                ["AuthenticationRequired"]: widget.authenticationRequired,
                ["WidgetType"]: widget.clientType,
                ["Flow"]: widget.flow != null ? widget.flow.name : null,
                ["AllowedDomains"]: widget.allowedDomains ? widget.allowedDomains.map(item => `${item}`).join('|') : "",
                ["WebChatSkin"]: widget.clientType == 'v1' ? widget.clientConfig.v1.webChatSkin : (widget.clientType == 'v1-http'? widget.clientConfig["v1-http"].webChatSkin : null),
                ["AuthenticationUrl"]: widget.clientType == 'v1' ?  widget.clientConfig.v1.authenticationUrl : (widget.clientType == 'v1-http'? widget.clientConfig["v1-http"].authenticationUrl : null)
            };
            return result;
        });
        const widgetsData = JSON.parse(JSON.stringify(widgetsForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Widgets');
            count_bulkExport.push(widgetsData.length);
        } else {
            await logActionData('Export', 'Exporting Widgets', widgetsData.length, 'Widgets');
        }
        const csvString = Papa.unparse([...widgetsData]);
        return new Blob([csvString]);
    }
};

export const extractLocations = async (env, token, isBulkExport = false) => {
    const locationsList = await getlocations(env, token);
    if (locationsList) {
        var locationsForExport = locationsList.map(location => {
            let result = {};
            result = { ...result, ["name"]: location.name };
            if (location.address && location.emergencyNumber) {
                result = {
                    ...result, ["street1"]: location.address.street1,
                    ["street2"]: "",
                    ["city"]: location.address.city,
                    ["state"]: location.address.state,
                    ["zip"]: location.address.zipcode,
                    ["countryAbbreviation"]: location.address.country,
                    ["country"]: location.address.countryName,
                    ["notes"]: location.notes,
                    ["emergencyNumber"]: location.emergencyNumber.e164,
                }
            }
            return result;
        });
        const locationData = JSON.parse(JSON.stringify(locationsForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Locations');
            count_bulkExport.push(locationData.length);
        } else {
            await logActionData('Export', 'Exporting Locations', locationData.length, 'Locations');
        }
        const csvString = Papa.unparse([...locationData]);
        return new Blob([csvString]);
    }
};

export const extractPhonesData = async (env, token, isBulkExport = false) => {
    let phonesList = await telephonyGetPhonesList(env, token);
    if (phonesList) {
        let phonesForExport = [];
        for (const phone of phonesList) {
            var webRtcUserName = "";
            let remoteAddress = "";
            let result = {};
            var detailedPhone = "";
            if (phone.phoneMetaBase.name === 'PureCloud WebRTC Phone') {
                try {
                    detailedPhone = await telephonyGetPhonesById(env, token, phone.id);
                    webRtcUserName = detailedPhone.webRtcUser.name;
                } catch (err) {
                    if (err.status === 429) {
                        await Misc.sleep(20000);
                    }
                }

            } else {
                if (phone.lines[0] !== undefined && phone.lines[0].properties !== undefined && phone.lines[0].properties.station_remote_address !== undefined) {
                    remoteAddress = phone.lines[0].properties.station_remote_address.value.instance;
                }
            }
            result = {
                ...result, ["name"]: phone.name,
                ["phoneBaseSettings"]: phone.phoneBaseSettings.name,
                ["site"]: phone.site.name,
                ["lineBaseSettings"]: phone.lineBaseSettings.name,
                ["person"]: webRtcUserName,
                ["remoteAddress"]: remoteAddress
            };
            phonesForExport.push(result);
        }
        let phoneData = JSON.parse(JSON.stringify(phonesForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Phones');
            count_bulkExport.push(phoneData.length);
        } else {
            await logActionData('Export', 'Exporting Phones', phoneData.length, 'Phones');
        }
        const csvString = Papa.unparse([...phoneData]);
        return new Blob([csvString]);
    }
};

export const extractQueuesData = async (env, token, isBulkExport = false) => {
    var queues = await getRoutingGetQueues(env, token);
    console.log(JSON.stringify(queues));
    if (queues) {
        var queuesForExport = [];
        for (const queue of queues) {
            var result = {};
            try {
                var wrapUpCodesByQueueId = await routingGetQueueWrapUpCode(env, token, queue.id);
                result = {
                    ...result, ["Queue name"]: queue.name,
                    ["Division"]: queue.division.name,
                    ["ACW"]: queue.acwSettings ? queue.acwSettings.wrapupPrompt : "",
                    ["ACW timeout"]: queue.acwSettings.timeoutMs ? queue.acwSettings.timeoutMs / 1000 : "",
                    ["Evaluation method"]: queue.skillEvaluationMethod,
                    ["Alerting timeout"]: queue.mediaSettings.call ? queue.mediaSettings.call.alertingTimeoutSeconds : "",
                    ["SL percentage"]: queue.mediaSettings.call ? queue.mediaSettings.call.serviceLevel.percentage * 100 : "",
                    ["SL duration"]: queue.mediaSettings.call ? queue.mediaSettings.call.serviceLevel.durationMs / 1000 : "",
                    ["Calling party name"]: queue.callingPartyName ? queue.callingPartyName : "",
                    ["Calling party number"]: queue.callingPartyNumber ? queue.callingPartyNumber : "",
                    ["Wrapup codes"]: wrapUpCodesByQueueId ? wrapUpCodesByQueueId.map(item => `${item.name}`).join('|') : ""
                };
                queuesForExport.push(result);
            } catch (err) {
                if (err.status === 429) {
                    await Misc.sleep(20000);
                }
            }
        }
        var queueData = JSON.parse(JSON.stringify(queuesForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Queues');
            count_bulkExport.push(queueData.length);
        } else {
            await logActionData('Export', 'Exporting Queues', queueData.length, 'Queues');
        }
        const csvString = Papa.unparse([...queueData]);
        return new Blob([csvString]);
    }
};

export const extractScheduleGroupsData = async (env, token, isBulkExport = false) => {
    var scheduleGroups = await getArchitects(env, token, 'scheduleGroups');
    if (scheduleGroups) {
        var scheduleGroupForExport = scheduleGroups.map(schedule => {
            let result = {};
            result = {
                ...result, ["name"]: schedule.name,
                ["timeZone"]: schedule.timeZone,
                ["openSchedules"]: schedule.openSchedules ? schedule.openSchedules.map(item => `${item.name}`).join('|') : "",
                ["closedSchedules"]: schedule.closedSchedules ? schedule.closedSchedules.map(item => `${item.name}`).join('|') : "",
                ["holidaySchedules"]: schedule.holidaySchedules ? schedule.holidaySchedules.map(item => `${item.name}`).join('|') : ""
            };
            return result;
        });
        const scheduleGroupData = JSON.parse(JSON.stringify(scheduleGroupForExport));
        if (isBulkExport) {
            entity_bulkExport.push('ScheduleGroups');
            count_bulkExport.push(scheduleGroupData.length);
        } else {
            await logActionData('Export', 'Exporting Schedule Groups', scheduleGroupData.length, 'ScheduleGroups');
        }
        const csvString = Papa.unparse([...scheduleGroupData]);
        return new Blob([csvString]);
    }
};

export const extractSchedulesData = async (env, token, isBulkExport = false) => {
    var schedules = await getArchitects(env, token, 'schedules');
    if (schedules) {
        var schedulesForExport = schedules.map(item => {
            let result = {};
            result = {
                ...result, ["name"]: item.name,
                ["start"]: moment(new Date(item.start)).format("YYYY-MM-DD" + "T" + "HH:mm"),
                ["end"]: moment(new Date(item.end)).format("YYYY-MM-DD" + "T" + "HH:mm"),
                ["rrule"]: item.rrule ? item.rrule : ""
            };
            return result;
        });
        var scheduleData = JSON.parse(JSON.stringify(schedulesForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Schedules');
            count_bulkExport.push(scheduleData.length);
        } else {
            await logActionData('Export', 'Exporting Schedules', scheduleData.length, 'Schedules');
        }
        const csvString = Papa.unparse([...scheduleData]);
        return new Blob([csvString]);
    }
};

export const extractSkillsMatrixData = async (env, token, isBulkExport = false) => {
    const userList = await usersGetUsers(env, token);
    if (userList) {
        const skillsListForExport = userList.filter(user => user.skills && user.skills.length > 0).map(user => {
            let result = {};
            var userEmail = user.email;
            result = { ...result, ["User email"]: userEmail }
            user.skills.forEach((skill, index) => {
                result = { ...result, [`Skill${index + 1}`]: skill.name, [`Proficiency${index + 1}`]: skill.proficiency }
            });
            return result;
        });

        skillsListForExport.sort(function (firstElem, secondElem) {
            var countFirstElem = 0;
            var countSecondElem = 0;
            Object.keys(firstElem).map(key => {
                countFirstElem = countFirstElem + 1;
            });
            Object.keys(secondElem).map(key => {
                countSecondElem = countSecondElem + 1;
            });
            return countSecondElem - countFirstElem;
        });
        const skillsMatrix = JSON.parse(JSON.stringify(skillsListForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Skills-matrix');
            count_bulkExport.push(skillsMatrix.length);
        } else {
            await logActionData('Export', 'Exporting Skills Matrix', skillsMatrix.length, 'Skills-matrix');
        }
        const csvString = Papa.unparse([...skillsMatrix]);
        return new Blob([csvString]);
    }
};

export const extractDivisionsData = async (env, token, isBulkExport = false) => {
    let divisionsList = await getAuthorizationDivisions(env, token);
    if (divisionsList) {
        let divisionsForExport = divisionsList.map(division => {
            let result = {};
            result = { ...result, ["Division name"]: division.name };
            return result;
        });

        const divisionsData = JSON.parse(JSON.stringify(divisionsForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Divisions');
            count_bulkExport.push(divisionsData.length);
        } else {
            await logActionData('Export', 'Exporting Divisions', divisionsData.length, 'Divisions');
        }
        const csvString = Papa.unparse([...divisionsData]);
        return new Blob([csvString]);
    }
};

export const extractSkillsData = async (env, token, isBulkExport = false) => {
    let skillsList = await getRoutingGetSkills(env, token);
    if (skillsList) {
        let skillsForExport = skillsList.map(skill => {
            let result = {};
            result = { ...result, ["Skill name"]: skill.name };
            return result;
        });

        const skillsData = JSON.parse(JSON.stringify(skillsForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Skills');
            count_bulkExport.push(skillsData.length);
        } else {
            await logActionData('Export', 'Exporting Skills', skillsData.length, 'Skills');
        }
        const csvString = Papa.unparse([...skillsData]);
        return new Blob([csvString]);
    }
};

export const extractWrapUpCodesData = async (env, token, isBulkExport = false) => {
    let wrapUpCodesList = await getRoutingWrapupCodes(env, token);
    if (wrapUpCodesList) {
        let wrapUpCodesForExport = wrapUpCodesList.map(codes => {
            let result = {};
            result = { ...result, ["Wrap-up code name"]: codes.name };
            return result;
        });

        const codesData = JSON.parse(JSON.stringify(wrapUpCodesForExport));
        if (isBulkExport) {
            entity_bulkExport.push('WrapUpCodes');
            count_bulkExport.push(codesData.length);
        } else {
            await logActionData('Export', 'Exporting Wrap-Up Codes', codesData.length, 'WrapUpCodes');
        }
        const csvString = Papa.unparse([...codesData]);
        return new Blob([csvString]);
    }
};

export const extractRolesData = async (env, token, isBulkExport = false) => {
    let rolesList = await getAuthorizationRoles(env, token);
    if (rolesList) {
        let rolesForExport = rolesList.map(roles => {
            let result = {};
            result = { ...result, ["Roles name"]: roles.name };
            return result;
        });

        const rolesData = JSON.parse(JSON.stringify(rolesForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Roles');
            count_bulkExport.push(rolesData.length);
        } else {
            await logActionData('Export', 'Exporting Roles', rolesData.length, 'Roles');
        }
        const csvString = Papa.unparse([...rolesData]);
        return new Blob([csvString]);
    }
};

export const extractSitesData = async (env, token, isBulkExport = false) => {
    const sitesList = await telephonyGetSites(env, token);
    if (sitesList) {
        var sitesDataForExport = sitesList.map(site => {
            let result = {};
            result = { ...result, ["SiteName"]: site.name, ["Description"]: site.description !== undefined ? site.description : "" };
            if (site.edgeAutoUpdateConfig && site.location) {
                result = {
                    ...result, ["Location"]: site.location.name,
                    ["Recurrence Type"]: site.edgeAutoUpdateConfig.rrule,
                    ["Time Zone"]: site.edgeAutoUpdateConfig.timeZone,
                    ["Start Time"]: site.edgeAutoUpdateConfig.start,
                    ["End Time"]: site.edgeAutoUpdateConfig.end
                };
            }
            return result;
        });
        const siteData = JSON.parse(JSON.stringify(sitesDataForExport));
        if (isBulkExport) {
            entity_bulkExport.push('Sites');
            count_bulkExport.push(siteData.length);
        } else {
            await logActionData('Export', 'Exporting Sites', siteData.length, 'Sites');
        }
        const csvString = Papa.unparse([...siteData]);
        return new Blob([csvString]);
    }
};

export const extractUsersData = async (env, token, isBulkExport = false) => {
    var users = await usersGetUsers(env, token);
    var rolesList = await getAuthorizationRoles(env, token);
    var queuesList = await getRoutingGetQueues(env, token);

    let usersQueueList = [];
    for (const queue of queuesList) {
        var queuesUsersIds = "";
        try {
            queuesUsersIds = await getRoutingGetQueueUsers(env, token, queue.id);
        } catch (err) {
            if (err.status === 429) {
                await Misc.sleep(20000);
            }
        }
        if (queuesUsersIds) {
            let result = {};
            result = {
                ...result, ["name"]: queue.name,
                ["usersIds"]: queuesUsersIds.map(usersIds => usersIds.id)
            };
            usersQueueList.push(result);
        }
    }

    let userRoleList = [];
    for (const role of rolesList) {
        var usersIdsForRole = "";
        var userRoles = [];
        var userCount = role.userCount;
        var pageCount = 1;
        try {
            while (userCount > 0) {
                usersIdsForRole = await authorizationGetListOfTheUsersInSpecifiedRole(env, token, role.id, pageCount);
                if (usersIdsForRole) {
                    userCount = userCount - usersIdsForRole.length;
                    pageCount += 1;
                    userRoles.push(...usersIdsForRole);
                }
            }
        } catch (err) {
            if (err.status === 429) {
                await Misc.sleep(20000);
            }
        }

        if (userRoles) {
            let result = {};
            result = {
                ...result, ["roleName"]: role.name,
                ["usersIds"]: userRoles.map(usersIds => usersIds.id)
            };
            userRoleList.push(result);
        }
    }

    let usersForExport = [];
    for (const user of users) {
        let roles = [];
        userRoleList.forEach(item => {
            let userIds = item.usersIds.filter(userId => userId === user.id);
            if (userIds && userIds.length) {
                roles.push(item.roleName);
            }
        });

        let queues = [];
        usersQueueList.forEach(item => {
            let usersIds = item.usersIds.filter(userId => userId === user.id);
            if (usersIds && usersIds.length) {
                queues.push(item.name);
            }
        });

        let phoneWork = "";
        if (user.addresses && user.addresses.length) {
            let workAddress = user.addresses.filter(addr => addr.type === "WORK");
            if (workAddress && workAddress.length) {
                phoneWork = workAddress[0].address ? workAddress[0].address : workAddress[0].display;
                phoneWork = phoneWork.replace(/[()]/g, '');
            }
        }

        let result = {};
        result = {
            ...result, ["name"]: user.name,
            ["email"]: user.email,
            //if user don't have permissions for getting password, it will be blank
            ["password"]: user.password ? user.password : "",
            ["phone_work"]: phoneWork,
            ["title"]: user.title ? user.title : "",
            ["department"]: user.department ? user.department : "",
            //We aren't saving this fields
            ["email_manager"]: "",
            ["location_work"]: "",
            //
            ["roles"]: roles ? roles.map(item => `${item}`).join('|') : "",
            ["queues"]: queues ? queues.map(item => `${item}`).join('|') : "",
            ["division"]: user.division.name,
            ["skills"]: user.skills ? user.skills.map(item => `${item.name}`).join('|') : "",
            ["proficiencies"]: user.skills ? user.skills.map(item => `${item.proficiency}`).join('|') : ""
        };
        usersForExport.push(result);
    }
    const userData = JSON.parse(JSON.stringify(usersForExport));
    if (isBulkExport) {
        entity_bulkExport.push('Users');
        count_bulkExport.push(userData.length);
        await logActionData('BulkExport', 'Bulk Exporting Objects', count_bulkExport.join('|'), entity_bulkExport.join('|'));
    } else {
        await logActionData('Export', 'Exporting Users', userData.length, 'Users');
    }
    const csvString = Papa.unparse([...userData]);
    return new Blob([csvString]);
};