import './widgetsInfo.css';
import React from 'react';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row, Table } from 'reactstrap';
import { FaTrash, FaPlusCircle } from 'react-icons/fa';
import Switch from 'react-ios-switch';


export default class WidgetsInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        var httpsRegex = new RegExp(/^((https):\/\/)?.([A-z]+)\.([A-z]{2,})/);
        if (this.props.widgetsName != '' && this.props.selectedWidgetsType != '') {
            if (this.props.selectedWidgetsType == 'Version 1.0' || this.props.selectedWidgetsType == 'Version 1.1') {
                if (this.props.requireAuth) {
                    if (this.props.authUrl != '') {
                        if (httpsRegex.test(this.props.authUrl)) {
                            isButtonEnabled = true
                        }
                    }
                } else {
                    isButtonEnabled = true
                }
            } else {
                isButtonEnabled = true;
            }
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="ivrRoutingModal">
                    <ModalHeader>{'Widgets Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={3} for="widgetsName">Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={9}>
                                    <Input invalid={this.props.widgetsName === '' ? true : false} value={this.props.widgetsName} onChange={this.props.handleInputChange('widgetsName')} id="widgetsName" type="text" placeholder="Enter the name of the Widget" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} for="description">Description:</Label>
                                <Col sm={9}>
                                    <Input value={this.props.description} onChange={this.props.handleInputChange('description')} id="description" type="text" />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>

                                    <Label sm={3} for="widgetsType">Widget Type:<span className="text-danger font-weight-bold">*</span></Label>
                                    <Col sm={9}>
                                        <select className="form-control selectpicker" value={this.props.selectedWidgetsType} onChange={this.props.handleInputChange('selectedWidgetsType')}>
                                            <option hidden>Select a Widget Type</option>
                                            {
                                                this.props.widgetsType.map((type, index) => (
                                                    <option key={index}>{type}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            {
                                this.props.selectedWidgetsType != 'Version 1.0' &&
                                <FormGroup>
                                    <Row>
                                        <Label sm={3} for="flow">Route To Flow:</Label>
                                        <Col sm={9}>
                                            <select className="form-control selectpicker" value={this.props.selectedFlow} onChange={this.props.handleInputChange('selectedFlow')}>
                                                <option hidden>Select an Inbound Chat Flow</option>
                                                {
                                                    this.props.inboundChatFlowList.map((flow) => (
                                                        <option key={flow.id}>{flow.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            }
                            {(this.props.selectedWidgetsType == 'Version 2' || this.props.selectedWidgetsType == 'Third Party') &&
                                <FormGroup row>
                                    <Label sm={3} for="domains">Allowed Domains:</Label>
                                    <FaPlusCircle title="Assign DIDs to be used with the Routing definition" sm={1} style={{ height: '2.5em', cursor: 'pointer' }} onClick={this.props.addDomains} />
                                    <Col sm={8}>
                                        <div className="did-wrap">
                                            <Table striped bordered className="table-condensed did-table ">
                                                <thead>
                                                    <tr className="didHeader">
                                                        <th>Domains</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.props.domains.map((domain, index) => {
                                                            return (
                                                                <tr key={index} style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.props.handleRowClick(domain)}>
                                                                    <td className="p-1">{domain}</td>
                                                                    <td className="p-1 did-button-cell"><FaTrash onClick={(e) => { this.props.deleteButtonClicked(domain, e) }} /></td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </FormGroup>}
                            {
                                (this.props.selectedWidgetsType == 'Version 1.1' || this.props.selectedWidgetsType == 'Version 1.0' || this.props.selectedWidgetsType == 'Third Party') &&
                                <FormGroup row className="rowAttrCamp">
                                    <Label sm={4} for="requireAuth">Require Authentication:</Label>
                                    <Col sm={4}>
                                        <Switch id="requireAuth" name="Arun" checked={this.props.requireAuth} onColor="rgb(6, 30, 69)" className="switch" style={{ transform: 'scale(0.7)' }} onChange={this.props.handleOnChange('requireAuth')} />
                                    </Col>
                                </FormGroup>
                            }
                            {
                                (this.props.selectedWidgetsType == 'Version 1.1' || this.props.selectedWidgetsType == 'Version 1.0') &&
                                <div>
                                    <FormGroup row className="rowAttrCamp">
                                        <Label sm={4} for="chatAppearance">Chat Apperarance:</Label>
                                        <div>
                                            <Col sm={4}>
                                                <Label><Input type="radio" name="chatAppearance" value="Modern" checked={this.props.selectedOption == 'Modern'} onChange={this.props.handleInputChange('selectedOption')} />Modern</Label>
                                            </Col>
                                            <Col sm={4}>
                                                <Label><Input type="radio" name="chatAppearance" value="Basic" checked={this.props.selectedOption == 'Basic'} onChange={this.props.handleInputChange('selectedOption')} />Basic</Label>
                                            </Col>
                                        </div>
                                    </FormGroup>
                                </div>

                            }
                            {
                                this.props.requireAuth && this.props.selectedWidgetsType != 'Third Party' && this.props.selectedWidgetsType != 'Version 2' &&
                                <FormGroup row className="rowAttrCamp">
                                    <Label sm={3} for="authUrl">Authentication URL:<span className="text-danger font-weight-bold">*</span></Label>
                                    <Col sm={9}>
                                        <Input value={this.props.authUrl} onChange={this.props.handleInputChange('authUrl')} id="authUrl" type="text" placeholder="Enter Auth Url (https://example.endpoint.com/path) to enable it" />
                                    </Col>
                                </FormGroup>

                            }
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isDomainSaveLocale
                                ?
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}