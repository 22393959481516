import './address.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input } from 'reactstrap'

export default class Address extends React.Component {
    render() {

        let isdidButtonEnabled = false;
        if (this.props.didNumber !== '') {
            isdidButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="ivrRoutingModal">
                    <ModalHeader>DIDs Configuration</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="didNumber">Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.didNumber === '' ? true : false} value={this.props.didNumber} onChange={this.props.handleInputChange('didNumber')} id="didNumber" type="text" placeholder="Enter address" />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isAddressSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateDidFunction} disabled={!isdidButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.addDidFunction} disabled={!isdidButtonEnabled}>Add</Button>
                        }

                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeAddressFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
