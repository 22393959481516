import moment from 'moment';
import config from '../../config';
const backEndURL = config.apiEndpoint;


export const logSessionData = async (userMail, org, action, text, sessionId, count, entity) => {
    let logData = {};
    logData['sessionId'] = sessionId;
    logData['logged'] = moment().format('YYYY-MMM-DD HH:mm:ss ') + moment.tz.zone(moment.tz.guess()).abbr(new Date().getTimezoneOffset());
    logData['loggedById'] = userMail;
    logData['entityName'] = entity;
    logData['action'] = action;
    logData['text'] = text;
    logData['org'] = org;
    logData['count'] = count;
    logData['toolType'] = 'Ignite';

    return fetch(backEndURL + `/admin/audit`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-accel-user': userMail,
            'x-accel-org': org,
        },
        body: JSON.stringify(logData)
    })
        .then(response => response.json())
        .then(response => {
            if (response.status < 200 || response.status > 299) throw response;
            return response;
        })
}