import { pureCloudLogout } from '../session/session'

export const usersGetUsers = async (env, token) => {
  let usersContainer = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await usersGetUsersWithPagination(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      usersContainer.push(respond)
    }
  }
  return [].concat(...usersContainer);
}

export const usersGetUsersWithPagination = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/users?pageSize=255&pageNumber=${index}&expand=skills`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const usersGetUserByEmail = (env, token, email) => {
  var body = {
    "query": [{
      "type": "EXACT",
      "fields": ["email"],
      "value": email
    }]
  }

  return fetch(`https://api.${env}/api/v2/users/search`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status < 200 || response.status > 299) throw new Error(response.message);
      return response;
    })
}

export const usersGetMe = (env, token) => {
  return fetch(`https://api.${env}/api/v2/users/me?expand=profileSkills`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
};

export const usersGetUsersRoles = (env, token, userId) => {
  return fetch(`https://api.${env}/api/v2/users/${userId}/roles`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
};

export const usersGetUsersQueues = (env, token, userId) => {
  return fetch(`https://api.${env}/api/v2/users/${userId}/queues`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
};

export const usersPostUser = (env, token, name, department, email, phoneWork, title, password, divisionId) => {

  var body = {
    'name': name,
    'department': department,
    'email': email,
    'addresses': [],
    'title': title,
    'password': password,
    'divisionId': divisionId
  }

  if (phoneWork) {
    const address = {
      'address': phoneWork,
      'mediaType': "PHONE",
      'type': "WORK"
    }
    body.addresses.push(address);
  }

  return fetch(`https://api.${env}/api/v2/users`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status < 200 || response.status > 299) throw new Error(response.message);
      return response;
    })
}

export const usersPutUserRoles = (env, token, userId, roleIdList) => {
  return fetch(`https://api.${env}/api/v2/users/${userId}/roles`, {
    method: 'PUT',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(roleIdList)
  })
    .then(response => response.json())
    .then(response => {
      if (response.status < 200 || response.status > 299) throw new Error(response.message)
      return response;
    })
}
