import './outboundQueues.css'
import React from 'react'

import { FaCodeBranch, FaPlus, FaTrash, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getRoutingWrapupCodes, routingPostQueue, routingPostQueuesWrapUpCodes } from '../../../../services/purecloud/routing';
import QueuesInfo from '../outboundQueues/queuesInfo';
import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import ImageMapper from 'react-image-mapper';
import { getAuthorizationDivisions } from '../../../../services/purecloud/authorization'

const uuidv1 = require('uuid/v1');
const queuesDataFields = [
    'Queue name',
    'Division',
    'ACW',
    'ACW timeout',
    'Evaluation method',
    'Alerting timeout',
    'SL percentage',
    'SL duration',
    'Calling party name',
    'Calling party number',
    'Wrapup codes'
];

export default class OutboundQueues extends React.Component {

    state = {
        env: '',
        token: '',
        queuesList: [],
        isQueuesInfoOpen: false,
        queueName: '',
        acwType: '',
        acwTimeout: '',
        evaluationMethod: '',
        alertingTimeout: '15',
        slPercentage: '80',
        slDuration: '20',
        callingPartyName: '',
        callingPartyNumber: '',
        wrapUpCodes: [],
        divisionList: [],
        selectedDivision: 'Home',
        rowID: null,
        isacwTimeOut: true,
        wrapUpCodeList: [],
        isSaveLocale: false,
        preloaderLocalShow: false,
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selectedWrapCodes: []
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount() {
        this.setState({ preloaderLocalShow: true });
        this.loadItems();
    }

    loadItems = async () => {
        this.setState({ 
            preloaderLocalShow: false,
            wrapUpCodeList: await getRoutingWrapupCodes(this.state.env, this.state.token),
            divisionList: await getAuthorizationDivisions(this.state.env, this.state.token) 
        });
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var queuesList = [...this.state.queuesList];
        for (const i in queuesList) {
            if (queuesList[i].rowid === rowid) {
                queuesList.splice(i, 1);
                break;
            }
        }
        await this.setState({ queuesList: queuesList });
    }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isQueuesInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            queueName: '',
            acwType: '',
            acwTimeout: '',
            evaluationMethod: '',
            alertingTimeout: '15',
            slPercentage: '80',
            slDuration: '20',
            callingPartyName: '',
            callingPartyNumber: '',
            wrapUpCodes: [],
            rowID: null,
            isacwTimeOut: true,
            selectedDivision: 'Home',
        })
    }

    handleSubmitFunction = () => {
        let queuesList = [...this.state.queuesList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in queuesDataFields) {
            if (queuesDataFields[i] === 'Queue name') {
                newRow[queuesDataFields[i]] = this.state.queueName;
            } else if (queuesDataFields[i] === 'ACW') {
                newRow[queuesDataFields[i]] = this.state.acwType;
            } else if (queuesDataFields[i] === 'ACW timeout') {
                newRow[queuesDataFields[i]] = this.state.acwTimeout;
            } else if (queuesDataFields[i] === 'Evaluation method') {
                newRow[queuesDataFields[i]] = this.state.evaluationMethod;
            } else if (queuesDataFields[i] === 'Alerting timeout') {
                newRow[queuesDataFields[i]] = this.state.alertingTimeout;
            } else if (queuesDataFields[i] === 'SL percentage') {
                newRow[queuesDataFields[i]] = this.state.slPercentage;
            } else if (queuesDataFields[i] === 'SL duration') {
                newRow[queuesDataFields[i]] = this.state.slDuration;
            } else if (queuesDataFields[i] === 'Calling party name') {
                newRow[queuesDataFields[i]] = this.state.callingPartyName;
            } else if (queuesDataFields[i] === 'Calling party number') {
                newRow[queuesDataFields[i]] = this.state.callingPartyNumber;
            } else if (queuesDataFields[i] === 'Wrapup codes') {
                newRow[queuesDataFields[i]] = this.state.wrapUpCodes;
            } else if (queuesDataFields[i] === 'Division') {
                newRow[queuesDataFields[i]] = this.state.selectedDivision;
            }
        }

        queuesList.push(newRow);

        this.setState({
            isQueuesInfoOpen: false,
            queuesList: queuesList,
        });
    }

    handleInputChange = (fieldName) => (e) => {
        if (fieldName === 'wrapUpCodes') {
            const options = e.target.selectedOptions;
            let value = [];
            for (let i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }
            this.setState({
                [fieldName]: value
            })
        } else if (fieldName === 'acwType') {
            if (e.target.value === 'MANDATORY' || e.target.value === 'OPTIONAL') {
                this.setState({
                    isacwTimeOut: true,
                    ['acwTimeout']: ''
                });
            } else {
                this.setState({
                    isacwTimeOut: false,
                });
            }
            this.setState({
                [fieldName]: e.target.value
            });
        } else {
            this.setState({
                [fieldName]: e.target.value
            });
        }

    }

    rowFn = (state, rowInfo, column, instance) => {
        return {
            onClick: (e, handleOriginal) => {
                this.updateState(rowInfo.original);
            },
            style: {
                cursor: 'pointer'
            },
        };
    };

    updateState = (rowInfo) => {
        this.setState({
            queueName: rowInfo['Queue name'],
            acwType: rowInfo['ACW'],
            acwTimeout: rowInfo['ACW timeout'],
            evaluationMethod: rowInfo['Evaluation method'],
            alertingTimeout: rowInfo['Alerting timeout'],
            slPercentage: rowInfo['SL percentage'],
            slDuration: rowInfo['SL duration'],
            callingPartyName: rowInfo['Calling party name'],
            callingPartyNumber: rowInfo['Calling party number'],
            wrapUpCodes: rowInfo['Wrapup codes'],
            selectedDivision: rowInfo['Division'],
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isQueuesInfoOpen: true,
        });
    }

    updateFunction = () => {
        let queuesList = [...this.state.queuesList];
        for (let queue of queuesList) {
            if (queue.rowid === this.state.rowID) {
                queue['Queue name'] = this.state.queueName;
                queue['ACW'] = this.state.acwType;
                queue['ACW timeout'] = this.state.acwTimeout;
                queue['Evaluation method'] = this.state.evaluationMethod;
                queue['Alerting timeout'] = this.state.alertingTimeout;
                queue['SL percentage'] = this.state.slPercentage;
                queue['SL duration'] = this.state.slDuration;
                queue['Calling party name'] = this.state.callingPartyName;
                queue['Calling party number'] = this.state.callingPartyNumber;
                queue['Wrapup codes'] = this.state.wrapUpCodes;
                queue['Division'] = this.state.selectedDivision;
            }
        }

        this.setState({
            queuesList: queuesList,
            isQueuesInfoOpen: false,
        })
    }

    importQueues = async () => {
        this.eventList = [];
        const queuesList = this.state.queuesList;
        for (const i in queuesList) {
            const queueData = queuesList[i];
            await this.setState({ preloaderLocalShow: true });
            if (this.queueDataValidation(queueData, i, queuesList)) {
                try {
                    let selectedDivisionId = '';
                    let selectedDivision = this.state.divisionList.find(el => el.name === queueData['Division']);
                    if (selectedDivision != undefined) {
                        selectedDivisionId = selectedDivision.id;
                    }
                    // <create queue>          
                    const queue = await routingPostQueue(this.state.env, this.state.token, selectedDivisionId, queueData["Queue name"], queueData.ACW, queueData["ACW timeout"], queueData["Evaluation method"], queueData["Alerting timeout"], queueData["SL percentage"], queueData["SL duration"], queueData["Calling party name"], queueData["Calling party number"]);
                    console.log(`queue created: ${JSON.stringify(queue)}`);
                    // </create queue>
                    // <assign wrap-up codes>
                    const queueWrapUpCodes = queueData["Wrapup codes"];
                    const queueWrapUpCodeIds = this.getWrapUpIdList(queueWrapUpCodes);
                    routingPostQueuesWrapUpCodes(this.state.env, this.state.token, queue.id, queueWrapUpCodeIds)
                    // </assign wrap-up codes>
                    await this.setState({ preloaderLocalShow: false });
                    this.props.handleQueues(queue, this.state.selectedWrapCodes);
                } catch (err) {
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        if (((this.eventList.find(evnt => evnt.isError === true)) === undefined)) {
            await logActionData('Import', `Importing Outbound Queues`, 1, 'Queues');
            //reset queues list grid if no errors
            await this.setState({ queuesList: [] });
        }
        await this.setState({ preloaderLocalShow: false });
        if (this.eventList.length > 0) {
            this.showEventList(this.eventList);
        }
    }

    queueDataValidation = (queue, i, queuesData) => {
        let isqueueValidated = true;
        // <validate fields that can't be empty>
        if (!queue["Queue name"].trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isqueueValidated = false; }
        // </validate fields that can't be empty>

        // <validate if wrapup code exist>
        var wrapupsArray = queue["Wrapup codes"];
        for (const queueWrapup of wrapupsArray) {
            var queueWrapupValid = false;
            for (const wrapup of this.state.wrapUpCodeList) {
                if (queueWrapup.toLowerCase() === wrapup.name.toLowerCase()) {
                    queueWrapupValid = true;
                }
            }
            if (!queueWrapupValid) { this.logEvent(`[Index ${i}] Wrapup code '${queueWrapup}' doesn't exist`, true); isqueueValidated = false; }
        }
        // </validate if wrapup code exist>

        // <validate if site name is unique>
        for (const j in queuesData) {
            const site1 = queuesData[j];
            if (i === j) { continue; }
            if (queue["Queue name"].trim().toLowerCase() === site1["Queue name"].trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isqueueValidated = false; }
        }
        // </validate if site name is unique>

        return isqueueValidated;
    }

    getWrapUpIdList = (queueWrapUpCodes) => {
        var result = [];
        var selectedWrapCodes = [];
        for (const i in queueWrapUpCodes) {
            const wrapUpCode = this.state.wrapUpCodeList.filter(s => s.name.toLowerCase() === queueWrapUpCodes[i].toLowerCase())[0];
            if (wrapUpCode) {
                result.push({ "id": wrapUpCode.id });
                selectedWrapCodes.push({ "id": wrapUpCode.id, "name": wrapUpCode.name });
            }
        }
        this.setState({ selectedWrapCodes: selectedWrapCodes });
        return result;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    render() {
        let MAP = {
            name: "c-map",
            areas: []
        }
        return (
            <div className="OqueuesCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                {/* <Information viewer> */}
                <QueuesInfo
                    isOpen={this.state.isQueuesInfoOpen}
                    isSaveLocale={this.state.isSaveLocale}
                    queueName={this.state.queueName}
                    acwType={this.state.acwType}
                    acwTimeout={this.state.acwTimeout}
                    evaluationMethod={this.state.evaluationMethod}
                    alertingTimeout={this.state.alertingTimeout}
                    slPercentage={this.state.slPercentage}
                    slDuration={this.state.slDuration}
                    callingPartyName={this.state.callingPartyName}
                    callingPartyNumber={this.state.callingPartyNumber}
                    wrapUpCodes={this.state.wrapUpCodes}
                    wrapUpCodeList={this.state.wrapUpCodeList}
                    divisionList={this.state.divisionList}
                    selectedDivision={this.state.selectedDivision}
                    isacwTimeOut={this.state.isacwTimeOut}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isQueuesInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 queueCardBody">
                        <CardTitle className="m-0 queueTitle">
                            <div className="divOQ">
                                <Badge className="titleBadgeOQ">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pQueues.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <p style={{ paddingLeft: "12px", fontSize: "2.5vmin", marginLeft: "-20px" }}>From this page, add new Outbound {this.props.cardTitle} individually </p>
                    </CardBody>
                    <CardBody className="p-3 CardBodyOQ">
                        <FormGroup row>
                            <Col>
                                <Button className="AddButtonOQ" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow || this.state.queuesList.length > 0}><FaPlus /> Add a Queue</Button>
                            </Col>
                        </FormGroup>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} />
                            {
                                this.state.queuesList.length > 0
                                &&
                                <div className="Queues-wrap">
                                    <ReactTable
                                        data={this.state.queuesList}
                                        getTrProps={this.rowFn}
                                        columns={[
                                            {
                                                Header: "Queue Name",
                                                accessor: "Queue name",
                                            },
                                            {
                                                Header: "Remove",
                                                accessor: "rowid",
                                                Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                            }
                                        ]}
                                        showPagination={false}
                                        style={{ height: "70px" }}
                                        noDataText="No Queues loaded"
                                        className="-striped -highlight"
                                    />
                                </div>
                            }
                            <Row className="mt-3">
                                <Col>
                                    <div>
                                        <Button className="SubmitButtonOQ" onClick={this.importQueues} disabled={this.state.queuesList.length === 0}>Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
