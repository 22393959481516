import './scheduleGroupsInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

export default class ScheduleGroupsInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.sgName !== '' && this.props.timeZone !== '' && this.props.openSchedules.length !== 0) {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="scheduleGroupsModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="siteName">Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.sgName === '' ? true : false} value={this.props.sgName} onChange={this.props.handleInputChange('sgName')} type="text" id="sgName" placeholder="Enter the Schedule Group name" />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="timeZone">Time Zone:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.timeZone} onChange={this.props.handleInputChange('timeZone')}>
                                            <option key="Z1" hidden>Select a Zone</option>
                                            {
                                                this.props.zonesList.map((zone, index) => (
                                                    <option key={index}>{zone.id}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label title="Select entry & Cntrl key for multiple selections">Open Schedules:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select multiple title="Select entry & Cntrl key for multiple selections" className="form-control selectpicker" value={this.props.openSchedules} onChange={this.props.handleInputChange('openSchedules')}>
                                            <option key="O1" selected disabled>Select Open Schedules</option>
                                            {
                                                this.props.scheduleList.map((schedule) => (
                                                    <option key={schedule.id + 'O'}>{schedule.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label title="Select entry & Cntrl key for multiple selections">Closed Schedules:</Label>
                                        <select multiple title="Select entry & Cntrl key for multiple selections" className="form-control selectpicker" value={this.props.closedSchedules} onChange={this.props.handleInputChange('closedSchedules')}>
                                            <option key="C1" selected disabled>Select Closed Schedules</option>
                                            {
                                                this.props.scheduleList.map((schedule) => (
                                                    <option key={schedule.id + 'C'}>{schedule.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label title="Select entry & Cntrl key for multiple selections">Holiday Schedules:</Label>
                                        <select multiple title="Select entry & Cntrl key for multiple selections" className="form-control selectpicker" value={this.props.holidaySchedules} onChange={this.props.handleInputChange('holidaySchedules')}>
                                            <option key="H1" selected disabled>Select Holiday Schedules</option>
                                            {
                                                this.props.scheduleList.map((schedule) => (
                                                    <option key={schedule.id + 'H'}>{schedule.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
