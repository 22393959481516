import React from 'react'
import { Router, Switch } from 'react-router-dom'
import createHistory from 'history/createBrowserHistory'
import PublicRoute from './PublicRoute'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from '../components/home/Home'
import Authorization from '../components/authorization/authorization'
import Logout from '../components/authorization/logout'

const history = createHistory();

const AppRouter = () => (
    <Router history={history}>
        <React.Fragment>
            <Switch>
                <PublicRoute path="/" component={Home} exact={true} history={history} />
                <PublicRoute path="/authorization" component={Authorization} />
                <PublicRoute path="/logout" component={Logout} />
            </Switch>
            <ToastContainer position="bottom-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
        </React.Fragment>
    </Router>
)

export default AppRouter