import './extensions.css'
import React from 'react'
import { FaLink, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import { telephonyPostExtensionPools } from '../../../../services/purecloud/telephony';
import ExtensionsInfo from '../extensions/extensionsInfo';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const extensionsDataFields = [
    'startNumber',
    'endNumber'
];

export default class Extensions extends React.Component {

    state = {
        env: '',
        token: '',
        extensionsList: [],
        isExtensionsInfoOpen: false,
        startNumber: '',
        endNumber: '',
        rowID: null,
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var extensionsList = [...this.state.extensionsList];
        var selectedList = this.state.selected;
        for (const i in extensionsList) {
            if (extensionsList[i].rowid === rowid) {
                extensionsList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined){
            await this.setState({selectAll: 0})
        }
        await this.setState({ extensionsList: extensionsList, selected : selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isExtensionsInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            startNumber: '',
            endNumber: '',
            rowID: null,
        })
    }

    handleSubmitFunction = () => {
        let extensionsList = [...this.state.extensionsList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in extensionsDataFields) {
            if (extensionsDataFields[i] === 'startNumber') {
                newRow[extensionsDataFields[i]] = this.state.startNumber;
            } else if (extensionsDataFields[i] === 'endNumber') {
                newRow[extensionsDataFields[i]] = this.state.endNumber;
            }
        }

        extensionsList.push(newRow);

        this.setState({
            isExtensionsInfoOpen: false,
            extensionsList: extensionsList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        this.setState({
            [fieldName]: event.target.value
        });
    }

    updateState = (rowInfo) => {
        this.setState({
            startNumber: rowInfo.startNumber,
            endNumber: rowInfo.endNumber,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isExtensionsInfoOpen: true,
        });
    }

    updateFunction = () => {
        let extensionsList = [...this.state.extensionsList];
        for (let did of extensionsList) {
            if (did.rowid === this.state.rowID) {
                did.startNumber = this.state.startNumber;
                did.endNumber = this.state.endNumber;
            }
        }

        this.setState({
            extensionsList: extensionsList,
            isExtensionsInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const didData = await CsvReader.parseCsvFileToExtensionsData(fileContent);
        await this.setState({ extensionsList: [...this.state.extensionsList, ...didData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importExtensions = async () => {
        this.eventList = [];
        const extensionsList = this.state.extensionsList;
        for (const i in extensionsList) {
            const extData = extensionsList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing extensions [${extData.startNumber}] [${extData.endNumber}]`);
            if (await this.dataValidated(extData, i)) {
                try {
                    // <create extension>          
                    const ext = await telephonyPostExtensionPools(this.state.env, this.state.token, extData.startNumber, extData.endNumber);
                    console.log(`Extension created: ${JSON.stringify(ext)}`);
                    // </create extension>
                    this.logEvent(`Extension [${extData.startNumber}] [${extData.endNumber}] successfully processed`);
                    this.removeRowClicked(extData.rowid);
                } catch (err) {
                    console.log(`${err.message}`);
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Extensions`, count, 'Extensions');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    dataValidated = async (ext, i) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!ext.endNumber || !ext.endNumber.trim()) { this.logEvent(`[Index ${i}] Start number can't be empty.`, true); isValidated = false; }
        if (!ext.endNumber || !ext.endNumber.trim()) { this.logEvent(`[Index ${i}] End number can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>
        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.extensionsList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Extensions...."  });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            } else if (area.name === '3') {
                this.props.navigateToHorizontalSlide('3');
            } else if (area.name === '2') {
                this.props.navigateToHorizontalSlide('2');
            } else if (area.name === '4') {
                this.props.navigateToHorizontalSlide('4');
            }
        }
    }

    render() {
        let MAP = {
            name: "ext-map",
            areas: [
                { name: "0", shape: "circle", coords: [23, 13, 12] },
                // { name: "1", shape: "circle", coords: [85.5, 13, 12] },
                { name: "2", shape: "circle", coords: [148, 13, 12] },
                { name: "3", shape: "circle", coords: [210, 13, 12] },
                { name: "4", shape: "circle", coords: [273, 13, 12] }
            ]
        }
        return (
            <div className="extCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <ExtensionsInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isExtensionsInfoOpen}
                    startNumber={this.state.startNumber}
                    endNumber={this.state.endNumber}
                    isSaveLocale={this.state.isSaveLocale}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isExtensionsInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 extensionsCardBody">
                        <CardTitle className="m-0 extensionsTitle">
                            <div className="divExtensions">
                                <Badge className="titleBadgeExtension">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pExtensions.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/extensions-import-template.csv" ><u>Here</u></a>
                        </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyExtension">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonExtension" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonExtension">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage} />
                            <div className="extensions-wrap">
                                <ReactTable
                                    data={this.state.extensionsList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.extensionsList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Start number",
                                            accessor: "startNumber",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: "End number",
                                            accessor: "endNumber",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "250px", height: "25px", display: "block", cursor: "pointer",paddingRight:"50px"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Extensions loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonExtension" onClick={this.importExtensions} disabled={this.state.extensionsList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
