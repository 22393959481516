import './bulkImport.css'
import React from 'react'
import ReactTable from "react-table";
import { FaCloudUploadAlt } from 'react-icons/fa';
import { Modal, ModalBody, Badge, ModalHeader, Card, CardBody, CardTitle, Button, ModalFooter, Row, Col, Input } from 'reactstrap'
import moment from 'moment';

import { locationsPostLocations, getlocations } from "../../services/purecloud/locations";
import {
    telephonyPostDidPools,
    telephonyPostExtensionPools,
    telephonyGetTrunkBaseSettings,
    telephonyGetLineBaseSettings,
    telephonyPostPhones,
    telephonyGetBaseSettings,
    telephonyGetSites,
    telephonyPostPhoneBaseSettings,
    telephonyGetAvailableMetaBases,
    telephonyPostEdgeGroups,
    telephonyPostSites
} from "../../services/purecloud/telephony";
import {
    authorizationPostDivision,
    getAuthorizationRoles,
    authorizationPostRole,
    getAuthorizationDivisions,
} from '../../services/purecloud/authorization'
import { getInboundCallflows } from '../../services/purecloud/flows'
import { architectPostIvrs, getArchitects, architectPostSchedule, architectPostScheduleGroup } from '../../services/purecloud/architect'
import {
    getRoutingGetSkills,
    getRoutingGetQueues,
    getRoutingWrapupCodes,
    routingPostWrapUpCode,
    routingPostSkill,
    routingPostQueue,
    routingPostQueuesWrapUpCodes,
    routingPostQueuesUsers,
    routingPatchRoutingSkillsBulk,
    postRoleDivisionGrants
} from '../../services/purecloud/routing'
import { usersPostUser, usersPutUserRoles, usersGetUsers } from '../../services/purecloud/users'
import { getUserMe, getOrgMe, getToken, logActionData } from '../../services/session/session';
import { getSelectedRegion } from '../../global/localStorage';
import DragAndDrop from '../Misc/dragAndDrop/dragAndDrop';
import { uploadFile } from "../../services/misc/zipImport.js";
import EventListViewer from '../Misc/eventListViewer/eventListViewer';
import EditAndRetryModal from "../Misc/editAndRetryModal/editAndRetryModal";
import PreloaderLocal from '../Misc/preloaderLocal/preloaderLocal';
import Misc from '../../services/misc/misc';

export default class BulkImport extends React.Component {

    state = {
        env: getSelectedRegion(),
        token: getToken(),
        userMe: {},
        orgMe: {},
        preloaderShow: false,
        preloaderMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        editAndRetryModalIsOpen: false,
        editModalFileName: '',
        eventsForCurrentRow: '',
        editModalContent: [],
        filesData: [],
        metaBaseSettings: [],
        skillList: [],
        phonesSites: [],
        usersList: [],
        trunkBaseSettingsList: [],
        phoneBaseSettingsList: [],
        lineBaseSettings: [],
        scheduleGroupList: [],
        flowList: [],
        locationList: [],
        wrapUpCodeList: [],
        roleList: [],
        queueList: [],
        divisionList: [],
        scheduleList: [],
        divisionsContent: [],
        skillsContent: [],
        wrapUpContent: [],
        rolesContent: [],
        locationsContent: [],
        sitesContent: [],
        didContent: [],
        extensionsContent: [],
        queuesContent: [],
        QueueContent: [],
        usersContent: [],
        skillsMatrixContent: [],
        edgeGroupsContent: [],
        phoneBaseSettingsContent: [],
        phonesContent: [],
        schedulesContent: [],
        scheduleGroupContent: [],
        ivrRoutingContent: [],
    }

    eventList = [];
    entity_bulkImport = [];
    count_bulkImport = [];

    logEvent = (name, message, isError = false, isWarning = false) => {
        const event = {
            name: name,
            isError: isError,
            isWarning: isWarning,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (name, eventList) => {
        const eventListForCurrent = eventList.filter(event => event.name === name);
        this.setState({
            eventList: eventListForCurrent,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        this.loadMetaBaseSettings();
        this.loadSkills();
        this.loadPhonesSitesItems();
        this.loadUsers();
        this.loadTrunkBaseSettingsList();
        this.loadPhoneBaseSettings();
        this.loadLinebaseSettings();
        this.loadIvrsRoutingItems();
        this.loadFlows();
        this.loadOrg();
    }

    loadOrg = async () => {
        try {
            const userMe = await getUserMe();
            const orgMe = await getOrgMe();
            await this.setState({ userMe: userMe });
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            console.error(error);
        }
    }

    loadMetaBaseSettings = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Meta Base Settings' });
        this.state.metaBaseSettings = await telephonyGetAvailableMetaBases(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadSkills = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Skills' });
        this.state.skillList = await getRoutingGetSkills(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadPhonesSitesItems = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Sites' });
        this.state.phonesSites = await telephonyGetSites(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadUsers = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Users' });
        this.state.usersList = await usersGetUsers(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadTrunkBaseSettingsList = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Trunk Base Settings' });
        this.state.trunkBaseSettingsList = await telephonyGetTrunkBaseSettings(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadPhoneBaseSettings = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Phone Base Settings' });
        this.state.phoneBaseSettingsList = await telephonyGetBaseSettings(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadLinebaseSettings = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Line Base Settings' });
        this.state.lineBaseSettings = await telephonyGetLineBaseSettings(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadIvrsRoutingItems = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading IVRs Routing Items' });
        this.state.scheduleGroupList = await getArchitects(this.state.env, this.state.token, 'scheduleGroups');
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadFlows = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Flows' });
        this.state.flowList = await getInboundCallflows(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    createDocReaderPromise = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                // Make a fileInfo Object
                const fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB', //eslint-disable-line
                    base64: reader.result,
                    file,
                };
                resolve(fileInfo.base64.split(',')[1]);
            };
            reader.onerror = () => {
                reject();
            };
        });
    }

    importZip = async (file) => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading' });
        this.logEvent(`Zip file import started`);
        this.eventList = [];
        try {
            const encodedFileContent = await this.createDocReaderPromise(file);
            const respond = await uploadFile(file.name, encodedFileContent, this.state.userMe.email, this.state.orgMe.name);
            let sortedOrder = {};
            if (respond.result === null && respond.messages.length) {
                for (let index = 0; index < respond.messages.length; index++) {
                    if (respond.messages[index].type == 'EXCPT') {
                        this.logEvent('Import', respond.messages[index].content, true);
                    } else {
                        this.logEvent('Import', respond.messages[index].Message, true);
                    }
                }
                this.showEventList('Import', this.eventList);
            } else {
                if (respond.messages.length) {
                    for (let index = 0; index < respond.messages.length; index++) {
                        if (respond.messages[index].type == 'INFO' || respond.messages[index].type == 'INVALID') {
                            this.logEvent('Import', respond.messages[index].content, true);
                        } else { this.logEvent('Import', respond.messages[index].Message, true); }
                    }
                    this.showEventList('Import', this.eventList);
                }

                Object.entries(respond.result).map(([key, value]) => {
                    if (value && value.length) {
                        this.sortBulkImport(key, value, sortedOrder);
                    }
                });

                for (var key in sortedOrder) {
                    await this.importFunction(sortedOrder[key][0], sortedOrder[key][1]);
                }
            }
        } catch (err) {
            this.logEvent(`${err.message}`, true);
        }
        if (this.entity_bulkImport.length > 0)
            await logActionData('BulkImport', 'Bulk Import Objects', this.count_bulkImport.join('|'), this.entity_bulkImport.join('|'));
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    sortBulkImport = (fileName, value, sortedOrder) => {
        if (fileName.includes("divisions")) {
            sortedOrder[1] = [fileName, value];
        }

        if (fileName.includes("skills") && !fileName.includes("matrix")) {
            sortedOrder[2] = [fileName, value];
        }

        if (fileName.includes("wrapup-codes")) {
            sortedOrder[3] = [fileName, value];
        }

        if (fileName.includes("roles")) {
            sortedOrder[4] = [fileName, value];
        }

        if (fileName.includes("locations")) {
            sortedOrder[5] = [fileName, value];
        }

        if (fileName.includes("sites")) {
            sortedOrder[6] = [fileName, value];
        }

        if (fileName.includes("dids")) {
            sortedOrder[7] = [fileName, value];
        }

        if (fileName.includes("extensions")) {
            sortedOrder[8] = [fileName, value];
        }

        if (fileName.includes("queues") && !fileName.includes("full")) {
            sortedOrder[9] = [fileName, value];
        }

        if (fileName.includes("queues-full")) {
            sortedOrder[10] = [fileName, value];
        }

        if (fileName.includes("users")) {
            sortedOrder[11] = [fileName, value];
        }

        if (fileName.includes("skills-matrix")) {
            sortedOrder[12] = [fileName, value];
        }

        if (fileName.includes("edgegroups")) {
            sortedOrder[13] = [fileName, value];
        }

        if (fileName.includes("base-settings")) {
            sortedOrder[14] = [fileName, value];
        }

        if (fileName.includes("phones") && !fileName.includes("base-settings")) {
            sortedOrder[15] = [fileName, value];
        }

        if (fileName.includes("schedules")) {
            sortedOrder[16] = [fileName, value];
        }

        if (fileName.includes("schedulegroups")) {
            sortedOrder[17] = [fileName, value];
        }

        if (fileName.includes("ivrsrouting")) {
            sortedOrder[18] = [fileName, value];
        }
    }

    importFunction = async (fileName, value) => {
        this.handleDelete(fileName);

        if (fileName.includes("divisions")) {
            await this.importDivisions(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/people-and-permissions/divisions'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'divisions', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Divisions');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("skills") && !fileName.includes("matrix")) {
            await this.importSkills(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/directory/acdSkills'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'skills', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Skills');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("wrapup-codes")) {
            await this.importWrapUp(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_wrapupCodesV2'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'WrapUp', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('WrapUpCodes');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
            await this.reloadData();
        }

        if (fileName.includes("roles")) {
            await this.importRoles(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/directory/rolesV2'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Roles', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Roles');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("locations")) {
            await this.importLocations(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/directory/locations'} target="_blank">Manage in Genesys Cloud</Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Locations', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Locations');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
            await this.reloadData();
        }

        if (fileName.includes("sites")) {
            await this.loadLocationListItems();
            await this.importSites(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/sites'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Sites', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Sites');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
            await this.reloadData();
        }

        if (fileName.includes("dids")) {
            await this.importDids(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/did/numbers'} target="_blank">Manage in Genesys Cloud</Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Dids', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Dids');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("extensions")) {
            await this.importExtensions(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/extensions/pools'} target="_blank">Manage in Genesys Cloud</Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Extensions', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Extensions');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("queues") && !fileName.includes("full")) {
            await this.importQueuesWithSingleField(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_queuesV2'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'queues', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Queues');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("queues-full")) {
            await this.loadWrapUpCodeList();
            await this.importQueues(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_queuesV2'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Queue', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Queues');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
            await this.reloadData();
        }

        if (fileName.includes("users")) {
            await this.loadItemsForUsersImport();
            await this.importUsers(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/directory/peopleV3'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Users', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Users');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("skills-matrix")) {
            await this.importSkillsMatrix(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/directory/peopleV3'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'SkillsMatrix', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Skills-matrix');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("edgegroups")) {
            await this.importEdgeGroups(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/edgeGroups'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'EdgeGroups', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('EdgeGroups');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("base-settings")) {
            await this.importPhoneBaseSettings(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/phoneManagement/baseSettings'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'phones-base-settings', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Phones-base-settings');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
            await this.reloadData();
        }

        if (fileName.includes("phones") && !fileName.includes("base-settings")) {
            await this.importPhones(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/phoneManagement/phones'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Phones', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Phones');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }

        if (fileName.includes("schedules")) {
            await this.importSchedules(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/routing/scheduling/schedules'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'Schedules', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('Schedules');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
            await this.reloadData();
        }

        if (fileName.includes("schedulegroups")) {
            await this.loadscheduleList();
            await this.importScheduleGroups(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/routing/scheduling'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'ScheduleGroup', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('ScheduleGroups');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
            await this.reloadData();
        }

        if (fileName.includes("ivrsrouting")) {
            await this.reloadData();
            await this.loadItemsForIVR();
            await this.importIvrsRouting(value, true).then(() => {
                const pureCloudLink = <Button color="info" href={'https://apps.' + this.state.env + '/directory/#/admin/routing/ivrs'} target="_blank">Manage in Genesys Cloud </Button>;
                const obj = { 'name': fileName, 'rows': value, 'status': { name: 'IvrsRouting', eventList: this.eventList }, 'pureCloudLink': pureCloudLink };
                this.logImportCount('IvrsRoutings');
                this.setState((prevState) => ({ filesData: [...prevState.filesData, obj] }));
            });
        }
    }

    logImportCount = (objectName) => {
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            this.entity_bulkImport.push(objectName);
            this.count_bulkImport.push(count);
        }
    }

    importDivisions = async (divisionsData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.divisionsContent = [];
        }
        let aloneDivisions = [];
        for (const divisionName of divisionsData) {
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Divisions' });
            this.logEvent('divisions', `Processing division [${divisionName.divisionname}]`);
            try {
                await authorizationPostDivision(this.state.env, this.state.token, divisionName.divisionname);
                this.logEvent('divisions', `Division [${divisionName.divisionname}] successfully processed`);
            } catch (err) {
                if (err.message.includes("Rate limit exceeded the maximum")) {
                    this.logEvent('divisions', `Rate limit exceeded the maximum`, true);
                    await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                    aloneDivisions.push(divisionName);
                    await this.sleep(20000);
                } else {
                    this.logEvent('divisions', `${err.message}`, true);
                    this.divisionsContent.push(JSON.stringify(divisionName));
                }
            }
        }

        if (aloneDivisions.length > 0) {
            this.importDivisions(aloneDivisions, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    importSkills = async (skillsData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.skillsContent = [];
        }
        let aloneSkills = [];
        for (const skill of skillsData) {
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Skills' });
            this.logEvent('skills', `Processing Skill [${skill.skillname}]`);
            try {
                await routingPostSkill(this.state.env, this.state.token, skill.skillname);
                this.logEvent('skills', `Skill [${skill.skillname}] successfully processed`);
            } catch (err) {
                if (err.message.includes("Rate limit exceeded the maximum")) {
                    this.logEvent('skills', `Rate limit exceeded the maximum`, true);
                    await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                    aloneSkills.push(skill);
                    await this.sleep(20000);
                } else {
                    this.logEvent('skills', `${err.message}`, true);
                    this.skillsContent.push(JSON.stringify(skill));
                }
            }
        }
        const data = await getRoutingGetSkills(this.state.env, this.state.token);
        this.setState({ skillList: data })
        if (aloneSkills.length > 0) {
            this.importSkills(aloneSkills, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    importWrapUp = async (wrapupCodesData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.wrapUpContent = [];
        }
        let aloneWrapup = [];
        for (const wrapupCode of wrapupCodesData) {
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Wrap-up' });
            this.logEvent('WrapUp', `Processing Wrap-up code [${wrapupCode.wrapupcodename}]`);
            try {
                await routingPostWrapUpCode(this.state.env, this.state.token, wrapupCode.wrapupcodename);
                this.logEvent('WrapUp', `Wrap-up code  [${wrapupCode.wrapupcodename}] successfully processed`);
            } catch (err) {
                if (err.message.includes("Rate limit exceeded the maximum")) {
                    this.logEvent('WrapUp', `Rate limit exceeded the maximum`, true);
                    await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                    aloneWrapup.push(wrapupCode);
                    await this.sleep(20000);
                } else {
                    this.logEvent('WrapUp', `${err.message}`, true);
                    this.wrapUpContent.push(JSON.stringify(wrapupCode));
                }
            }
        }

        if (aloneWrapup.length > 0) {
            this.importWrapUp(aloneWrapup, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    importRoles = async (rolesData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.rolesContent = [];
        }
        let aloneRoles = [];
        for (const role of rolesData) {
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Roles' });
            this.logEvent('Roles', `Processing Role [${role.rolename}]`);
            try {
                await authorizationPostRole(this.state.env, this.state.token, role.rolename);
                this.logEvent('Roles', `Role  [${role.rolename}] successfully processed`);
            } catch (err) {
                if (err.message.includes("Rate limit exceeded the maximum")) {
                    this.logEvent('Roles', `Rate limit exceeded the maximum`, true);
                    await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                    aloneRoles.push(role);
                    await this.sleep(20000);
                } else {
                    this.logEvent('Roles', `${err.message}`, true);
                    this.rolesContent.push(JSON.stringify(role));
                }
            }
        }

        if (aloneRoles.length > 0) {
            this.importRoles(aloneRoles, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    importLocations = async (locationsData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.locationsContent = [];
        }
        let aloneLocations = [];
        for (const i in locationsData) {
            const locationData = locationsData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Locations' });
            this.logEvent('Locations', `Processing location [${locationData.name}]`);
            if (this.locationsDataValidated(locationData, i, locationsData)) {
                try {
                    // <create location>
                    const location = await locationsPostLocations(this.state.env, this.state.token, locationData.name, locationData.street1, locationData.street2, locationData.city, locationData.state, locationData.zip, locationData.countryAbbreviation, locationData.country, locationData.notes, locationData.emergencyNumber);
                    console.log(`location created: ${JSON.stringify(location)}`);
                    // </create location>

                    this.logEvent('Locations', `Location [${locationData.name}] successfully processed`);

                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Locations', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneLocations.push(locationData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('Locations', `${err.message}`, true);
                        this.locationsContent.push(JSON.stringify(locationData));
                    }
                }
            } else {
                this.locationsContent.push(JSON.stringify(locationData));
            }
        }

        if (aloneLocations.length > 0) {
            this.importLocations(aloneLocations, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    locationsDataValidated = (location, i, locationsData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!location.name || !location.name.trim()) { this.logEvent('Locations', `[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!location.street1 || !location.street1.trim()) { this.logEvent('Locations', `[Index ${i}] Street 1 can't be empty.`, true); isValidated = false; }
        if (!location.city || !location.city.trim()) { this.logEvent('Locations', `[Index ${i}] City can't be empty.`, true); isValidated = false; }
        if (!location.zip || !location.zip.trim()) { this.logEvent('Locations', `[Index ${i}] Zip can't be empty.`, true); isValidated = false; }
        if (!location.country || !location.country.trim()) { this.logEvent('Locations', `[Index ${i}] Country can't be empty.`, true); isValidated = false; }
        if (!location.countryAbbreviation || !location.countryAbbreviation.trim()) { this.logEvent('Locations', `[Index ${i}] Country abbreviation can't be empty.`, true); isValidated = false; }
        if (!location.emergencyNumber || !location.emergencyNumber.trim()) { this.logEvent('Locations', `[Index ${i}] Emergency Number can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if location name is unique>
        for (const j in locationsData) {
            const location1 = locationsData[j];
            if (i === j) { continue; }
            if (location.name.trim().toLowerCase() === location1.name.trim().toLowerCase()) { this.logEvent('Locations', `[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if location name is unique>
        return isValidated;
    }

    importSites = async (sitesData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.sitesContent = [];
        }
        let aloneSites = [];

        for (const i in sitesData) {
            const siteData = sitesData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Sites' });
            this.logEvent('Sites', `Processing site [${siteData.name}]`);
            if (this.sitesDataValidated(siteData, i, sitesData)) {
                try {
                    // <create site>
                    const location = this.getLocation(siteData.location);
                    const site = await telephonyPostSites(this.state.env, this.state.token, siteData.siteName, siteData.description, location.id, siteData.recurrenceType, siteData.timeZone, siteData.startTime, siteData.endTime);
                    console.log(`Site created: ${JSON.stringify(site)}`);
                    // </create site>
                    this.logEvent('Sites', `Site [${siteData.siteName}] successfully processed`);
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Sites', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneSites.push(siteData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('Sites', `${err.message}`, true);
                        this.sitesContent.push(JSON.stringify(siteData));
                    }
                }
            } else {
                this.sitesContent.push(JSON.stringify(siteData));
            }
        }

        if (aloneSites.length > 0) {
            this.importSites(aloneSites, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getLocation = (name) => {
        const location = this.state.locationList.filter(r => {
            return r.name.toLowerCase() === name.toLowerCase()
        })[0];
        if (location) { return location; }
    }

    sitesDataValidated = (site, i, sitesData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!site.siteName || !site.siteName.trim()) { this.logEvent('Sites', `[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!site.location || !site.location.trim()) { this.logEvent('Sites', `[Index ${i}] Location name can't be empty.`, true); isValidated = false; }
        if (!site.recurrenceType || !site.recurrenceType.trim()) { this.logEvent('Sites', `[Index ${i}] Recurrence type can't be empty.`, true); isValidated = false; }
        if (!site.timeZone || !site.timeZone.trim()) { this.logEvent('Sites', `[Index ${i}] Time zone can't be empty.`, true); isValidated = false; }
        if (!site.startTime || !site.startTime.trim()) { this.logEvent('Sites', `[Index ${i}] Start time can't be empty.`, true); isValidated = false; }
        if (!site.endTime || !site.endTime.trim()) { this.logEvent('Sites', `[Index ${i}] End time can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if location exist>
        var locationNameValid = false;
        for (const location of this.state.locationList) {
            if (site.location.toLowerCase() === location.name.toLowerCase()) {
                locationNameValid = true;
            }
        }
        if (!locationNameValid) { this.logEvent('Sites', `[Index ${i}] Location '${site.locationName}' doesn't exist`, true); isValidated = false; }
        // </validate if location exist>

        // <validate if site name is unique>
        if (sitesData.length !== 1) {
            for (const j in sitesData) {
                const site1 = sitesData[j];
                if (i === j) { continue; }
                if (site.siteName.trim().toLowerCase() === site1.siteName.trim().toLowerCase()) { this.logEvent('Sites', `[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
            }
        }
        // </validate if site name is unique>

        return isValidated;
    }

    importDids = async (didsData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.didContent = [];
        }
        let aloneDIDs = [];
        for (const i in didsData) {
            const didData = didsData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading DIDs' });
            this.logEvent('Dids', `Processing DID [${didData.startPhoneNumber}] [${didData.endPhoneNumber}]`);
            if (this.didsDataValidation(didData, i)) {
                try {
                    // <create DID>          
                    const did = await telephonyPostDidPools(this.state.env, this.state.token, didData.startPhoneNumber, didData.endPhoneNumber, didData.provider, didData.comments);
                    console.log(`DID created: ${JSON.stringify(did)}`);
                    // </create DID>
                    this.logEvent('Dids', `DID [${didData.startPhoneNumber}] [${didData.endPhoneNumber}] successfully processed`);
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Dids', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneDIDs.push(didData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('Dids', `${err.message}`, true);
                        this.didContent.push(JSON.stringify(didData));
                    }
                }
            } else {
                this.didContent.push(JSON.stringify(didData));
            }
        }

        if (aloneDIDs.length > 0) {
            this.importDids(aloneDIDs, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    didsDataValidation = (did, i) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!did.startPhoneNumber || !did.startPhoneNumber.trim()) { this.logEvent('Dids', `[Index ${i}] Field startPhoneNumber can't be empty.`, true); isValidated = false; }
        if (!did.endPhoneNumber || !did.endPhoneNumber.trim()) { this.logEvent('Dids', `[Index ${i}] Field endPhoneNumber can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>
        return isValidated;
    }

    importExtensions = async (extensionsData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.extensionsContent = [];
        }
        let aloneExtensions = [];
        for (const i in extensionsData) {
            const extData = extensionsData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Extensions' });
            this.logEvent('Extensions', `Processing extensions [${extData.startNumber}] [${extData.endNumber}]`);
            if (this.extensionsDataValidated(extData, i)) {
                try {
                    // <create extension>          
                    const ext = await telephonyPostExtensionPools(this.state.env, this.state.token, extData.startNumber, extData.endNumber);
                    console.log(`Extension created: ${JSON.stringify(ext)}`);
                    // </create extension>
                    this.logEvent('Extensions', `Extension [${extData.startNumber}] [${extData.endNumber}] successfully processed`);
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Extensions', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneExtensions.push(extData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('Extensions', `${err.message}`, true);
                        this.extensionsContent.push(JSON.stringify(extData));
                    }

                }
            } else {
                this.extensionsContent.push(JSON.stringify(extData));
            }
        }

        if (aloneExtensions.length > 0) {
            this.importExtensions(aloneExtensions, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    extensionsDataValidated = (ext, i) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!ext.endNumber || !ext.endNumber.trim()) { this.logEvent('Extensions', `[Index ${i}] Start number can't be empty.`, true); isValidated = false; }
        if (!ext.endNumber || !ext.endNumber.trim()) { this.logEvent('Extensions', `[Index ${i}] End number can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>
        return isValidated;
    }

    importQueuesWithSingleField = async (queuesData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.queuesContent = [];
        }
        let aloneQueuesField = [];
        for (const queue of queuesData) {
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Queues With Single Field' });
            this.logEvent('queues', `Processing Queue [${queue.queuename}]`);
            try {
                await routingPostQueue(this.state.env, this.state.token, queue.queuename);
                this.logEvent('queues', `Queue [${queue.queuename}] successfully processed`);
            } catch (err) {
                if (err.message.includes("Rate limit exceeded the maximum")) {
                    this.logEvent('queues', `Rate limit exceeded the maximum`, true);
                    await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                    aloneQueuesField.push(queue);
                    await this.sleep(20000);
                } else {
                    this.logEvent('queues', `${err.message}`, true);
                    this.queuesContent.push(JSON.stringify(queue));
                }
            }
        }

        if (aloneQueuesField.length > 0) {
            this.importQueuesWithSingleField(aloneQueuesField, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    importQueues = async (queuesData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.QueueContent = [];
        }
        let aloneQueues = [];
        for (const i in queuesData) {
            const queueData = queuesData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Queues' });
            this.logEvent('Queue', `Processing queue [${queueData.queuename}]`);
            if (this.queueDataValidation(queueData, i, queuesData)) {
                try {
                    let selectedDivisionId = '';
                    let selectedDivision = this.state.divisionList.find(el => el.name === queueData.division);
                    if (selectedDivision != undefined) {
                        selectedDivisionId = selectedDivision.id;
                    }
                    // <create queue>          
                    const queue = await routingPostQueue(this.state.env, this.state.token, selectedDivisionId, queueData.queuename, queueData.aCW, queueData.aCWtimeout, queueData.evaluationmethod, queueData.alertingtimeout, queueData.sLpercentage, queueData.sLduration, queueData.callingpartyname, queueData.callingpartynumber);
                    console.log(`queue created: ${JSON.stringify(queue)}`);
                    // </create queue>
                    // <assign wrap-up codes>
                    var queueWrapUpCodes;
                    if (queueData.wrapupcodes.contains("|")) {
                        queueWrapUpCodes = queueData.wrapupcodes.split("|");
                    } else {
                        queueWrapUpCodes = Misc.displayListToArray(queueData.wrapupcodes);
                    }
                    const queueWrapUpCodeIds = this.getWrapUpIdList(queueWrapUpCodes);
                    routingPostQueuesWrapUpCodes(this.state.env, this.state.token, queue.id, queueWrapUpCodeIds)
                    // </assign wrap-up codes>
                    this.logEvent('Queue', `Queue [${queueData.queuename}] successfully processed`);
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Queue', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneQueues.push(queueData);
                        await this.sleep(20000);
                    } else {
                        this.QueueContent.push(JSON.stringify(queueData));
                        this.logEvent('Queue', `${err.message}`, true);
                    }
                }
            } else {
                this.QueueContent.push(JSON.stringify(queueData));
            }
        }

        if (aloneQueues.length > 0) {
            this.importQueues(aloneQueues, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getWrapUpIdList = (queueWrapUpCodes) => {
        var result = [];
        for (const i in queueWrapUpCodes) {
            const wrapUpCode = this.state.wrapUpCodeList.filter(s => s.name.toLowerCase() === queueWrapUpCodes[i].toLowerCase())[0];
            if (wrapUpCode) { result.push({ "id": wrapUpCode.id }); }
        }
        return result;
    }

    queueDataValidation = (queue, i, queuesData) => {
        let isqueueValidated = true;
        // <validate fields that can't be empty>
        if (!queue.queuename || !queue.queuename.trim()) { this.logEvent('Queue', `[Index ${i}] Name can't be empty.`, true); isqueueValidated = false; }
        // </validate fields that can't be empty>

        // <validate if wrapup code exist>
        var wrapupsArray;
        if (queue.wrapupcodes.contains("|")) {
            wrapupsArray = queue.wrapupcodes.split("|");
        } else {
            wrapupsArray = Misc.displayListToArray(queue.wrapupcodes);
        }
        for (const queueWrapup of wrapupsArray) {
            var queueWrapupValid = false;
            for (const wrapup of this.state.wrapUpCodeList) {
                if (queueWrapup.toLowerCase() === wrapup.name.toLowerCase()) {
                    queueWrapupValid = true;
                }
            }
            if (!queueWrapupValid) { this.logEvent('Queue', `[Index ${i}] Wrapup code '${queueWrapup}' doesn't exist`, true); isqueueValidated = false; }
        }
        // </validate if wrapup code exist>

        // <validate if site name is unique>
        for (const j in queuesData) {
            const site1 = queuesData[j];
            if (i === j) { continue; }
            if (queue.queuename.trim().toLowerCase() === site1.queuename.trim().toLowerCase()) { this.logEvent('Queue', `[Index ${i}][Index ${j}] Name should be unique.`, true); isqueueValidated = false; }
        }
        // </validate if site name is unique>

        return isqueueValidated;
    }

    importUsers = async (usersData, isMainCall) => {
        if (isMainCall) {
            this.usersContent = [];
            this.eventList = [];
        }
        let aloneUsers = [];
        for (const i in usersData) {
            const userData = usersData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Users' });
            this.logEvent('Users', `Processing user [${userData.name}] [${userData.email}]`);
            if (this.usersDataValidation(userData, i, usersData)) {
                try {
                    var rolesNameList;
                    var rolesIdList;
                    var result = {};
                    var roleNames = [];
                    if (userData.roles_divisions && userData.roles_divisions.length > 0) {
                        rolesNameList = userData.roles_divisions.split("|");
                        rolesNameList.forEach(role => {
                            let roleArray = role.split(':');
                            if (roleArray.length == 1) {
                                result[roleArray[0]] = [userData.division];
                                roleNames.push(roleArray[0]);
                            } else {
                                let divArray = roleArray[1].replace('[', '').replace(']', '').split(';');
                                result[roleArray[0]] = [userData.division].concat(divArray.map(s => s.trim()));
                                roleNames.push(roleArray[0]);
                            }
                        });
                        rolesIdList = this.getRoleIdList(roleNames);
                    }

                    if (Array.isArray(rolesIdList) && rolesIdList.length === 0) {
                        this.logEvent('Users', `\"Role:${userData.roles_divisions}\" does not exist on same row as User [${userData.name}] [${userData.email}] was not created!`, true);
                        this.usersContent.push(JSON.stringify(userData));
                    } else {
                        // <create user>
                        const divisionId = this.getDivisionId(userData.division);
                        const user = await usersPostUser(this.state.env, this.state.token, userData.name, userData.department, userData.email, userData.phone_work, userData.title, userData.password, divisionId);
                        console.log(`user created: ${JSON.stringify(user)}`);

                        // <assign roles>
                        if (userData.roles_divisions && userData.roles_divisions.length > 0) {
                            //const roleNameList = userData.roles.split("|");
                            const roleIdList = this.getRoleIdList(roleNames);
                            if (roleIdList.length > 0) {
                                await usersPutUserRoles(this.state.env, this.state.token, user.id, roleIdList);
                                console.log(`roles assigned: ${roleIdList.join(',')}`);
                            }
                        }
                        // </assign roles>


                        // <Assign Divisions to Roles>
                        let roleDivArray = [];
                        for (const role of Object.keys(result)) {
                            let roleId = this.state.roleList.find(el => el.name.toLowerCase() == role.toLowerCase()).id;
                            result[role].forEach(divisionName => {
                                let roleDivObj = {};
                                roleDivObj.roleId = roleId;
                                roleDivObj.divisionId = this.state.divisionList.find(el => el.name.toLowerCase() == divisionName.toLowerCase()).id;
                                roleDivArray.push(roleDivObj);
                            });
                        }

                        await postRoleDivisionGrants(roleDivArray, user.id, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name)
                        // <Assign Divisions to Roles>

                        // <assign queues>
                        if (userData.queues && userData.queues.length > 0) {
                            const queueNameList = userData.queues.split("|");
                            const queueIdList = this.getQueueIdList(queueNameList);
                            for (const queueId of queueIdList) {
                                await routingPostQueuesUsers(this.state.env, this.state.token, user.id, queueId);
                                console.log(`queue assigned: ${queueId}`);
                            }
                        }
                        // </assign queues>

                        // <assign skills>
                        if (userData.skills && userData.skills.length > 0) {
                            const skillNameList = userData.skills.split("|")
                            const proficiencyList = userData.proficiencies.split("|");
                            const skillIdList = this.getSkillIdListForUsers(skillNameList, proficiencyList);
                            if (skillIdList.length) {
                                await routingPatchRoutingSkillsBulk(this.state.env, this.state.token, user.id, skillIdList);
                                console.log(`skills assigned: ${skillNameList.join(',')}`);
                            }
                        }
                        // </assign skills>
                        this.setState({ usersList: [...this.state.usersList, user] });
                        this.logEvent('Users', `User [${userData.name}] [${userData.email}] successfully processed`);
                    }
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Users', `Rate limit exceeded the maximum`, true);
                        await this.setState({
                            preloaderShow: true,
                            preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds'
                        });
                        aloneUsers.push(userData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('Users', `${err.message}`, true);
                        this.usersContent.push(JSON.stringify(userData));
                    }
                }
            } else {
                this.usersContent.push(JSON.stringify(userData));
            }
        }

        if (aloneUsers.length > 0) {
            this.importUsers(aloneUsers, false);
        }

        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getSkillsListItems = async () => {
        return await getRoutingGetSkills(this.state.env, this.state.token);
    }

    getRoleIdList = (roleNameList) => {
        var result = [];
        for (const roleName of roleNameList) {
            const role = this.state.roleList.filter(r => r.name.toLowerCase() === roleName.toLowerCase())[0];
            if (role) { result.push(role.id); }
        }
        return result;
    }

    getDivisionId = (name) => {
        const division = this.state.divisionList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (division) { return division.id; }
    }

    getQueueIdList = (queueNameList) => {
        var result = [];
        for (const queueName of queueNameList) {
            const queue = this.state.queueList.filter(q => q.name.toLowerCase() === queueName.toLowerCase())[0];
            if (queue) { result.push(queue.id); }
        }
        return result;
    }

    getSkillIdListForUsers = (skillNameList, proficiencyList) => {
        var result = [];
        let skillList = this.state.skillList;
        for (const i in skillNameList) {
            const skill = skillList.filter(s => s.name.toLowerCase() === skillNameList[i].toLowerCase())[0];
            const proficiency = proficiencyList.length === skillNameList.length ? proficiencyList[i] : 5;
            if (skill) { result.push({ "id": skill.id, "proficiency": proficiency }); }
        }
        return result;
    }

    usersDataValidation = (user, i, usersData) => {
        let isUserValidated = true;
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        // <validate fields that can't be empty>
        if (!user.name || !user.name.trim()) { this.logEvent('Users', `[Index ${i}] Name can't be empty.`, true); isUserValidated = false; }
        if (!user.email || !user.email.trim()) { this.logEvent('Users', `[Index ${i}] Email can't be empty.`, true); isUserValidated = false; }
        if (!user.password || !strongRegex.test(user.password)) { this.logEvent('Users', `[Index ${i}] The password does not meet the requirements of more than 8 characters, must contain upper and lowercase chars, a digit, and at least one special character.`, true); isUserValidated = false; }
        if (!user.roles_divisions || !user.roles_divisions.trim()) { this.logEvent('Users', `[Index ${i}] Roles can't be empty.`, true); isUserValidated = false; }
        if (!user.division || !user.division.trim()) { this.logEvent('Users', `[Index ${i}] Division can't be empty.`, true); isUserValidated = false; }
        // </validate fields that can't be empty>

        // <validate if roles exist>
        var roleNames = [];
        if (user.roles_divisions) {
            let rolesNameList = user.roles_divisions.split("|");
            rolesNameList.forEach(role => {
                let roleArray = role.split(':');
                roleNames.push(roleArray[0]);
            });
        }

        for (const userRole of roleNames) {
            var userRoleValid = false;
            for (const role of this.state.roleList) {
                if (userRole.toLowerCase() === role.name.toLowerCase()) {
                    userRoleValid = true;
                }
            }
            if (!userRoleValid) { this.logEvent('Users', `[Index ${i}] Role '${userRole}' doesn't exist`, true); isUserValidated = false; }
        }
        // </validate if roles exist>

        // <validate if queues exist>
        const queueNameList = Misc.displayListToArray(user.queues);
        const newQueueNameList = this.getSplitedByLineList(queueNameList);
        for (const userQueue of newQueueNameList) {
            var userQueueValid = false;
            for (const queue of this.state.queueList) {
                if (userQueue.toLowerCase() === queue.name.toLowerCase()) {
                    userQueueValid = true;
                }

            }
            if (!userQueueValid) { this.logEvent('Users', `[Index ${i}] Queue '${userQueue}' doesn't exist`, true); isUserValidated = false; }
        }
        // </validate if queues exist>

        // <validate if division exist>
        const userDivision = user.division;
        if (userDivision) {
            var userDivisionValid = false;
            for (const division of this.state.divisionList) {
                if (userDivision.toLowerCase() === division.name.toLowerCase()) {
                    userDivisionValid = true;
                }
            }
            if (!userDivisionValid) { this.logEvent('Users', `[Index ${i}] Division '${userDivision}' doesn't exist`, true); isUserValidated = false; }
        }
        // </validate if division exist>
        const skillsArray = user.skills === "" ? "" : user.skills.split("|");
        const proficienciesArray = user.proficiencies === "" ? "" : user.proficiencies.split("|");

        // <validate number of skills and proficiences>      
        if (skillsArray.length !== 0 && proficienciesArray.length !== 0) {
            // if there are no skills at all proficiencies are ignored
            // if there are no proficiencies at all a default values will be applied
            if (skillsArray.length !== proficienciesArray.length) {
                this.logEvent('Users', `[Index ${i}] Number of proficiencies is different than number of skills`, true);
                isUserValidated = false;
            }
        }
        // </validate number of skills and proficiences>

        this.getSkillsListItems().then(skillList => {
            // <validate if skills exist>
            for (const userSkill of skillsArray) {
                var userSkillValid = false;
                for (const skill of skillList) {
                    if (userSkill.toLowerCase() === skill.name.toLowerCase()) {
                        userSkillValid = true;
                    }
                }
                if (!userSkillValid) {
                    this.logEvent('Users', `[Index ${i}] Skill '${userSkill}' doesn't exist`, true);
                    isUserValidated = false;
                }
            }
        });

        // </validate if skills exist>

        // <validate proficiency values>
        for (const prof of proficienciesArray) {
            if (!Misc.isNumeric(prof) || prof < 0 || prof > 5) { this.logEvent('Users', `[Index ${i}] Proficiency must be an integer between 0 and 5`, true); isUserValidated = false; }
        }
        // </validate proficiency values>

        // <validate if user name and email are unique>
        for (const j in usersData) {
            const user1 = usersData[j];
            if (i === j) { continue; }
            if (user.name.trim().toLowerCase() === user1.name.trim().toLowerCase()) { this.logEvent('Users', `[Index ${i}][Index ${j}] Name should be unique.`, true); isUserValidated = false; }
            if (user.email.trim().toLowerCase() === user1.email.trim().toLowerCase()) { this.logEvent('Users', `[Index ${i}][Index ${j}] Email should be unique.`, true); isUserValidated = false; }
        }
        // </validate if user name and email are unique>
        return isUserValidated;
    }

    importSkillsMatrix = async (skillsMatrixData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.skillsMatrixContent = [];
        }
        let aloneSkillMatrix = [];
        for (const i in skillsMatrixData) {
            const skillsData = skillsMatrixData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Skills Matrix' });
            this.logEvent('SkillsMatrix', `Processing skills for [${skillsData.useremail}]`);
            if (this.skillsMatrixDataValidation(skillsData, i, skillsMatrixData)) {
                try {
                    // <serach for user>
                    const user = this.getUser(skillsData.useremail);
                    if (!user) { throw new Error("User not found"); }
                    // </serach for user>

                    // <assign skills>
                    const skillIdList = this.getSkillIdList(skillsData);
                    if (skillIdList && skillIdList.length) {
                        await routingPatchRoutingSkillsBulk(this.state.env, this.state.token, user.id, skillIdList);
                        // </assign skills>

                        this.logEvent('SkillsMatrix', `Skills for [${skillsData.useremail}] successfully processed`);
                    } else {
                        this.logEvent('SkillsMatrix', `Can't execute import for skills [${skillsData.useremail}]. `, true);
                        this.skillsMatrixContent.push(JSON.stringify(skillsData));
                    }

                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('SkillsMatrix', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneSkillMatrix.push(skillsData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('SkillsMatrix', `${err.message}`, true);
                        this.skillsMatrixContent.push(JSON.stringify(skillsData));
                    }
                }
            } else {
                this.skillsMatrixContent.push(JSON.stringify(skillsData));
            }
        }

        if (aloneSkillMatrix.length > 0) {
            this.importSkillsMatrix(aloneSkillMatrix, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getSkillIdList = (skillsData) => {
        var result = [];
        var count = 1;
        Object.keys(skillsData).map(key => {
            if (key === 'skill' + count) {
                const skillHeader = 'skill' + count;
                const proficiencyHeader = 'proficiency' + count;
                const skillValue = skillsData[skillHeader];
                const proficiencyValue = skillsData[proficiencyHeader];
                if (skillValue && proficiencyValue) {
                    var skill = this.getSkill(skillValue);
                    if (skill) {
                        result.push({ "id": skill.id, "proficiency": proficiencyValue });
                    }
                }
                count = count + 1;
            }
        });
        return result;
    };

    getUser = (email) => {
        const user = this.state.usersList.filter(u => u.email.toLowerCase() === email.toLowerCase())[0];
        if (user) { return user; }
    }

    getSkill = (name) => {
        const skill = this.state.skillList.filter(s => s.name.toLowerCase() === name.toLowerCase())[0];
        if (skill) { return skill; }
    }

    skillsMatrixDataValidation = (skills, i, skillsMatrixData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!skills.useremail || !skills.useremail.trim()) { this.logEvent('SkillsMatrix', `[Index ${i}] Email can't be empty.`, true); isValidated = false; }
        if (!skills.skill1 || !skills.skill1.trim()) { this.logEvent('SkillsMatrix', `[Index ${i}] Enter skill 1.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        //<validate if email is unique>
        for (const j in skillsMatrixData) {
            const skills1 = skillsMatrixData[j];
            if (i === j) { continue; }
            if (skills.useremail.trim().toLowerCase() === skills1.useremail.trim().toLowerCase()) { this.logEvent('SkillsMatrix', `[Index ${i}][Index ${j}] Email should be unique.`, true); isValidated = false; }
        }
        //</validate if email is unique>

        //<validate if email is valid>
        const user = this.getUser(skills.useremail);
        if (!user) { this.logEvent('SkillsMatrix', `[Index ${i}] User not found for email: ${skills.useremail}`, true); isValidated = false; }
        //</validate if email is valid>

        // <validate skills>
        var count = 1;
        Object.keys(skills).map(key => {
            if (key === 'skill' + count) {
                const skillHeader = 'skill' + count;
                const proficiencyHeader = 'proficiency' + count;

                const skillValue = skills[skillHeader];
                const proficiencyValue = skills[proficiencyHeader];

                if (skillValue && skillValue.trim()) {
                    const skill = this.getSkill(skillValue);
                    if (!skill) {
                        this.logEvent('SkillsMatrix', `[Index ${i}] Skill '${skillValue}' doesn't exist`, true);
                        isValidated = false;
                    }
                    if (!Misc.isNumeric(proficiencyValue) || proficiencyValue < 0 || proficiencyValue > 5) {
                        this.logEvent('SkillsMatrix', `[Index ${i}] Proficiency must be an integer between 0 and 5`, true);
                        isValidated = false;
                    }
                }
                count = count + 1;
            }
        });
        // </validate skills>

        return isValidated;
    }

    importEdgeGroups = async (edgeGroupsData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.edgeGroupsContent = [];
        }
        let aloneEdgeGroups = [];
        for (const i in edgeGroupsData) {
            const edgeGroupData = edgeGroupsData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Edge Groups' });
            this.logEvent('EdgeGroups', `Processing edge group [${edgeGroupData.name}]`);
            if (this.edgeGroupsDataDataValidation(edgeGroupData, i, edgeGroupsData)) {
                try {
                    // <create edge group>
                    const phoneTrunk = this.getTrunkBaseSettingsByName(edgeGroupData.phoneTrunkBaseName);
                    const edgeGroup = await telephonyPostEdgeGroups(this.state.env, this.state.token, edgeGroupData.name, edgeGroupData.description, edgeGroupData.managed.toLowerCase(), phoneTrunk.id, edgeGroupData.phoneTrunkBaseName);
                    console.log(`Edge group created: ${JSON.stringify(edgeGroup)}`);
                    // </create edge group>

                    this.logEvent('EdgeGroups', `Edge group [${edgeGroupData.name}] successfully processed`);

                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('EdgeGroups', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneEdgeGroups.push(edgeGroupData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('EdgeGroups', `${err.message}`, true);
                        this.edgeGroupsContent.push(JSON.stringify(edgeGroupData));
                    }
                }
            } else {
                this.edgeGroupsContent.push(JSON.stringify(edgeGroupData));
            }
        }

        if (aloneEdgeGroups.length > 0) {
            this.importEdgeGroups(aloneEdgeGroups, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getTrunkBaseSettingsByName = (name) => {
        const trunkBaseSettings = this.state.trunkBaseSettingsList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (trunkBaseSettings) { return trunkBaseSettings; }
    }

    edgeGroupsDataDataValidation = (edgeGroup, i, edgeGroupsData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!edgeGroup.name || !edgeGroup.name.trim()) { this.logEvent('EdgeGroups', `[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!edgeGroup.description || !edgeGroup.description.trim()) { this.logEvent('EdgeGroups', `[Index ${i}] Description can't be empty.`, true); isValidated = false; }
        if (!edgeGroup.phoneTrunkBaseName || !edgeGroup.phoneTrunkBaseName.trim()) { this.logEvent('EdgeGroups', `[Index ${i}] Phone Trunk Base Name can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if trunk base settings exist>
        var trunkBaseSettingsValid = false;
        for (const trunkBaseSettings of this.state.trunkBaseSettingsList) {
            if (edgeGroup.phoneTrunkBaseName.toLowerCase() === trunkBaseSettings.name.toLowerCase()) {
                trunkBaseSettingsValid = true;
            }
        }
        if (!trunkBaseSettingsValid) { this.logEvent('EdgeGroups', `[Index ${i}] Phone Trunk '${edgeGroup.phoneTrunkBaseName}' doesn't exist`, true); isValidated = false; }
        // <//validate if trunk base settings exist>

        // <validate if edge group anem is unique>
        for (const j in edgeGroupsData) {
            const edgeGroup1 = edgeGroupsData[j];
            if (i === j) { continue; }
            if (edgeGroup.name.trim().toLowerCase() === edgeGroup1.name.trim().toLowerCase()) { this.logEvent('EdgeGroups', `[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if edge group name is unique>

        return isValidated;
    }

    importPhoneBaseSettings = async (phonesBaseSettings, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.phoneBaseSettingsContent = [];
        }
        let alonePhoneBaseSettings = [];
        for (const i in phonesBaseSettings) {
            const phonesBaseSetting = phonesBaseSettings[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Phone Base Settings' });
            this.logEvent('phones-base-settings', `Processing phone base setting [${phonesBaseSetting.name}]`);
            if (this.phonesBaseSettingsDataValidation(phonesBaseSetting, i, phonesBaseSettings)) {
                try {
                    // <create phone>
                    const metaBaseSetting = this.getPhoneMetaBaseByPhoneName(phonesBaseSetting.phoneMetaBase);
                    console.log('metaBaseSetting => ', metaBaseSetting)
                    const phoneSetting = await telephonyPostPhoneBaseSettings(this.state.env, this.state.token, phonesBaseSetting.name, metaBaseSetting);

                    console.log(`Phone base setting created: ${JSON.stringify(phoneSetting)}`);
                    this.logEvent('phones-base-settings', `Phone base settings [${phonesBaseSetting.name}] successfully processed`);
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('phones-base-settings', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        alonePhoneBaseSettings.push(phonesBaseSetting);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('phones-base-settings', `${err.message}`, true);
                        this.phoneBaseSettingsContent.push(JSON.stringify(phonesBaseSetting));
                    }
                }
            } else {
                this.phoneBaseSettingsContent.push(JSON.stringify(phonesBaseSetting));
            }
        }

        if (alonePhoneBaseSettings.length > 0) {
            this.importPhoneBaseSettings(alonePhoneBaseSettings, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getPhoneMetaBaseByPhoneName = (name) => {
        const metaBaseSetting = this.state.metaBaseSettings.filter(setting => {
            return setting.name.toLowerCase() === name.toLowerCase()
        })[0];
        if (metaBaseSetting) { return metaBaseSetting; }
    }

    phonesBaseSettingsDataValidation = (baseSetting, i, baseSettings) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!baseSetting.name || !baseSetting.name.trim()) { this.logEvent('phones-base-settings', `[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!baseSetting.phoneMetaBase || !baseSetting.phoneMetaBase.trim()) { this.logEvent('phones-base-settings', `[Index ${i}] Phone Make and Model can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if phone name is unique>
        for (const j in baseSettings) {
            const phone1 = baseSettings[j];
            if (i === j) { continue; }
            if (baseSetting.name.trim().toLowerCase() === phone1.name.trim().toLowerCase()) { this.logEvent('phones-base-settings', `[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if phone name is unique>

        return isValidated;
    }

    importPhones = async (phonesData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.phonesContent = [];
            await this.loadPhonesSitesItems();
            await this.loadPhoneBaseSettings();
            await this.loadLinebaseSettings();
        }
        let alonePhones = [];
        for (const i in phonesData) {
            const phoneData = phonesData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Phones' });
            this.logEvent('Phones', `Processing phone [${phoneData.name}]`);
            if (this.phonesDataValidated(phoneData, i, phonesData)) {
                try {
                    let isValid = true;
                    // <create phone>
                    const phoneSite = this.getSitesByName(phoneData.site);
                    if (!phoneSite) {
                        this.logEvent('Phones', `Phone site does not exist.`, true); isValid = false;
                    }
                    const phoneBaseSettings = this.getBaseSettingsByName(phoneData.phoneBaseSettings);
                    if (!phoneBaseSettings) {
                        this.logEvent('Phones', `phoneBaseSettings does not exist.`, true); isValid = false;
                    }
                    const lineBaseSetting = this.getLineBaseSettingsByName(phoneData.lineBaseSettings);
                    if (!lineBaseSetting) {
                        this.logEvent('Phones', `lineBaseSettings does not exist.`, true); isValid = false;
                    }

                    if (isValid) {
                        var user = null;
                        if (phoneBaseSettings !== undefined) {
                            if (phoneBaseSettings.name === "WebRTC Phone") {
                                user = this.getUserByName(phoneData.person);
                            }
                        }
                        const phone = await telephonyPostPhones(this.state.env, this.state.token, phoneData, phoneBaseSettings, phoneSite, lineBaseSetting, user);
                        console.log(`Phone created: ${JSON.stringify(phone)}`);
                        // </create phone>

                        this.logEvent('Phones', `Phone [${phoneData.name}] successfully processed`);
                    } else {
                        this.phonesContent.push(JSON.stringify(phoneData));
                    }
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Phones', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        alonePhones.push(phoneData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('Phones', `${err.message}`, true);
                        this.phonesContent.push(JSON.stringify(phoneData));
                    }
                }
            } else {
                this.phonesContent.push(JSON.stringify(phoneData));
            }
        }

        if (alonePhones.length > 0) {
            this.importPhones(alonePhones, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getBaseSettingsByName = (name) => {
        const baseSettings = this.state.phoneBaseSettingsList.filter(setting => setting.name.toLowerCase() === name.toLowerCase())[0];
        if (baseSettings) { return baseSettings; }
    }

    getSitesByName = (name) => {
        const sites = this.state.phonesSites.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (sites) { return sites; }
    }

    getUserByName = (name) => {
        const user = this.state.usersList.filter(user => user.name.toLowerCase() === name.toLowerCase())[0];
        if (user) { return user; }
    }

    getLineBaseSettingsByName = (name) => {
        const line = this.state.lineBaseSettings.filter(line => {
            return line.name.toLowerCase() === name.toLowerCase();
        })[0];
        if (line) { return line; }
    }

    phonesDataValidated = (phone, i, phonesData) => {
        let isValidated = true;

        // <validate fields that can't be empty>
        if (!phone.name || !phone.name.trim()) { this.logEvent('Phones', `[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!phone.phoneBaseSettings || !phone.phoneBaseSettings.trim()) { this.logEvent('Phones', `[Index ${i}] Base Settings can't be empty.`, true); isValidated = false; }
        if (!phone.lineBaseSettings || !phone.lineBaseSettings.trim()) { this.logEvent('Phones', `[Index ${i}] LineBaseSettings can't be empty.`, true); isValidated = false; }
        if (!phone.site || !phone.site.trim()) { this.logEvent('Phones', `[Index ${i}] Site can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if phone name is unique>
        for (const j in phonesData) {
            const phone1 = phonesData[j];
            if (i === j) { continue; }
            if (phone.name.trim().toLowerCase() === phone1.name.trim().toLowerCase()) { this.logEvent('Phones', `[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if phone name is unique>

        return isValidated;
    }

    importSchedules = async (schedulesData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.schedulesContent = [];
        }
        let aloneSchedules = [];
        for (const i in schedulesData) {
            const scheduleData = schedulesData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Schedules' });
            this.logEvent('Schedules', `Processing schedules [${scheduleData.name}]`);
            if (this.schedulesDataValidation(scheduleData, i, schedulesData)) {
                try {
                    // <create Schedule>          
                    const schedule = await architectPostSchedule(this.state.env, this.state.token, scheduleData.name, scheduleData.start, scheduleData.end, scheduleData.rrule);
                    console.log(`Schedule created: ${JSON.stringify(schedule)}`);
                    // </create Schedule>
                    this.logEvent('Schedules', `Schedule [${scheduleData.name}] successfully processed`);
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('Schedules', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneSchedules.push(scheduleData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('Schedules', `${err.message}`, true);
                        this.schedulesContent.push(JSON.stringify(scheduleData));
                    }
                }
            } else {
                this.schedulesContent.push(JSON.stringify(scheduleData));
            }
        }

        if (aloneSchedules.length > 0) {
            this.importSchedules(aloneSchedules, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    schedulesDataValidation = (schedule, i, schedulesData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!schedule.name || !schedule.name.trim()) { this.logEvent('Schedules', `[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!schedule.start || !schedule.start.trim()) { this.logEvent('Schedules', `[Index ${i}] Start can't be empty.`, true); isValidated = false; }
        if (!schedule.end || !schedule.end.trim()) { this.logEvent('Schedules', `[Index ${i}] Endcan't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if name is unique>
        for (const j in schedulesData) {
            const schedule1 = schedulesData[j];
            if (i === j) { continue; }
            if (schedule.name.trim().toLowerCase() === schedule1.name.trim().toLowerCase()) { this.logEvent('Schedules', `[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if name is unique>

        return isValidated;
    }

    importScheduleGroups = async (scheduleGroupsData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.scheduleGroupContent = [];
        }
        let aloneScheduleGroups = [];
        for (const i in scheduleGroupsData) {
            const scheduleGroupData = scheduleGroupsData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Schedule Groups' });
            this.logEvent('ScheduleGroup', `Processing schedule groups [${scheduleGroupData.name}]`);
            if (this.dataValidatedScheduleGroupsData(scheduleGroupData, i, scheduleGroupsData)) {
                try {
                    const openScheduleNames = Misc.displayListToArray(scheduleGroupData.openSchedules);
                    const closedScheduleNames = Misc.displayListToArray(scheduleGroupData.closedSchedules);
                    const holidayScheduleNames = Misc.displayListToArray(scheduleGroupData.holidaySchedules);

                    const openSchedules = this.getScheduleList(this.getSplitedByLineList(openScheduleNames));
                    const closedSchedules = this.getScheduleList(this.getSplitedByLineList(closedScheduleNames));
                    const holidaySchedules = this.getScheduleList(this.getSplitedByLineList(holidayScheduleNames));

                    // <create schedule group>          
                    const schedule = await architectPostScheduleGroup(this.state.env, this.state.token, scheduleGroupData.name, scheduleGroupData.timeZone, openSchedules, closedSchedules, holidaySchedules);
                    console.log(`Schedule created: ${JSON.stringify(schedule)}`);
                    // </create schedule group>
                    this.logEvent('ScheduleGroup', `Schedule group [${scheduleGroupData.name}] successfully processed`);
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('ScheduleGroup', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneScheduleGroups.push(scheduleGroupData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('ScheduleGroup', `${err.message}`, true);
                        this.scheduleGroupContent.push(JSON.stringify(scheduleGroupData));
                    }
                }
            } else {
                this.scheduleGroupContent.push(JSON.stringify(scheduleGroupData));
            }
        }

        if (aloneScheduleGroups.length > 0) {
            this.importScheduleGroups(aloneScheduleGroups, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getScheduleList = (scheduleNameList) => {
        var result = [];
        for (const scheduleName of scheduleNameList) {
            const schedule = this.state.scheduleList.filter(q => q.name.toLowerCase() === scheduleName.toLowerCase())[0];
            if (schedule) { result.push(schedule); }
        }
        return result;
    }

    dataValidatedScheduleGroupsData = (scheduleGroup, i, scheduleGroupsData) => {
        let isValidated = true;

        // <validate fields that can't be empty>
        if (!scheduleGroup.name || !scheduleGroup.name.trim()) { this.logEvent('ScheduleGroup', `[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!scheduleGroup.timeZone || !scheduleGroup.timeZone.trim()) { this.logEvent('ScheduleGroup', `[Index ${i}] Time zone can't be empty.`, true); isValidated = false; }
        if (!scheduleGroup.openSchedules || !scheduleGroup.openSchedules.trim()) { this.logEvent('ScheduleGroup', `[Index ${i}] Enter at least one open schedule.`, true); isValidated = false; }
        //</validate fields that can't be empty>

        // <validate if open schedules exist>
        const openScheduleNames = scheduleGroup.openSchedules.split('|');
        for (const openSchedule of openScheduleNames) {
            var openScheduleValid = false;
            for (const schedule of this.state.scheduleList) {
                if (openSchedule.toLowerCase() === schedule.name.toLowerCase()) {
                    openScheduleValid = true;
                }
            }
            if (openSchedule === "") {
                this.logEvent('ScheduleGroup', `[Index ${i}] Open Schedules is empty`, false, true);
            } else if (!openScheduleValid) {
                this.logEvent('ScheduleGroup', `[Index ${i}] Schedule '${openSchedule}' doesn't exist`, true);
                isValidated = false;
            }
        }
        // </validate if open schedules exist>

        // <validate if closed schedules exist>
        const closedScheduleNames = scheduleGroup.closedSchedules.split('|');
        for (const closedSchedule of closedScheduleNames) {
            var closedScheduleValid = false;
            for (const schedule of this.state.scheduleList) {
                if (closedSchedule.toLowerCase() === schedule.name.toLowerCase()) {
                    closedScheduleValid = true;
                }
            }
            if (closedSchedule === "") {
                this.logEvent('ScheduleGroup', `[Index ${i}] Closed Schedules is empty`, false, true);
            } else if (!closedScheduleValid) {
                this.logEvent('ScheduleGroup', `[Index ${i}] Schedule '${closedSchedule}' doesn't exist`, true);
                isValidated = false;
            }
        }
        // </validate if closed schedules exist>

        // <validate if holiday schedules exist>
        const holidayScheduleNames = scheduleGroup.holidaySchedules.split('|');
        for (const holidaySchedule of holidayScheduleNames) {
            var holidayScheduleValid = false;
            for (const schedule of this.state.scheduleList) {
                if (holidaySchedule.toLowerCase() === schedule.name.toLowerCase()) {
                    holidayScheduleValid = true;
                }
            }
            if (holidaySchedule === "") {
                this.logEvent('ScheduleGroup', `[Index ${i}] Holiday Schedule is empty`, false, true);
            } else if (!holidayScheduleValid) {
                this.logEvent('ScheduleGroup', `[Index ${i}] Schedule '${holidaySchedule}' doesn't exist`, true);
                isValidated = false;
            }
        }
        // </validate if holiday schedules exist>

        //<validate if name is unique>
        for (const j in scheduleGroupsData) {
            const scheduleGroup1 = scheduleGroupsData[j];
            if (i === j) { continue; }
            if (scheduleGroup.name.trim().toLowerCase() === scheduleGroup1.name.trim().toLowerCase()) { this.logEvent('ScheduleGroup', `[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        //</validate if name is unique>
        return isValidated;
    }

    importIvrsRouting = async (ivrsRoutingData, isMainCall) => {
        if (isMainCall) {
            this.eventList = [];
            this.ivrRoutingContent = [];
        }
        let aloneIVRs = [];
        for (const i in ivrsRoutingData) {
            const ivrRouteData = ivrsRoutingData[i];
            await this.setState({ preloaderShow: true, preloaderMessage: 'Loading IVRs Routing' });
            this.logEvent('IvrsRouting', `Processing IVR routing [${ivrRouteData.name}]`);
            if (this.ivrsRoutingdataValidation(ivrRouteData, i, ivrsRoutingData)) {
                try {
                    // <create IVR route>
                    const addresses = ivrRouteData.addresses.split("|");
                    const schGroup = this.getScheduleGroup(ivrRouteData.scheduleGroup);
                    const openHoursFlow = this.getFlow(ivrRouteData.openHoursFlow);
                    const closedHoursFlow = this.getFlow(ivrRouteData.closedHoursflow);
                    const holidayHoursFlow = this.getFlow(ivrRouteData.holidayHoursflow);
                    var ivrRoute = "";
                    if (schGroup) {
                        if (openHoursFlow === undefined && closedHoursFlow === undefined) {
                            this.logEvent('IvrsRouting', `The schedule group of '${ivrRouteData.scheduleGroup}' does not have a definition for Open or Closed hour flows.`, true);
                            this.ivrRoutingContent.push(JSON.stringify(ivrRouteData));
                        } else {
                            ivrRoute = await architectPostIvrs(this.state.env, this.state.token, ivrRouteData.name, addresses, schGroup, openHoursFlow, closedHoursFlow, holidayHoursFlow);
                            console.log(`IVR routing ${JSON.stringify(ivrRoute)}`);
                            // </create IVR route>
                            this.logEvent('IvrsRouting', `IVR routing [${ivrRouteData.name}] successfully processed`);
                        }
                    } else {
                        ivrRoute = await architectPostIvrs(this.state.env, this.state.token, ivrRouteData.name, addresses, schGroup, openHoursFlow, closedHoursFlow, holidayHoursFlow);
                        console.log(`IVR routing ${JSON.stringify(ivrRoute)}`);
                        // </create IVR route>
                        this.logEvent('IvrsRouting', `IVR routing [${ivrRouteData.name}] successfully processed`);
                    }
                } catch (err) {
                    if (err.message.includes("Rate limit exceeded the maximum")) {
                        this.logEvent('IvrsRouting', `Rate limit exceeded the maximum`, true);
                        await this.setState({ preloaderShow: true, preloaderMessage: 'Rate limit exceeded, Please Wait for few seconds' });
                        aloneIVRs.push(ivrRouteData);
                        await this.sleep(20000);
                    } else {
                        this.logEvent('IvrsRouting', `${err.message}`, true);
                        this.ivrRoutingContent.push(JSON.stringify(ivrRouteData));
                    }
                }
            } else {
                this.ivrRoutingContent.push(JSON.stringify(ivrRouteData));
            }
        }

        if (aloneIVRs.length > 0) {
            this.importIvrsRouting(aloneIVRs, false);
        }
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    getScheduleGroup = (name) => {
        const schGroup = this.state.scheduleGroupList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (schGroup) { return schGroup; }
    }

    getFlow = (name) => {
        const flow = this.state.flowList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (flow) { return flow; }
    }

    ivrsRoutingdataValidation = (ivrRoute, i, ivrsRoutingData) => {
        let isIVRValidated = true;

        // <validate fields that can't be empty>
        if (!ivrRoute.name || !ivrRoute.name.trim()) { this.logEvent('IvrsRouting', `[Index ${i}] Name can't be empty.`, true); isIVRValidated = false; }
        if (!ivrRoute.addresses || !ivrRoute.addresses.trim()) { this.logEvent('IvrsRouting', `[Index ${i}] Addresses can't be empty.`, true); isIVRValidated = false; }
        if (!ivrRoute.scheduleGroup || !ivrRoute.scheduleGroup.trim()) { this.logEvent('IvrsRouting', `[index ${i}] Scheduled group can't be empty.`, true); isIVRValidated = false; }
        if (!ivrRoute.openHoursFlow || !ivrRoute.openHoursFlow.trim()) { this.logEvent('IvrsRouting', `[Index ${i}] Open hours flow can't be empty.`, true); isIVRValidated = false; }
        if (!ivrRoute.closedHoursflow || !ivrRoute.closedHoursflow.trim()) { this.logEvent('IvrsRouting', `[Index ${i}] Closed hours flow can't be empty.`, true); isIVRValidated = false; }
        // </validate fields that can't be empty>

        // <validate if the schedule group exist>
        var schGroupValid = false;
        if (ivrRoute.openHoursFlow) {
            for (const schGroup of this.state.scheduleGroupList) {
                if (ivrRoute.scheduleGroup.toLowerCase() === schGroup.name.toLowerCase()) {
                    schGroupValid = true;
                }
            }
        }
        if (!schGroupValid) { this.logEvent('IvrsRouting', `[Index ${i}] Schedule group '${ivrRoute.scheduleGroup}' doesn't exist`, true); isIVRValidated = false; }
        // </validate if the schedule group exist>

        // <validate if flows exist>
        var openHoursFlowValid = false;
        var closedHoursFlowValid = false;
        var holidayHoursFlowValid = false;
        if (ivrRoute.openHoursFlow) {
            for (const flow of this.state.flowList) {
                if (ivrRoute.openHoursFlow.toLowerCase() === flow.name.toLowerCase()) {
                    openHoursFlowValid = true;
                }
                if (ivrRoute.closedHoursflow.toLowerCase() === flow.name.toLowerCase()) {
                    closedHoursFlowValid = true;
                }
                if (ivrRoute.holidayHoursflow) {
                    if (ivrRoute.holidayHoursflow.toLowerCase() === flow.name.toLowerCase()) {
                        holidayHoursFlowValid = true;
                    }
                }
            }
        }
        if (ivrRoute.openHoursFlow && !openHoursFlowValid) { this.logEvent('IvrsRouting', `[Index ${i}] Flow '${ivrRoute.openHoursFlow}' doesn't exist`, true); isIVRValidated = false; }
        if (ivrRoute.closedHoursflow && !closedHoursFlowValid) { this.logEvent('IvrsRouting', `[Index ${i}] Flow '${ivrRoute.closedHoursflow}' doesn't exist`, true); isIVRValidated = false; }
        if (ivrRoute.holidayHoursflow && !holidayHoursFlowValid) { this.logEvent('IvrsRouting', `[Index ${i}] Flow '${ivrRoute.holidayHoursflow}' doesn't exist`, true); isIVRValidated = false; }
        // </validate if flows exist>

        // <validate if name is unique>
        for (const j in ivrsRoutingData) {
            const ivrRoute1 = ivrsRoutingData[j];
            if (i === j) { continue; }
            if (ivrRoute.name.trim().toLowerCase() === ivrRoute1.name.trim().toLowerCase()) { this.logEvent('IvrsRouting', `[Index ${i}][Index ${j}] Name should be unique.`, true); isIVRValidated = false; }
        }
        // </validate if name is unique>

        return isIVRValidated;
    }

    getEditModalContet = (objectType) => {
        if (objectType === "divisions") {
            return this.divisionsContent;
        } else if (objectType === 'skills') {
            return this.skillsContent;
        } else if (objectType === 'WrapUp') {
            return this.wrapUpContent;
        } else if (objectType === 'Roles') {
            return this.rolesContent;
        } else if (objectType === 'Locations') {
            return this.locationsContent;
        } else if (objectType === 'Sites') {
            return this.sitesContent;
        } else if (objectType === 'Dids') {
            return this.didContent;
        } else if (objectType === 'Extensions') {
            return this.extensionsContent;
        } else if (objectType === 'queues') {
            return this.queuesContent;
        } else if (objectType === 'Queue') {
            return this.QueueContent;
        } else if (objectType === 'Users') {
            return this.usersContent;
        } else if (objectType === 'SkillsMatrix') {
            return this.skillsMatrixContent;
        } else if (objectType === 'EdgeGroups') {
            return this.edgeGroupsContent;
        } else if (objectType === 'phones-base-settings') {
            return this.phoneBaseSettings;
        } else if (objectType === 'Phones') {
            return this.phonesContent;
        } else if (objectType === 'Schedules') {
            return this.schedulesContent;
        } else if (objectType === 'ScheduleGroup') {
            return this.scheduleGroupContent;
        } else if (objectType === 'IvrsRouting') {
            return this.ivrRoutingContent;
        }
        return [];
    }

    getSplitedByLineList = (listToSplit) => {
        const newList = [];
        for (const name of listToSplit) {
            if (name.contains('|')) {
                const names = name.split('|');
                for (const item of names) {
                    newList.push(item);
                }
            } else {
                newList.push(name);
            }
        }
        return newList;
    }

    handleDelete = itemId => {
        const items = this.state.filesData.filter(item => item.name !== itemId);
        this.setState({ filesData: items });
    };

    reloadData = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Updating Items' });
        await this.sleep(2000);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadLocationListItems = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading' });
        this.state.locationList = await getlocations(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadWrapUpCodeList = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading' });
        this.state.wrapUpCodeList = await getRoutingWrapupCodes(this.state.env, this.state.token);
        this.state.divisionList = await getAuthorizationDivisions(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadItemsForUsersImport = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading Items for Users' });
        this.state.roleList = await getAuthorizationRoles(this.state.env, this.state.token);
        this.state.queueList = await getRoutingGetQueues(this.state.env, this.state.token);
        this.state.divisionList = await getAuthorizationDivisions(this.state.env, this.state.token);
        this.state.skillList = await getRoutingGetSkills(this.state.env, this.state.token);
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadscheduleList = async () => {
        await this.setState({ preloaderShow: true, preloaderMessage: 'Loading' });
        this.state.scheduleList = await getArchitects(this.state.env, this.state.token, 'schedules');
        await this.setState({ preloaderShow: false, preloaderMessage: 'Loading' });
    }

    loadItemsForIVR = async () => {
        await this.loadIvrsRoutingItems();
        await this.loadFlows();
    }

    openEditAndRetryModal = (name, rows, eventsForCurrentRow, objectType) => {
        let content = this.getEditModalContet(objectType);
        this.setState({ editAndRetryModalIsOpen: true, editModalFileName: name, editModalContent: content, eventsForCurrentRow: eventsForCurrentRow })
    }

    sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.zip')) {
                console.error('Only ZIP files are allowed');
                return;
            }
            if (file) {
                this.importZip(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importZip(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        return (
            <div>

                {/* <event list viewer> */}
                <EventListViewer title="Files import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Edit And Retry Modal> */}
                {this.state.editAndRetryModalIsOpen && <EditAndRetryModal isOpen fileName={this.state.editModalFileName} eventsForCurrentRow={this.state.eventsForCurrentRow} importFunction={this.importFunction} content={this.state.editModalContent} closeFunction={() => { this.setState({ editAndRetryModalIsOpen: false }) }} />}
                {/* </Edit And Retry Modal> */}

                <Modal isOpen={this.props.isOpen} className="BulkModalImport">
                    <PreloaderLocal show={this.state.preloaderShow} text={this.state.preloaderMessage} />
                    <ModalHeader>Bulk Import</ModalHeader>
                    <ModalBody>
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ marginRight: "5px" }}>
                                    <label className="custom-file-upload">
                                        <input style={{ display: "none" }} type="file" accept=".zip" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                                <div style={{ width: "70%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Bulk Import file here" />
                                    </DragAndDrop>
                                </div>
                            </Col>
                        </Row>
                        {
                            this.state.filesData.length > 0
                            &&
                            <Row>
                                <Col>
                                    <Card className="mb-4 cardDesign">
                                        <CardBody className="p-3">
                                            <CardTitle className="m-0">Files imported<Badge className="ml-2" style={{ background: "#ff4f1f" }}>{this.state.filesData.length}</Badge></CardTitle>
                                        </CardBody>
                                        <CardBody className="p-3">
                                            <Row className="mb-3">
                                                <Col>
                                                    <ReactTable
                                                        data={this.state.filesData}
                                                        pageSize={this.state.filesData.length}
                                                        columns={[
                                                            {
                                                                minWidth: 200,
                                                                Header: "File name",
                                                                accessor: "name",
                                                                Cell: this.renderEditable
                                                            },
                                                            {
                                                                minWidth: 200,
                                                                Header: "Rows",
                                                                accessor: "rows",
                                                                Cell: row => <div style={{ textAlign: "center" }}>{row.value.length}</div>
                                                            },
                                                            {
                                                                minWidth: 150,
                                                                Header: "Status",
                                                                accessor: "status",
                                                                Cell: row => <div style={{ textAlign: "center" }}> < Button color={row.value.eventList.filter(event => event.name === row.value.name).some(event => event.isError) ? "danger" : "success"} onClick={() => this.showEventList(row.value.name, row.value.eventList)}> Show Result</Button></div>
                                                            },
                                                            {
                                                                minWidth: 150,
                                                                Header: "Manage in Genesys Cloud",
                                                                accessor: "pureCloudLink",
                                                                Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
                                                            },
                                                            {
                                                                minWidth: 150,
                                                                accessor: "status",
                                                                Cell: row => {
                                                                    const eventsForCurrentRow = row.value.eventList.filter(event => row.original.name.includes(event.name.toLowerCase()));
                                                                    return <div style={{ textAlign: "center" }}> < Button disabled={!eventsForCurrentRow.some(event => event.isError)} onClick={() => this.openEditAndRetryModal(row.original.name, row.original.rows, eventsForCurrentRow, row.value.name)} color='link' target="_blank" >  Edit & Retry </Button></div>
                                                                }
                                                            }
                                                        ]}
                                                        showPagination={false}
                                                        className="-striped -highlight"
                                                    />
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}