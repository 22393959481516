import './informationMessage.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap'

export default class InformationMessage extends React.Component {
    render() {
        let message = <p></p>;
        if (this.props.title === 'Divisions') {
            message = (<div>
                <p>A division is a separate unit that allows you to group and separate configurable objects, but keep them inside the same organization.</p>
                <p>Divisions can be organized by business unit, office location, country, brand, or any other classification you define. In Genesys Cloud, administrators can move objects into divisions and then grant users access to specific objects based on access control.</p>
                <p>Configurable objects include users, queues, flows, outbound campaigns, contact lists, Do Not Call lists, and management units.</p>
            </div>)
        } else if (this.props.title === 'Skills') {
            message = (<div>
                <p>A skill is a task, expertise, or knowledge that an agent must have to handle an interaction. For example, skills might be Linux, Windows, billing, or sales). Agents with assigned skills can have proficiency ratings for those skills. Proficiency ratings indicate the agent’s level of expertise or knowledge of that skill. Genesys Cloud ACD considers proficiency ratings when matching agents and interactions.</p>
            </div>
            );
        } else if (this.props.title === 'Wrap-Up Codes') {
            message = (<div>
                <p>Wrap-up codes indicate the nature of an interaction. Agents specify wrap-up codes after completing an interaction; for example, a completed sale, a customer dissatisfied with service, or a billing problem.</p>
            </div>
            );
        } else if (this.props.title === 'Roles') {
            message = (<div>
                <p>Each role in Genesys Cloud contains one or more permissions. The permissions allow users with that role to do various tasks, such as create groups, set up integrations, and supervise contact center activity. Roles are used to manage Genesys Cloud licenses within an organization. When a role is assigned to a Genesys Cloud users, the license is also assigned.</p>
            </div>
            );
        } else if (this.props.title === 'Locations') {
            message = (<div>
                <p>The locations page allows the user to define the specific attributes for the locations that are associated through configuration with the Sites. Attributes will include Name, detailed address information, Notes, and an Emergency Number.</p>
            </div>
            );
        } else if (this.props.title === 'Sites') {
            message = (<div>
                <p>A site is the home of a set of phones. The site defines the call classification rules and outbound routing rules as well as the telephony properties for dialing.</p>
            </div>
            );
        } else if (this.props.title === 'DIDs') {
            message = (<div>
                <p>DID is a series of digits passed by the telecommunications service provider with an inbound call.</p>
            </div>
            );
        } else if (this.props.title === 'Extensions') {
            message = (<div>
                <p>Add a single extension or a range of extension numbers associated with the organization.</p>
            </div>
            );
        } else if (this.props.title === 'Edge Groups') {
            message = (<div>
                <p>An Edge group is a set of Edge devices that are connected through a high-bandwidth and low-latency network. Each Edge within a group connects directly to all other Edges within the same group, requiring no additional hops or mediators to communicate.</p>
                <p>Associating an Edge with an Edge group gives the Edge access to the trunks connected to other Edges in the Edge group.</p>
                <p><b>Edge Devices: </b>Responsible for handling telephony events and media at the “edge” of the cloud network. The Edge provides media processing, SIP, and telephony services for the Genesys Cloud platform</p>
            </div>
            );
        } else if (this.props.title === 'Phones') {
            message = (<div>
                <p>Genesys Cloud supports a number of types of phones(Managed, Unmanaged, Remote, and WebRTC) and provides basic and advanced configuration controls</p>
                <p><b>Managed: </b>Managed phones are phone models that you can configure through Genesys Cloud. Through the secure (HTTPS) Genesys Cloud provisioning process, the phone is sent the configuration which includes TLS (secure certificate exchange) and redundancy (primary and secondary SIP registrations). Managed phones have default base settings profiles set up in Genesys Cloud. Mutual authentication is standard with Genesys Cloud Voice and managed phones.</p>
                <p><b>Unmanaged: </b>Any SIP-compliant phone can register with Genesys Cloud as an unmanaged phone. The configuration of phone features are managed outside of Genesys Cloud. Only basic SIP connection information needs to be configured in Genesys Cloud to enable the registration and a contact address. Unmanaged phones do not have default base settings profiles set up in Genesys Cloud. Unmanaged phones use a generic SIP base settings profile. Features such as TLS/SRTP and phone redundancy are possible to configure, but not as simple as with managed phones. FXS analog devices can be used with this phone type.</p>
                <p><b>Remote: </b>A remote phone is an external phone number or SIP address, such as a cell phone. When a call is placed or answered in the Genesys Cloud browser, a call is made to the remote number to connect the user to the call. The connection call to a remote number follows the site’s numbering plans and outbound routes.</p>
                <p><b>WebRTC: </b>Genesys Cloud supports the WebRTC technology with the Genesys Cloud WebRTC phone. Provisioning a Genesys Cloud WebRTC phone for a user creates a specific phone line for that user. The Genesys Cloud WebRTC phone does not require the installation of a software client on the PC. You use the Genesys Cloud call controls for the WebRTC phone calls.</p>
            </div>
            );
        } else if (this.props.title === 'Phones Base Settings') {
            message = (<div>
                <p>The base settings configuration contains a group of settings that define how a particular phone model is to operate in Genesys Cloud.</p>
            </div>
            );
        } else if (this.props.title === 'Queues') {
            message = (<div>
                <p>A queue is a “waiting line” of interactions.</p>
            </div>
            );
        } else if (this.props.title === 'Users') {
            message = (<div>
                <p>A user represents an actual person or potentially a programmatic entity (chatbot).  A user has multiple representations (configurations) based on media end-point, assigned distribution queues, and collection of skills and ratings.</p>
            </div>
            );
        } else if (this.props.title === 'Schedules') {
            message = (<div>
                <p>Schedules are used for common definitions of time, which are then applied to multiple groups under multiple circumstances. A schedule stipulates when a flow runs, based upon the date, time, or event. You can define schedules to handle recurring events, holidays, or special situations, such as your regular business hours or after-hours support. </p>
                <p>Architect uses schedules to determine how to manage inbound and outbound interaction routing. For example, a schedule can determine which flow to execute during open or closed hours.</p>
            </div>
            );
        } else if (this.props.title === 'Schedule Groups') {
            message = (<div>
                <p>Schedule Groups allow you to combine multiple schedules and associate them to a singular routing definition.</p>
            </div>
            );
        } else if (this.props.title === 'IVR Routing') {
            message = (<div>
                <p>IVR is a telephony industry term that references the collective feature set of customer experience options pertaining to call flows, DTMF and speech entry, menus, and audio prompting. In Genesys Cloud, IVRs are built in Architect. IVR is also referred to as routing in Genesys Cloud.</p>
                <p>Routing includes the set of features that administrators use to set up Genesys Cloud routing. Use routing features to create schedules, configure call and message routing, plan for emergencies, and store data locally in data tables for use in Architect.</p>
            </div>
            );
        } else if (this.props.title === 'Widgets') {
            message = (<div>
                <p>Widgets will allow the user to implement Web Chat from their website to their agents.</p>
            </div>
            );
        } else if (this.props.title === 'Design Flows') {
            message = (<div>
                <p>A call flow is the organizational process, typically audio files and menu options, of the caller’s experience prior to agent assignment. A call flow can be the inbound auto-attendant, an inbound or outbound IVR, or the call’s in-queue handling.</p>
            </div>
            );
        } else if (this.props.title === 'Library of Callflows') {
            message = (<div>
                <p>A call flow is the organizational process, typically audio files and menu options, of the caller’s experience prior to agent assignment. A call flow can be the inbound auto-attendant, an inbound or outbound IVR, or the call’s in-queue handling.</p>
                <p>Current Library includes <b>Inbound Calls</b>, <b>Inbound Chat</b>, <b>Inbound Email</b>, <b>InQueue</b> and <b>Outbound Flows</b></p>
            </div>
            );
        } else if (this.props.title === 'Prompts') {
            message = (<div>
                <p>A call prompt is a .wav format audio file that Architect plays to guide callers through an interaction.</p>
            </div>
            );
        } else if (this.props.title === 'Campaign Type') {
            if (this.props.type !== '') {
                if (this.props.type === 'Preview') {
                    message = (<div>
                        <p>In Preview mode, the agent receives or retrieves a record and initiates the call. An optional timer automatically launches the call for the agent.</p>
                    </div>
                    );
                } else if (this.props.type === 'Progressive') {
                    message = (<div>
                        <p>In Progressive mode, Genesys Cloud automatically places the call-based agent availability for the specific campaign. Call analysis ensures connections (human or machine answers).</p>
                    </div>
                    );
                } else if (this.props.type === 'Predictive') {
                    message = (<div>
                        <p>In Predictive mode, Genesys Cloud automatically places the call based on the pacing algorithm and expected agent availability.</p>
                    </div>
                    );
                } else if (this.props.type === 'Agent-less') {
                    message = (<div>
                        <p>In Agentless mode, Genesys Cloud automatically places calls based on the number of lines specified in the campaign settings. Depending on whether a machine or live person answers the call, the call can either be transferred to an outbound flow (IVR) or disconnected.</p>
                    </div>
                    );
                } else if (this.props.type === 'SMS') {
                    message = (<div>
                        <p>The campaign is started and begins contacting consumers based on the campaign settings configured. The Genesys Cloud system checks each contact record against the Do Not Call list(s) assigned to the campaign to filter out consumers who should not be contacted. The customer can specify the SMS message body for each contact record by assigning a column in the list as the message column. Alternatively the message body can be specified in a SMS campaign templated associated with the campaign.</p>
                    </div>
                    );
                }
            } else {
                message = (<div>
                    <p>A customer can choose to run outbound dialing campaigns using Preview, Progressive, Predictive, Agentless modes or can use Genesys SMS Notification. Either the customer admin or Genesys PS configures the campaign</p>
                </div>
                );
            }
        } else if (this.props.title === 'Contact and Do_Not_Call Lists') {
            message = (<div>
                <p>Easily manage list data. Maintain large contact lists and do-not-call lists.</p>
                <p><b>Contact lists: </b>Each contact list is a table containing phone numbers to call during a campaign. During calls, the campaign script presents columns from the contact list to the agent as visual controls. When the call ends, information collected or modified by the agent routes back to the contact list. The contact list is an essential component of any outbound dialing system because it contains customer information and telephone numbers dialed in attempts to reach contacts.</p>
                <p><b>Do not contact lists : </b>A DNC list is a data source of telephone numbers that a campaign should not dial. When a campaign selects records from its contact list, numbers which match entries in any DNC source assigned to that campaign are excluded from dialing. A campaign may have multiple DNC entries assigned to it. A DNC entry may be assigned to multiple campaigns.</p>
                <p><b>Automatic time zone mapping: </b>Automatic time zone mapping is a simplified alternative to manually configuring contactable time sets. With automatic time zone mapping, the system maps contacts to time zones based on the contacts’ phone numbers and (optionally) zip codes. The campaign dials the contacts only during compliant dialing times for their time zone. <br></br><b>Note: </b> Automatic time zone mapping supports NANP phone numbers (United States, Canada, and certain Caribbean area codes) and United States zip codes. Automatic time zone mapping currently allows calls between 8:00 AM and 9:00 PM (consumer time) each day. Since this may or may not satisfy rules in all North American countries, customers may need to take other actions to ensure they are in compliance.</p>
            </div>
            );
        } else if (this.props.title === 'Wrap-up Code Mapping') {
            message = (<div>
                <p>Outbound transaction  Wrap-Up Code mapping will be used to set specific WU Codes based on the Outbound interaction result</p>
            </div>
            );
        } else if (this.props.title === 'Queues ') {
            message = (<div>
                <p>A outbound queue is a 'waiting line' for processing outbound interactions</p>
            </div>
            );
        } else if (this.props.title === 'Campaign Settings') {
            message = (<div>
                <p>Campaign Settings values are used to determine the Campaign Name, Preview options, and CallerID information associated with the outbound transaction.</p>
            </div>
            );
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen}>
                    <ModalHeader>{this.props.title + ' Information'} </ModalHeader>
                    <ModalBody>
                        {message}
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}