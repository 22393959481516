import './preloaderLocal.css'
import React from 'react'

export default class PreloaderLocal extends React.Component {

  state = { text: 'Loading' }

  render() {
    return (
      <div hidden={this.props.show === false} className="preloader-local-background">
        <div className="preloader-local-outer" >
          <div className="preloader-local-inner">
            {/* <img className="preLoaderLocal-logo" src="/logo-app.svg" alt="logo"></img> */}
            <h5>{this.props.text ? this.props.text : this.state.text}</h5>
            <img src="/loader.gif" alt="loading"></img>
          </div>
        </div>
      </div>
    )
  }
}
