import './outboundWrapCodes.css'
import React from 'react'
import { FaCog, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, Badge } from 'reactstrap';
import { getRoutingWrapupCodes } from '../../../../services/purecloud/routing';
import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken } from '../../../../services/session/session';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import { Table } from 'reactstrap';
import Switch from 'react-ios-switch';
import { postWrapUpCode, getQueueWrapUpCodeMappings, putWrapUpCodeMappings } from '../../../../services/purecloud/outbound';
import moment from 'moment';
import ImageMapper from 'react-image-mapper';

export default class OutboundWrapCodes extends React.Component {

    state = {
        env: '',
        token: '',
        preloaderLocalShow: false,
        isInfoOpen: false,
        queueWrapCodes: [],
        wrapUpCodeList: [],
        defaultWrapUpCodes: [],
        wrapUpCodeMappings: undefined,
        checked: true,
        selectedWrapCodesLength: 0,
        wrapUpCodeMappingsVersion: 0,
        eventListIsOpen: false,
        eventList: [],
        toRemoveWrapCodes: []
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    eventList = [];

    logEvent = (message, isError = false) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    loadInitData = async () => {
        await this.setState({ preloaderLocalShow: true });
        let wrapUpCodeList = await getRoutingWrapupCodes(this.state.env, this.state.token);
        let wrapUpCodeMappings = await getQueueWrapUpCodeMappings(this.state.env, this.state.token);
        let defaultWrapUpCodes =
            [{ name: 'Success', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' },
            { name: 'Failed', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' },
            { name: 'Left Message on VM', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' },
            { name: 'Left Message with Live Party', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' },
            { name: 'Bad Number', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' },
            { name: 'Busy', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' },
            { name: 'No Answer', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' },
            { name: 'Scheduled Callback', contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: '' }];

        this.setState({
            wrapUpCodeList: wrapUpCodeList,
            wrapUpCodeMappings: wrapUpCodeMappings.mapping,
            wrapUpCodeMappingsVersion: wrapUpCodeMappings.version
        });

        defaultWrapUpCodes.forEach((wrapCodes) => {
            const found = wrapUpCodeList.find(el => el.name.toLowerCase() === wrapCodes.name.toLowerCase());
            if (found === undefined) {
                this.addNewWrapCode(wrapCodes.name);
            }
            else {
                let wrapCode = defaultWrapUpCodes.find(el => el.name.toLowerCase() === wrapCodes.name.toLowerCase());
                wrapCode.id = found.id;
                this.updateMappingstoList(wrapCode);
            }
        })
        this.setState({
            defaultWrapUpCodes: defaultWrapUpCodes
        });
        await this.setState({ preloaderLocalShow: false });
    }

    componentDidMount = () => {
        this.loadInitData();
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    componentDidUpdate = () => {
        if (this.props.selectedWrapCodes !== undefined) {
            if (this.state.selectedWrapCodesLength !== this.props.selectedWrapCodes.length) {
                let toRemoveWrapCodes = [];
                this.props.selectedWrapCodes.forEach((el) => {
                    let isExistingValue = this.state.defaultWrapUpCodes.find(def => def.name.toLowerCase() === el.name.toLowerCase())
                    if (isExistingValue === undefined) {
                        let wrapCode = { name: el.name, contactUncallable: false, numberUncallable: false, rightPartyContact: false, id: el.id };
                        this.updateMappingstoList(wrapCode);
                        this.state.defaultWrapUpCodes.push(wrapCode);
                        toRemoveWrapCodes.push(wrapCode);
                    }
                })
                this.setState({
                    toRemoveWrapCodes: toRemoveWrapCodes,
                    queueWrapCodes: this.props.selectedWrapCodes,
                    selectedWrapCodesLength: this.props.selectedWrapCodes.length
                });

            }
        }
    }

    addNewWrapCode = async (wrapCodesName) => {
        const response = await postWrapUpCode(this.state.env, this.state.token, wrapCodesName);
        if (response !== null) {
            this.state.defaultWrapUpCodes.find(el => el.name.toLowerCase() === response.name.toLowerCase()).id = response.id;
        }
    }

    updateMappingstoList = (wrapCode) => {
        let wcMappings = this.state.wrapUpCodeMappings;
        if (wcMappings !== null && wcMappings !== undefined) {
            const isfound = Object.keys(wcMappings).find(el => el === wrapCode.id);
            let mapping = (isfound !== null && isfound !== undefined) ? wcMappings[wrapCode.id] : null;
            if (mapping !== null && mapping.length > 0) {
                if (mapping.find(el => el === 'Contact_UnCallable')) {
                    wrapCode.contactUncallable = true;
                }
                if (mapping.find(el => el === 'Number_UnCallable')) {
                    wrapCode.numberUncallable = true;
                }
                if (mapping.find(el => el === 'Right_Party_Contact')) {
                    wrapCode.rightPartyContact = true;
                }
            }
        }
    }

    handleOnChange = (name, id) => (event) => {
        let wrapUpCodes = this.state.defaultWrapUpCodes;
        if (name === 'contactUncallable') {
            wrapUpCodes.find(el => el.id === id).contactUncallable = event;
        } else if (name === 'numberUncallable') {
            wrapUpCodes.find(el => el.id === id).numberUncallable = event;
        } else if (name === 'rightPartyContact') {
            wrapUpCodes.find(el => el.id === id).rightPartyContact = event;
        }
        this.setState({
            defaultWrapUpCodes: wrapUpCodes
        })
    }

    handleSubmit = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.eventList = [];
        await this.constructSubmitRequest();
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    closeEventViewer = () => {
        let moveToCampaignSlide = ((this.eventList.find(evnt => evnt.isError === true)) === undefined);
        if (moveToCampaignSlide) {
            //reset queue-wrap codes after successful import
            this.state.toRemoveWrapCodes.forEach(element => {
                let i = this.state.defaultWrapUpCodes.findIndex(el => el.id === element.id)
                if (i >= 0) { this.state.defaultWrapUpCodes.splice(i, 1) }
            });
            this.setState({ eventListIsOpen: false, eventList: [] });
            this.props.handleWrapCodes(moveToCampaignSlide);
        } else {
            this.setState({ eventListIsOpen: false, eventList: [] });
        }
    }

    constructSubmitRequest = async () => {
        try {
            let request = {}; let mappings = {};
            this.state.defaultWrapUpCodes.forEach((wrapCodes) => {
                let arrayValue = [];
                if (wrapCodes.contactUncallable === true) arrayValue.push('Contact_UnCallable');
                if (wrapCodes.numberUncallable === true) arrayValue.push('Number_UnCallable');
                if (wrapCodes.rightPartyContact === true) arrayValue.push('Right_Party_Contact');
                mappings[wrapCodes.id] = arrayValue;
            })

            request['version'] = this.state.wrapUpCodeMappingsVersion;
            request['mapping'] = mappings;
            const response = await putWrapUpCodeMappings(this.state.env, this.state.token, request);
            if (response) {
                if (response.mapping !== null && response.mapping !== undefined) {
                    this.logEvent('Wrap-up Code mapping processed successfully', false);
                    this.setState({
                        wrapUpCodeMappingsVersion: response.version
                    })
                } else {
                    this.logEvent(response.message, true);
                }
            }
        } catch (err) {
            this.logEvent(err, true);
        }
    }

    render() {
        let MAP = {
            name: "c-map",
            areas: []
        }

        return (
            <div className="outWrapCard">

                {/* <event list viewer> */}
                <EventListViewer title="Upload completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={this.closeEventViewer} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 outWrapCardBody">
                        <CardTitle className="m-0 outWrapTitle">
                            <div className="divWrapCodes">
                            <Badge className="titleBadgeWrapCodes">{this.props.cardTitle}</Badge>
                            <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                            <sup className="supText">  Learn More</sup>
                            <div className="ImageMapper">
                                    <ImageMapper src="/images/pWrapCodes.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <p style={{ paddingLeft: "12px", fontSize: "2.5vmin", marginLeft: "-20px" }}>From this page, Map Outbound {this.props.cardTitle} individually </p>
                    </CardBody>
                    <CardBody className="p-3 CardBodyWrapCodes">
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} />
                            <div className="outWrap-wrap">
                                <div className="single-field-editor-table-wrap">
                                    <Table bordered striped className="table-condensed single-field-editor-table" size="lg">
                                        <thead>
                                            <tr className="rowHeader">
                                                <th>Wrap-up Codes</th>
                                                <th>Contact Uncallable</th>
                                                <th>Number Uncallable</th>
                                                <th>Right Party Contact</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.defaultWrapUpCodes.map((wrapUpCode, index) => {
                                                return (
                                                    <tr key={index} className="items">
                                                        <td className="wrapCodes"><label>{wrapUpCode.name}</label></td>
                                                        <td><Switch checked={wrapUpCode.contactUncallable} onColor="rgb(6, 30, 69)" className="switch" style={{ transform: 'scale(0.6)' }} onChange={this.handleOnChange('contactUncallable', wrapUpCode.id)} /></td>
                                                        <td><Switch checked={wrapUpCode.numberUncallable} onColor="rgb(6, 30, 69)" className="switch" style={{ transform: 'scale(0.6)' }} onChange={this.handleOnChange('numberUncallable', wrapUpCode.id)} /></td>
                                                        <td><Switch checked={wrapUpCode.rightPartyContact} onColor="rgb(6, 30, 69)" className="switch" style={{ transform: 'scale(0.6)' }} onChange={this.handleOnChange('rightPartyContact', wrapUpCode.id)} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <Row className="mt-3">
                                <Col>
                                    <div>
                                        <Button className="SubmitButtonWrapCodes" onClick={this.handleSubmit} disabled={this.state.isSubmitEnabled}>Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
