import './sitesInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

export default class SitesInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.siteName !== '' && this.props.location !== '' && this.props.recurrType !== '' && this.props.timeZone !== '' && this.props.startTime !== '' && this.props.endTime !== '') {
            isButtonEnabled = true;
        }

        if (this.props.recurrType === 'WEEKLY') {
            if (this.props.weeklyMonday || this.props.weeklyTuesday || this.props.weeklyWednesday || this.props.weeklyThursday || this.props.weeklyFriday || this.props.weeklySaturday || this.props.weeklySunday) {
            } else {
                isButtonEnabled = false;
            }
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="sitesModal">
                    <ModalHeader>{this.props.title + ' Information'} </ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="siteName">Site Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.siteName === '' ? true : false} value={this.props.siteName} onChange={this.props.handleInputChange('siteName')} type="text" id="siteName" placeholder="Enter the site name" />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="description">Description:</Label>
                                        <Input value={this.props.description} onChange={this.props.handleInputChange('description')} type="text" id="description" placeholder="(optional) Enter the Description" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="location">Location:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.location} onChange={this.props.handleInputChange('location')}>
                                            <option key="L1" hidden>Select a Location</option>
                                            {
                                                this.props.locationList.map((location) => (
                                                    <option key={location.id}>{location.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="recurrType">Recurrence Type:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.recurrType} onChange={this.props.handleInputChange('recurrType')}>
                                            <option key="T1" hidden>Select a Type</option>
                                            <option key="T2">DAILY</option>
                                            <option key="T3">WEEKLY</option>
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="timeZone">Time Zone:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.timeZone} onChange={this.props.handleInputChange('timeZone')}>
                                            <option key="L1" hidden>Select a Zone</option>
                                            {
                                                this.props.zonesList.map((zone, index) => (
                                                    <option key={index}>{zone.id}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            {
                                this.props.recurrType === 'WEEKLY'
                                &&
                                <FormGroup row>
                                    <Label sm={1} for="weeklyDays">Every:<span className="text-danger font-weight-bold">*</span></Label>
                                    <Col sm={10}>
                                        <div className="btn-group" id="weeklyDays">
                                            <label className="btn btn-primary"><input type="checkbox" id="weeklyMonday" checked={this.props.weeklyMonday} onChange={this.props.handleInputChange('weeklyMonday')} />Mo</label>
                                            <label className="btn btn-primary"><input type="checkbox" id="weeklyTuesday" checked={this.props.weeklyTuesday} onChange={this.props.handleInputChange('weeklyTuesday')} />Tu</label>
                                            <label className="btn btn-primary"><input type="checkbox" id="weeklyWednesday" checked={this.props.weeklyWednesday} onChange={this.props.handleInputChange('weeklyWednesday')} />We</label>
                                            <label className="btn btn-primary"><input type="checkbox" id="weeklyThursday" checked={this.props.weeklyThursday} onChange={this.props.handleInputChange('weeklyThursday')} />Th</label>
                                            <label className="btn btn-primary"><input type="checkbox" id="weeklyFriday" checked={this.props.weeklyFriday} onChange={this.props.handleInputChange('weeklyFriday')} />Fr</label>
                                            <label className="btn btn-primary"><input type="checkbox" id="weeklySaturday" checked={this.props.weeklySaturday} onChange={this.props.handleInputChange('weeklySaturday')} />Sa</label>
                                            <label className="btn btn-primary"><input type="checkbox" id="weeklySunday" checked={this.props.weeklySunday} onChange={this.props.handleInputChange('weeklySunday')} />Su</label>
                                        </div>
                                    </Col>
                                </FormGroup>
                            }
                            <FormGroup>
                                <Row>
                                    <Col md={2}>
                                        <Label>Time:<span className="text-danger font-weight-bold">*</span></Label>
                                        <FormGroup check>
                                            <Input type="checkbox" name="check" id="allDayCheck" checked={this.props.allDayCheck} onChange={this.props.handleInputChange('allDayCheck')} />
                                            <Label for="allDayCheck" check>All Day: </Label>
                                        </FormGroup>
                                    </Col>
                                    {
                                        !this.props.allDayCheck &&
                                        <FormGroup row>
                                            <Col md={6}>
                                                <Label for="startTime" check>Start Time:</Label>
                                                <Input type="time" id="startTime" value={this.props.startTime} onChange={this.props.handleInputChange('startTime')} />
                                            </Col>
                                            <Col md={6}>
                                                <Label for="endTime" check>End Time:</Label>
                                                <Input type="time" id="endTime" value={this.props.endTime} onChange={this.props.handleInputChange('endTime')} />
                                            </Col>
                                        </FormGroup>
                                    }
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
