import './designFlowsInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input } from 'reactstrap'

export default class DesignFlowsInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        let node = this.props.nodesList;
        if(this.props.callFlowName !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="designFlowsInfoModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="ivrName">Call Flow:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.callFlowName === '' ? true : false} value={this.props.callFlowName} onChange={this.props.handleInputChange('callFlowName')} id="callFlowName" type="text" placeholder="Enter Call Flow Name " />
                                </Col>
                            </FormGroup>
                            <div className="ml-4 text-muted">
                                <p>Click on a digit to set the corresponding action.</p>
                            </div>
                            <div className="container phone-body">
                                <div className="col-md-6 phone">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="num-pad">
                                                <div className="span4">
                                                    <div className={this.props.default === '1' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '1') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('1')}}>1</div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className={this.props.default === '2' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '2') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('2')}}>2
                                                            <span className="phone-small">
                                                                <p className="phone-p">ABC</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className={this.props.default === '3' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '3') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('3')}}>3
                                                            <span className="phone-small">
                                                                <p className="phone-p">DEF</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="num-pad">
                                                <div className="span4">
                                                    <div className={this.props.default === '4' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '4') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('4')}}>4
                                                            <span className="phone-small">
                                                                <p className="phone-p">GHI</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className={this.props.default === '5' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '5') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('5')}}>5
                                                            <span className="phone-small">
                                                                <p className="phone-p">JKL</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className={this.props.default === '6' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '6') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('6')}}>6
                                                            <span className="phone-small">
                                                                <p className="phone-p">MNO</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="num-pad">
                                                <div className="span4">
                                                    <div className={this.props.default === '7' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '7') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('7')}}>7
                                                            <span className="phone-small">
                                                                <p className="phone-p">PQRS</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className={this.props.default === '8' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '8') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('8')}}>8
                                                            <span className="phone-small">
                                                                <p className="phone-p">TUV</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className={this.props.default === '9' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '9') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('9')}}>9
                                                            <span className="phone-small">
                                                                <p className="phone-p">WXYZ</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="num-pad">
                                                <div className="span4">
                                                    <div className="">
                                                        <div className="txt text-muted digit">*</div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className={this.props.default === '0' ? "num bg-secondary" : "num"}>
                                                        <div className={node.findIndex(x => x.dtmf === '0') > -1 ? "txt digit txt-success" : "txt digit"} onClick={() => {this.props.handleDigitClick('0')}}>0
                                                            <span className="phone-small">
                                                                <p className="phone-p">+</p>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="span4">
                                                    <div className="">
                                                        <div className="txt text-muted">#</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
