export default function fileDownload(f, filename) {
    if (navigator.msSaveBlob) {
      // For IE11/Edge
      navigator.msSaveBlob(f, filename);
    } else {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(f);
      link.setAttribute('download', filename);
      document.body.appendChild(link);  // Required for FF, others can just .click()
      link.click();
      document.body.removeChild(link);
    }
  }
  