import './promptsInfo.css'
import React from 'react'
import { FaCloudUploadAlt } from 'react-icons/fa';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

import DragAndDrop from '../../Misc/dragAndDrop/dragAndDrop';

export default class PromptsInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.promptName !== '' && this.props.language !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="promptsModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={3} for="promptName">Prompt Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={9}>
                                    <Input invalid={this.props.promptName === '' ? true : false} value={this.props.promptName} onChange={this.props.handleInputChange('promptName')} type="text" id="promptName" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} for="description">Description:</Label>
                                <Col sm={9}>
                                    <Input value={this.props.description} onChange={this.props.handleInputChange('description')} type="text" id="description" placeholder="Enter description for prompt" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3} for="language">Language:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={9}>
                                    <select className="form-control selectpicker" value={this.props.language} onChange={this.props.handleInputChange('language')}>
                                        <option key="L1" hidden>Select a Language</option>
                                        {
                                            this.props.languagesList.map((language, index) => (
                                                <option key={index}>{language}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={2}>
                                        <FormGroup check>
                                            <Input type="checkbox" id="useTTS" checked={this.props.useTTS} onChange={this.props.handleInputChange('useTTS')} />
                                            <Label for="useTTS" check>Use TTS? </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                            {
                                this.props.useTTS
                                    ?
                                    <FormGroup row>
                                        <Label sm={3} for="promptText">Prompt Text:</Label>
                                        <Col sm={9}>
                                            <Input type="textarea" id="promptText" value={this.props.promptText} onChange={this.props.handleInputChange('promptText')} />
                                        </Col>
                                    </FormGroup>
                                    :
                                    <FormGroup row>
                                        <Label sm={3}>Prompt file:</Label>
                                        <Col sm={9} style={{ display: "flex" }}>
                                            <div style={{ width: "70%" }}>
                                                <DragAndDrop handleDrop={this.props.handleDropFile}>
                                                    <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop WAV file here" />
                                                </DragAndDrop>
                                            </div>
                                            <div style={{ marginLeft: "5px" }}>
                                                <label className="prompt-file-upload">
                                                    <input style={{ display: "none" }} type="file" accept=".wav" onChange={this.props.handleImportFile} />
                                                    <FaCloudUploadAlt /> Browse
                                                </label>
                                            </div>
                                        </Col>
                                    </FormGroup>
                            }
                            {
                                !this.props.useTTS
                                &&
                                <FormGroup row>
                                    <Label sm={3}>Uploaded File:</Label>
                                    <Col sm={9}>
                                        <Input disabled={true} type="text" id="promptFile" value={this.props.promptFile} />
                                    </Col>
                                </FormGroup>
                            }
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
