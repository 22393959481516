import { saveAs } from 'file-saver';
var JSZip = require("jszip");
var zip;

export const initialize = () => {
    zip = new JSZip();
}

export const addToZip = (f, filename) => {
    zip.file(filename, f);
}

export const exportZip = (filename) => {
    zip.generateAsync({type: "blob"}).then((content) => {
        saveAs(content, filename);
      });
}

export default {
    initialize,
    addToZip,
    exportZip
  }