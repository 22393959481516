import './scheduleGroups.css'
import React from 'react'
import { FaCalendarWeek, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import { architectPostScheduleGroup, getArchitects } from '../../../../services/purecloud/architect';
import { telephonyGetTimeZones } from '../../../../services/purecloud/telephony';
import ScheduleGroupsInfo from '../scheduleGroups/scheduleGroupsInfo';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const scheduleGroupsDataFields = [
    'name',
    'timeZone',
    'openSchedules',
    'closedSchedules',
    'holidaySchedules'
];

export default class ScheduleGroups extends React.Component {

    state = {
        env: '',
        token: '',
        sgList: [],
        isSgInfoOpen: false,
        sgName: '',
        timeZone: '',
        openSchedules: [],
        closedSchedules: [],
        holidaySchedules: [],
        rowID: null,
        scheduleList: [],
        zonesList: [],
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false, isWarning = false) => {
        const event = {
            isError: isError,
            isWarning: isWarning,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.state.scheduleList = await getArchitects(this.state.env, this.state.token, 'schedules');
        this.state.zonesList = await telephonyGetTimeZones(this.state.env, this.state.token);
        await this.setState({ preloaderLocalShow: false });
    }

    updateSchedules = async () => {
        this.state.scheduleList = await getArchitects(this.state.env, this.state.token, 'schedules');
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var sgList = [...this.state.sgList];
        var selectedList = this.state.selected;
        for (const i in sgList) {
            if (sgList[i].rowid === rowid) {
                sgList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ sgList: sgList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.updateSchedules();
        this.setState({
            isSgInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            sgName: '',
            timeZone: '',
            openSchedules: [],
            closedSchedules: [],
            holidaySchedules: [],
            rowID: null,
        })
    }

    handleSubmitFunction = () => {
        let sgList = [...this.state.sgList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in scheduleGroupsDataFields) {
            if (scheduleGroupsDataFields[i] === 'name') {
                newRow[scheduleGroupsDataFields[i]] = this.state.sgName;
            } else if (scheduleGroupsDataFields[i] === 'timeZone') {
                newRow[scheduleGroupsDataFields[i]] = this.state.timeZone;
            } else if (scheduleGroupsDataFields[i] === 'openSchedules') {
                newRow[scheduleGroupsDataFields[i]] = this.state.openSchedules;
            } else if (scheduleGroupsDataFields[i] === 'closedSchedules') {
                newRow[scheduleGroupsDataFields[i]] = this.state.closedSchedules;
            } else if (scheduleGroupsDataFields[i] === 'holidaySchedules') {
                newRow[scheduleGroupsDataFields[i]] = this.state.holidaySchedules;
            }
        }

        sgList.push(newRow);

        this.setState({
            isSgInfoOpen: false,
            sgList: sgList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'openSchedules' || fieldName === 'closedSchedules' || fieldName === 'holidaySchedules') {
            const options = event.target.selectedOptions;
            let value = [];
            for (let i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }
            this.setState({
                [fieldName]: value
            })
        } else {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    }

    updateState = (rowInfo) => {
        this.setState({
            sgName: rowInfo.name,
            timeZone: rowInfo.timeZone,
            openSchedules: rowInfo.openSchedules,
            closedSchedules: rowInfo.closedSchedules,
            holidaySchedules: rowInfo.holidaySchedules,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isSgInfoOpen: true,
        });
    }

    updateFunction = () => {
        let sgList = [...this.state.sgList];
        for (let did of sgList) {
            if (did.rowid === this.state.rowID) {
                did.name = this.state.sgName;
                did.timeZone = this.state.timeZone;
                did.openSchedules = this.state.openSchedules;
                did.closedSchedules = this.state.closedSchedules;
                did.holidaySchedules = this.state.holidaySchedules;
            }
        }

        this.setState({
            sgList: sgList,
            isSgInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        await this.updateSchedules();
        const fileContent = await CsvReader.readFile(file);
        const sgData = await CsvReader.parseCsvFileToScheduleGroupsData(fileContent);
        await this.setState({ sgList: [...this.state.sgList, ...sgData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importScheduleGroups = async () => {
        this.eventList = [];
        const sgList = this.state.sgList;
        for (const i in sgList) {
            const scheduleGroupData = sgList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing schedule groups [${scheduleGroupData.name}]`);
            if (await this.dataValidated(scheduleGroupData, i, sgList)) {
                try {
                    const openSchedules = this.getScheduleList(scheduleGroupData.openSchedules);
                    const closedSchedules = this.getScheduleList(scheduleGroupData.closedSchedules);
                    const holidaySchedules = this.getScheduleList(scheduleGroupData.holidaySchedules);

                    // <create schedule group>          
                    const schedule = await architectPostScheduleGroup(this.state.env, this.state.token, scheduleGroupData.name, scheduleGroupData.timeZone, openSchedules, closedSchedules, holidaySchedules);
                    console.log(`Schedule created: ${JSON.stringify(schedule)}`);
                    this.logEvent(`Schedule group [${scheduleGroupData.name}] successfully processed`);
                    this.removeRowClicked(scheduleGroupData.rowid);
                } catch (err) {
                    console.log(`${err.message}`);
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Schedule Groups`, count, 'ScheduleGroups');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    getScheduleList = (scheduleNameList) => {
        var result = [];
        for (const scheduleName of scheduleNameList) {
            const schedule = this.state.scheduleList.filter(q => q.name.toLowerCase() === scheduleName.toLowerCase())[0];
            if (schedule) { result.push(schedule); }
        }
        return result;
    }

    dataValidated = async (scheduleGroup, i, scheduleGroupsData) => {
        let isValidated = true;

        // <validate fields that can't be empty>
        if (!scheduleGroup.name || !scheduleGroup.name.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!scheduleGroup.timeZone || !scheduleGroup.timeZone.trim()) { this.logEvent(`[Index ${i}] Time zone can't be empty.`, true); isValidated = false; }
        if (!scheduleGroup.openSchedules || scheduleGroup.openSchedules.length === 0) { this.logEvent(`[Index ${i}] Enter at least one open schedule.`, true); isValidated = false; }
        //</validate fields that can't be empty>

        // <validate if open schedules exist>
        const openScheduleNames = scheduleGroup.openSchedules;
        for (const openSchedule of openScheduleNames) {
            var openScheduleValid = false;
            for (const schedule of this.state.scheduleList) {
                if (openSchedule.toLowerCase() === schedule.name.toLowerCase()) {
                    openScheduleValid = true;
                }
            }
            if (!openScheduleValid) { this.logEvent(`[Index ${i}] Schedule '${openSchedule}' doesn't exist`, true); isValidated = false; }
        }
        // </validate if open schedules exist>

        // <validate if closed schedules exist>
        const closedScheduleNames = scheduleGroup.closedSchedules;
        for (const closedSchedule of closedScheduleNames) {
            var closedScheduleValid = false;
            for (const schedule of this.state.scheduleList) {
                if (closedSchedule.toLowerCase() === schedule.name.toLowerCase()) {
                    closedScheduleValid = true;
                }
            }
            if (closedSchedule === "") {
                this.logEvent(`[Index ${i}] Closed Schedules is empty`, false, true);
            } else if (!closedScheduleValid) {
                this.logEvent(`[Index ${i}] Schedule '${closedSchedule}' doesn't exist`, true);
                isValidated = false;
            }
        }
        // </validate if closed schedules exist>

        // <validate if holiday schedules exist>
        const holidayScheduleNames = scheduleGroup.holidaySchedules;
        for (const holidaySchedule of holidayScheduleNames) {
            var holidayScheduleValid = false;
            for (const schedule of this.state.scheduleList) {
                if (holidaySchedule.toLowerCase() === schedule.name.toLowerCase()) {
                    holidayScheduleValid = true;
                }
            }
            if (holidaySchedule === "") {
                this.logEvent(`[Index ${i}] Holiday Schedule is empty`, false, true);
            } else if (!holidayScheduleValid) {
                this.logEvent(`[Index ${i}] Schedule '${holidaySchedule}' doesn't exist`, true);
                isValidated = false;
            }
        }
        // </validate if holiday schedules exist>      

        // <validate if name is unique>
        for (const j in scheduleGroupsData) {
            const scheduleGroup1 = scheduleGroupsData[j];
            if (i === j) { continue; }
            if (scheduleGroup.name.trim().toLowerCase() === scheduleGroup1.name.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if name is unique>
        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.sgList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Schedule Groups...."  });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            } else if (area.name === '2') {
                this.props.navigateToHorizontalSlide('2');
            } else if (area.name === '3') {
                this.props.navigateToHorizontalSlide('3');
            }
        }
    }

    render() {
        let MAP = {
            name: "sg-map",
            areas: [
                { name: "0", shape: "circle", coords: [24, 13, 12] },
                // { name: "1", shape: "circle", coords: [83, 13, 12] },
                { name: "2", shape: "circle", coords: [140, 13, 12] },
                { name: "3", shape: "circle", coords: [198, 13, 12] }
            ]
        }
        return (
            <div className="scheduleGroupsCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <ScheduleGroupsInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isSgInfoOpen}
                    sgName={this.state.sgName}
                    timeZone={this.state.timeZone}
                    openSchedules={this.state.openSchedules}
                    closedSchedules={this.state.closedSchedules}
                    holidaySchedules={this.state.holidaySchedules}
                    scheduleList={this.state.scheduleList}
                    zonesList={this.state.zonesList}
                    isSaveLocale={this.state.isSaveLocale}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isSgInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 scheduleGroupsCardBody">
                        <CardTitle className="m-0 scheduleGroupsTitle">
                            <div className="divSg">
                                <Badge className="titleBadgeScheduleGroups">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pschgrps.png" map={MAP} width={225} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/schedulegroups-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyScheduleGroups">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonScheduleGroups" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonScheduleGroups">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage}/>
                            <div className="scheduleGroups-wrap">
                                <ReactTable
                                    data={this.state.sgList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.sgList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Schedule Groups loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonScheduleGroups" onClick={this.importScheduleGroups} disabled={this.state.sgList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
