import './ivrRouting.css'
import React from 'react'
import { FaRoute, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import { getInboundCallflows } from '../../../../services/purecloud/flows';
import { architectPostIvrs, getArchitects } from '../../../../services/purecloud/architect';
import IvrRoutingInfo from '../ivrRouting/ivrRoutingInfo';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import Address from '../ivrRouting/address';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const ivrRoutingDataFields = [
    'Name',
    'Addresses',
    'Schedule Group',
    'Open Hours Flow',
    'Closed Hours flow',
    'Holiday Hours flow'
];

export default class IvrRouting extends React.Component {

    state = {
        env: '',
        token: '',
        ivrList: [],
        isIvrInfoOpen: false,
        ivrName: '',
        Addresses: [],
        scheduleGroup: '',
        openHoursFlow: '',
        closedHoursFlow: '',
        holidayHoursFlow: '',
        rowID: null,
        flowList: [],
        scheduleGroupList: [],
        isSaveLocale: false,
        isAddressOpen: false,
        isAddressSaveLocale: false,
        didNumber: '',
        lastUsedDid: '',
        preloaderLocalShow: false,
        preloaderMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Loading addresses' });
        this.state.flowList = await getInboundCallflows(this.state.env, this.state.token);
        this.state.scheduleGroupList = await getArchitects(this.state.env, this.state.token, 'scheduleGroups');
        await this.setState({ preloaderLocalShow: false, preloaderMessage: 'Loading' });
    }

    updateScheduleGroups = async () => {
        this.state.scheduleGroupList = await getArchitects(this.state.env, this.state.token, 'scheduleGroups');
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var ivrList = [...this.state.ivrList];
        var selectedList = this.state.selected;
        for (const i in ivrList) {
            if (ivrList[i].rowid === rowid) {
                ivrList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ ivrList: ivrList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.updateScheduleGroups();
        this.setState({
            isIvrInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            ivrName: '',
            Addresses: [],
            scheduleGroup: '',
            openHoursFlow: '',
            closedHoursFlow: '',
            holidayHoursFlow: '',
            rowID: null,
            didNumber: '',
            lastUsedDid: '',
        })
    }

    handleSubmitFunction = () => {
        let ivrList = [...this.state.ivrList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in ivrRoutingDataFields) {
            if (ivrRoutingDataFields[i] === 'Name') {
                newRow[ivrRoutingDataFields[i]] = this.state.ivrName;
            } else if (ivrRoutingDataFields[i] === 'Addresses') {
                newRow[ivrRoutingDataFields[i]] = this.state.Addresses;
            } else if (ivrRoutingDataFields[i] === 'Schedule Group') {
                newRow[ivrRoutingDataFields[i]] = this.state.scheduleGroup;
            } else if (ivrRoutingDataFields[i] === 'Open Hours Flow') {
                newRow[ivrRoutingDataFields[i]] = this.state.openHoursFlow;
            } else if (ivrRoutingDataFields[i] === 'Closed Hours flow') {
                newRow[ivrRoutingDataFields[i]] = this.state.closedHoursFlow;
            } else if (ivrRoutingDataFields[i] === 'Holiday Hours flow') {
                newRow[ivrRoutingDataFields[i]] = this.state.holidayHoursFlow;
            }
        }

        ivrList.push(newRow);

        this.setState({
            isIvrInfoOpen: false,
            ivrList: ivrList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'Addresses') {
            const options = event.target.selectedOptions;
            let value = [];
            for (let i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }
            this.setState({
                [fieldName]: value
            })
        } else {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    }

    updateState = (rowInfo) => {
        this.setState({
            ivrName: rowInfo.Name,
            Addresses: rowInfo.Addresses,
            scheduleGroup: rowInfo['Schedule Group'],
            openHoursFlow: rowInfo['Open Hours Flow'],
            closedHoursFlow: rowInfo['Closed Hours flow'],
            holidayHoursFlow: rowInfo['Holiday Hours flow'],
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isIvrInfoOpen: true,
        });
    }

    updateFunction = () => {
        let ivrList = [...this.state.ivrList];
        for (let did of ivrList) {
            if (did.rowid === this.state.rowID) {
                did.Name = this.state.ivrName;
                did.Addresses = this.state.Addresses;
                did['Schedule Group'] = this.state.scheduleGroup;
                did['Open Hours Flow'] = this.state.openHoursFlow;
                did['Closed Hours flow'] = this.state.closedHoursFlow;
                did['Holiday Hours flow'] = this.state.holidayHoursFlow;
            }
        }

        this.setState({
            ivrList: ivrList,
            isIvrInfoOpen: false,
        })
    }

    addAddresses = () => {
        this.setState({
            isAddressOpen: true,
            isAddressSaveLocale: false,
            didNumber: '',
            lastUsedDid: '',
        })
    }

    deleteButtonClicked = async (did, e) => {
        if (e) {
            e.stopPropagation();
        }
        let result = this.state.Addresses.filter(item => item !== did);
        this.setState({
            Addresses: result
        });
    }

    handleRowClick = (address) => {
        this.setState({
            didNumber: address,
            lastUsedDid: address,
            isAddressOpen: true,
            isAddressSaveLocale: true,
        })
    }

    addDidFunction = async () => {
        let address = this.state.Addresses;
        address.push(this.state.didNumber);
        this.setState({
            isAddressOpen: false,
            Addresses: address
        });
    }

    updateDidFunction = async () => {
        let result = this.state.Addresses.filter(item => item !== this.state.lastUsedDid);
        result.push(this.state.didNumber);
        this.setState({
            isAddressOpen: false,
            Addresses: result
        });
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        await this.updateScheduleGroups();
        const fileContent = await CsvReader.readFile(file);
        const ivrData = await CsvReader.parseCsvFileToIvrRoutingData(fileContent);
        await this.setState({ ivrList: [...this.state.ivrList, ...ivrData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importIVR = async () => {
        this.eventList = [];
        const ivrList = this.state.ivrList;
        for (const i in ivrList) {
            const ivrRouteData = ivrList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing IVR routing [${ivrRouteData.Name}]`);
            if (await this.dataValidated(ivrRouteData, i, ivrList)) {
                try {
                    // <create IVR route>

                    const dnis = ivrRouteData.Addresses;
                    const schGroup = this.getScheduleGroup(ivrRouteData["Schedule Group"]);
                    const openHoursFlow = this.getFlow(ivrRouteData["Open Hours Flow"]);
                    const closedHoursFlow = this.getFlow(ivrRouteData["Closed Hours flow"]);
                    const holidayHoursFlow = this.getFlow(ivrRouteData["Holiday Hours flow"]);

                    const ivrRoute = await architectPostIvrs(this.state.env, this.state.token, ivrRouteData.Name, dnis, schGroup, openHoursFlow, closedHoursFlow, holidayHoursFlow);
                    console.log(`IVR routing ${JSON.stringify(ivrRoute)}`);
                    // </create IVR route>
                    this.logEvent(`IVR routing [${ivrRouteData.Name}] successfully processed`);
                    this.removeRowClicked(ivrRouteData.rowid);
                } catch (err) {
                    console.log(`${err.message}`);
                    if(err.message.contains(`Field 'didPoolId' is required`)){
                        this.logEvent(`The provided DID is not within the configured DID ranges provisioned for this ORG`, true);
                    } else {
                        this.logEvent(`${err.message}`, true);
                    }
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing IVRs Routings`, count, 'IvrsRoutings');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    getScheduleGroup = (name) => {
        const schGroup = this.state.scheduleGroupList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (schGroup) { return schGroup; }
    }

    getFlow = (name) => {
        const flow = this.state.flowList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (flow) { return flow; }
    }

    dataValidated = async (ivrRoute, i, ivrsRoutingData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!ivrRoute.Name || !ivrRoute.Name.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!ivrRoute.Addresses || ivrRoute.Addresses.length === 0) { this.logEvent(`[Index ${i}] DNIS can't be empty.`, true); isValidated = false; }
        if (!ivrRoute["Schedule Group"] || !ivrRoute["Schedule Group"].trim()) { this.logEvent(`[index ${i}] Scheduled group can't be empty.`, true); isValidated = false; }
        if (!ivrRoute["Open Hours Flow"] || !ivrRoute["Open Hours Flow"].trim()) { this.logEvent(`[Index ${i}] Open hours flow can't be empty.`, true); isValidated = false; }
        if (!ivrRoute["Closed Hours flow"] || !ivrRoute["Closed Hours flow"].trim()) { this.logEvent(`[Index ${i}] Closed hours flow can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if the schedule group exist>
        var schGroupValid = false;
        if (ivrRoute["Open Hours Flow"]) {
            for (const schGroup of this.state.scheduleGroupList) {
                if (ivrRoute["Schedule Group"].toLowerCase() === schGroup.name.toLowerCase()) {
                    schGroupValid = true;
                }
            }
        }
        if (!schGroupValid) { this.logEvent(`[Index ${i}] Schedule group '${ivrRoute["Schedule Group"]}' doesn't exist`, true); isValidated = false; }
        // </validate if the schedule group exist>

        // <validate if flows exist>
        var openHoursFlowValid = false;
        var closedHoursFlowValid = false;
        var holidayHoursFlowValid = false;
        var openHoursFlowText = "";
        var closedHoursFlowText = "";
        var holidayHoursFlowText = "";
        if (ivrRoute["Open Hours Flow"]) {
            for (const flow of this.state.flowList) {
                if (ivrRoute["Open Hours Flow"].toLowerCase() === flow.name.toLowerCase()) {
                    openHoursFlowValid = true;
                } else if (ivrRoute["Open Hours Flow"].toLowerCase().split(/\s/).join('') === flow.name.toLowerCase().split(/\s/).join('')) {
                    openHoursFlowText = flow.name;
                }

                if (ivrRoute["Closed Hours flow"].toLowerCase() === flow.name.toLowerCase()) {
                    closedHoursFlowValid = true;
                } else if (ivrRoute["Closed Hours flow"].toLowerCase().split(/\s/).join('') === flow.name.toLowerCase().split(/\s/).join('')) {
                    closedHoursFlowText = flow.name;
                }

                if (ivrRoute["Holiday Hours flow"].toLowerCase() === flow.name.toLowerCase()) {
                    holidayHoursFlowValid = true;
                } else if (ivrRoute["Holiday Hours flow"].toLowerCase().split(/\s/).join('') === flow.name.toLowerCase().split(/\s/).join('')) {
                    holidayHoursFlowText = flow.name;
                }
            }
        }
        if (ivrRoute["Open Hours Flow"] && !openHoursFlowValid) {
            if (openHoursFlowText !== '') {
                this.logEvent(`Flow name '${ivrRoute["Open Hours Flow"]}' has multiple spaces sequentially. Please correct the data to no longer contain multiple sequential spaces. Followed by Import retry.`, true);
            } else {
                this.logEvent(`[Index ${i}] Flow '${ivrRoute["Open Hours Flow"]}' doesn't exist`, true);
            }
            isValidated = false;
        }
        if (ivrRoute["Closed Hours flow"] && !closedHoursFlowValid) {
            if (closedHoursFlowText !== '') {
                this.logEvent(`Flow name '${ivrRoute["Closed Hours flow"]}' has multiple spaces sequentially. Please correct the data to no longer contain multiple sequential spaces. Followed by Import retry.`, true);
            } else {
                this.logEvent(`[Index ${i}] Flow '${ivrRoute["Closed Hours flow"]}' doesn't exist`, true);
            }
            isValidated = false;
        }
        if (ivrRoute["Holiday Hours flow"] && !holidayHoursFlowValid) {
            if (holidayHoursFlowText !== '') {
                this.logEvent(`Flow name '${ivrRoute["Holiday Hours flow"]}' has multiple spaces sequentially. Please correct the data to no longer contain multiple sequential spaces. Followed by Import retry.`, true);
            } else {
                this.logEvent(`[Index ${i}] Flow '${ivrRoute["Holiday Hours flow"]}' doesn't exist`, true);
            }
            isValidated = false;
        }
        // </validate if flows exist>

        // <validate if name is unique>
        for (const j in ivrsRoutingData) {
            const ivrRoute1 = ivrsRoutingData[j];
            if (i === j) { continue; }
            if (ivrRoute.Name.trim().toLowerCase() === ivrRoute1.Name.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if name is unique>
        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.ivrList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderMessage: "Deleting Selected IVR Routings...."  });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            } else if (area.name === '1') {
                this.props.navigateToHorizontalSlide('1');
            } else if (area.name === '3') {
                this.props.navigateToHorizontalSlide('3');
            }
        }
    }

    render() {
        let MAP = {
            name: "ivr-map",
            areas: [
                { name: "0", shape: "circle", coords: [24, 13, 12] },
                { name: "1", shape: "circle", coords: [83, 13, 12] },
                // { name: "2", shape: "circle", coords: [140, 13, 12] },
                { name: "3", shape: "circle", coords: [198, 13, 12] }
            ]
        }
        return (
            <div className="ivrRoutingCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <IvrRoutingInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isIvrInfoOpen}
                    ivrName={this.state.ivrName}
                    Addresses={this.state.Addresses}
                    scheduleGroup={this.state.scheduleGroup}
                    openHoursFlow={this.state.openHoursFlow}
                    closedHoursFlow={this.state.closedHoursFlow}
                    holidayHoursFlow={this.state.holidayHoursFlow}
                    isSaveLocale={this.state.isSaveLocale}
                    scheduleGroupList={this.state.scheduleGroupList}
                    flowList={this.state.flowList}
                    addAddresses={this.addAddresses}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    deleteButtonClicked={this.deleteButtonClicked}
                    handleRowClick={this.handleRowClick}
                    closeFunction={() => { this.setState({ isIvrInfoOpen: false }) }} />
                {/* <Information viewer> */}

                {/* <DIDs Information viewer> */}
                <Address
                    didNumber={this.state.didNumber}
                    isOpen={this.state.isAddressOpen}
                    isAddressSaveLocale={this.state.isAddressSaveLocale}
                    handleInputChange={this.handleInputChange}
                    addDidFunction={this.addDidFunction}
                    updateDidFunction={this.updateDidFunction}
                    closeAddressFunction={() => { this.setState({ isAddressOpen: false }) }}
                />
                {/* <DIDs Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 ivrRoutingCardBody">
                        <CardTitle className="m-0 ivrRoutingTitle">
                            <div className="divIvr">
                                <Badge className="titleBadgeIvr">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pIVR_New.png" map={MAP} width={225} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/ivrsrouting-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyIvr">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonIvr" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonIvr">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderMessage} />
                            <div className="ivrRouting-wrap">
                                <ReactTable
                                    data={this.state.ivrList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.ivrList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "Name",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No IVR's loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonIvr" onClick={this.importIVR} disabled={this.state.ivrList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
