import './editAndRetryModal.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Col, Row, Tooltip } from 'reactstrap'
import { FaPlus, FaEdit } from 'react-icons/fa';
import ReactTable from "react-table";
import { headerByAccesor } from './utils';
import { Resizable } from 'react-resizable';
import CsvReader from "../../../services/misc/csvReader";

export default class EditAndRetryModal extends React.Component {

  state = {
    columns: [],
    tableData: [],
    width: 700,
    height: 200
  }

  componentDidMount() {
    this.generateColumns();
  }

  generateColumns() {
    if (this.props.content) {
      const eventsForCurrentRow = this.props.eventsForCurrentRow;
      const firstRow = JSON.parse(this.props.content[0]);
      const columns = Object.keys(firstRow).map(key => {
        let isError;
        return {
          minWidth: 150,
          Header: headerByAccesor[key],
          accesor: key,
          getProps: (state, rowInfo, column) => {
            isError = eventsForCurrentRow.find(item => item.isError && item.message.includes(key));
            return {
              style: {
                background: isError ? '#dc3545' : null,
                color: isError ? 'white' : null
              },
            };
          },
          Cell: row => this.renderEditable(row.index, row.column.accesor, isError)
        }
      });
      columns.push({
        minWidth: 150,
        Header: "Remove",
        accessor: "rowid",
        Cell: row => (<Button key={row.value} value={row.value} onClick={() => { this.removeRowClicked(row.index) }} close />)
      })
      const tableData = this.props.content.map(item => JSON.parse(item))
      this.setState({ columns, tableData })
    }
  }

  renderEditable = (index, accesor, isError) => {
    const cellId = `${accesor}${index}`;
    return (
      <div>
        <div
          id={cellId}
          contentEditable
          suppressContentEditableWarning
          onBlur={e => {
            const tableData = [...this.state.tableData];
            const newValue = e.target.innerText;
            tableData[index][accesor] = newValue;
            this.setState({ tableData: tableData });
          }}
          onMouseEnter={() => {
            if (isError) {
              this.setState({ cellId })
            }
          }}
          onMouseLeave={() => {
            if (isError) {
              this.setState({ cellId: null })
            }
          }}
          dangerouslySetInnerHTML={{
            __html: this.state.tableData[index][accesor]
          }}
        />
        {isError && <Tooltip placement={'right-start'} isOpen={this.state.cellId === cellId} target={cellId}>
          {isError.message}
        </Tooltip>}
      </div>
    );
  }

  modalImportButtonClicked = async () => {
    this.props.importFunction(this.props.fileName, this.state.tableData)
    this.props.closeFunction()
  }

  onResize = (event, { element, size, handle }) => {
    this.setState({ width: size.width, height: size.height });
  };

  removeRowClicked = async (rowid) => {
    let data = [...this.state.tableData];
    data.splice(rowid, 1)
    this.setState({ tableData: data });
  }

  addRowClicked = async () => {
    var tableData = [...this.state.tableData];
    var newRow = '';
    const name = this.props.fileName;
    if (name.includes("locations")) {
      newRow = CsvReader.getEmptyLocationsObject();
    }
    if (name.includes("dids")) {
      newRow = CsvReader.getEmptyDidObject();
    }
    if (name.includes("extensions")) {
      newRow = CsvReader.getEmptyExtensionObject();
    }
    if (name.includes("phones")) {
      newRow = CsvReader.getEmptyPhonesObject();
    }
    if (name.includes("phones-base-settings")) {
      newRow = CsvReader.getEmptyPhonesObject();
    }
    if (name.includes("sites")) {
      newRow = CsvReader.getEmptySiteObject();
    }
    if (name.includes("divisions")) {
      newRow = CsvReader.getEmptyDivisionObject();
    }
    if (name.includes("wrapup")) {
      newRow = CsvReader.getEmptyWrapUpCodesObject();
    }
    if (name.includes("skills") && !name.includes('matrix')) {
      newRow = CsvReader.getEmptySkillsObject();
    }
    if (name.includes("users")) {
      newRow = CsvReader.getEmptyUserObject();
    }
    if (name.includes("roles")) {
      newRow = CsvReader.getEmptyRolesObject();
    }
    if (name.includes("edgegroups")) {
      newRow = CsvReader.getEmptyEdgeGroupsObject();
    }
    if (name.includes("schedule")) {
      newRow = CsvReader.getEmptyScheduleObject();
    }
    if (name.includes("schedulegroups")) {
      newRow = CsvReader.getEmptyScheduleGroupObject();
    }
    if (name.includes("queue")) {
      newRow = CsvReader.getEmptyQueueObject();
    }
    if (name.includes("ivrsrouting")) {
      newRow = CsvReader.getEmptyivrRoutingObject();
    }
    tableData.push(newRow);
    console.log(tableData);
    await this.setState({ tableData: tableData });
  }

  render() {
    return (
      <div>
        <Resizable className="box" height={this.state.height} width={this.state.width} onResize={this.onResize} resizeHandles={['sw', 'se', 'nw', 'ne', 'w', 'e', 'n', 's']}>
          <Modal isOpen={this.props.isOpen} style={{ minWidth: `700px`, minHeight: `500px`, maxWidth: `${this.state.width}px`, width: `${this.state.width}px`, maxHeight: `${this.state.height}px`, height: `${this.state.height}px` }} >
            <ModalHeader><FaEdit /> Edit and Retry</ModalHeader>
            <ModalBody>{this.state.tableData.length ? <ReactTable
              data={this.state.tableData}
              pageSize={this.state.tableData.length}
              columns={this.state.columns}
              showPagination={false} /> : null}
            </ModalBody>
            <ModalFooter>
              <Row>
                <Col>
                  <Button style={{backgroundColor:"#061e45" }} onClick={this.addRowClicked}><FaPlus /> Add row</Button>
                </Col>
              </Row>
              <Button style={{backgroundColor:"#061e45" }} onClick={this.modalImportButtonClicked} > Import</Button>
              <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
            </ModalFooter>
          </Modal>
        </Resizable>
      </div>
    )
  }
}