import { usersGetMe } from '../purecloud/users';
import { organizationsGetMe } from '../purecloud/organizations';
import { getSelectedRegion } from '../../global/localStorage';
import { logSessionData } from '../misc/logSession';

export const pureCloudLogout = async () => { window.location.href = '/logout'; }

export const getToken = () => { return sessionStorage.getItem('purecloud_token'); }

export const getUserMe = async () => {
    var userMe = await sessionStorage.getItem('purecloud_user');
    if (!userMe) {
        userMe = await usersGetMe(getSelectedRegion(), getToken());
        await sessionStorage.setItem('purecloud_user', JSON.stringify(userMe));
    } else {
        userMe = await JSON.parse(userMe);
    }
    return userMe;
}

export const getOrgMe = async () => {
    var orgMe = await sessionStorage.getItem('purecloud_org');
    if (!orgMe) {
        orgMe = await organizationsGetMe(getSelectedRegion(), getToken());
        await sessionStorage.setItem('purecloud_org', JSON.stringify(orgMe));
    } else {
        orgMe = await JSON.parse(orgMe);
    }
    return orgMe;
}

export const addLoginSession = async (action, text) => {
    let orgMe = await getOrgMe();
    let userMe = await getUserMe();
    try {
        let response = await logSessionData(userMe.email, orgMe.name, action, text, null, 0, 'User');
        if (response) {
            console.log(response);
            if (response.messages.length === 0) {
                await sessionStorage.setItem('session_id', response.result);
            } else {
                response.messages.map((message) => {
                    console.log('Error in Logging Session Data -' + message.content)
                });
            }
        }
    } catch (ex) {
        console.log('Exception occured while Logging Session Data -' + ex);
    }
}

export const logActionData = async (action, text, count, entity) => {
    let sessionId = sessionStorage.getItem('session_id');
    let orgMe = await getOrgMe();
    let userMe = await getUserMe();
    if (sessionId === null) {
        await addLoginSession('Login', 'user has logged in');
        sessionId = sessionStorage.getItem('session_id');
    }
    try {
        await logSessionData(userMe.email, orgMe.name, action, text, sessionId, count, entity);
    } catch (ex) {
        console.log('Exception occured while Logging Session Data -' + ex);
    }
}
