import './ivrRoutingInfo.css';
import React from 'react';
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row, Table } from 'reactstrap';
import { FaTrash, FaPlusCircle } from 'react-icons/fa';

export default class IvrRoutingInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.ivrName !== '' && this.props.Addresses.length !== 0 && this.props.scheduleGroup !== '' && this.props.openHoursFlow !== '' && this.props.closedHoursFlow !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="ivrRoutingModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={2} for="ivrName">Name:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <Input invalid={this.props.ivrName === '' ? true : false} value={this.props.ivrName} onChange={this.props.handleInputChange('ivrName')} id="ivrName" type="text" placeholder="Enter the name of the IVR" />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="scheduleGroup">Schedule Group:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.scheduleGroup} onChange={this.props.handleInputChange('scheduleGroup')}>
                                            <option key="SG1" hidden>Select a Schedule Group</option>
                                            {
                                                this.props.scheduleGroupList.map((sg) => (
                                                    <option key={sg.id}>{sg.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="openHoursFlow">Open Hours Flow:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.openHoursFlow} onChange={this.props.handleInputChange('openHoursFlow')}>
                                            <option key="O1" hidden>Select a Flow</option>
                                            {
                                                this.props.flowList.map((flow) => (
                                                    <option key={flow.id + 'O'}>{flow.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="closedHoursFlow">Closed Hours Flow:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.closedHoursFlow} onChange={this.props.handleInputChange('closedHoursFlow')}>
                                            <option key="C1" hidden>Select a Flow</option>
                                            {
                                                this.props.flowList.map((flow) => (
                                                    <option key={flow.id + 'C'}>{flow.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="holidayHoursFlow">Holiday Hours Flow:</Label>
                                        <select className="form-control selectpicker" value={this.props.holidayHoursFlow} onChange={this.props.handleInputChange('holidayHoursFlow')}>
                                            <option key="H1" hidden>Select a Flow</option>
                                            {
                                                this.props.flowList.map((flow) => (
                                                    <option key={flow.id + 'H'}>{flow.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="Addresses">Addresses:</Label>
                                <FaPlusCircle title="Assign DIDs to be used with the Routing definition" sm={1} style={{ height: '2.5em', cursor: 'pointer' }} onClick={this.props.addAddresses} />
                                <Col sm={9}>
                                    <div className="did-wrap">
                                        <Table striped bordered className="table-condensed did-table ">
                                            <thead>
                                                <tr className="didHeader">
                                                    <th>Address</th>
                                                    <th>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.props.Addresses.map((address, index) => {
                                                        return (
                                                            <tr key={index} style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.props.handleRowClick(address)}>
                                                                <td className="p-1">{address}</td>
                                                                <td className="p-1 did-button-cell"><FaTrash onClick={(e) => { this.props.deleteButtonClicked(address, e) }} /></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
