import { pureCloudLogout } from '../session/session'
import config from '../../config';
const backEndURL = config.apiEndpoint;

export const extendedBulkExport = async (env, token, userMail, orgName, exportUri, sessionId) => {
    return fetch(backEndURL + exportUri, {
        method: 'GET',
        headers: {
            'x-accel-user': userMail,
            'x-accel-org': orgName,
            'authToken': token,
            'x-accel-env': env,
            'session-id': sessionId,
            'tool-type': 'Ignite'
        }
    })
        .then(response => response.json())
        .then(response => {
            if (response.status === 401) {
                pureCloudLogout();
                return;
            }
            if (response.status < 200 || response.status > 299) throw response;
            return response;
        })
}

export const checkProgress = (userMail, orgName, token) => {
    return fetch(backEndURL + `/pcat/progress/${token}/${orgName}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'x-accel-user': userMail,
        },
    })
        .then((response) => {
            if (response.ok) {
                if (response.status == 201) {
                    return response.headers.get('Location');
                }
                return response.json();
            } else {
                throw response.statusText;
            }
        })
        .then((responseJson) => {
            if (typeof (responseJson) != typeof ('string')) {
                if (responseJson.status.code === "OK") {
                    return responseJson.result;
                } else {
                    return responseJson.result;
                }
            } else {
                return responseJson;
            }
        })
        .catch((err) => {
            return err;
        });
}
