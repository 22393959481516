import './designFlows.css'
import React from 'react'
import { FaServer, FaPlus, FaTrash, FaInfoCircle, FaSign } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import { getRoutingGetSkills, getRoutingGetQueues } from '../../../../services/purecloud/routing';
import { createInboundCallFlow, archyResults, getArchitects } from '../../../../services/purecloud/architect';
import DesignFlowsInfo from '../designFlows/designFlowsInfo';
import BehaviourModal from '../designFlows/behaviourModal';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const designDataFields = [
    'Name',
    'nodes',
    'default',
];

export default class DesignFlows extends React.Component {

    state = {
        env: '',
        token: '',
        designList: [],
        nodesList: [],
        isDesignInfoOpen: false,
        callFlowName: '',
        digit: '',
        rowID: null,
        default: '',
        behaviour: '',
        queues: '',
        skills: '',
        Prompts: '',
        isSaveLocale: false,
        isBehaviorOpen: false,
        isBehaviorSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        queueList: [],
        skillList: [],
        promptsList: [],
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.state.queueList = await getRoutingGetQueues(this.state.env, this.state.token);
        this.state.skillList = await getRoutingGetSkills(this.state.env, this.state.token);
        this.state.promptsList = await getArchitects(this.state.env, this.state.token, 'prompts');
        await this.setState({ preloaderLocalShow: false });
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var designList = [...this.state.designList];
        var selectedList = this.state.selected;
        for (const i in designList) {
            if (designList[i].rowid === rowid) {
                designList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ designList: designList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isDesignInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            callFlowName: '',
            digit: '',
            rowID: null,
            behaviour: '',
            default: '',
            queues: '',
            skills: '',
            Prompts: '',
            nodesList: [],
        })
    }

    handleSubmitFunction = () => {
        let designList = [...this.state.designList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in designDataFields) {
            if (designDataFields[i] === 'Name') {
                newRow[designDataFields[i]] = this.state.callFlowName;
            } else if (designDataFields[i] === 'nodes') {
                newRow[designDataFields[i]] = this.state.nodesList;
            } else if (designDataFields[i] == 'default') {
                newRow[designDataFields[i]] = this.state.default;
            }
        }

        designList.push(newRow);

        this.setState({
            isDesignInfoOpen: false,
            designList: designList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'behaviour') {
            this.setState({
                [fieldName]: event.target.value,
                queues: '',
                skills: '',
                Prompts: '',
            });
        } else {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    }

    handleDefaultOption = (digit) => (event) => {
        const ele = document.getElementById('defaultOption');
        if (ele.checked) {
            this.setState({
                default: digit,
            });
        } else {
            this.setState({
                default: '',
            });
        }
    }

    handleDigitClick = (digit) => {
        let nodesList = this.state.nodesList;
        const index = nodesList.findIndex(x => x.dtmf === digit);
        if (index > -1) {
            let node = nodesList[index];
            if (node.behaviour === 'Play a Message and Disconnect') {
                this.setState({
                    digit: digit,
                    isBehaviorOpen: true,
                    isBehaviorSaveLocale: true,
                    behaviour: node.behaviour,
                    queues: '',
                    skills: '',
                    Prompts: node.prompt,
                });
            } else if (node.behaviour === 'Transfer to Queue') {
                this.setState({
                    digit: digit,
                    isBehaviorOpen: true,
                    isBehaviorSaveLocale: true,
                    behaviour: node.behaviour,
                    queues: node.queueName,
                    skills: node.skillName,
                    Prompts: '',
                });
            } else {
                this.setState({
                    digit: digit,
                    isBehaviorOpen: true,
                    isBehaviorSaveLocale: true,
                    behaviour: node.behaviour,
                    queues: '',
                    skills: '',
                    Prompts: '',
                });
            }
        } else {
            this.setState({
                digit: digit,
                isBehaviorOpen: true,
                isBehaviorSaveLocale: false,
                behaviour: '',
                queues: '',
                skills: '',
                Prompts: '',
            });
        }
    }

    addBehaviourFunction = () => {
        let nodesList = this.state.nodesList;
        let node = {}
        node['behaviour'] = this.state.behaviour;
        node['dtmf'] = this.state.digit;
        if (this.state.behaviour === 'Transfer to Queue') {
            node['queueName'] = this.state.queues;
            if (this.state.skills !== '') {
                node['skillName'] = this.state.skills;
            }
        } else if (this.state.behaviour === 'Play a Message and Disconnect') {
            node['prompt'] = this.state.Prompts;
        }
        nodesList.push(node);
        this.setState({
            digit: '',
            isBehaviorOpen: false,
            nodesList: nodesList,
        });
    }

    updateState = (rowInfo) => {
        this.setState({
            callFlowName: rowInfo.Name,
            nodesList: rowInfo.nodes,
            default: rowInfo.default,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isDesignInfoOpen: true,
        });
    }

    updateFunction = () => {
        let designList = [...this.state.designList];
        for (let design of designList) {
            if (design.rowid === this.state.rowID) {
                design.Name = this.state.callFlowName;
                design.nodes = this.state.nodesList;
                design.default = this.state.default;
            }
        }

        this.setState({
            designList: designList,
            isDesignInfoOpen: false,
        })
    }

    updateBehaviourFunction = () => {
        let nodesList = this.state.nodesList;
        let index = nodesList.findIndex(x => x.dtmf === this.state.digit);
        if (index > -1) {
            nodesList.splice(index, 1);
        }
        let node = {}
        node['behaviour'] = this.state.behaviour;
        node['dtmf'] = this.state.digit;
        if (this.state.behaviour === 'Transfer to Queue') {
            node['queueName'] = this.state.queues;
            if (this.state.skills !== '') {
                node['skillName'] = this.state.skills;
            }
        } else if (this.state.behaviour === 'Play a Message and Disconnect') {
            node['prompt'] = this.state.Prompts;
        }
        nodesList.push(node);
        this.setState({
            digit: '',
            isBehaviorOpen: false,
            nodesList: nodesList,
        });
    }

    importDesignFlows = async () => {
        this.eventList = [];
        const designList = this.state.designList;
        for (const i in designList) {
            const design = designList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing Flow [${design.Name}]`);
            try {
                await createInboundCallFlow(this.state.env, this.state.token, design.Name, design.nodes);
                this.logEvent(`Flow [${design.Name}] successfully processed`);
                this.removeRowClicked(design.rowid);
            } catch (err) {
                archyResults.forEach(element => {
                    this.logEvent(element, true);
                });
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing ${this.props.cardTitle}`, count, 'Callflows');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.designList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Call Flows...."  });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '1') {
                this.props.navigateToHorizontalSlide('1');
            }
        }
    }

    render() {
        let MAP = {
            name: "df-map",
            areas: [
                // { name: "0", shape: "circle", coords: [31, 16, 16] },
                { name: "1", shape: "circle", coords: [112, 16, 16] },
            ]
        }
        return (
            <div className="designFlowsCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <DesignFlowsInfo
                    title={this.props.cardTitle}
                    default={this.state.default}
                    isOpen={this.state.isDesignInfoOpen}
                    callFlowName={this.state.callFlowName}
                    isSaveLocale={this.state.isSaveLocale}
                    nodesList={this.state.nodesList}
                    handleDigitClick={this.handleDigitClick}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isDesignInfoOpen: false }) }} />
                {/* <Information viewer> */}

                {/* <Behaviour Information viewer> */}
                <BehaviourModal
                    digit={this.state.digit}
                    default={this.state.default}
                    behaviour={this.state.behaviour}
                    queues={this.state.queues}
                    skills={this.state.skills}
                    Prompts={this.state.Prompts}
                    isOpen={this.state.isBehaviorOpen}
                    isBehaviorSaveLocale={this.state.isBehaviorSaveLocale}
                    queueList={this.state.queueList}
                    skillList={this.state.skillList}
                    promptsList={this.state.promptsList}
                    handleDefault={this.handleDefault}
                    handleDefaultOption={this.handleDefaultOption}
                    handleInputChange={this.handleInputChange}
                    addBehaviourFunction={this.addBehaviourFunction}
                    updateBehaviourFunction={this.updateBehaviourFunction}
                    closeBehaviourFunction={() => { this.setState({ isBehaviorOpen: false, digit: '' }) }}
                />
                {/* <Behaviour Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 designFlowsCardBody">
                        <CardTitle className="m-0 designFlowsTitle">
                            <div className="divDesignFlows">
                                <Badge className="titleBadgeDF">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pDesignFlows.png" map={MAP} width={150} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Design a new Callflow by configuring actions.
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyDesignFlows">
                        <FormGroup row>
                            <Col>
                                <Button className="AddButtonDesignFlows" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                            </Col>
                        </FormGroup>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage} />
                            <div className="designFlows-wrap">
                                <ReactTable
                                    data={this.state.designList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.designList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "Name",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Call Flows loaded"
                                    className="-striped -highlight"
                                />
                            </div>

                            <Row className="mt-3">
                                <Col>
                                    <div>
                                        <Button className="SubmitButtonDesignFlows" onClick={this.importDesignFlows} disabled={this.state.designList.length === 0}>Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
