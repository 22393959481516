import './behaviourModal.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Row, Input } from 'reactstrap'

export default class BehaviourModal extends React.Component {
    render() {

        let isBehaviourButtonEnabled = false;
        if (this.props.behaviour !== '') {
            isBehaviourButtonEnabled = true;
        }

        if (this.props.behaviour === 'Transfer to Queue') {
            if (this.props.queues === '') {
                isBehaviourButtonEnabled = false;
            }
        } else if (this.props.behaviour === 'Play a Message and Disconnect') {
            if (this.props.Prompts === '') {
                isBehaviourButtonEnabled = false;
            }
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="designFlowsInfoModal">
                    <ModalHeader>Digit Configuration</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup check>
                                            <Input type="checkbox" id="defaultOption" checked={this.props.default === this.props.digit} onChange={this.props.handleDefaultOption(this.props.digit)} />
                                            <Label for="defaultOption" check>Default Option? </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="behaviour">Behavior:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={10}>
                                    <select className="form-control selectpicker" value={this.props.behaviour} onChange={this.props.handleInputChange('behaviour')}>
                                        <option key="B1" hidden>Select a Behavior</option>
                                        <option key="B2">Transfer to Queue</option>
                                        <option key="B3">Play a Message and Disconnect</option>
                                        <option key="B4">Repeat Menu</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            {
                                this.props.behaviour === 'Transfer to Queue'
                                &&
                                <FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <Label>Queue:<span className="text-danger font-weight-bold">*</span></Label>
                                            <select className="form-control selectpicker" value={this.props.queues} onChange={this.props.handleInputChange('queues')}>
                                                <option key="Q1" hidden>Select a Queue</option>
                                                {
                                                    this.props.queueList.map((queue) => (
                                                        <option key={queue.id}>{queue.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                        <Col md={6}>
                                            <Label>Skill:</Label>
                                            <select className="form-control selectpicker" value={this.props.skills} onChange={this.props.handleInputChange('skills')}>
                                                <option key="S1" hidden>Select a Skill</option>
                                                {
                                                    this.props.skillList.map((skill) => (
                                                        <option key={skill.id}>{skill.name}</option>
                                                    ))
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            }
                            {
                                this.props.behaviour === 'Play a Message and Disconnect'
                                &&
                                <FormGroup row>
                                    <Label sm={2} for="Prompts">Prompt:<span className="text-danger font-weight-bold">*</span></Label>
                                    <Col sm={10}>
                                        <select className="form-control selectpicker" value={this.props.Prompts} onChange={this.props.handleInputChange('Prompts')}>
                                            <option key="B1" hidden>Select a Prompt</option>
                                            {
                                                this.props.promptsList.map((prompt) => (
                                                    <option key={prompt.id}>{prompt.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </FormGroup>
                            }
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isBehaviorSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateBehaviourFunction} disabled={!isBehaviourButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.addBehaviourFunction} disabled={!isBehaviourButtonEnabled}>Add</Button>
                        }

                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeBehaviourFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
