import './eventListViewer.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from 'reactstrap'
import { FaClipboard, FaExclamationTriangle, FaExclamationCircle } from 'react-icons/fa';
import ReactTable from "react-table";

export default class EventListViewer extends React.Component {
  render() {
    return (
      <div>
        <Modal className="event-list-viewer-main" isOpen={this.props.isOpen}>
          <ModalHeader><FaClipboard /> {this.props.title}</ModalHeader>
          <ModalBody>
            {
              this.props.eventList.length > 0
              &&
              <ReactTable
                data={this.props.eventList}
                pageSize={this.props.eventList.length}
                columns={[
                  {
                    maxWidth: 30,
                    Header: "",
                    accessor: "isError",
                    Cell: props => <b>{props.value ? <FaExclamationCircle /> : ''}</b>
                  },
                  {
                    maxWidth: 30,
                    accessor: "isWarning",
                    Cell: props => <b>{props.value ? <FaExclamationTriangle /> : ''}</b>
                  },
                  {
                    maxWidth: 120,
                    accessor: "isWarning",
                    Cell: props => <b>{props.value ? 'Warning' : ''}</b>
                  },
                  {
                    maxWidth: 120,
                    Header: "Time",
                    accessor: "time",
                  },
                  {
                    Header: "Value",
                    accessor: "message",
                  }
                ]}
                showPagination={false}
                className="-striped -highlight"
                getTrProps={(state, rowInfo, column) => {
                  console.log(rowInfo.row);
                  return {
                    style: {
                      background: rowInfo.row.isError ? '#ffebe6' : rowInfo.row.isWarning ? '#e8d589' : '#e6ffe6'
                    }
                  }
                }}
              />
            }
          </ModalBody>
          <ModalFooter>
            <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}