import './addNewItem.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'
import { FaCloudUploadAlt } from 'react-icons/fa';
import DragAndDrop from '../../../../Misc/dragAndDrop/dragAndDrop';
import PreloaderLocal from '../../../../Misc/preloaderLocal/preloaderLocal';


export default class AddNewItem extends React.Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="BulkModalCampType">
                    <ModalHeader>Add New Call Response</ModalHeader>
                    <PreloaderLocal show={this.props.preloaderShow} text ={this.props.preloaderText} />
                    <ModalBody>
                        <div>
                            <FormGroup row className="rowAttrCamp">
                                <Label sm={4} for="callResponseName">Name<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={5}>
                                    <Input value={this.props.callResponseName} type="text" id="callResponseName" onChange={this.props.handleInputChange('callResponseName')} placeholder="Enter Call Response Name" />
                                </Col>
                            </FormGroup>
                            <FormGroup row className="rowAttr">
                                <Col md={4}>
                                    <Label>Live Voice<span className="text-danger font-weight-bold">*</span></Label>
                                </Col>
                                <Col md={5}>
                                    <select className="form-control selectpicker" disabled={true}>
                                        <option key="1">Transfer to Outbound Flow</option>
                                    </select>
                                </Col>
                            </FormGroup>
                            <FormGroup row >
                                <Col md={4}>
                                    <Label>Select an Outbound Flow<span className="text-danger font-weight-bold">*</span></Label>
                                </Col>
                                <Col md={5}>
                                    <select className="form-control selectpicker" disabled={this.props.disableOutboundRow} value={this.props.selectedoutboundFlows} onChange={this.props.handleInputChange('selectedoutboundFlows')}>
                                        <option key="E1" >Select an Outbound Flow</option>
                                        {
                                            this.props.outboundFlows.map((name) => (
                                                <option >{name}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                                {/* <Col md={3}>
                                    <Button className="AddButtonCampType" disabled={this.props.disableOutboundRow} onClick={this.props.handleAddNewOutboundFlow}>Add New</Button>
                                </Col> */}
                            </FormGroup>
                            {
                                this.props.disableOutboundRow &&
                                <FormGroup row className="rowAttr">
                                    <Col md={6}>
                                        <p>Click to download <a onClick={this.props.handleDownloadSubs}><u style={{ cursor: "pointer", color: "blue" }}>Substitution Template file</u></a></p>
                                    </Col>
                                </FormGroup>
                            }
                            {
                                this.props.disableOutboundRow &&
                                <FormGroup row className="rowAttr">
                                    <Col md={4}>
                                        <Label>Substitution File: </Label>
                                    </Col>
                                    <Col md={5}>
                                        <DragAndDrop handleDrop={this.props.handleDropFile}>
                                            <Input type="text" style={{ fontWeight: "900" }} placeholder="Drop Substitution file here" disabled value={this.props.subsFileName} />
                                        </DragAndDrop>
                                    </Col>
                                    <Col md={3}>
                                        <label className="custom-file-upload">
                                            <input style={{ display: "none" }} type="file" id="subsFile" accept=".yml, .yaml" onChange={this.props.handleSubsFile} />
                                            <FaCloudUploadAlt /> Browse
                                        </label>
                                    </Col>
                                </FormGroup>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="ModalButtonCamp" disabled = {this.props.callResponseName == ''} onClick={this.props.postNewCallResponse} >Submit</Button>
                        <Button className="ModalButtonCamp" onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>)
    }
}