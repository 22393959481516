import './domainInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input } from 'reactstrap'

export default class DomainInfo extends React.Component {
    render() {
        let isdisabled = true;
        var httpsRegex = new RegExp(/^((https):\/\/)?.([A-z]+)\.([A-z]{2,})/);
        if(this.props.domainName != '' ){
            if (httpsRegex.test(this.props.domainName)){
                isdisabled = false;
            }
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="ivrRoutingModal">
                    <ModalHeader>Allowed Domain</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup row>
                                <Label sm={3} for="domainName">Domain:<span className="text-danger font-weight-bold">*</span></Label>
                                <Col sm={9}>
                                    <Input invalid={this.props.domainName === '' ? true : false} value={this.props.domainName} pattern="ab?c" onChange={this.props.handleInputChange('domainName')} id="domainName" type="text" placeholder="Enter Domain (https://www.example.com) to enable it" />
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateDomainFunction} disabled={isdisabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.addDomainFunction} disabled={isdisabled}>Add</Button>
                        }

                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeDomainFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
