import './skillProficiencies.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row, Table } from 'reactstrap'

export default class SkillProficiencies extends React.Component {
    render() {

        let isSkillsButtonEnabled = false;
        if (this.props.skills !== '' && this.props.proficiencies !== '') {
            isSkillsButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="usersModal">
                    <ModalHeader>Skills Configuration</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Skills:</Label>
                                        <select className="form-control selectpicker" value={this.props.skills} onChange={this.props.handleInputChange('skills')}>
                                            <option key="S1" hidden>Select Skills</option>
                                            {
                                                this.props.skillList.map((skill) => (
                                                    <option key={skill.id}>{skill.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label for="proficiencies">Proficiencies:</Label>
                                        <select className="form-control selectpicker" value={this.props.proficiencies} onChange={this.props.handleInputChange('proficiencies')}>
                                            <option key="1" hidden>Select Proficiencies</option>
                                            <option key="2">0</option>
                                            <option key="3">1</option>
                                            <option key="4">2</option>
                                            <option key="5">3</option>
                                            <option key="6">4</option>
                                            <option key="7">5</option>
                                        </select>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSkillSaveLocale
                            ?
                            <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateSkillsFunction} disabled={!isSkillsButtonEnabled}>Update</Button>
                            :
                            <Button style={{backgroundColor:"#061e45" }} onClick={this.props.addSkillsFunction} disabled={!isSkillsButtonEnabled}>Add</Button>
                        }
                        
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeSkilsFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
