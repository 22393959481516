import { pureCloudLogout } from '../session/session'

export const organizationsGetMe = (env, token) => {
  return fetch(`https://api.${env}/api/v2/organizations/me`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      return response;
    })
}