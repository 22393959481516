import { pureCloudLogout } from '../session/session';
import config from '../../config';
const backEndURL = config.apiEndpoint;

// <roles>
export const getAuthorizationRoles = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await authorizationGetRoles(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const authorizationGetRoles = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/authorization/roles?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const authorizationGetListOfTheUsersInSpecifiedRole = (env, token, roleId, pageNumber) => {
  return fetch(`https://api.${env}/api/v2/authorization/roles/${roleId}/users?pageSize=255&pageNumber=${pageNumber}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
};

export const authorizationToAcceleratorApi = (email, org) => {
  return fetch(backEndURL + `/authentication/user/${email}/${org}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(response => {
      if (response.status < 200 || response.status > 299) {
        return response.json();
      }
      return '';
    })
}

export const authorizationPostRole = (env, token, name) => {
  return fetch(`https://api.${env}/api/v2/authorization/roles`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const authorizationDeleteRole = (env, token, id) => {
  return fetch(`https://api.${env}/api/v2/authorization/roles/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
}
// </roles>

// <divisions>
export const getAuthorizationDivisions = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await authorizationGetDivisions(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const authorizationGetDivisions = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/authorization/divisions?pageSize=255&pageNumber=${index}`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const authorizationPostDivision = (env, token, name) => {
  return fetch(`https://api.${env}/api/v2/authorization/divisions?pageSize=255&pageNumber=1`, {
    method: 'POST',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      name
    })
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const authorizationDeleteDivision = (env, token, id) => {
  return fetch(`https://api.${env}/api/v2/authorization/divisions/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then(response => response.json())
    .then(response => {
      if (response.status == 400) {
        return response.message;
      }
    })
}
// </divisions>