import './campaigns.css'
import React from 'react'
import { FaCog, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, Form, FormGroup, Label, Input, Modal, ModalBody, ModalHeader, ModalFooter, Badge } from 'reactstrap';
import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, getUserMe, getOrgMe, logActionData } from '../../../../services/session/session';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import Switch from 'react-ios-switch';
import { createCampaign, createMessagingCampaign } from '../../../../services/purecloud/outbound'
import moment from 'moment';
import ImageMapper from 'react-image-mapper';

export default class Campaigns extends React.Component {

    state = {
        env: '',
        token: '',
        userMe: {},
        orgMe: {},
        preloaderLocalShow: false,
        isInfoOpen: false,
        campaignName: '',
        previewCalls: true,
        enablePreviewTimer: false,
        previewTimerMinutes: 1,
        previewTimerSeconds: 0,
        callerIdPhone: '',
        callerIdName: '',
        callerNamerIsEmpty: true,
        callerPhoneIsEmpty: true,
        campNameIsEmpty: true,
        eventListIsOpen: false,
        eventList: [],
        isModalOpen: false,
        messagesPerMinute: 1,
        selectedContactListColumn: '',
        selectedPhoneColumn: '',
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    campaignName = '';
    eventList = [];
    logEvent = (message, isError = false) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }
    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    componentDidMount = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.loadUser();
        await this.setState({ preloaderLocalShow: false });
    }

    loadUser = async () => {
        try {
            const userMe = await getUserMe();
            const orgMe = await getOrgMe();
            await this.setState({ userMe: userMe });
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            throw error;
        }
    }

    constructRequestBody = async () => {
        try {
            let campaign = this.props.campaign;
            campaign['name'] = this.state.campaignName;
            campaign['contactList'] = { "id": this.props.contactList[0], "name": this.props.contactList[1], "selfUri": "" };
            campaign['phoneColumns'] = [{ "columnName": "PhoneNumber", "type": "Cell" }];
            if (this.props.dncLists.length > 0) {
                campaign['dncLists'] = [{ "id": this.props.dncLists[0], "name": this.props.dncLists[1], "selfUri": "" }];
            }
            campaign['queue'] = { "id": this.props.queue['id'], "name": this.props.queue['name'], "selfUri": this.props.queue['selfUri'] };
            campaign['callerName'] = this.state.callerIdName;
            campaign['callerAddress'] = this.state.callerIdPhone;
            campaign['skipPreviewDisabled'] = !this.state.previewCalls;
            campaign['timeoutEnabled'] = this.state.enablePreviewTimer;

            campaign['timeoutMinutes'] = (this.state.enablePreviewTimer) ? this.state.previewTimerMinutes : 0;
            campaign['timeoutSeconds'] = (this.state.enablePreviewTimer) ? this.state.previewTimerSeconds : 0;
            campaign['previewTimeOutSeconds'] = (this.state.enablePreviewTimer) ? (parseInt(this.state.previewTimerMinutes) * 60) + parseInt(this.state.previewTimerSeconds) : 0;


            console.log(JSON.stringify(campaign));
            let response = await createCampaign(campaign, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (response.messages.length === 0) {
                await logActionData('Import', `Importing Campaign`, 1, 'Campaign');
                this.campaignName = this.state.campaignName;
                await this.setState({ isModalOpen: true });
                await this.resetState();
            }
            else {
                response.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            this.logEvent(err.message, true);
        }
    }

    constructSmsRequestBody = async () => {
        try {
            let messagingCampaign = {};
            messagingCampaign['name'] = this.state.campaignName;
            messagingCampaign['alwaysRunning'] = false;
            messagingCampaign['callableTimeSet'] = null;
            messagingCampaign['campaignStatus'] = "off";
            messagingCampaign['contactSorts'] = [];
            messagingCampaign['messagesPerMinute'] = this.state.messagesPerMinute;
            messagingCampaign['contactListFilters'] = [];

            messagingCampaign['contactList'] = { "id": this.props.contactList[0], "name": this.props.contactList[1], "selfUri": "" };
            messagingCampaign['division'] = this.props.divisionDetails;
            if (this.props.dncLists.length > 0) {
                messagingCampaign['dncLists'] = [{ "id": this.props.dncLists[0], "name": this.props.dncLists[1], "selfUri": "" }];
            }
            messagingCampaign['smsConfig'] = { "contentTemplate": null, "messageColumn": this.state.selectedContactListColumn, "phoneColumn": this.state.selectedPhoneColumn, "senderSmsPhoneNumber": { "phoneNumber": this.props.campaign['phoneNumber'] } };
            let response = await createMessagingCampaign(messagingCampaign, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (response.messages.length === 0) {
                await logActionData('Import', `Importing Campaign`, 1, 'Campaign');
                this.campaignName = this.state.campaignName;
                await this.setState({ isModalOpen: true });
                await this.resetState();
            }
            else {
                response.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            this.logEvent(err.message, true);
        }
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    handleInputText = (text) => (event) => {
        let isEmpty = event.target.value === '' ? true : false;
        if (text === 'campaignName') {
            this.setState({
                campaignName: event.target.value,
                campNameIsEmpty: isEmpty
            })
        } else if (text === 'callerIdName') {
            this.setState({
                callerIdName: event.target.value,
                callerNamerIsEmpty: isEmpty
            })
        } else if (text === 'callerIdPhone') {
            this.setState({
                callerIdPhone: event.target.value,
                callerPhoneIsEmpty: isEmpty
            })
        }
    }

    handleOnChange = (name) => (event) => {
        if (name === 'previewCalls') {
            this.setState({
                previewCalls: event
            });
        } else if (name === 'enablePreviewTimer') {
            this.setState({
                enablePreviewTimer: event
            });
        }
    }

    handleInputChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        })
    }

    handleSubmit = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.eventList = [];
        const regex = /^\+?[0-9]+$/;
        if (this.props.isSMSCampType) {
            await this.constructSmsRequestBody();
        }
        else {
            if (regex.test(this.state.callerIdPhone)) {
                await this.constructRequestBody();
            } else {
                this.logEvent('Phone number must be numbers only or E.164 format (without alphabetic characters)', true)
            }
        }
        if (this.eventList.length > 0) {
            this.showEventList(this.eventList);
        }
        await this.setState({ preloaderLocalShow: false });
    }

    handleModalInputChange = (text) => (event) => {
        if (text === 'AddNew') {
            this.props.moveToNewCampaign(true);
        } else if (text === 'Close') {
            this.props.moveToNewCampaign(false);
        }
        this.setState({ isModalOpen: false });
    }

    resetState = async () => {
        await this.setState({
            campaignName: '',
            previewCalls: true,
            enablePreviewTimer: false,
            previewTimerMinutes: 1,
            previewTimerSeconds: 0,
            callerIdPhone: '',
            callerIdName: '',
            callerNamerIsEmpty: true,
            callerPhoneIsEmpty: true,
            campNameIsEmpty: true,
            selectedContactListColumn: '',
            selectedPhoneColumn: '',
        });
    }

    disableSubmit = async () => {
        // if (this.state.campNameIsEmpty || this.state.callerNamerIsEmpty || this.state.callerPhoneIsEmpty) {
        //     return true;
        // } else if (this.props.isSMSCampType && this.state.campNameIsEmpty) {
        //     return true;
        // } else {
        //     return false;
        // }
        return false
    }

    render() {
        let MAP = {
            name: "c-map",
            areas: []
        }

        return (
            <div className="campCard">
                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}
                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 campCardBody">
                        <CardTitle className="m-0 campTitle">
                            <div className="divCamp">
                                <Badge className="titleBadgeCamp">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    {
                                        this.props.isSMSCampType ?
                                            <ImageMapper src="/images/smsCampaign.png" map={MAP} width={200} onClick={area => this.clickedArea(area)} />
                                            :
                                            <ImageMapper src="/images/pCampNew.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                    }
                                </div>
                            </div>
                        </CardTitle>
                        <p style={{ paddingLeft: "12px", fontSize: "2.5vmin", marginLeft: "-20px" }}>Add values for the attributes associated with the newly created Campaign.</p>
                    </CardBody>
                    <CardBody className="p-3 CardBodyCamp">
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} />
                            <div className="camp-wrap">
                                <Form className="modal-body form-horizontal">
                                    <FormGroup row className="rowAttrCamp">
                                        <Label sm={6} for="campaignName">Campaign Name<span className="text-danger font-weight-bold">*</span></Label>
                                        <Col sm={6}>
                                            <Input value={this.state.campaignName} onChange={this.handleInputText('campaignName')} type="text" id="campaignName" placeholder="Enter Campaign Name" />
                                        </Col>
                                    </FormGroup>
                                    {this.props.isPreviewCampType &&
                                        <div>
                                            <FormGroup row className="rowAttrCamp">
                                                <Label sm={6} for="previewCalls">Allow Skipping of Preview Calls</Label>
                                                <Col sm={6}>
                                                    <Switch id="previewCalls" checked={this.state.previewCalls} onColor="rgb(6, 30, 69)" className="switch" style={{ transform: 'scale(0.7)' }} onChange={this.handleOnChange('previewCalls')} />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="rowAttrCamp">
                                                <Label sm={6} for="enablePreviewTimer">Enable Preview Timer</Label>
                                                <Col sm={6}>
                                                    <Switch id="enablePreviewTimer" checked={this.state.enablePreviewTimer} onColor="rgb(6, 30, 69)" className="switch" style={{ transform: 'scale(0.7)' }} onChange={this.handleOnChange('enablePreviewTimer')} />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    }
                                    {
                                        this.state.enablePreviewTimer &&
                                        <FormGroup row className="rowAttrCamp">
                                            <Label sm={6} for="previewTimer">Preview Timer</Label>
                                            <Col sm={2}>
                                                <input className="form-control" value={this.state.previewTimerMinutes} onChange={this.handleInputChange('previewTimerMinutes')} type="number" id="previewTimer" min="1" />
                                            </Col>
                                            <span sm={1} style={{ marginTop: 5, marginLeft: -5, marginRight: 5 }}>m</span>
                                            <Col sm={2}>
                                                <input className="form-control" value={this.state.previewTimerSeconds} onChange={this.handleInputChange('previewTimerSeconds')} type="number" id="previewTimer" min="0" />
                                            </Col>
                                            <span sm={1} style={{ marginTop: 5, marginLeft: -5, marginRight: 5 }}>s</span>
                                        </FormGroup>
                                    }
                                    {
                                        !this.props.isSMSCampType &&
                                        <div>
                                            <FormGroup row className="rowAttrCamp">
                                                <Label sm={6} for="callerIdPhone">CallerID Phone<span className="text-danger font-weight-bold">*</span></Label>
                                                <Col sm={6}>
                                                    <Input value={this.state.callerIdPhone} onChange={this.handleInputText('callerIdPhone')} type="text" id="callerIdPhone" placeholder="Enter Caller Number" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="rowAttrCamp">
                                                <Label sm={6} for="callerIdName">CallerID Name<span className="text-danger font-weight-bold">*</span></Label>
                                                <Col sm={6}>
                                                    <Input value={this.state.callerIdName} onChange={this.handleInputText('callerIdName')} type="text" id="callerIdName" placeholder="Enter Caller Name" />
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    }
                                    {
                                        this.props.isSMSCampType &&
                                        <div>
                                            <FormGroup row className="rowAttrCamp">
                                                <Label sm={6} for="messagesPerMinute">Messages per Minute</Label>
                                                <Col sm={3}>
                                                    <input className="form-control" value={this.state.messagesPerMinute} onChange={this.handleInputChange('messagesPerMinute')} type="number" id="messagesPerMinute" min="1" />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="rowAttr">
                                                <Col md={6}>
                                                    <Label>Phone Column:<span className="text-danger font-weight-bold">*</span></Label>
                                                </Col>
                                                <Col md={6} class="wrapper">
                                                    <select className="form-control selectpicker" onfocus='this.size=3' value={this.state.selectedPhoneColumn} onChange={this.handleInputChange('selectedPhoneColumn')}>
                                                        <option key="1" hidden>Select a Phone Column</option>
                                                        {
                                                            this.props.columnNames.map((name) => (
                                                                <option >{name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="rowAttr">
                                                <Col md={6}>
                                                    <Label>Contact List Column:<span className="text-danger font-weight-bold">*</span></Label>
                                                </Col>
                                                <Col md={6} class="wrapper">
                                                    <select className="form-control selectpicker" onfocus='this.size=3' value={this.state.selectedContactListColumn} onChange={this.handleInputChange('selectedContactListColumn')}>
                                                        <option key="1" hidden>Select a Contact List Column</option>
                                                        {
                                                            this.props.columnNames.map((name) => (
                                                                <option >{name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </Col>
                                            </FormGroup>
                                        </div>
                                    }
                                </Form>
                            </div>
                            <Row className="mt-3">
                                <Col>
                                    <div>
                                        <Button className="SubmitButtonCamp" onClick={this.handleSubmit} disabled={false}>Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
                <Modal isOpen={this.state.isModalOpen} className="BulkModal">
                    <ModalHeader>Outbound Campaign</ModalHeader>
                    <ModalBody>
                        Campaign "{this.campaignName}" imported successfully
                    </ModalBody>
                    <ModalFooter>
                        <Button className="ModalButtonCamp" onClick={this.handleModalInputChange('AddNew')} >Add Another Outbound Campaign</Button>
                        <Button className="ModalButtonCamp" onClick={this.handleModalInputChange('Close')}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
