import './schedules.css'
import React from 'react'
import { FaCalendar, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import { architectPostSchedule } from '../../../../services/purecloud/architect';
import SchedulesInfo from '../schedules/schedulesInfo';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const schedulesDataFields = [
    'name',
    'start',
    'end',
    'rrule'
];
const rRule = window.rrule

export default class Schedules extends React.Component {

    state = {
        env: '',
        token: '',
        schedulesList: [],
        isSchedulesInfoOpen: false,
        scheduleName: '',
        start: '',
        end: '',
        rrule: '',
        rowID: null,
        repeatingEvent: false,
        allDay: false,
        noEndDate: false,
        interval: '1',
        recurrenceType: '',
        yearMonthSelect: '',
        monthlyRecurrenceType: 'On',
        yearlyRecurrenceType: 'On',
        weeklyMonday: false,
        weeklyTuesday: false,
        weeklyWednesday: false,
        weeklyThursday: false,
        weeklyFriday: false,
        weeklySaturday: false,
        weeklySunday: false,
        byMonthDay: '30',
        byYearDay: '30',
        monthlyMonday: false,
        monthlyTuesday: false,
        monthlyWednesday: false,
        monthlyThursday: false,
        monthlyFriday: false,
        monthlySaturday: false,
        monthlySunday: false,
        yearlyMonday: false,
        yearlyTuesday: false,
        yearlyWednesday: false,
        yearlyThursday: false,
        yearlyFriday: false,
        yearlySaturday: false,
        yearlySunday: false,
        startDate: '',
        endDate: '',
        startTime: '09:00',
        endTime: '17:00',
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var schedulesList = [...this.state.schedulesList];
        var selectedList = this.state.selected;
        for (const i in schedulesList) {
            if (schedulesList[i].rowid === rowid) {
                schedulesList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ schedulesList: schedulesList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isSchedulesInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            scheduleName: '',
            start: '',
            end: '',
            rrule: '',
            rowID: null,
            repeatingEvent: false,
            allDay: false,
            noEndDate: false,
            interval: '1',
            recurrenceType: '',
            yearMonthSelect: '',
            weeklyMonday: false,
            weeklyTuesday: false,
            weeklyWednesday: false,
            weeklyThursday: false,
            weeklyFriday: false,
            weeklySaturday: false,
            weeklySunday: false,
            monthlyRecurrenceType: 'On',
            yearlyRecurrenceType: 'On',
            byMonthDay: '30',
            byYearDay: '30',
            monthlyMonday: false,
            monthlyTuesday: false,
            monthlyWednesday: false,
            monthlyThursday: false,
            monthlyFriday: false,
            monthlySaturday: false,
            monthlySunday: false,
            yearlyMonday: false,
            yearlyTuesday: false,
            yearlyWednesday: false,
            yearlyThursday: false,
            yearlyFriday: false,
            yearlySaturday: false,
            yearlySunday: false,
            startDate: '',
            endDate: '',
            startTime: '09:00',
            endTime: '17:00',
        })
    }

    getMonthValues = async (month) => {
        let value = "";
        switch (month) {
            case 'January':
                value = '1';
                break;
            case 'February':
                value = '2';
                break;
            case 'March':
                value = '3';
                break;
            case 'April':
                value = '4';
                break;
            case 'May':
                value = '5';
                break;
            case 'June':
                value = '6';
                break;
            case 'July':
                value = '7';
                break;
            case 'August':
                value = '8';
                break;
            case 'September':
                value = '9';
                break;
            case 'October':
                value = '10';
                break;
            case 'November':
                value = '11';
                break;
            case 'December':
                value = '12';
                break;
            default:
                break;
        }
        return value;
    }

    getMonths = async (item) => {
        let value = "";
        switch (item) {
            case '1':
                value = 'January';
                break;
            case '2':
                value = 'February';
                break;
            case '3':
                value = 'March';
                break;
            case '4':
                value = 'April';
                break;
            case '5':
                value = 'May';
                break;
            case '6':
                value = 'June';
                break;
            case '7':
                value = 'July';
                break;
            case '8':
                value = 'August';
                break;
            case '9':
                value = 'September';
                break;
            case '10':
                value = 'October';
                break;
            case '11':
                value = 'November';
                break;
            case '12':
                value = 'December';
                break;
            default:
                break;
        }
        return value;
    }

    generateDateTime = async () => {
        let dateTime = { start: '', end: '' };
        let startDateTime = '';
        let endDateTime = '';
        if (this.state.allDay) {
            startDateTime = this.state.startDate + 'T' + '00:00';
            if (this.state.noEndDate) {
                endDateTime = this.state.startDate + 'T' + '23:59';
            } else {
                endDateTime = this.state.endDate + 'T' + '23:59';
            }
        } else {
            startDateTime = this.state.startDate + 'T' + this.state.startTime;
            if (this.state.noEndDate) {
                endDateTime = this.state.startDate + 'T' + this.state.endTime;
            } else {
                endDateTime = this.state.endDate + 'T' + this.state.endTime;
            }
        }
        dateTime.start = startDateTime;
        dateTime.end = endDateTime;
        return dateTime;
    }

    validateRule = async (newRRule) => {
        if (this.state.noEndDate || this.state.allDay) {
            newRRule.origOptions.until = null;
            return newRRule;
        }
        return newRRule;
    }

    generaterRule = async (dateTime) => {
        let recurrenceType = this.state.recurrenceType;
        let freq;
        let interval;
        if (this.state.repeatingEvent) {
            switch (recurrenceType) {
                case "DAILY":
                    freq = rRule.RRule.DAILY;
                    break;
                case "WEEKLY":
                    freq = rRule.RRule.WEEKLY;
                    break;
                case "MONTHLY":
                    freq = rRule.RRule.MONTHLY;
                    break;
                case "YEARLY":
                    freq = rRule.RRule.YEARLY;
                    break;
                default:
                    console.error('Unsupported recurrence:', recurrenceType);
                    break;
            }
            interval = this.state.interval
        }

        let byWeekDay = [];
        if (recurrenceType === "WEEKLY") {
            if (this.state.weeklyMonday) { byWeekDay.push(rRule.RRule.MO); }
            if (this.state.weeklyTuesday) { byWeekDay.push(rRule.RRule.TU); }
            if (this.state.weeklyWednesday) { byWeekDay.push(rRule.RRule.WE); }
            if (this.state.weeklyThursday) { byWeekDay.push(rRule.RRule.TH); }
            if (this.state.weeklyFriday) { byWeekDay.push(rRule.RRule.FR); }
            if (this.state.weeklySaturday) { byWeekDay.push(rRule.RRule.SA); }
            if (this.state.weeklySunday) { byWeekDay.push(rRule.RRule.SU); }
        }

        let byMonthDay = [], bySetPos;
        if (recurrenceType === "MONTHLY") {
            let monthlyRecurrenceType = this.state.monthlyRecurrenceType;
            if (monthlyRecurrenceType === "On") {
                byMonthDay.push(this.state.byMonthDay);
            } else {
                switch (monthlyRecurrenceType) {
                    case "If Day is":
                        // Nothing to do here
                        break;
                    case "First":
                        bySetPos = [1]
                        break;
                    case "Second":
                        bySetPos = [2];
                        break;
                    case "Third":
                        bySetPos = [3];
                        break;
                    case "Fourth":
                        bySetPos = [4];
                        break;
                    case "Last":
                        bySetPos = [-1];
                        break;
                    default:
                        console.error("Unsupported monthly recurrence type:", monthlyRecurrenceType);
                        break;
                }
                if (this.state.monthlyMonday) { byWeekDay.push(rRule.RRule.MO); }
                if (this.state.monthlyTuesday) { byWeekDay.push(rRule.RRule.TU); }
                if (this.state.monthlyWednesday) { byWeekDay.push(rRule.RRule.WE); }
                if (this.state.monthlyThursday) { byWeekDay.push(rRule.RRule.TH); }
                if (this.state.monthlyFriday) { byWeekDay.push(rRule.RRule.FR); }
                if (this.state.monthlySaturday) { byWeekDay.push(rRule.RRule.SA); }
                if (this.state.monthlySunday) { byWeekDay.push(rRule.RRule.SU); }
            }
        }

        let byMonth = [];
        if (recurrenceType === "YEARLY") {
            // Got a month?

            let yearMonthSelect = await this.getMonthValues(this.state.yearMonthSelect);
            if (yearMonthSelect.length > 0) {
                byMonth.push(parseInt(yearMonthSelect));
            }

            // Recurrence
            let yearlyRecurrenceType = this.state.yearlyRecurrenceType;
            if (yearlyRecurrenceType === "On") {
                byMonthDay.push(this.state.byYearDay);
            } else {
                switch (yearlyRecurrenceType) {
                    case "If Day is":
                        // Nothing to do here
                        break;
                    case "First":
                        bySetPos = [1]
                        break;
                    case "Second":
                        bySetPos = [2];
                        break;
                    case "Third":
                        bySetPos = [3];
                        break;
                    case "Fourth":
                        bySetPos = [4];
                        break;
                    case "Last":
                        bySetPos = [-1];
                        break;
                    default:
                        console.error("Unsupported monthly recurrence type:", yearlyRecurrenceType);
                        break;
                }
                if (this.state.yearlyMonday) { byWeekDay.push(rRule.RRule.MO); }
                if (this.state.yearlyTuesday) { byWeekDay.push(rRule.RRule.TU); }
                if (this.state.yearlyWednesday) { byWeekDay.push(rRule.RRule.WE); }
                if (this.state.yearlyThursday) { byWeekDay.push(rRule.RRule.TH); }
                if (this.state.yearlyFriday) { byWeekDay.push(rRule.RRule.FR); }
                if (this.state.yearlySaturday) { byWeekDay.push(rRule.RRule.SA); }
                if (this.state.yearlySunday) { byWeekDay.push(rRule.RRule.SU); }
            }
        }

        let rule = new rRule.RRule({
            freq: freq,
            interval: interval,
            until: dateTime.end,
            byweekday: byWeekDay,
            bymonthday: byMonthDay,
            bysetpos: bySetPos,
            bymonth: byMonth
        });

        return rule;
    }

    handleSubmitFunction = async () => {
        let finalRule;
        let schedulesList = [...this.state.schedulesList]
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Generating rrule' });
        const dateTime = await this.generateDateTime();
        if (this.state.rrule != null && this.state.rrule != '' && this.state.rrule.trim() != '') {
            let rule = await this.generaterRule(dateTime);
            let valnewRRule = await this.validateRule(rule);
            let rrule = valnewRRule.toString();
            finalRule = rrule.slice(6, rrule.length);
        } else {
            finalRule = this.state.rrule;
        }

        await this.setState({ preloaderLocalShow: false, preloaderMessage: 'Loading' });
        var newRow = { 'rowid': uuidv1() };
        for (const i in schedulesDataFields) {
            if (schedulesDataFields[i] === 'name') {
                newRow[schedulesDataFields[i]] = this.state.scheduleName;
            } else if (schedulesDataFields[i] === 'start') {
                newRow[schedulesDataFields[i]] = dateTime.start;
            } else if (schedulesDataFields[i] === 'end') {
                newRow[schedulesDataFields[i]] = dateTime.end;
            } else if (schedulesDataFields[i] === 'rrule') {
                newRow[schedulesDataFields[i]] = finalRule;
            }
        }

        schedulesList.push(newRow);

        this.setState({
            isSchedulesInfoOpen: false,
            schedulesList: schedulesList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'repeatingEvent') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked,
                interval: '1',
                recurrenceType: '',
                yearMonthSelect: '',
                monthlyRecurrenceType: 'On',
                yearlyRecurrenceType: 'On',
                weeklyMonday: false,
                weeklyTuesday: false,
                weeklyWednesday: false,
                weeklyThursday: false,
                weeklyFriday: false,
                weeklySaturday: false,
                weeklySunday: false,
                byMonthDay: '30',
                byYearDay: '30',
                monthlyMonday: false,
                monthlyTuesday: false,
                monthlyWednesday: false,
                monthlyThursday: false,
                monthlyFriday: false,
                monthlySaturday: false,
                monthlySunday: false,
                yearlyMonday: false,
                yearlyTuesday: false,
                yearlyWednesday: false,
                yearlyThursday: false,
                yearlyFriday: false,
                yearlySaturday: false,
                yearlySunday: false,
            });
        } else if (fieldName === 'weeklyMonday' || fieldName === 'weeklyTuesday' || fieldName === 'weeklyWednesday' || fieldName === 'weeklyThursday' || fieldName === 'weeklyFriday' || fieldName === 'weeklySaturday' || fieldName === 'weeklySunday') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked
            });
        } else if (fieldName === 'monthlyMonday' || fieldName === 'monthlyTuesday' || fieldName === 'monthlyWednesday' || fieldName === 'monthlyThursday' || fieldName === 'monthlyFriday' || fieldName === 'monthlySaturday' || fieldName === 'monthlySunday') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked
            });
        } else if (fieldName === 'yearlyMonday' || fieldName === 'yearlyTuesday' || fieldName === 'yearlyWednesday' || fieldName === 'yearlyThursday' || fieldName === 'yearlyFriday' || fieldName === 'yearlySaturday' || fieldName === 'yearlySunday') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked
            });
        } else if (fieldName === 'allDay') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked,
                startTime: '09:00',
                endTime: '17:00',
            });
        } else if (fieldName === 'noEndDate') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked,
                endDate: '',
            });
        } else if (fieldName === 'interval') {
            const intervalEle = event.target.value;
            if (intervalEle < 0) {
                this.setState({
                    [fieldName]: '1'
                });
            } else {
                this.setState({
                    [fieldName]: intervalEle
                });
            }

        } else {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    }

    updateDateTime = async (rowInfo) => {
        let dateTime = { startDate: '', endDate: '', startTime: '', endTime: '', allDay: false };
        dateTime.startDate = rowInfo.start.slice(0, rowInfo.start.indexOf('T'));
        dateTime.startTime = rowInfo.start.slice(rowInfo.start.indexOf('T') + 1, rowInfo.start.length);
        dateTime.endDate = rowInfo.end.slice(0, rowInfo.end.indexOf('T'));
        dateTime.endTime = rowInfo.end.slice(rowInfo.end.indexOf('T') + 1, rowInfo.end.length);

        if (dateTime.startTime === '00:00' && dateTime.endTime === '23:59') {
            dateTime.allDay = true;
        }
        return dateTime;
    }

    decoderRule = async (rowInfo) => {
        let decoder = {
            repeatingEvent: false,
            noEndDate: false,
            interval: '1',
            recurrenceType: '',
            yearMonthSelect: '',
            monthlyRecurrenceType: 'On',
            yearlyRecurrenceType: 'On',
            byMonthDay: '30',
            byYearDay: '30',
            weeklyMonday: false,
            weeklyTuesday: false,
            weeklyWednesday: false,
            weeklyThursday: false,
            weeklyFriday: false,
            weeklySaturday: false,
            weeklySunday: false,
            monthlyMonday: false,
            monthlyTuesday: false,
            monthlyWednesday: false,
            monthlyThursday: false,
            monthlyFriday: false,
            monthlySaturday: false,
            monthlySunday: false,
            yearlyMonday: false,
            yearlyTuesday: false,
            yearlyWednesday: false,
            yearlyThursday: false,
            yearlyFriday: false,
            yearlySaturday: false,
            yearlySunday: false,
        }

        if (rowInfo.rrule === '') {
            return decoder;
        } else {
            const rules = rowInfo.rrule.split(';');
            if (!rowInfo.rrule.includes('UNTIL')) {
                decoder.noEndDate = true;
            }

            //Added Logic for fix of ACC-1458, where noEndDate should be false for one data where start and end has different dates
            if (rowInfo.end != null && rowInfo.end != '' && (rowInfo.end.slice(8, 10) != rowInfo.start.slice(8, 10))) {
                decoder.noEndDate = false;
            }

            for (const rule of rules) {
                if (rule.includes('FREQ')) {
                    decoder.repeatingEvent = true;
                    decoder.recurrenceType = rule.slice(rule.indexOf('=') + 1, rule.length);
                } else if (rule.includes('INTERVAL')) {
                    decoder.interval = rule.slice(rule.indexOf('=') + 1, rule.length);
                } else if (rule.includes('BYDAY')) {
                    if (decoder.recurrenceType === 'WEEKLY') {
                        if (rule.includes('MO')) {
                            decoder.weeklyMonday = true;
                        }
                        if (rule.includes('TU')) {
                            decoder.weeklyTuesday = true;
                        }
                        if (rule.includes('WE')) {
                            decoder.weeklyWednesday = true;
                        }
                        if (rule.includes('TH')) {
                            decoder.weeklyThursday = true;
                        }
                        if (rule.includes('FR')) {
                            decoder.weeklyFriday = true;
                        }
                        if (rule.includes('SA')) {
                            decoder.weeklySaturday = true;
                        }
                        if (rule.includes('SU')) {
                            decoder.weeklySunday = true;
                        }
                    } else if (decoder.recurrenceType === 'MONTHLY') {
                        if (rule.includes('MO')) {
                            decoder.monthlyMonday = true;
                        }
                        if (rule.includes('TU')) {
                            decoder.monthlyTuesday = true;
                        }
                        if (rule.includes('WE')) {
                            decoder.monthlyWednesday = true;
                        }
                        if (rule.includes('TH')) {
                            decoder.monthlyThursday = true;
                        }
                        if (rule.includes('FR')) {
                            decoder.monthlyFriday = true;
                        }
                        if (rule.includes('SA')) {
                            decoder.monthlySaturday = true;
                        }
                        if (rule.includes('SU')) {
                            decoder.monthlySunday = true;
                        }
                        if (!rowInfo.rrule.includes('BYSETPOS')) {
                            if (!rowInfo.rrule.includes('BYMONTHDAY')) {
                                decoder.monthlyRecurrenceType = 'If Day is';
                            }
                        }
                    } else if (decoder.recurrenceType === 'YEARLY') {
                        if (rule.includes('MO')) {
                            decoder.yearlyMonday = true;
                        }
                        if (rule.includes('TU')) {
                            decoder.yearlyTuesday = true;
                        }
                        if (rule.includes('WE')) {
                            decoder.yearlyWednesday = true;
                        }
                        if (rule.includes('TH')) {
                            decoder.yearlyThursday = true;
                        }
                        if (rule.includes('FR')) {
                            decoder.yearlyFriday = true;
                        }
                        if (rule.includes('SA')) {
                            decoder.yearlySaturday = true;
                        }
                        if (rule.includes('SU')) {
                            decoder.yearlySunday = true;
                        }
                        if (!rowInfo.rrule.includes('BYSETPOS')) {
                            if (!rowInfo.rrule.includes('BYMONTHDAY')) {
                                decoder.yearlyRecurrenceType = 'If Day is';
                            }
                        }
                    }
                } else if (rule.includes('BYMONTHDAY')) {
                    if (decoder.recurrenceType === 'MONTHLY') {
                        decoder.byMonthDay = rule.slice(rule.indexOf('=') + 1, rule.length);
                        decoder.monthlyRecurrenceType = 'On';
                    } else if (decoder.recurrenceType === 'YEARLY') {
                        decoder.yearlyRecurrenceType = 'On';
                        decoder.byYearDay = rule.slice(rule.indexOf('=') + 1, rule.length);
                    }
                } else if (rule.includes('BYMONTH')) {
                    const bymonthValue = rule.slice(rule.indexOf('=') + 1, rule.length);
                    decoder.yearMonthSelect = await this.getMonths(bymonthValue);
                } else if (rule.includes('BYSETPOS')) {
                    const bysetPosValue = parseInt(rule.slice(rule.indexOf('=') + 1, rule.length));
                    if (decoder.recurrenceType === 'MONTHLY') {
                        if (bysetPosValue === 1) {
                            decoder.monthlyRecurrenceType = 'First';
                        } else if (bysetPosValue === 2) {
                            decoder.monthlyRecurrenceType = 'Second';
                        } else if (bysetPosValue === 3) {
                            decoder.monthlyRecurrenceType = 'Third';
                        } else if (bysetPosValue === 4) {
                            decoder.monthlyRecurrenceType = 'Fourth';
                        } else if (bysetPosValue === -1) {
                            decoder.monthlyRecurrenceType = 'Last';
                        }
                    } else if (decoder.recurrenceType === 'YEARLY') {
                        if (bysetPosValue === 1) {
                            decoder.yearlyRecurrenceType = 'First';
                        } else if (bysetPosValue === 2) {
                            decoder.yearlyRecurrenceType = 'Second';
                        } else if (bysetPosValue === 3) {
                            decoder.yearlyRecurrenceType = 'Third';
                        } else if (bysetPosValue === 4) {
                            decoder.yearlyRecurrenceType = 'Fourth';
                        } else if (bysetPosValue === -1) {
                            decoder.yearlyRecurrenceType = 'Last';
                        }
                    }
                }
            }
        }

        return decoder;
    }

    updateState = async (rowInfo) => {
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Loading Current State' });
        const dateTime = await this.updateDateTime(rowInfo);
        const decodedRule = await this.decoderRule(rowInfo);
        await this.setState({ preloaderLocalShow: false, preloaderMessage: 'Loading' });
        this.setState({
            scheduleName: rowInfo.name,
            start: rowInfo.start,
            end: rowInfo.end,
            rrule: rowInfo.rrule,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isSchedulesInfoOpen: true,
            repeatingEvent: decodedRule.repeatingEvent,
            allDay: dateTime.allDay,
            noEndDate: decodedRule.noEndDate,
            interval: decodedRule.interval,
            recurrenceType: decodedRule.recurrenceType,
            yearMonthSelect: decodedRule.yearMonthSelect,
            monthlyRecurrenceType: decodedRule.monthlyRecurrenceType,
            yearlyRecurrenceType: decodedRule.yearlyRecurrenceType,
            weeklyMonday: decodedRule.weeklyMonday,
            weeklyTuesday: decodedRule.weeklyTuesday,
            weeklyWednesday: decodedRule.weeklyWednesday,
            weeklyThursday: decodedRule.weeklyThursday,
            weeklyFriday: decodedRule.weeklyFriday,
            weeklySaturday: decodedRule.weeklySaturday,
            weeklySunday: decodedRule.weeklySunday,
            byMonthDay: decodedRule.byMonthDay,
            byYearDay: decodedRule.byYearDay,
            monthlyMonday: decodedRule.monthlyMonday,
            monthlyTuesday: decodedRule.monthlyTuesday,
            monthlyWednesday: decodedRule.monthlyWednesday,
            monthlyThursday: decodedRule.monthlyThursday,
            monthlyFriday: decodedRule.monthlyFriday,
            monthlySaturday: decodedRule.monthlySaturday,
            monthlySunday: decodedRule.monthlySunday,
            yearlyMonday: decodedRule.yearlyMonday,
            yearlyTuesday: decodedRule.yearlyTuesday,
            yearlyWednesday: decodedRule.yearlyWednesday,
            yearlyThursday: decodedRule.yearlyThursday,
            yearlyFriday: decodedRule.yearlyFriday,
            yearlySaturday: decodedRule.yearlySaturday,
            yearlySunday: decodedRule.yearlySunday,
            startDate: dateTime.startDate,
            endDate: dateTime.endDate,
            startTime: dateTime.startTime,
            endTime: dateTime.endTime,
        });
    }

    updateFunction = async () => {
        let finalRule;
        let schedulesList = [...this.state.schedulesList];
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Updating rrule' });
        const dateTime = await this.generateDateTime();
        if (this.state.rrule != null && this.state.rrule != '' && this.state.rrule.trim() != '') {
            let rule = await this.generaterRule(dateTime);
            let valnewRRule = await this.validateRule(rule);
            let rrule = valnewRRule.toString();
            finalRule = rrule.slice(6, rrule.length);
        } else {
            finalRule = this.state.rrule;
        }
        await this.setState({ preloaderLocalShow: false, preloaderMessage: 'Loading' });
        for (let did of schedulesList) {
            if (did.rowid === this.state.rowID) {
                did.name = this.state.scheduleName;
                did.start = dateTime.start;
                did.end = dateTime.end;
                did.rrule = finalRule;
            }
        }

        this.setState({
            schedulesList: schedulesList,
            isSchedulesInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const schedulesData = await CsvReader.parseCsvFileToSchedulesData(fileContent);
        await this.setState({ schedulesList: [...this.state.schedulesList, ...schedulesData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importSchedules = async () => {
        this.eventList = [];
        const schedulesList = this.state.schedulesList;
        for (const i in schedulesList) {
            const scheduleData = schedulesList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing schedules [${scheduleData.name}]`);
            if (await this.dataValidated(scheduleData, i, schedulesList)) {
                try {
                    // <create Schedule>          
                    const schedule = await architectPostSchedule(this.state.env, this.state.token, scheduleData.name, scheduleData.start, scheduleData.end, scheduleData.rrule);
                    console.log(`Schedule created: ${JSON.stringify(schedule)}`);
                    // </create Schedule>
                    this.logEvent(`Schedule [${scheduleData.name}] successfully processed`);
                    this.removeRowClicked(scheduleData.rowid);
                } catch (err) {
                    console.log(`${err.message}`);
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Schedules`, count, 'Schedules');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    dataValidated = async (schedule, i, schedulesData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!schedule.name || !schedule.name.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!schedule.start || !schedule.start.trim()) { this.logEvent(`[Index ${i}] Start can't be empty.`, true); isValidated = false; }
        if (!schedule.end || !schedule.end.trim()) { this.logEvent(`[Index ${i}] Endcan't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if name is unique>
        for (const j in schedulesData) {
            const schedule1 = schedulesData[j];
            if (i === j) { continue; }
            if (schedule.name.trim().toLowerCase() === schedule1.name.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if name is unique>
        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.schedulesList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderMessage: "Deleting Selected IVR Routings...." });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '2') {
                this.props.navigateToHorizontalSlide('2');
            } else if (area.name === '1') {
                this.props.navigateToHorizontalSlide('1');
            } else if (area.name === '3') {
                this.props.navigateToHorizontalSlide('3');
            }
        }
    }

    render() {
        let MAP = {
            name: "sch-map",
            areas: [
                // { name: "0", shape: "circle", coords: [28, 13, 12] },
                { name: "1", shape: "circle", coords: [83, 13, 12] },
                { name: "2", shape: "circle", coords: [140, 13, 12] },
                { name: "3", shape: "circle", coords: [198, 13, 12] }
            ]
        }
        return (
            <div className="schedulesCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <SchedulesInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isSchedulesInfoOpen}
                    scheduleName={this.state.scheduleName}
                    start={this.state.start}
                    end={this.state.end}
                    rrule={this.state.rrule}
                    isSaveLocale={this.state.isSaveLocale}
                    repeatingEvent={this.state.repeatingEvent}
                    allDay={this.state.allDay}
                    noEndDate={this.state.noEndDate}
                    interval={this.state.interval}
                    recurrenceType={this.state.recurrenceType}
                    yearMonthSelect={this.state.yearMonthSelect}
                    weeklyMonday={this.state.weeklyMonday}
                    weeklyTuesday={this.state.weeklyTuesday}
                    weeklyWednesday={this.state.weeklyWednesday}
                    weeklyThursday={this.state.weeklyThursday}
                    weeklyFriday={this.state.weeklyFriday}
                    weeklySaturday={this.state.weeklySaturday}
                    weeklySunday={this.state.weeklySunday}
                    monthlyMonday={this.state.monthlyMonday}
                    monthlyTuesday={this.state.monthlyTuesday}
                    monthlyWednesday={this.state.monthlyWednesday}
                    monthlyThursday={this.state.monthlyThursday}
                    monthlyFriday={this.state.monthlyFriday}
                    monthlySaturday={this.state.monthlySaturday}
                    monthlySunday={this.state.monthlySunday}
                    yearlyMonday={this.state.yearlyMonday}
                    yearlyTuesday={this.state.yearlyTuesday}
                    yearlyWednesday={this.state.yearlyWednesday}
                    yearlyThursday={this.state.yearlyThursday}
                    yearlyFriday={this.state.yearlyFriday}
                    yearlySaturday={this.state.yearlySaturday}
                    yearlySunday={this.state.yearlySunday}
                    monthlyRecurrenceType={this.state.monthlyRecurrenceType}
                    yearlyRecurrenceType={this.state.yearlyRecurrenceType}
                    byMonthDay={this.state.byMonthDay}
                    byYearDay={this.state.byYearDay}
                    startDate={this.state.startDate}
                    startTime={this.state.startTime}
                    endTime={this.state.endTime}
                    endDate={this.state.endDate}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isSchedulesInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 schedulesCardBody">
                        <CardTitle className="m-0 schedulesTitle">
                            <div className="divSch">
                                <Badge className="titleBadgeSchedules">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pSch.png" map={MAP} width={225} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/schedules-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodySchedules">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonSchedules" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonSchedules">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderMessage} />
                            <div className="schedules-wrap">
                                <ReactTable
                                    data={this.state.schedulesList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.schedulesList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Schedule Name",
                                            accessor: "name",
                                            Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer",paddingLeft:"0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: "Rrule",
                                            accessor: "rrule",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer",paddingLeft:"0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Schedules loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonSchedules" onClick={this.importSchedules} disabled={this.state.schedulesList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
