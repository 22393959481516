import './telephony.css'
import React from 'react'
import { FaThList, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Badge, Input } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../global/localStorage';
import { getToken, logActionData } from '../../../services/session/session';
import TelephonyInfo from '../telephony/telephonyInfo';
import DragAndDrop from '../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../services/misc/csvReader';
import PreloaderLocal from '../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../Misc/informationMessage/informationMessage';
import { telephonyPostDidPools } from '../../../services/purecloud/telephony'
import BulkDelete from '../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const didDataFields = [
    'startPhoneNumber',
    'endPhoneNumber',
    'provider',
    'comments'
];

export default class Telephony extends React.Component {

    state = {
        env: '',
        token: '',
        telephonyList: [],
        isTelephonyInfoOpen: false,
        didStart: '',
        didEnd: '',
        provider: '',
        comments: '',
        rowID: null,
        isSaveLocale: false,
        preloaderLocalShow: false,
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var telephonyList = [...this.state.telephonyList];
        var selectedList = this.state.selected;
        for (const i in telephonyList) {
            if (telephonyList[i].rowid === rowid) {
                telephonyList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined){
            await this.setState({selectAll: 0})
        }
        await this.setState({ telephonyList: telephonyList, selected : selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.setState({
            isTelephonyInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            didStart: '',
            didEnd: '',
            provider: '',
            comments: '',
            rowID: null,
        })
    }

    handleSubmitFunction = () => {
        let telephonyList = [...this.state.telephonyList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in didDataFields) {
            if (didDataFields[i] === 'startPhoneNumber') {
                newRow[didDataFields[i]] = this.state.didStart;
            } else if (didDataFields[i] === 'endPhoneNumber') {
                newRow[didDataFields[i]] = this.state.didEnd;
            } else if (didDataFields[i] === 'provider') {
                newRow[didDataFields[i]] = this.state.provider;
            } else if (didDataFields[i] === 'comments') {
                newRow[didDataFields[i]] = this.state.comments;
            }
        }

        telephonyList.push(newRow);

        this.setState({
            isTelephonyInfoOpen: false,
            telephonyList: telephonyList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        this.setState({
            [fieldName]: event.target.value
        });
    }

    updateState = (rowInfo) => {
        this.setState({
            didStart: rowInfo.startPhoneNumber,
            didEnd: rowInfo.endPhoneNumber,
            provider: rowInfo.provider,
            comments: rowInfo.comments,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isTelephonyInfoOpen: true,
        });
    }

    updateFunction = () => {
        let telephonyList = [...this.state.telephonyList];
        for (let did of telephonyList) {
            if (did.rowid === this.state.rowID) {
                did.startPhoneNumber = this.state.didStart;
                did.endPhoneNumber = this.state.didEnd;
                did.provider = this.state.provider;
                did.comments = this.state.comments;
            }
        }

        this.setState({
            telephonyList: telephonyList,
            isTelephonyInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const didData = await CsvReader.parseCsvFileToDidsData(fileContent);
        await this.setState({ telephonyList: [...this.state.telephonyList, ...didData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importTelephony = async () => {
        this.eventList = [];
        const telephonyList = this.state.telephonyList;
        for (const i in telephonyList) {
            const didData = telephonyList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing DID [${didData.startPhoneNumber}] [${didData.endPhoneNumber}]`);
            if (await this.dataValidated(didData, i)) {
                try {
                    await telephonyPostDidPools(this.state.env, this.state.token, didData.startPhoneNumber, didData.endPhoneNumber, didData.provider, didData.comments);
                    this.logEvent(`DID [${didData.startPhoneNumber}] [${didData.endPhoneNumber}] successfully processed`);
                    this.removeRowClicked(didData.rowid);
                } catch (err) {
                    console.log(`${err.message}`);
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing Dids`, count, 'Dids');
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    dataValidated = async (did, i) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!did.startPhoneNumber || !did.startPhoneNumber.trim()) { this.logEvent(`[Index ${i}] Start phone number can't be empty.`, true); isValidated = false; }
        if (!did.endPhoneNumber || !did.endPhoneNumber.trim()) { this.logEvent(`[Index ${i}] End phone number can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>
        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.telephonyList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected DiD's...." });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '4') {
                this.props.navigateToHorizontalSlide('4');
            } else if (area.name === '1') {
                this.props.navigateToHorizontalSlide('1');
            } else if (area.name === '2') {
                this.props.navigateToHorizontalSlide('2');
            } else if (area.name === '3') {
                this.props.navigateToHorizontalSlide('3');
            }
        }
    }

    render() {
        let MAP = {
            name: "tel-map",
            areas: [
                // { name: "0", shape: "circle", coords: [23, 13, 12] },
                { name: "1", shape: "circle", coords: [85.5, 13, 12] },
                { name: "2", shape: "circle", coords: [148, 13, 12] },
                { name: "3", shape: "circle", coords: [210, 13, 12] },
                { name: "4", shape: "circle", coords: [273, 13, 12] }
            ]
        }
        return (
            <div className="didCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <TelephonyInfo
                    isOpen={this.state.isTelephonyInfoOpen}
                    didStart={this.state.didStart}
                    didEnd={this.state.didEnd}
                    provider={this.state.provider}
                    comments={this.state.comments}
                    isSaveLocale={this.state.isSaveLocale}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isTelephonyInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 telephonyCardBody">
                        <CardTitle className="m-0 telephonyTitle">
                            <div className="divTelephony">
                                <Badge className="titleBadgeTelephony">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pDid.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/dids-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyTelephony">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonTelephony" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonTelephony">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage}/>
                            <div className="telephony-wrap">
                                <ReactTable
                                    data={this.state.telephonyList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.telephonyList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "DID Start Range",
                                            accessor: "startPhoneNumber",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: "DID End Range",
                                            accessor: "endPhoneNumber",
                                            Cell: row => (<span style={{ width: "400px", height: "25px", display: "block", cursor: "pointer" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{ width: "250px", height: "25px", display: "block", cursor: "pointer",paddingRight:"50px"
                                            ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px" }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No DIDs loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonTelephony" onClick={this.importTelephony} disabled={this.state.telephonyList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
