import './peopleUsers.css'
import React from 'react'

import { FaLayerGroup, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import UsersInfo from '../peopleUsers/usersInfo';
import SkillProficiencies from '../peopleUsers/skillProficiencies';
import DivisionsInfo from '../peopleUsers/divisionsInfo';
import { usersPostUser, usersPutUserRoles } from '../../../../services/purecloud/users'
import { getlocations } from "../../../../services/purecloud/locations";
import { getRoutingGetSkills, getRoutingGetQueues, routingPostQueuesUsers, routingPatchRoutingSkillsBulk, postRoleDivisionGrants } from '../../../../services/purecloud/routing'
import { getAuthorizationRoles, getAuthorizationDivisions } from '../../../../services/purecloud/authorization'
import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData, getUserMe, getOrgMe } from '../../../../services/session/session';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import Misc from '../../../../services/misc/misc';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import ImageMapper from 'react-image-mapper';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'

const uuidv1 = require('uuid/v1');
const userDataFields = [
    'name',
    'email',
    'password',
    'phone_work',
    'title',
    'department',
    'email_manager',
    'location_work',
    'roles',
    'queues',
    'division',
    'skills',
    'proficiencies',
    'skillProf',
    'rolesDivisions'
];

export default class PeopleUsers extends React.Component {

    state = {
        env: '',
        token: '',
        userMe: {},
        orgMe: {},
        rolesDivisions: {},
        isBulkDeleteModalOpen: false,
        isRoleConfigOpen: false,
        divisionArray: [],
        selectedRole: '',
        usersList: [],
        isUsersInfoOpen: false,
        isskillProfOpen: false,
        name: '',
        email: '',
        password: '',
        phone_work: '',
        title: '',
        department: '',
        email_manager: '',
        location_work: '',
        roles: [],
        queues: [],
        division: '',
        locationList: [],
        roleList: [],
        queueList: [],
        divisionList: [],
        skillList: [],
        skillProf: {},
        skills: '',
        proficiencies: '',
        lastUsedSkill: '',
        rowID: null,
        isSaveLocale: false,
        isSkillSaveLocale: false,
        preloaderLocalShow: false,
        preloaderLocalMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false,
        isRolesSaveLocale: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount = async () => {
        await this.setState({ preloaderLocalShow: true });
        await this.loadUser();
        await this.loadItems();
        await this.setState({ preloaderLocalShow: false });
    }

    loadUser = async () => {
        try {
            const userMe = await getUserMe();
            const orgMe = await getOrgMe();
            await this.setState({ userMe: userMe });
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            throw error;
        }
    }

    loadItems = async () => {
        this.state.locationList = await getlocations(this.state.env, this.state.token);
        this.state.roleList = await getAuthorizationRoles(this.state.env, this.state.token);
        this.state.queueList = await getRoutingGetQueues(this.state.env, this.state.token);
        this.state.divisionList = await getAuthorizationDivisions(this.state.env, this.state.token);
        this.state.skillList = await getRoutingGetSkills(this.state.env, this.state.token);
    }

    updateQueues = async () => {
        this.state.queueList = await getRoutingGetQueues(this.state.env, this.state.token);
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var usersList = [...this.state.usersList];
        var selectedList = this.state.selected;
        for (const i in usersList) {
            if (usersList[i].rowid === rowid) {
                usersList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ usersList: usersList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    deleteSkillItemButtonClicked = async (skill, e) => {
        if (e) {
            e.stopPropagation();
        }
        let result = this.state.skillProf;
        delete result[skill];
        this.setState({
            skillProf: result
        })
    }

    deleteRolesItemButtonClicked = async (role, e) => {
        if (e) {
            e.stopPropagation();
        }
        let result = this.state.rolesDivisions;
        delete result[role];
        this.setState({
            rolesDivisions: result
        })
    }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.updateQueues();
        this.setState({
            isUsersInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            name: '',
            email: '',
            password: '',
            phone_work: '',
            title: '',
            department: '',
            email_manager: '',
            location_work: '',
            roles: [],
            queues: [],
            division: '',
            rowID: null,
            skillProf: {},
            rolesDivisions: {},
            skills: '',
            proficiencies: '',
            lastUsedSkill: '',
        })
    }

    handleSubmitFunction = () => {
        let usersList = [...this.state.usersList]
        var newRow = { 'rowid': uuidv1() };
        for (const i in userDataFields) {
            if (userDataFields[i] === 'name') {
                newRow[userDataFields[i]] = this.state.name;
            } else if (userDataFields[i] === 'email') {
                newRow[userDataFields[i]] = this.state.email;
            } else if (userDataFields[i] === 'password') {
                newRow[userDataFields[i]] = this.state.password;
            } else if (userDataFields[i] === 'phone_work') {
                newRow[userDataFields[i]] = this.state.phone_work;
            } else if (userDataFields[i] === 'title') {
                newRow[userDataFields[i]] = this.state.title;
            } else if (userDataFields[i] === 'department') {
                newRow[userDataFields[i]] = this.state.department;
            } else if (userDataFields[i] === 'email_manager') {
                newRow[userDataFields[i]] = this.state.email_manager;
            } else if (userDataFields[i] === 'location_work') {
                newRow[userDataFields[i]] = this.state.location_work;
            } else if (userDataFields[i] === 'roles') {
                newRow[userDataFields[i]] = this.state.roles;
            } else if (userDataFields[i] === 'queues') {
                newRow[userDataFields[i]] = this.state.queues;
            } else if (userDataFields[i] === 'division') {
                newRow[userDataFields[i]] = this.state.division;
            } else if (userDataFields[i] === 'skillProf') {
                newRow[userDataFields[i]] = this.state.skillProf;
            } else if (userDataFields[i] === 'rolesDivisions') {
                newRow[userDataFields[i]] = this.state.rolesDivisions;
            }
        }

        usersList.push(newRow);
        this.setState({
            isUsersInfoOpen: false,
            usersList: usersList,
        });
    }

    handleInputChange = (fieldName) => (e) => {
        if (fieldName === 'queues') {
            const options = e.target.selectedOptions;
            let value = [];
            for (let i = 0, l = options.length; i < l; i++) {
                value.push(options[i].value);
            }
            this.setState({
                [fieldName]: value
            })
        } else if (fieldName === 'skills') {
            this.setState({
                [fieldName]: e.target.value,
                ['proficiencies']: '3'
            });
        } else {
            this.setState({
                [fieldName]: e.target.value
            });
        }
    }

    submitRolesConfigFunction = () => {
        let rolesDivisionsObj = this.state.rolesDivisions;
        rolesDivisionsObj[this.state.selectedRole] = this.state.divisionArray;
        this.setState({
            isRoleConfigOpen: false,
            rolesDivisions: rolesDivisionsObj
        })
    }

    handleRoleConfig = () => (e) => {
        const options = e.target.selectedOptions;
        let value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            value.push(options[i].value);
        }
        this.setState({ divisionArray: value })
    }

    updateRolesConfigFunction = () => {
        let roleDivObj = this.state.rolesDivisions;
        roleDivObj[this.state.selectedRole] = this.state.divisionArray;
        this.setState({ rolesDivisions: roleDivObj, isRoleConfigOpen: false })
    }

    editRolesConfiguration = (role, division) => {
        this.setState({
            isRoleConfigOpen: true,
            divisionArray: division,
            selectedRole: role,
            isRolesSaveLocale: true
        })
    }

    addRolesDivisions = () => {
        if (this.state.division != null) {
            let divArray = [];
            divArray.push(this.state.division);
            this.setState({ divisionArray: divArray })
        } else {
            this.setState({ divisionArray: [] })
        }
        this.setState({
            isRoleConfigOpen: true,
            isRolesSaveLocale: false,
            selectedRole: '',
        })
    }

    updateState = (rowInfo) => {
        this.setState({
            name: rowInfo.name,
            email: rowInfo.email,
            password: rowInfo.password,
            phone_work: rowInfo.phone_work,
            title: rowInfo.title,
            department: rowInfo.department,
            email_manager: rowInfo.email_manager,
            location_work: rowInfo.location_work,
            roles: rowInfo.roles,
            queues: rowInfo.queues,
            division: rowInfo.division,
            skillProf: rowInfo.skillProf,
            rolesDivisions: rowInfo.rolesDivisions,
            rowID: rowInfo.rowid,
            isSaveLocale: true,
            isUsersInfoOpen: true,
        });
    }

    updateFunction = () => {
        let usersList = [...this.state.usersList];
        for (let user of usersList) {
            if (user.rowid === this.state.rowID) {
                user.name = this.state.name;
                user.email = this.state.email;
                user.password = this.state.password;
                user.phone_work = this.state.phone_work;
                user.title = this.state.title;
                user.department = this.state.department;
                user.email_manager = this.state.email_manager;
                user.location_work = this.state.location_work;
                user.roles = this.state.roles;
                user.queues = this.state.queues;
                user.skillProf = this.state.skillProf;
                user.rolesDivisions = this.state.rolesDivisions;
                user.division = this.state.division;
            }
        }
        this.setState({
            usersList: usersList,
            isUsersInfoOpen: false,
        })
    }

    addSkillsFunction = async () => {
        let skillProfsList = this.state.skillProf;
        skillProfsList[this.state.skills] = this.state.proficiencies;
        this.setState({
            isskillProfOpen: false,
            skillProf: skillProfsList
        });
    }

    updateSkillsFunction = async () => {
        let skillProfsList = this.state.skillProf;
        await this.deleteSkillItemButtonClicked(this.state.lastUsedSkill);
        skillProfsList[this.state.skills] = this.state.proficiencies;
        this.setState({
            isskillProfOpen: false,
            skillProf: skillProfsList
        });
    }

    addSkillProf = () => {
        this.setState({
            isskillProfOpen: true,
            isSkillSaveLocale: false,
            skills: '',
            proficiencies: '',
            lastUsedSkill: '',
        })
    }

    handleRowClick = (skill, proficiency) => {
        this.setState({
            skills: skill,
            lastUsedSkill: skill,
            proficiencies: proficiency,
            isskillProfOpen: true,
            isSkillSaveLocale: true,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        await this.updateQueues();
        const fileContent = await CsvReader.readFile(file);
        const usersData = await CsvReader.parseCsvFileToUsersData(fileContent);
        await this.setState({ usersList: [...this.state.usersList, ...usersData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importUsers = async () => {
        this.eventList = [];
        const usersList = this.state.usersList;
        for (const i in usersList) {
            const userData = usersList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing user [${userData.name}] [${userData.email}]`);
            if (this.usersDataValidation(userData, i, usersList)) {
                try {
                    // <create user>
                    const divisionId = this.getDivisionId(userData.division);
                    const user = await usersPostUser(this.state.env, this.state.token, userData.name, userData.department, userData.email, userData.phone_work, userData.title, userData.password, divisionId);
                    console.log(`user created: ${JSON.stringify(user)}`);
                    // </create user>

                    // <assign roles>
                    if (userData.rolesDivisions) {
                        const roleNameList = Object.keys(userData.rolesDivisions);
                        const roleIdList = this.getRoleIdList(roleNameList);
                        await usersPutUserRoles(this.state.env, this.state.token, user.id, roleIdList);
                        console.log(`roles assigned: ${roleIdList.join(',')}`);
                    }
                    // </assign roles>

                    // <assign queues>
                    if (userData.queues && userData.queues.length > 0) {
                        const queueNameList = userData.queues;
                        const queueIdList = this.getQueueIdList(queueNameList);
                        for (const queueId of queueIdList) {
                            await routingPostQueuesUsers(this.state.env, this.state.token, user.id, queueId);
                            console.log(`queue assigned: ${queueId}`);
                        }
                    }
                    // </assign queues>

                    // <assign skills>
                    const skillNameList = Object.keys(userData.skillProf);
                    const proficiencyList = Object.values(userData.skillProf);
                    if (skillNameList && skillNameList.length > 0) {
                        const skillIdList = this.getSkillIdList(skillNameList, proficiencyList);
                        await routingPatchRoutingSkillsBulk(this.state.env, this.state.token, user.id, skillIdList);
                        console.log(`skills assigned: ${skillNameList.join(',')}`);
                    }
                    // </assign skills>

                    // <Assign Divisions to Roles>
                    let roleDivArray = [];
                    for (const role of Object.keys(userData.rolesDivisions)) {
                        let roleId = this.state.roleList.find(el => el.name == role).id;
                        userData.rolesDivisions[role].forEach(divisionName => {
                            let roleDivObj = {};
                            roleDivObj.roleId = roleId;
                            roleDivObj.divisionId = this.state.divisionList.find(el => el.name == divisionName).id;
                            roleDivArray.push(roleDivObj);
                        });
                    }
                    await postRoleDivisionGrants(roleDivArray, user.id, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name)
                    // <Assign Divisions to Roles>

                    this.logEvent(`User [${userData.name}] [${userData.email}] successfully processed`);
                    this.removeRowClicked(userData.rowid);
                } catch (err) {
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing ${this.props.cardTitle}`, count, this.props.cardTitle);
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    getQueueIdList = (queueNameList) => {
        var result = [];
        for (const queueName of queueNameList) {
            const queue = this.state.queueList.filter(q => q.name.toLowerCase() === queueName.toLowerCase())[0];
            if (queue) { result.push(queue.id); }
        }
        return result;
    }

    getRoleIdList = (roleNameList) => {
        var result = [];
        for (const roleName of roleNameList) {
            const role = this.state.roleList.filter(r => r.name.toLowerCase() === roleName.toLowerCase())[0];
            if (role) { result.push(role.id); }
        }
        return result;
    }

    getDivisionId = (name) => {
        const division = this.state.divisionList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (division) { return division.id; }
    }

    getSkillIdList = (skillNameList, proficiencyList) => {
        var result = [];
        for (const i in skillNameList) {
            const skill = this.state.skillList.filter(s => s.name.toLowerCase() === skillNameList[i].toLowerCase())[0];
            const proficiency = proficiencyList.length === skillNameList.length ? proficiencyList[i] : 5;
            if (skill) { result.push({ "id": skill.id, "proficiency": proficiency }); }
        }
        return result;
    }

    usersDataValidation = (user, i, usersData) => {
        let isUserValidated = true;
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        // <validate fields that can't be empty>
        if (!user.name || !user.name.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isUserValidated = false; }
        if (!user.email || !user.email.trim()) { this.logEvent(`[Index ${i}] Email can't be empty.`, true); isUserValidated = false; }
        if (!user.password || !strongRegex.test(user.password)) { this.logEvent(`[Index ${i}] The password does not meet the requirements of more than 8 characters, must contain upper and lowercase chars, a digit, and at least one special character.`, true); isUserValidated = false; }
        if (!user.rolesDivisions) { this.logEvent(`[Index ${i}] Roles can't be empty.`, true); isUserValidated = false; }
        if (!user.division || !user.division.trim()) { this.logEvent(`[Index ${i}] Division can't be empty.`, true); isUserValidated = false; }
        // </validate fields that can't be empty>

        // <validate if roles exist>
        for (const userRole of Object.keys(user.rolesDivisions)) {
            var userRoleValid = false;
            for (const role of this.state.roleList) {
                if (userRole.toLowerCase() === role.name.toLowerCase()) {
                    userRoleValid = true;
                }
            }
            if (!userRoleValid) { this.logEvent(`[Index ${i}] Role '${userRole}' doesn't exist`, true); }
        }
        // </validate if roles exist>

        // <validate if queues exist>
        for (const userQueue of user.queues) {
            var userQueueValid = false;
            for (const queue of this.state.queueList) {
                if (userQueue.toLowerCase() === queue.name.toLowerCase()) {
                    userQueueValid = true;
                }

            }
            if (!userQueueValid) { this.logEvent(`[Index ${i}] Queue '${userQueue}' doesn't exist`, true); isUserValidated = false; }
        }
        // </validate if queues exist>

        // <validate if division exist>
        const userDivision = user.division;
        if (userDivision) {
            var userDivisionValid = false;
            for (const division of this.state.divisionList) {
                if (userDivision.toLowerCase() === division.name.toLowerCase()) {
                    userDivisionValid = true;
                }
            }
            if (!userDivisionValid) { this.logEvent(`[Index ${i}] Division '${userDivision}' doesn't exist`, true); isUserValidated = false; }
        }
        // </validate if division exist>

        const skillsArray = Object.keys(user.skillProf);
        const proficienciesArray = Object.values(user.skillProf);

        // <validate number of skills and proficiences>      
        if (skillsArray.length !== 0 && proficienciesArray.length !== 0) {
            // if there are no skills at all proficiencies are ignored
            // if there are no proficiencies at all a default values will be applied
            if (skillsArray.length !== proficienciesArray.length) {
                this.logEvent(`[Index ${i}] Number of proficiencies is different than number of skills`, true);
                isUserValidated = false;
            }
        }
        // </validate number of skills and proficiences>

        // <validate if skills exist>
        for (const userSkill of skillsArray) {
            var userSkillValid = false;
            for (const skill of this.state.skillList) {
                if (userSkill.toLowerCase() === skill.name.toLowerCase()) {
                    userSkillValid = true;
                }
            }
            if (!userSkillValid) { this.logEvent(`[Index ${i}] Skill '${userSkill}' doesn't exist`, true); isUserValidated = false; }
        }
        // </validate if skills exist>

        // <validate proficiency values>
        for (const prof of proficienciesArray) {
            if (!Misc.isNumeric(prof) || prof < 0 || prof > 5) { this.logEvent(`[Index ${i}] Proficiency must be an integer between 0 and 5`, true); isUserValidated = false; }
        }
        // </validate proficiency values>

        // <validate if user name and email are unique>
        for (const j in usersData) {
            const user1 = usersData[j];
            if (i === j) { continue; }
            if (user.name.trim().toLowerCase() === user1.name.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isUserValidated = false; }
            if (user.email.trim().toLowerCase() === user1.email.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Email should be unique.`, true); isUserValidated = false; }
        }
        // </validate if user name and email are unique>
        return isUserValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.usersList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Users...." });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '1') {
                this.props.navigateToHorizontalSlide('0');
            }
        }
    }

    render() {
        let MAP = {
            name: "user-map",
            areas: [
                { name: "1", shape: "circle", coords: [31, 16, 16] }
            ]
        }
        return (
            <div className="usersCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <UsersInfo
                    isOpen={this.state.isUsersInfoOpen}
                    name={this.state.name}
                    email={this.state.email}
                    password={this.state.password}
                    phone_work={this.state.phone_work}
                    title={this.state.title}
                    department={this.state.department}
                    email_manager={this.state.email_manager}
                    location_work={this.state.location_work}
                    roles={this.state.roles}
                    queues={this.state.queues}
                    division={this.state.division}
                    isSaveLocale={this.state.isSaveLocale}
                    locationList={this.state.locationList}
                    skillProf={this.state.skillProf}
                    roleList={this.state.roleList}
                    queueList={this.state.queueList}
                    divisionList={this.state.divisionList}
                    addSkillProf={this.addSkillProf}
                    rolesDivisions={this.state.rolesDivisions}
                    addRolesDivisions={this.addRolesDivisions}
                    editRolesConfiguration={this.editRolesConfiguration}
                    deleteRolesItemButtonClicked={this.deleteRolesItemButtonClicked}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    deleteSkillItemButtonClicked={this.deleteSkillItemButtonClicked}
                    handleRowClick={this.handleRowClick}
                    closeFunction={() => { this.setState({ isUsersInfoOpen: false }) }} />
                {/* <Information viewer> */}

                {/* <Skills Information viewer> */}
                <SkillProficiencies
                    skillList={this.state.skillList}
                    skills={this.state.skills}
                    proficiencies={this.state.proficiencies}
                    isOpen={this.state.isskillProfOpen}
                    isSkillSaveLocale={this.state.isSkillSaveLocale}
                    handleInputChange={this.handleInputChange}
                    addSkillsFunction={this.addSkillsFunction}
                    updateSkillsFunction={this.updateSkillsFunction}
                    closeSkilsFunction={() => { this.setState({ isskillProfOpen: false }) }}
                />
                {/* <Skills Information viewer> */}

                <DivisionsInfo
                    isOpen={this.state.isRoleConfigOpen}
                    roleList={this.state.roleList}
                    selectedRole={this.state.selectedRole}
                    divisionArray={this.state.divisionArray}
                    divisionList={this.state.divisionList}
                    isRolesSaveLocale={this.state.isRolesSaveLocale}
                    handleInputChange={this.handleInputChange}
                    handleRoleConfig={this.handleRoleConfig}
                    updateRolesConfigFunction={this.updateRolesConfigFunction}
                    submitRolesConfigFunction={this.submitRolesConfigFunction}
                    closeRolesConfigFunction={() => { this.setState({ isRoleConfigOpen: false }) }}
                />


                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 usersCardBody">
                        <CardTitle className="m-0 usersTitle">
                            <div className="divUsers">
                                <Badge className="titleBadgePeople">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pu.png" map={MAP} width={150} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px" }}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/user-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodyPeople">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonPeople" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonPeople">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage} />
                            <div className="Users-wrap">
                                <ReactTable
                                    data={this.state.usersList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.usersList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "name",
                                            Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: "Email",
                                            accessor: "email",
                                            Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{
                                                width: "200px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px"
                                                , backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius: (this.state.selectAll != 0) ? "25px" : "0px"
                                            }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Users loaded"
                                    className="-striped -highlight"
                                />
                            </div>
                            <Row>
                                <Col>
                                    <Button className="SubmitButtonPeople" onClick={this.importUsers} disabled={this.state.usersList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
