
import moment from 'moment';
import 'moment-timezone';
const csv = require('csvtojson')
const uuidv1 = require('uuid/v1');

export const parseCsvFile = (csvStr) => {
  csvStr = csvStr.replace(/['"]+/g, '').trim();
  return csv({
    noheader: false,
    output: "csv"
  }).fromString(csvStr)
}

export const parseCsvNoHeader = async (csvStr) => {
  return csv({
    noheader: true,
    output: "csv"
  }).fromString(csvStr);
}

const divisionDataFields = [
  'divisionname'
];

export const getEmptyDivisionObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in divisionDataFields) {
    if (divisionDataFields[i] === 'divisionname') {
      result[divisionDataFields[i]] = 'new Division';
    }
  }
  return result;
}

const skillDataFields = [
  'skillname'
];

export const getEmptySkillsObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in skillDataFields) {
    if (skillDataFields[i] === 'skillname') {
      result[skillDataFields[i]] = 'new Skill';
    }
  }
  return result;
}

const wrapupDataFields = [
  'wrapup-code name'
];

export const getEmptyWrapUpCodesObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in wrapupDataFields) {
    if (wrapupDataFields[i] === 'wrapup-code name') {
      result[wrapupDataFields[i]] = '';
    }
  }
  return result;
}

const rolesDataFields = [
  'rolename'
];

export const getEmptyRolesObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in rolesDataFields) {
    if (rolesDataFields[i] === 'rolename') {
      result[rolesDataFields[i]] = '';
    }
  }
  return result;
}

const userDataFields = [
  'name',
  'email',
  'password',
  'phone_work',
  'title',
  'department',
  'email_manager',
  'location_work',
  'roles',
  'queues',
  'division',
  'skills',
  'proficiencies',
  'skillProf',
  'rolesDivisions'
];

export const parseCsvFileToUsersData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    var skillsData = [];
    var profData = [];
    var roles = [];
    var divisions = [];
    for (const i in row) {
      if (userDataFields[i] === 'queues') {
        resultRow[userDataFields[i]] = row[i].split('|');
        continue;
      } else if (userDataFields[i] === 'roles') {
        roles = row[i].split('|');
        resultRow[userDataFields[i]] = roles;
        continue;
      } else if (userDataFields[i] === 'skills') {
        skillsData = row[i].split('|');
        continue;
      } else if (userDataFields[i] === 'proficiencies') {
        profData = row[i].split('|');
        continue;
      } else if (userDataFields[i] === 'division') {
        divisions = row[i].split('|');
        resultRow[userDataFields[i]] = row[i];
        continue;
      }
      resultRow[userDataFields[i]] = row[i];
    }
    const skillProfData = await getSkillProfObject(skillsData, profData);
    resultRow['skillProf'] = skillProfData;
    const rolesDivisions = await getRolesDivObject(roles, divisions);
    resultRow['rolesDivisions'] = rolesDivisions;
    result.push(resultRow);
  }
  return result;
}

export const getSkillProfObject = async (skillsData, profData) => {
  var result = {};
  if (skillsData.length > 0 && profData.length > 0) {
    skillsData.forEach((key, i) => result[key] = profData[i]);
  }
  return result;
}

export const getRolesDivObject = async (roles, divisions) => {
  var result = {};
  roles.forEach(role => {
    let roleArray = role.split(':');
    if(roleArray.length == 1){
      result[roleArray[0]] = divisions;
    } else{
      let divArray = roleArray[1].replace('[','').replace(']','').split(';');
      console.log(divArray)
      result[roleArray[0]] = divisions.concat(divArray.map(s=> s.trim()));
    }
  });
  console.log(result)
  return result;
}

export const parseCsvFileforSites = (csvStr) => {
  return csv({
    noheader: false,
    output: "csv"
  }).fromString(csvStr)
}

const sitesDataFields = [
  'SiteName',
  'Description',
  'Location',
  'Recurrence Type',
  'Time Zone',
  'Start Time',
  'End Time',
  'StartMoment',
  'EndMoment',
  'allDayCheck'
];

export const parseCsvFileToSitesData = async (csvStr) => {
  var parsedCsv = await parseCsvFileforSites(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      if (sitesDataFields[i] === 'Start Time') {
        resultRow['StartMoment'] = row[i];
        const time = moment(row[i]).format('HH:mm');
        resultRow[sitesDataFields[i]] = time;
        continue;
      } else if (sitesDataFields[i] === 'End Time') {
        resultRow['EndMoment'] = row[i];
        const time = moment(row[i]).format('HH:mm');
        resultRow[sitesDataFields[i]] = time;
        continue;
      } else if (sitesDataFields[i] === 'Recurrence Type') {
        resultRow[sitesDataFields[i]] = row[i];
        continue;
      }
      resultRow[sitesDataFields[i]] = row[i];
    }
    if (resultRow['Start Time'] === '00:00' && resultRow['End Time'] === '00:00') {
      resultRow['allDayCheck'] = true;
    } else {
      resultRow['allDayCheck'] = false;
    }
    result.push(resultRow);
  }
  return result;
}

const edgeGroupsDataFields = [
  'name',
  'description',
  'managed',
  'phoneTrunkBaseName'
];

export const parseCsvFileToEdgeGroupsData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[edgeGroupsDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const phonesDataFields = [
  'name',
  'phoneBaseSettings',
  'site',
  'lineBaseSettings',
  'person',
  'remoteAddress'
];

export const parseCsvFileToPhonesData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[phonesDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const locationsDataFields = [
  'name',
  'street1',
  'street2',
  'city',
  'state',
  'zip',
  'countryAbbreviation',
  'country',
  'notes',
  'emergencyNumber'
];

export const parseCsvFileToLocationsData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[locationsDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const didsDataFields = [
  'startPhoneNumber',
  'endPhoneNumber',
  'provider',
  'comments'
];

export const parseCsvFileToDidsData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[didsDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const extensionsDataFields = [
  'startNumber',
  'endNumber'
];

export const parseCsvFileToExtensionsData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[extensionsDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const telephonyPhonesBaseSettingsDataFields = [
  'name',
  'phoneMetaBase'
];

export const parseCsvFileToPhonesBaseSettingsData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[telephonyPhonesBaseSettingsDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

export const parseCsvFileforSchedules = (csvStr) => {
  return csv({
    noheader: false,
    output: "csv"
  }).fromString(csvStr)
}

const schedulesDataFields = [
  'name',
  'start',
  'end',
  'rrule'
];

export const parseCsvFileToSchedulesData = async (csvStr) => {
  var parsedCsv = await parseCsvFileforSchedules(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[schedulesDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const scheduleGroupsDataFields = [
  'name',
  'timeZone',
  'openSchedules',
  'closedSchedules',
  'holidaySchedules'
];

export const parseCsvFileToScheduleGroupsData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      if (scheduleGroupsDataFields[i] === 'openSchedules'
        | scheduleGroupsDataFields[i] === 'closedSchedules'
        | scheduleGroupsDataFields[i] === 'holidaySchedules') {
        resultRow[scheduleGroupsDataFields[i]] = row[i].split('|');
        continue;
      }
      resultRow[scheduleGroupsDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const skillsMatrixDataFields = [
  'User email',
  'Skill1',
  'Proficiency1',
  'Skill2',
  'Proficiency2',
  'Skill3',
  'Proficiency3',
  'Skill4',
  'Proficiency4',
  'Skill5',
  'Proficiency5',
];

export const parseCsvFileToSkillsMatrixData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var fieldCounts = [];
  parsedCsv.forEach(obj => {
    var count = 0;
    obj.forEach(key => {
      if (key !== '') { count = count + 1; }
    });
    fieldCounts.push(count);
  })
  var maxRow = Math.max.apply(null, fieldCounts);
  if (maxRow > 11) {
    var deffFieldCount = 5
    let diff = (maxRow - 11) / 2;
    for (var i = 0; i < diff; i++) {
      deffFieldCount = deffFieldCount + 1;
      skillsMatrixDataFields.push('Skill' + deffFieldCount,
        'Proficiency' + deffFieldCount)
    }
  }
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      resultRow[skillsMatrixDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const queuesDataFields = [
  'Queue name',
  'Division',
  'ACW',
  'ACW timeout',
  'Evaluation method',
  'Alerting timeout',
  'SL percentage',
  'SL duration',
  'Calling party name',
  'Calling party number',
  'Wrapup codes'
];

export const parseCsvFileToQueuesData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      if (queuesDataFields[i] === 'Wrapup codes') {
        if(row[i].trim() !== ''){
          resultRow[queuesDataFields[i]] = row[i].split('|');
           } else {
          resultRow[queuesDataFields[i]] = []
          }
        continue;
      }
      resultRow[queuesDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const ivrRoutingDataFields = [
  'Name',
  'Addresses',
  'Schedule Group',
  'Open Hours Flow',
  'Closed Hours flow',
  'Holiday Hours flow'
];

export const parseCsvFileToIvrRoutingData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      if (ivrRoutingDataFields[i] === 'Addresses') {
        resultRow[ivrRoutingDataFields[i]] = row[i].split('|');
        continue;
      }
      resultRow[ivrRoutingDataFields[i]] = row[i];
    }
    result.push(resultRow);
  }
  return result;
}

const widgetDataFields = [
  'Name',
  'Description',
  'AuthenticationRequired',
  'WidgetType',
  'Flow',
  'AllowedDomains',
  'WebChatSkin',
  'AuthenticationUrl'
];

export const parseCsvFileToWidgetsData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'rowid': uuidv1() }
    for (const i in row) {
      if (widgetDataFields[i] === 'AllowedDomains') {
        resultRow[widgetDataFields[i]] = row[i].split('|');
        continue;
      } else if (widgetDataFields[i] === 'WebChatSkin') {
        if (row[i].toLowerCase().trim() == 'modern-caret-skin') {
          resultRow[widgetDataFields[i]] = 'Modern'
        } else if (row[i].toLowerCase().trim() == 'basic') {
          resultRow[widgetDataFields[i]] = 'Basic'
        }
      } else if (widgetDataFields[i] === 'AuthenticationRequired') {
        if (row[i].trim() === 'FALSE') {
          resultRow[widgetDataFields[i]] = false;
        } else {
          resultRow[widgetDataFields[i]] = true;
        }
      } else if (widgetDataFields[i] === 'WidgetType') {
        if (row[i].toLowerCase().trim() == 'v2') {
          resultRow[widgetDataFields[i]] = 'Version 2'
        } else if (row[i].toLowerCase().trim() == 'v1') {
          resultRow[widgetDataFields[i]] = 'Version 1.0'
        } else if (row[i].toLowerCase().trim() == 'v1-http') {
          resultRow[widgetDataFields[i]] = 'Version 1.1'
        } else if (row[i].toLowerCase().trim() == 'third-party') {
          resultRow[widgetDataFields[i]] = 'Third Party'
        }
        continue;
      } else { resultRow[widgetDataFields[i]] = row[i]; }
    }
    result.push(resultRow);
  }
  return result;
}

export const parseCsvFileToSingleFieldData = async (csvStr) => {
  var parsedCsv = await parseCsvFile(csvStr);
  var result = [];
  for (const row of parsedCsv) {
    const resultRow = { 'item': row[0] }
    result.push(resultRow);
  }
  return result;
}

export const getEmptyUserObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in userDataFields) {
    if (userDataFields[i] === 'rowid') {
      result[userDataFields[i]] = uuidv1();
      continue;
    }
    if (userDataFields[i] === 'name') {
      result[userDataFields[i]] = 'new user';
      continue;
    }
    if (userDataFields[i] === 'email') {
      result[userDataFields[i]] = 'new@user.com';
      continue;
    }
    if (userDataFields[i] === 'password') {
      result[userDataFields[i]] = 'P@ssword123';
      continue;
    }
    result[userDataFields[i]] = '';
  }
  return result;
}

export const getEmptySiteObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in sitesDataFields) {
    if (sitesDataFields[i] === 'SiteName') {
      result[userDataFields[i]] = 'new site';
      continue;
    }
    result[sitesDataFields[i]] = '';
  }
  return result;
}

export const getEmptyEdgeGroupsObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in edgeGroupsDataFields) {
    if (edgeGroupsDataFields[i] === 'name') {
      result[edgeGroupsDataFields[i]] = 'new edge group';
      continue;
    }
    result[edgeGroupsDataFields[i]] = '';
  }
  return result;
}

export const getEmptyLocationsObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in locationsDataFields) {
    if (locationsDataFields[i] === 'name') {
      result[locationsDataFields[i]] = 'new location';
      continue;
    }
    result[locationsDataFields[i]] = '';
  }
  return result;
}

export const getEmptyPhonesObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in phonesDataFields) {
    if (phonesDataFields[i] === 'name') {
      result[phonesDataFields[i]] = ' new Phone ';
      continue;
    }
    result[phonesDataFields[i]] = '';
  }
  return result;
}

export const getEmptyDidObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in didsDataFields) {
    result[didsDataFields[i]] = '';
  }
  return result;
}

export const getEmptyExtensionObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in extensionsDataFields) {
    result[extensionsDataFields[i]] = '';
  }
  return result;
}

export const getEmptyScheduleObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in schedulesDataFields) {
    if (schedulesDataFields[i] === 'name') {
      result[schedulesDataFields[i]] = 'new schedule';
      continue;
    }
    result[schedulesDataFields[i]] = '';
  }
  return result;
}

export const getEmptyScheduleGroupObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in scheduleGroupsDataFields) {
    if (scheduleGroupsDataFields[i] === 'name') {
      result[scheduleGroupsDataFields[i]] = 'new schedule group';
      continue;
    }
    result[scheduleGroupsDataFields[i]] = '';
  }
  return result;
}

export const getEmptySkillsMatrixObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in skillsMatrixDataFields) {
    if (skillsMatrixDataFields[i] === 'User email') {
      result[skillsMatrixDataFields[i]] = 'existing@user.com';
      continue;
    }
    result[skillsMatrixDataFields[i]] = '';
  }
  return result;
}

export const getEmptyQueueObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in queuesDataFields) {
    if (queuesDataFields[i] === 'Queue name') {
      result[queuesDataFields[i]] = 'new queue';
      continue;
    }
    result[queuesDataFields[i]] = '';
  }
  return result;
}

export const getEmptyivrRoutingObject = () => {
  var result = { 'rowid': uuidv1() };
  for (const i in ivrRoutingDataFields) {
    if (ivrRoutingDataFields[i] === 'Name') {
      result[ivrRoutingDataFields[i]] = 'new IVR route';
      continue;
    }
    result[ivrRoutingDataFields[i]] = '';
  }
  return result;
}

export const readFile = (file) => {
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort()
      reject('reader error')
    }
    reader.onload = () => { resolve(reader.result) }
    reader.readAsText(file, "UTF-8")
  })
}

export default {
  getEmptyUserObject,
  getEmptySiteObject,
  getEmptyLocationsObject,
  getEmptyEdgeGroupsObject,
  getEmptyPhonesObject,
  getEmptyRolesObject,
  getEmptyDidObject,
  getEmptyExtensionObject,
  getEmptyScheduleObject,
  getEmptyScheduleGroupObject,
  getEmptySkillsMatrixObject,
  getEmptyivrRoutingObject,
  getEmptyDivisionObject,
  getEmptySkillsObject,
  getEmptyWrapUpCodesObject,
  getEmptyQueueObject,
  parseCsvFileToUsersData,
  getSkillProfObject,
  parseCsvFileToSitesData,
  parseCsvFileToLocationsData,
  parseCsvFileToEdgeGroupsData,
  parseCsvFileToPhonesData,
  parseCsvFileToPhonesBaseSettingsData,
  parseCsvFileToDidsData,
  parseCsvFileToExtensionsData,
  parseCsvFileToSingleFieldData,
  parseCsvFileToSchedulesData,
  parseCsvFileToScheduleGroupsData,
  parseCsvFileToSkillsMatrixData,
  parseCsvFileToQueuesData,
  parseCsvFileToIvrRoutingData,
  parseCsvFileToWidgetsData,
  parseCsvFile,
  parseCsvFileforSchedules,
  parseCsvNoHeader,
  readFile,
}