import { pureCloudLogout } from '../session/session';
import config from '../../config';
const backEndURL = config.apiEndpoint;


export const getInboundCallflows = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await flowsGetFlows(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const flowsGetFlows = (env, token, index) => {
  return fetch(`https://api.${env}/api/v2/flows?pageNumber=${index}&pageSize=255&publishVersionId=*&sortBy=name&sortOrder=asc&type=inboundcall`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const getInboundChatflows = async (env, token) => {
  let container = [];
  let isTherePagination = true;
  for (let index = 1; isTherePagination; index++) {
    let respond = await getInboundChatFlowsperPage(env, token, index);
    if (respond === undefined) {
      isTherePagination = false;
    } else {
      container.push(respond)
    }
  }
  return [].concat(...container);
}

export const getInboundChatFlowsperPage = (env, token, index) => {
  return fetch(`https://apps.${env}/platform/api/v2/flows/divisionViews?pageNumber=${index}&pageSize=50&sortBy=name&sortOrder=asc&publishVersionId=*&type=inboundchat`, {
    method: 'GET',
    headers: {
      'Authorization': `bearer ${token}`,
      'Content-Type': 'application/json'
    }
  })
    .then(response => response.json())
    .then(response => {
      if (response.status === 401) {
        pureCloudLogout();
        return;
      }
      if (response.status < 200 || response.status > 299) throw response;
      if (response.entities && response.entities.length > 0) {
        return response.entities;
      }
    })
}

export const selectedCallFlows = (userMail, orgName) => {
  return fetch(backEndURL + `/pcat/callflows/${orgName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
    },
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response.messages;
        if (response.status.code === "OK") {
          return response.result;
        }
      }
      return response;
    })
}

export const getI3FlowFileData = (userMail, flowPath, orgName) => {
  return fetch(backEndURL + `/pcat/i3file/${flowPath}/${orgName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
    },
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response.messages;
        if (response.status.code === "OK") {
          return response.result;
        }
      }
      return response;
    })
}

export const publishCallFlows = (userMail, orgName, flowPath, subsContent, subsFile, token, env) => {
  const publishFlow = {
    substitutionFileContent: subsContent !== undefined ? subsContent : null,
    substitutionTemplateFilename: subsFile !== undefined ? subsFile : null,
    flowName: flowPath,
    authorizationToken: token,
    location: env,
  };
  return fetch(backEndURL + `/pcat/export/callflows/${orgName}`, {
    // content-type header should not be specified!
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
    },
    body: JSON.stringify(publishFlow)
  })
    .then(response => response.json())
    .then(response => {
      if (response) {
        if (response.status < 200 || response.status > 299) throw response.messages;
        if (response.status.code === "OK") {
          if (response.result) {
            return response.result.token;
          }
        }
      }
      return response;
    })
}

export const checkProgress = (userMail, orgName, token) => {
  return fetch(backEndURL + `/pcat/progress/${token}/${orgName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw response.statusText;
      }
    })
    .then((responseJson) => {
      if (responseJson) {
        if (responseJson.status.code === "OK") {
          return responseJson.result;
        } else {
          return responseJson.result;
        }
      }
    })
    .catch((err) => {
      return err;
    });
}

export const downloadSubsFile = (userMail, orgName, subsFile) => {
  let responseData = {
    isSuccess: true,
    response: null,
  }
  return fetch(backEndURL + `/pcat/download/template/${subsFile}/${orgName}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-accel-user': userMail,
    },
  })
  .then((response) => {
    return response.json();
  })
  .then((responseJson) => {
    if (responseJson) {
      if (responseJson.status.code === "OK") {
        responseData.isSuccess = true;
        responseData.response = responseJson.result;
        return responseData;
      } else {
        responseData.isSuccess = false;
        if (responseJson.messages) {
          responseData.response = responseJson.messages[0].content;
          return responseData;
        } else {
          responseData.response = responseJson.status.text;
          return responseData;
        }        
      }
    }
  })
  .catch((err) => {
    return err;
  });
}