import './itemsPage.css';
import React from 'react';
import { Fullpage, Slide, HorizontalSlider } from 'fullpage-react';
import { FaArrowLeft, FaArrowRight, FaArrowUp } from 'react-icons/fa';
import { Button } from 'reactstrap';
import OrgSettings from '../items/orgSettings/orgSettings';
import Locale from '../items/Locale/locale';
import Sites from '../items/Locale/sites/sites';
import Telephony from '../items/telephony/telephony';
import PeopleQueues from '../items/people/peopleQueues/peopleQueues';
import PeopleUsers from '../items/people/peopleUsers/peopleUsers';
import { getRoutingWrapupCodes, routingPostWrapUpCode, routingDeleteWrapUpCode, getRoutingGetSkills, routingPostSkill, routingDeleteSkill } from '../../services/purecloud/routing';
import { getAuthorizationRoles, authorizationPostRole, authorizationDeleteRole, getAuthorizationDivisions, authorizationPostDivision, authorizationDeleteDivision } from '../../services/purecloud/authorization';
import Extensions from '../items/telephony/extensions/extensions';
import EdgeGroups from '../items/telephony/EdgeGroups/edgeGroups';
import Phones from '../items/telephony/Phones/phones';
import PhoneBaseSettings from '../items/telephony/PhoneBaseSettings/phoneBaseSettings';
import Schedules from '../items/schedulesRouting/schedules/schedules';
import ScheduleGroups from '../items/schedulesRouting/scheduleGroups/scheduleGroups';
import IvrRouting from '../items/schedulesRouting/ivrRouting/ivrRouting';
import DesignFlows from '../items/callFlows/designFlows/designFlows';
import ImportLibraryCallFlows from '../items/callFlows/importLibraryCallFlows/importLibraryCallFlows';
import Prompts from '../items/prompts/prompts';
import CampTypes from '../items/outbound/campTypes/campTypes';
import OutboundLists from '../items/outbound/outboundLists/outboundLists';
import OutboundQueues from '../items/outbound/outboundQueues/outboundQueues';
import OutboundWrapCodes from '../items/outbound/outboundWrapCodes/outboundWrapCodes';
import Campaigns from '../items/outbound/campaigns/campaigns';
import Widgets from '../items/schedulesRouting/widgets/widgets'
import { Container } from 'reactstrap';

let { changeHorizontalSlide } = Fullpage;
const fullPageOptions = {
    // for mouse/wheel events
    // represents the level of force required to generate a slide change on non-mobile, 0 is default
    scrollSensitivity: 2,

    // for touchStart/touchEnd/mobile scrolling
    // represents the level of force required to generate a slide change on mobile, 0 is default
    touchSensitivity: 2,
    scrollSpeed: 150,
    resetSlides: true,
    hideScrollBars: true,
    enableArrowKeys: false,

    // optional, set the initial vertical slide
    activeSlide: 0
};

const horizontalNavStyle = {
    position: 'absolute',
    width: '100%',
    top: '40%',
    zIndex: 10
};

const horizontalSliderProps = {
    name: 'horizontalSlider1',
    infinite: true,
};

class ItemsPage extends React.Component {

    state = {
        campaign: undefined,
        contactList: '',
        dncLists: '',
        queue: '',
        selectedWrapCodes: undefined,
        isPreviewCampType: false,
        isSMSCampType: false,
        divisionDetails: undefined,
        columnNames: []
    }

    constructor(props) {
        super(props);
        changeHorizontalSlide = changeHorizontalSlide.bind(this);
        this.onSlideChangeStart = this.onSlideChangeStart.bind(this);
        this.onSlideChangeEnd = this.onSlideChangeEnd.bind(this);
    }

    onSlideChangeStart(name, props, state, newState) {
        if (!this.horizontalNav) {
            this.horizontalNav = document.getElementById('horizontal-nav');
        }
        if (name === 'horizontalSlider1') {
            scrollNavStart(this.horizontalNav);
        }
    }

    onSlideChangeEnd(name, props, state, newState) {
        if (name === 'horizontalSlider1') {
            scrollNavEnd(this.horizontalNav);
        }

        const oldActive = this.props.active;
        const sliderState = {
            [name]: newState.activeSlide
        };

        const updatedState = Object.assign(oldActive, sliderState);
        this.props.updateSliderState(updatedState);
    }

    nextHorizontalSlide = () => {
        const horizontalSliderName = horizontalSliderProps.name;
        const horizontalActive = this.props.active[horizontalSliderName];
        changeHorizontalSlide(horizontalSliderName, horizontalActive + 1);
    }

    navigateToHorizontalSlide = (count) => {
        const horizontalSliderName = horizontalSliderProps.name;
        const horizontalActive = this.props.active[horizontalSliderName];
        changeHorizontalSlide(horizontalSliderName, count);
    }

    previousHorizontalSlide = () => {
        //horizontalActive === 0
        const horizontalSliderName = horizontalSliderProps.name;
        const horizontalActive = this.props.active[horizontalSliderName];
        changeHorizontalSlide(horizontalSliderName, horizontalActive - 1);
    }

    clearSlideState = async (cardItem) => {
        const horizontalSliderName = horizontalSliderProps.name;
        changeHorizontalSlide(horizontalSliderName, 0);
        this.props.navigateToCards(cardItem);
    }

    handleCampType = async (campaign, moveToListsSlide, isPreviewCampType) => {
        if (campaign['dialingMode'] == 'sms') {
            await this.setState({ isSMSCampType: true });
        }
        this.setState({
            campaign: campaign,
            isPreviewCampType: isPreviewCampType
        });
        if (moveToListsSlide) {
            this.nextHorizontalSlide();
        }
    }

    handleLists = (contactList, dncLists, divisionDetails, moveToQueueSlide, columnNames) => {
        this.setState({
            contactList: contactList,
            dncLists: dncLists,
            divisionDetails: divisionDetails,
            columnNames: columnNames
        });
        if (moveToQueueSlide) {
            if (this.state.isSMSCampType) {
                this.navigateToHorizontalSlide('4');
            } else {
                this.nextHorizontalSlide();
            }
        }
    }

    handleQueues = (queues, selectedWrapCodes) => {
        this.setState({
            queue: queues,
            selectedWrapCodes: selectedWrapCodes
        });
        this.nextHorizontalSlide();
    }

    handleWrapCodes = (moveToCampaignSlide) => {
        if (moveToCampaignSlide) {
            this.setState({
                selectedWrapCodes: [] // To clear props value
            });
            this.nextHorizontalSlide();
        }
    }

    moveToNewCampaign = (value) => {
        if (value) {
            this.moveToFirstHorizontalSlide();
        } else {
            this.clearSlideState('outbound');
        }
    }

    moveToFirstHorizontalSlide = () => {
        const horizontalSliderName = horizontalSliderProps.name;
        changeHorizontalSlide(horizontalSliderName, 0);
    }

    render() {
        const horizontalSliderName = horizontalSliderProps.name;
        const horizontalActive = this.props.active[horizontalSliderName];
        let tileSectionName = '';
        const horizontalLeftNav = (
            <div id='horizontal-nav' style={horizontalNavStyle}>
                {(this.props.cardItem !== 'outbound') && horizontalActive != 0 &&
                    <FaArrowLeft className="sliderButton" onClick={this.previousHorizontalSlide} style={{ position: 'absolute', left: '75px', bottom: '3px' }} />}
            </div>);
        const horizontalNav = (
            <div id='horizontal-nav' style={horizontalNavStyle}>
                {
                    (this.props.cardItem === 'Org' && horizontalActive === 3)
                        ?
                        <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('Org')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                        :
                        this.props.cardItem === 'Org' && <FaArrowRight className="sliderButton" onClick={this.nextHorizontalSlide} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
                {
                    (this.props.cardItem === 'locale' && horizontalActive === 1)
                        ?
                        <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('locale')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                        :
                        this.props.cardItem === 'locale' && <FaArrowRight className="sliderButton" onClick={this.nextHorizontalSlide} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
                {
                    (this.props.cardItem === 'telephony' && horizontalActive === 4)
                        ?
                        <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('telephony')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                        :
                        this.props.cardItem === 'telephony' && <FaArrowRight className="sliderButton" onClick={this.nextHorizontalSlide} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
                {
                    (this.props.cardItem === 'people' && horizontalActive === 1)
                        ?
                        <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('people')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                        :
                        this.props.cardItem === 'people' && <FaArrowRight className="sliderButton" onClick={this.nextHorizontalSlide} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
                {
                    (this.props.cardItem === 'schedule' && horizontalActive === 3)
                        ?
                        <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('schedule')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                        :
                        this.props.cardItem === 'schedule' && <FaArrowRight className="sliderButton" onClick={this.nextHorizontalSlide} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
                {
                    (this.props.cardItem === 'callFlows' && horizontalActive === 1)
                        ?
                        <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('callFlows')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                        :
                        this.props.cardItem === 'callFlows' && <FaArrowRight className="sliderButton" onClick={this.nextHorizontalSlide} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
                {
                    (this.props.cardItem === 'announcements' && horizontalActive === 0)
                        ?
                        <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('announcements')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                        :
                        this.props.cardItem === 'announcements' && <FaArrowRight className="sliderButton" onClick={this.nextHorizontalSlide} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
                {
                    (this.props.cardItem === 'outbound' && horizontalActive === 4)
                    &&
                    <FaArrowUp className="sliderButton" onClick={() => this.clearSlideState('outbound')} style={{ position: 'absolute', right: '75px', bottom: '3px' }} />
                }
            </div>
        );

        if (this.props.cardItem === 'Org') {
            const horizontalSlides = [
                <Container className="containerCardSection"><OrgSettings cardTitle="Divisions" navigateToHorizontalSlide={this.navigateToHorizontalSlide} getListMethod={getAuthorizationDivisions} addItemMethod={authorizationPostDivision} deleteItemMethod={authorizationDeleteDivision} /></Container>,
                <Container className="containerCardSection"><OrgSettings cardTitle="Skills" navigateToHorizontalSlide={this.navigateToHorizontalSlide} getListMethod={getRoutingGetSkills} addItemMethod={routingPostSkill} deleteItemMethod={routingDeleteSkill} /></Container>,
                <Container className="containerCardSection"><OrgSettings cardTitle="Wrap-Up Codes" navigateToHorizontalSlide={this.navigateToHorizontalSlide} getListMethod={getRoutingWrapupCodes} addItemMethod={routingPostWrapUpCode} deleteItemMethod={routingDeleteWrapUpCode} /></Container>,
                <Container className="containerCardSection"><OrgSettings cardTitle="Roles" navigateToHorizontalSlide={this.navigateToHorizontalSlide} getListMethod={getAuthorizationRoles} addItemMethod={authorizationPostRole} deleteItemMethod={authorizationDeleteRole} /></Container>
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'Organizational Settings';
        } else if (this.props.cardItem === 'locale') {
            const horizontalSlides = [
                <Container className="containerCardSection"><Locale cardTitle="Locations" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><Sites cardTitle="Sites" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'Location Settings';
        } else if (this.props.cardItem === 'telephony') {
            const horizontalSlides = [
                <Container className="containerCardSection"><Telephony cardTitle="DIDs" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><Extensions cardTitle="Extensions" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><EdgeGroups cardTitle="Edge Groups" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><PhoneBaseSettings cardTitle="Phones Base Settings" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><Phones cardTitle="Phones" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'Telephony Settings';
        } else if (this.props.cardItem === 'people') {
            const horizontalSlides = [
                <Container className="containerCardSection"><PeopleQueues cardTitle="Queues" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><PeopleUsers cardTitle="Users" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'People Settings';
        } else if (this.props.cardItem === 'schedule') {
            const horizontalSlides = [
                <Container className="containerCardSection"><Schedules cardTitle="Schedules" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><ScheduleGroups cardTitle="Schedule Groups" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><IvrRouting cardTitle="IVR Routing" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><Widgets cardTitle="Widgets" navigateToHorizontalSlide ={this.navigateToHorizontalSlide}/></Container>
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'Schedule & IVR Settings';
        } else if (this.props.cardItem === 'callFlows') {
            const horizontalSlides = [
                <Container className="containerCardSection"><DesignFlows cardTitle="Design Flows" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>,
                <Container className="containerCardSection"><ImportLibraryCallFlows cardTitle="Library of Callflows" navigateToHorizontalSlide={this.navigateToHorizontalSlide} /></Container>
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'CallFlows Settings';
        } else if (this.props.cardItem === 'announcements') {
            const horizontalSlides = [
                <Container className="containerCardSection"><Prompts cardTitle="Prompts" /></Container>,
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'Announcements Settings';
        } else if (this.props.cardItem === 'outbound') {
            const horizontalSlides = [
                <Container className="containerCardSection"><CampTypes cardTitle="Campaign Type" handleCampType={this.handleCampType} /></Container>,
                <Container className="containerCardSection"><OutboundLists cardTitle="Contact and Do_Not_Call Lists" dialingMode={this.state.campType} isSMSCampType={this.state.isSMSCampType} handleLists={this.handleLists} /></Container>,
                <Container className="containerCardSection"><OutboundQueues cardTitle="Queues " handleQueues={this.handleQueues} /></Container>,
                <Container className="containerCardSection"><OutboundWrapCodes cardTitle="Wrap-up Code Mapping" selectedWrapCodes={this.state.selectedWrapCodes} handleWrapCodes={this.handleWrapCodes} /></Container>,
                <Container className="containerCardSection"><Campaigns cardTitle="Campaign Settings" campaign={this.state.campaign} isPreviewCampType={this.state.isPreviewCampType} isSMSCampType={this.state.isSMSCampType}
                contactList={this.state.contactList} dncLists={this.state.dncLists} divisionDetails={this.state.divisionDetails} columnNames ={this.state.columnNames} queue={this.state.queue} moveToNewCampaign={this.moveToNewCampaign} /></Container>
            ];
            horizontalSliderProps.slides = horizontalSlides;
            tileSectionName = 'Outbound Settings';
        }

        const horizontalSlider = <HorizontalSlider id='horizontal-slider-1' {...horizontalSliderProps}>{horizontalLeftNav}{horizontalNav}</HorizontalSlider>;
        const verticalSlides = [
            horizontalSlider,
        ];
        fullPageOptions.slides = verticalSlides;

        return (
            <div>
                <h2 className='header'>Let's walk-through the common {tileSectionName} </h2>
                {
                    this.props.cardItem === 'Org'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
                {
                    this.props.cardItem === 'locale'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
                {
                    this.props.cardItem === 'telephony'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
                {
                    this.props.cardItem === 'people'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
                {
                    this.props.cardItem === 'schedule'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
                {
                    this.props.cardItem === 'callFlows'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
                {
                    this.props.cardItem === 'announcements'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
                {
                    this.props.cardItem === 'outbound'
                    &&
                    <Fullpage onSlideChangeStart={this.onSlideChangeStart} onSlideChangeEnd={this.onSlideChangeEnd} {...fullPageOptions}>
                    </Fullpage>
                }
            </div>
        )
    }
}

function scrollNavStart(nav) {
    // make the nav fixed when we start scrolling horizontally
    nav.style.position = 'fixed';
}

function scrollNavEnd(nav) {
    // make the nav absolute when scroll finishes
    nav.style.position = 'absolute';
}

export default ItemsPage;