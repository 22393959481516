import './usersInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row, Table } from 'reactstrap'
import { FaTrash, FaPlusCircle } from 'react-icons/fa';

export default class UsersInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.name !== '' && this.props.email !== '' && this.props.password !== '' && this.props.division !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="usersModal">
                    <ModalHeader>User Configuration</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="name">Name:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.name === '' ? true : false} value={this.props.name} onChange={this.props.handleInputChange('name')} type="text" id="name" placeholder="Enter the User's full name" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="title">Job Title:</Label>
                                        <Input value={this.props.title} onChange={this.props.handleInputChange('title')} type="text" id="title" placeholder="Job Title" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="email">Email:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.email === '' ? true : false} value={this.props.email} onChange={this.props.handleInputChange('email')} type="text" id="email" placeholder="Enter User Mail" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="password">Password:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.email === '' ? true : false} value={this.props.password} onChange={this.props.handleInputChange('password')} type="password" id="password" placeholder="User password" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="phone_work">Phone Work:</Label>
                                        <Input value={this.props.phone_work} onChange={this.props.handleInputChange('phone_work')} type="text" id="phone_work" placeholder="Phone Work" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="department">Department:</Label>
                                        <Input value={this.props.department} onChange={this.props.handleInputChange('department')} type="text" id="department" placeholder="Department" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Location:</Label>
                                        <select className="form-control selectpicker" value={this.props.location_work} onChange={this.props.handleInputChange('location_work')}>
                                            <option key="L1" hidden>Select a Location</option>
                                            {
                                                this.props.locationList.map((location) => (
                                                    <option key={location.id}>{location.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label>Division:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.division} onChange={this.props.handleInputChange('division')}>
                                            <option key="D1" hidden>Select a Division</option>
                                            {
                                                this.props.divisionList.map((division) => (
                                                    <option key={division.id}>{division.name}</option>
                                                ))
                                            }
                                        </select>

                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Label sm={2} title="Select entry & Cntrl key for multiple selections">Queues:</Label>
                                    <Col sm={9}>
                                        <select multiple title="Select entry & Cntrl key for multiple selections" className="form-control selectpicker" value={this.props.queues} onChange={this.props.handleInputChange('queues')}>
                                            <option key="Q1" selected disabled>Select Queues</option>
                                            {
                                                this.props.queueList.map((queue) => (
                                                    <option key={queue.id}>{queue.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    {/* <Col md={6}>
                                        <Label title="Select entry & Cntrl key for multiple selections">Roles:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select multiple title="Select entry & Cntrl key for multiple selections" className="form-control selectpicker" value={this.props.roles} disabled={this.props.division == ''} onChange={this.props.handleInputChange('roles')}>
                                            <option key="R1" selected disabled>Select Roles</option>
                                            {
                                                this.props.roleList.map((role) => (
                                                    <option key={role.id}>{role.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col> */}
                                </Row>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={2} for="email_manager">Email Manager:</Label>
                                <Col sm={9}>
                                    <Input value={this.props.email_manager} onChange={this.props.handleInputChange('email_manager')} id="email_manager" type="text" placeholder="Email Manager" />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={1} for="Skills">Skills:</Label>
                                <FaPlusCircle title="Add Skill and Proficiency" sm={1} style={{ height: '2.5em', cursor: 'pointer' }} onClick={this.props.addSkillProf} />
                                <Col sm={10}>
                                    <div className="userInfo-wrap">
                                        <Table striped bordered className="table-condensed userInfo-table ">
                                            <thead>
                                                <tr className="userInfoHeader">
                                                    <th>Skill</th>
                                                    <th>Proficiency</th>
                                                    <th>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.entries(this.props.skillProf).map(([key, value]) => {
                                                        return (
                                                            <tr key={key} style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.props.handleRowClick(key, value)}>
                                                                <td className="p-1">{key}</td>
                                                                <td className="p-1">{value}</td>
                                                                <td className="p-1 userInfo-button-cell"><FaTrash onClick={(e) => { this.props.deleteSkillItemButtonClicked(key, e) }} /></td>
                                                            </tr>
                                                        )
                                                    })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </FormGroup>
                                <FormGroup row>
                                    <Label sm={1} for="RolesDiv">Roles:</Label>
                                    <FaPlusCircle title="Add Roles and Divisions" sm={1} style={{ height: '2.5em', cursor: 'pointer' }} onClick={this.props.addRolesDivisions} />
                                    <Col sm={10}>
                                        <div className="userInfo-wrap">
                                            <Table striped bordered className="table-condensed userInfo-table ">
                                                <thead>
                                                    <tr className="userInfoHeader">
                                                        <th>Role</th>
                                                        <th>Divisions</th>
                                                        <th>Remove</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Object.entries(this.props.rolesDivisions).map(([key, value]) => {
                                                            return (
                                                                <tr key={key} style={{ cursor: 'pointer', textAlign: 'center' }} onClick={() => this.props.editRolesConfiguration(key, value)}>
                                                                    <td className="p-1">{key}</td>
                                                                    <td className="p-1">{value.toString()}</td>
                                                                    <td className="p-1 userInfo-button-cell"><FaTrash onClick={(e) => { this.props.deleteRolesItemButtonClicked(key, e) }} /></td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{ backgroundColor: "#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
