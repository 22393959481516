import './appHeader.css';
import React from 'react';
import Misc from '../../services/misc/misc'
import { Navbar, NavbarBrand, Nav, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import { headerTitle } from '../../config';
import { FaBuilding, FaSignOutAlt, FaGlobeAmericas, FaAnchor, FaBook } from 'react-icons/fa';
import { setSelectedRegion, setUserAuthorizationKey, getSelectedRegionDisplay, setSelectedRegionDisplay, getSelectedRegion } from '../../global/localStorage';
import config from '../../config';
import { pureCloudLogout, getUserMe, getOrgMe, getToken, addLoginSession } from '../../services/session/session';
import { authorizationToAcceleratorApi } from "../../services/purecloud/authorization";
import OrgInformation from '../Misc/orgInformation/orgInformation';
import OrgProperties from '../Misc/orgInformation/orgProperties';
import { organizationsGetMe } from '../../services/purecloud/organizations';
import Preloader from '../Misc/preloader/preloader';
import zipper from '../../services/misc/zipper';
import moment from "moment";
import exportfile from '../../services/misc/fileDownload';
import BulkImport from '../BulkImport/bulkImport';
import BulkExport from '../BulkExport/bulkExport';
import { extendedBulkExport, checkProgress } from '../../services/misc/extendedExportUtils'
import EventListViewer from '../Misc/eventListViewer/eventListViewer';

import {
  extractDidsData,
  extractDivisionsData,
  extractEdgeGroupsData,
  extractExtensionsData,
  extractIvrRoutingData,
  extractWidgetsData,
  extractLocations,
  extractPhoneBaseSettingsData,
  extractPhonesData,
  extractQueuesData,
  extractRolesData,
  extractScheduleGroupsData,
  extractSchedulesData,
  extractSitesData,
  extractSkillsData,
  extractUsersData,
  extractWrapUpCodesData,
  extractSkillsMatrixData,
} from "../../services/misc/exportUtils";


export default class AppHeader extends React.Component {

  state = {
    isUserDropdownOpen: false,
    isToolsDropdownOpen: false,
    isRegionDropdownOpen: false,
    regionName: getSelectedRegionDisplay(),
    env: getSelectedRegion(),
    token: getToken(),
    userMe: {},
    orgMe: {},
    userPicture: '',
    conversationIdToBeOpened: '',
    conversationIdToBeOpenedInvalid: false,
    version: Misc.getVersion(),
    isOrgOpen: false,
    orgProperties: [],
    orgFeatures: [],
    isOrgPropsOpen: false,
    preloader: false,
    preloaderMessage: 'Loading',
    isBulkImportOpen: false,
    isBulkExportOpen: false,
    isExtendedExport: false,
    eventListIsOpen: false,
    eventList: [],
    objectType: ''
  }

  constructor(props) {
    super(props);
    if (this.props.modeProp === 'authorized') this.loadSessionData();
  }

  eventList = [];

  logEvent = (message, isError = false) => {
    const event = {
      isError: isError,
      time: moment().format('HH:mm:ss'),
      message: message
    }
    this.eventList.push(event);
  }

  showEventList = (eventList) => {
    this.setState({
      eventList: eventList,
      eventListIsOpen: true
    });
  }

  loadSessionData = async () => {
    try {
      const userMe = await getUserMe();
      const orgMe = await getOrgMe();
      if (!userMe.email.includes('@genesys.com')) {
        this.validateNonGenesysUsers(userMe, orgMe);
      }
      await this.setState({ userMe: userMe });
      await this.setState({ orgMe: orgMe });
      this.extractUserPicture();
    } catch (error) {
      console.error(error);
    }
  }

  validateNonGenesysUsers = async (userMe, orgMe) => {
    await authorizationToAcceleratorApi(userMe.email, orgMe.name).then((response) => {
      if (response !== '') {
        setUserAuthorizationKey(response.messages[0].content);
        pureCloudLogout();
      }
    });
  }

  extractUserPicture = async () => {
    try {
      const userMe = this.state.userMe;
      if (!userMe) { return; }
      if (!userMe.images) { return; }
      const userPicture = userMe.images.filter(p => p.resolution === "x48")[0].imageUri;
      await this.setState({ userPicture: userPicture });
      console.log(`User's picture URL: ${userPicture}`);
    } catch (error) {
      console.error(error);
    }
  }

  getPureCloudRegionList = () => {
    return config.purecloud.regions;
  }

  setPureCloudRegion = (name, display) => () => {
    setSelectedRegion(name);
    setSelectedRegionDisplay(display);
    window.location.href = '/';
  }

  aboutPureCloud() {
    window.open("./docs/IgniteReleaseNotes.pdf");
  }

  PureCloudUsersGuide() {
    window.open("./docs/IgniteUserManual.pdf");
  }

  handleOrgInfo = () => {
    this.setState({
      isOrgOpen: true,
    });
  }

  moreFunction = () => {
    this.setState({
      isOrgOpen: false,
      isOrgPropsOpen: true,
    });
  }

  moreFunction = async () => {
    await this.setState({ preloader: true });
    const orgMe = await organizationsGetMe(this.state.env, this.state.token);
    var orgProperties = [];
    var orgFeatures = [];
    const keys = Object.keys(orgMe);
    const values = Object.values(orgMe);

    // <parsing properties>
    for (const i in keys) {
      if (keys[i] === 'features') {
        // <parsing features>
        const featureKeys = Object.keys(values[i]);
        const featureValues = Object.values(values[i]);
        for (const j in featureKeys) {
          const featureItem = {
            key: featureKeys[j],
            value: featureValues[j].toString()
          }
          orgFeatures.push(featureItem);
        }
        // </parsing features>
      } else {
        const item = {
          key: keys[i],
          value: values[i].toString()
        }
        orgProperties.push(item);
      }
    }
    // </parsing properties>      

    await this.setState({ orgProperties: orgProperties });
    await this.setState({ orgFeatures: orgFeatures });
    this.setState({ isOrgOpen: false, isOrgPropsOpen: true });
    await this.setState({ preloader: false });
  }

  handleManageGC = () => {
    if (this.props.currPage === 0) {
      window.open('https://apps.' + this.state.env + '/directory/#/admin/organization/details');
    } else if (this.props.currPage === 1) {
      if (this.props.cardItem === 'Org') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/people-and-permissions/divisions');
        } else if (this.props.currentSlide === 1) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/acdSkills');
        } else if (this.props.currentSlide === 2) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_wrapupCodesV2');
        } else if (this.props.currentSlide === 3) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/rolesV2');
        }
      } else if (this.props.cardItem === 'locale') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/locations');
        } else if (this.props.currentSlide === 1) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/sites');
        }
      } else if (this.props.cardItem === 'telephony') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/telephony/did/ranges');
        } else if (this.props.currentSlide === 1) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/telephony/extensions/pools');
        } else if (this.props.currentSlide === 2) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/edgeGroups');
        } else if (this.props.currentSlide === 3) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/phoneManagement/baseSettings');
        } else if (this.props.currentSlide === 4) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/telephonyAdmin/phoneManagement/phones');
        }
      } else if (this.props.cardItem === 'people') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_queuesV2');
        } else if (this.props.currentSlide === 1) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/directory/peopleV3');
        }
      } else if (this.props.cardItem === 'schedule') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/routing/scheduling/schedules');
        } else if (this.props.currentSlide === 1) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/routing/scheduling');
        } else if (this.props.currentSlide === 2) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/routing/ivrs');
        } else if (this.props.currentSlide === 3) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/integrations/widgets');
        }
      } else if (this.props.cardItem === 'callFlows') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/architect/#/inboundcall/flows');
        } else if (this.props.currentSlide === 1) {
          window.open('https://apps.' + this.state.env + '/architect/#/inboundcall/flows');
        }
      } else if (this.props.cardItem === 'announcements') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/architect/#/call/userprompts');
        }
      } else if (this.props.cardItem === 'outbound') {
        if (this.props.currentSlide === 0) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/campaignManagement/campaigns');
        } else if (this.props.currentSlide === 1) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/lists/contactLists');
        } else if (this.props.currentSlide === 2) {
          window.open('https://apps.' + this.state.env + '/directory/#/admin/admin/organization/_queuesV2');
        } else if (this.props.currentSlide === 3) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/wrapupMappings');
        } else if (this.props.currentSlide === 4) {
          window.open('https://apps.' + this.state.env + '/directory/#/engage/outbound/admin/campaignManagement/campaigns');
        }
      }
    }
  }

  exportButtonClicked = async () => {
    this.setState({ preloader: true, preloaderMessage: 'Loading' });
    let zipFileName = this.state.orgMe.name + `_` + `bulk_export_configuration_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.zip`;
    zipper.initialize();

    //divisions
    this.setState({ preloader: true, preloaderMessage: 'Exporting Divisions' });
    let divisionsFileName = this.state.orgMe.name + `_` + `Divisions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const divisionsBlob = await extractDivisionsData(this.state.env, this.state.token, true);
    if (divisionsBlob) {
      zipper.addToZip(divisionsBlob, divisionsFileName);
    }

    //skills
    this.setState({ preloader: true, preloaderMessage: 'Exporting Skills' });
    let skillsFileName = this.state.orgMe.name + `_` + `Skills_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const skillsBlob = await extractSkillsData(this.state.env, this.state.token, true);
    if (skillsBlob) {
      zipper.addToZip(skillsBlob, skillsFileName);
    }

    //Wrap-up Codes
    this.setState({ preloader: true, preloaderMessage: 'Exporting Wrap-up Codes' });
    let wrapUpCodesFileName = this.state.orgMe.name + `_` + `Wrap-Up Codes_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const codesBlob = await extractWrapUpCodesData(this.state.env, this.state.token, true);
    if (codesBlob) {
      zipper.addToZip(codesBlob, wrapUpCodesFileName);
    }

    //Roles
    this.setState({ preloader: true, preloaderMessage: 'Exporting Roles' });
    let rolesFileName = this.state.orgMe.name + `_` + `Roles_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const rolesBlob = await extractRolesData(this.state.env, this.state.token, true);
    if (rolesBlob) {
      zipper.addToZip(rolesBlob, rolesFileName);
    }

    //skills matrix
    this.setState({ preloader: true, preloaderMessage: 'Exporting Skills Matrix' });
    let skillsMatrixFileName = this.state.orgMe.name + `_` + `SkillsMatrix_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const skillsMatrixBlob = await extractSkillsMatrixData(this.state.env, this.state.token, true);
    if (skillsMatrixBlob) {
      zipper.addToZip(skillsMatrixBlob, skillsMatrixFileName);
    }

    //sites
    this.setState({ preloader: true, preloaderMessage: 'Exporting Sites' });
    let sitesFileName = this.state.orgMe.name + `_` + `Sites_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const sitesBlob = await extractSitesData(this.state.env, this.state.token, true);
    if (sitesBlob) {
      zipper.addToZip(sitesBlob, sitesFileName);
    }

    //Schedules
    this.setState({ preloader: true, preloaderMessage: 'Exporting Schedules' });
    let schedulesFileName = this.state.orgMe.name + `_` + `Schedules_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const schedulesBlob = await extractSchedulesData(this.state.env, this.state.token, true);
    if (schedulesBlob) {
      zipper.addToZip(schedulesBlob, schedulesFileName);
    }

    // ScheduleGroups
    this.setState({ preloader: true, preloaderMessage: 'Exporting Schedule Groups' });
    let scheduleGroupsFileName = this.state.orgMe.name + `_` + `ScheduleGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const scheduleGroupsBlob = await extractScheduleGroupsData(this.state.env, this.state.token, true);
    if (scheduleGroupsBlob) {
      zipper.addToZip(scheduleGroupsBlob, scheduleGroupsFileName);
    }

    //PhoneBaseSettings
    this.setState({ preloader: true, preloaderMessage: 'Exporting Phone Base Settings' });
    let phoneBaseSettingsFileName = this.state.orgMe.name + `_` + `PhoneBaseSettings_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const phoneBaseSettingsBlob = await extractPhoneBaseSettingsData(this.state.env, this.state.token, true);
    if (phoneBaseSettingsBlob) {
      zipper.addToZip(phoneBaseSettingsBlob, phoneBaseSettingsFileName);
    }

    //Locations
    this.setState({ preloader: true, preloaderMessage: 'Exporting Locations' });
    let locationsFileName = this.state.orgMe.name + `_` + `Locations_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const locationsBlob = await extractLocations(this.state.env, this.state.token, true);
    if (locationsBlob) {
      zipper.addToZip(locationsBlob, locationsFileName);
    }

    //Dids
    this.setState({ preloader: true, preloaderMessage: 'Exporting DIDs' });
    let didsFileName = this.state.orgMe.name + `_` + `DIDs_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const didsBlob = await extractDidsData(this.state.env, this.state.token, true);
    if (didsBlob) {
      zipper.addToZip(didsBlob, didsFileName);
    }

    //Extensions
    this.setState({ preloader: true, preloaderMessage: 'Exporting Extensions' });
    let extensionsFileName = this.state.orgMe.name + `_` + `Extensions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const extensionsBlob = await extractExtensionsData(this.state.env, this.state.token, true);
    if (extensionsBlob) {
      zipper.addToZip(extensionsBlob, extensionsFileName);
    }

    //EdgeGroups
    this.setState({ preloader: true, preloaderMessage: 'Exporting Edge Groups' });
    let edgeGroupsFileName = this.state.orgMe.name + `_` + `EdgeGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const edgeGroupsBlob = await extractEdgeGroupsData(this.state.env, this.state.token, true);
    if (edgeGroupsBlob) {
      zipper.addToZip(edgeGroupsBlob, edgeGroupsFileName);
    }

    //IvrRouting
    this.setState({ preloader: true, preloaderMessage: 'Exporting IVR Routing' });
    let ivrRoutingFileName = this.state.orgMe.name + `_` + `IVR_Routing_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const ivrRoutingBlob = await extractIvrRoutingData(this.state.env, this.state.token, true);
    if (ivrRoutingBlob) {
      zipper.addToZip(ivrRoutingBlob, ivrRoutingFileName);
    }

    //Widgets
    this.setState({ preloader: true, preloaderMessage: 'Exporting Widgets' });
    let widgetsFileName = this.state.orgMe.name + `_` + `Widgets_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const widgetsBlob = await extractWidgetsData(this.state.env, this.state.token, true);
    if (widgetsBlob) {
      zipper.addToZip(ivrRoutingBlob, widgetsFileName);
    }

    //phones
    this.setState({ preloader: true, preloaderMessage: 'Exporting Phones' });
    let phonesFileName = this.state.orgMe.name + `_` + `Phones_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const phonesBlob = await extractPhonesData(this.state.env, this.state.token, true);
    if (phonesBlob) {
      zipper.addToZip(phonesBlob, phonesFileName);
    }

    //queues
    this.setState({ preloader: true, preloaderMessage: 'Exporting Queues' });
    let queuesFileName = this.state.orgMe.name + `_` + `Queues_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const queuesBlob = await extractQueuesData(this.state.env, this.state.token, true);
    if (queuesBlob) {
      zipper.addToZip(queuesBlob, queuesFileName);
    }

    //users Rate limit exceeded the maximum [300] requests within [60] seconds"
    this.setState({ preloader: true, preloaderMessage: 'Exporting Users' });
    let usersFileName = this.state.orgMe.name + `_` + `Users_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
    const usersBlob = await extractUsersData(this.state.env, this.state.token, true);
    if (usersBlob) {
      zipper.addToZip(usersBlob, usersFileName);
    }

    await zipper.exportZip(zipFileName);
    this.setState({ preloader: false, preloaderMessage: 'Loading' });
  };

  exportExtendedButtonClicked = async (objectType) => {
    this.eventList = [];
    let exportUri = '';
    this.setState({ preloader: true, preloaderMessage: 'Exporting Extended Attributes' });
    exportUri = this.getExportUri(objectType);
    if (exportUri != '') {
      try {
        let sessionId = sessionStorage.getItem('session_id');
        if (sessionId === null) {
          await addLoginSession('Login', 'user has logged in');
          sessionId = sessionStorage.getItem('session_id');
        }
        let response = await extendedBulkExport(this.state.env, this.state.token, this.state.userMe.email, this.state.orgMe.name, exportUri, sessionId);
        if (response.result != null) {
          this.showProgress(response.result.token);
        } else {
          response.messages.map((message) => {
            this.logEvent(message.content, true);
          });
        }
      } catch (ex) {
        this.logEvent(ex, true);
      }
    } else {
      this.logEvent(`Extended export is not available for ${objectType}`, true);
    }
    if (this.eventList.length > 0) {
      this.showEventList(this.eventList);
    }
    this.setState({ preloader: false });
  }

  showProgress = async (token) => {
    while (true) {
      let response = await checkProgress(this.state.userMe.email, this.state.orgMe.name, token);
      if (response.progress !== undefined) {
        await this.setState({ preloader: true, preloaderMessage: response.text + ' - ' + response.progress + '% ' });
        await this.sleep(3000);
      } else if (typeof (response) == typeof ('string')) {
        window.location.href = response;
        await this.setState({ preloader: false });
        break;
      }
    }
  }

  sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  handleInputChange = () => {
    this.setState({
      isExtendedExport: !this.state.isExtendedExport
    })
  }

  exportFunction = async () => {
    this.setState({ isBulkExportOpen: false });
    if (this.state.objectType == '') {
      if (!this.state.isExtendedExport) {
        this.exportButtonClicked()
      }
      else {
        this.exportExtendedButtonClicked(this.state.objectType);
      }
    } else if (this.state.objectType === 'Prompts') {
      this.exportExtendedButtonClicked(this.state.objectType);
    } else {
      if (!this.state.isExtendedExport) {
        this.handleExportObjects(this.state.objectType);
      } else if (this.state.objectType === 'Widgets'){
        this.handleExportObjects(this.state.objectType);
      }else {
        this.exportExtendedButtonClicked(this.state.objectType);
      }
    }
    this.setState({ isExtendedExport: false });
  }

  getExportUri = (objectType) => {
    if (objectType === '') {
      return '/export/bulk';
    } else if (objectType === 'Divisions') {
      return '/export/divisions';
    } else if (objectType === 'Skills') {
      return '/export/skills';
    } else if (objectType === 'Wrap-Up Codes') {
      return '/export/wrapupcodes';
    } else if (objectType === 'Roles') {
      return '/export/roles';
    } else if (objectType === 'Locations') {
      return '/export/locations';
    } else if (objectType === 'Sites') {
      return '/export/sites';
    } else if (objectType === 'DIDs') {
      return '/export/dids';
    } else if (objectType === 'Extensions') {
      return '/export/extensions';
    } else if (objectType === 'Edge Groups') {
      return '/export/edgegroups';
    } else if (objectType === 'Phones') {
      return '/export/phones';
    } else if (objectType === 'PhoneBase Settings') {
      return '/export/phonebasesettings';
    } else if (objectType === 'Queues') {
      return '/export/queues';
    } else if (objectType === 'Users') {
      return '/export/users';
    } else if (objectType === 'Schedules') {
      return '/export/schedules';
    } else if (objectType === 'Schedule Groups') {
      return '/export/schedulegroups';
    } else if (objectType === 'IVR Routing') {
      return '/export/ivrrouting';
    } else if (objectType === 'Prompts') {
      return '/export/prompts';
    }
  }

  handleExportObjects = async (objectType) => {
    if (objectType === 'Divisions') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Divisions' });
      let divisionsFileName = this.state.orgMe.name + `_` + `Divisions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const divisionsBlob = await extractDivisionsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(divisionsBlob, divisionsFileName);
    } else if (objectType === 'Skills') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Skills' });
      let skillsFileName = this.state.orgMe.name + `_` + `Skills_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const skillsBlob = await extractSkillsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(skillsBlob, skillsFileName);
    } else if (objectType === 'Wrap-Up Codes') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Wrap-up Codes' });
      let wrapUpCodesFileName = this.state.orgMe.name + `_` + `Wrap-Up Codes_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const codesBlob = await extractWrapUpCodesData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(codesBlob, wrapUpCodesFileName);
    } else if (objectType === 'Roles') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Roles' });
      let rolesFileName = this.state.orgMe.name + `_` + `Roles_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const rolesBlob = await extractRolesData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(rolesBlob, rolesFileName);
    } else if (objectType === 'Locations') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Locations' });
      let locationsFileName = this.state.orgMe.name + `_` + `Locations_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const locationsBlob = await extractLocations(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(locationsBlob, locationsFileName);
    } else if (objectType === 'Sites') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Sites' });
      let sitesFileName = this.state.orgMe.name + `_` + `Sites_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const sitesBlob = await extractSitesData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(sitesBlob, sitesFileName);
    } else if (objectType === 'DIDs') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting DIDs' });
      let didsFileName = this.state.orgMe.name + `_` + `DIDs_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const didsBlob = await extractDidsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(didsBlob, didsFileName);
    } else if (objectType === 'Extensions') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Extensions' });
      let extensionsFileName = this.state.orgMe.name + `_` + `Extensions_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const extensionsBlob = await extractExtensionsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(extensionsBlob, extensionsFileName);
    } else if (objectType === 'Edge Groups') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Edge Groups' });
      let edgeGroupsFileName = this.state.orgMe.name + `_` + `EdgeGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const edgeGroupsBlob = await extractEdgeGroupsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(edgeGroupsBlob, edgeGroupsFileName);
    } else if (objectType === 'Phones') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Phones' });
      let phonesFileName = this.state.orgMe.name + `_` + `Phones_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const phonesBlob = await extractPhonesData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(phonesBlob, phonesFileName);
    } else if (objectType === 'PhoneBase Settings') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Phone Base Settings' });
      let phoneBaseSettingsFileName = this.state.orgMe.name + `_` + `PhoneBaseSettings_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const phoneBaseSettingsBlob = await extractPhoneBaseSettingsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(phoneBaseSettingsBlob, phoneBaseSettingsFileName);
    } else if (objectType === 'Queues') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Queues' });
      let queuesFileName = this.state.orgMe.name + `_` + `Queues_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const queuesBlob = await extractQueuesData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(queuesBlob, queuesFileName);
    } else if (objectType === 'Users') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Users' });
      let usersFileName = this.state.orgMe.name + `_` + `Users_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const usersBlob = await extractUsersData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(usersBlob, usersFileName);
    } else if (objectType === 'Schedules') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Schedules' });
      let schedulesFileName = this.state.orgMe.name + `_` + `Schedules_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const schedulesBlob = await extractSchedulesData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(schedulesBlob, schedulesFileName);
    } else if (objectType === 'Schedule Groups') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Schedule Groups' });
      let scheduleGroupsFileName = this.state.orgMe.name + `_` + `ScheduleGroups_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const scheduleGroupsBlob = await extractScheduleGroupsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(scheduleGroupsBlob, scheduleGroupsFileName);
    } else if (objectType === 'IVR Routing') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting IVR Routing' });
      let ivrRoutingFileName = this.state.orgMe.name + `_` + `IVR_Routing_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const ivrRoutingBlob = await extractIvrRoutingData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(ivrRoutingBlob, ivrRoutingFileName);
    } else if (objectType === 'Widgets') {
      this.setState({ preloader: true, preloaderMessage: 'Exporting Widgets' });
      let widgetsFileName = this.state.orgMe.name + `_` + `Widgets_` + moment().format('YYYY-MM-DDTHH-mm-ss') + `.csv`;
      const widgetsBlob = await extractWidgetsData(this.state.env, this.state.token);
      this.setState({ preloader: false, preloaderMessage: 'Loading' });
      exportfile(widgetsBlob, widgetsFileName);
    }
  }

  handleImport = () => {
    this.setState({
      isBulkImportOpen: true,
    });
  }

  handleBulkExport = (objectType) => {
    this.setState({
      isBulkExportOpen: true,
      objectType: objectType
    });
  }

  handleDownloadOffTemplate = () => {
    window.open("./templates/bulk-import-template.zip");
  }


  render() {

    let manageGcTitle = '';
    let exportTitle = '';
    let templateTitle = '';
    let objectType = '';
    let notEligible = false;

    if (this.props.currPage === 0) {
      manageGcTitle = 'Navigate to Genesys Cloud for detailed Organization Information viewing and\\or Editing';
      exportTitle = 'Export all previously configured objects in a compressed .zip package';
      templateTitle = 'Download a .zip file containing templates for each ObjectType';
      objectType = '';
      notEligible = false;
    } else if (this.props.currPage === 1) {
      if (this.props.cardItem === 'Org') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Divisions information viewing and\\or Editing';
          exportTitle = 'Export previously configured Divisions in a  .csv file';
          templateTitle = 'Download a .csv import template for Divisions';
          objectType = 'Divisions';
          notEligible = false;
        } else if (this.props.currentSlide === 1) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Skills information viewing and\\or Editing';
          exportTitle = 'Export previously configured Skills in a  .csv file';
          templateTitle = 'Download a .csv import template for Skills';
          objectType = 'Skills';
          notEligible = false;
        } else if (this.props.currentSlide === 2) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Wrap-Up Codes information viewing and\\or Editing';
          exportTitle = 'Export previously configured Wrap-Up Codes in a  .csv file';
          templateTitle = 'Download a .csv import template for Wrap-Up Codes';
          objectType = 'Wrap-Up Codes';
          notEligible = false;
        } else if (this.props.currentSlide === 3) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Roles information viewing and\\or Editing';
          exportTitle = 'Export previously configured Roles in a  .csv file';
          templateTitle = 'Download a .csv import template for Roles';
          objectType = 'Roles';
          notEligible = false;
        }
      } else if (this.props.cardItem === 'locale') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Locations information viewing and\\or Editing';
          exportTitle = 'Export previously configured Locations in a  .csv file';
          templateTitle = 'Download a .csv import template for Locations';
          objectType = 'Locations';
          notEligible = false;
        } else if (this.props.currentSlide === 1) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Sites information viewing and\\or Editing';
          exportTitle = 'Export previously configured Sites in a  .csv file';
          templateTitle = 'Download a .csv import template for Sites';
          objectType = 'Sites';
          notEligible = false;
        }
      } else if (this.props.cardItem === 'telephony') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed DIDs information viewing and\\or Editing';
          exportTitle = 'Export previously configured DIDs in a  .csv file';
          templateTitle = 'Download a .csv import template for DIDs';
          objectType = 'DIDs';
          notEligible = false;
        } else if (this.props.currentSlide === 1) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Extensions information viewing and\\or Editing';
          exportTitle = 'Export previously configured Extensions in a  .csv file';
          templateTitle = 'Download a .csv import template for Extensions';
          objectType = 'Extensions';
          notEligible = false;
        } else if (this.props.currentSlide === 2) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Edge Groups information viewing and\\or Editing';
          exportTitle = 'Export previously configured Edge Groups in a  .csv file';
          templateTitle = 'Download a .csv import template for Edge Groups';
          objectType = 'Edge Groups';
          notEligible = false;
        } else if (this.props.currentSlide === 3) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed PhoneBase Settings information viewing and\\or Editing';
          exportTitle = 'Export previously configured PhoneBase Settings in a  .csv file';
          templateTitle = 'Download a .csv import template for PhoneBase Settings';
          objectType = 'PhoneBase Settings';
          notEligible = false;
        } else if (this.props.currentSlide === 4) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Phones information viewing and\\or Editing';
          exportTitle = 'Export previously configured Phones in a  .csv file';
          templateTitle = 'Download a .csv import template for Phones';
          objectType = 'Phones';
          notEligible = false;
        }
      } else if (this.props.cardItem === 'people') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Queues information viewing and\\or Editing';
          exportTitle = 'Export previously configured Queues in a  .csv file';
          templateTitle = 'Download a .csv import template for Queues';
          objectType = 'Queues';
          notEligible = false;
        } else if (this.props.currentSlide === 1) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Users information viewing and\\or Editing';
          exportTitle = 'Export previously configured Users in a  .csv file';
          templateTitle = 'Download a .csv import template for Users';
          objectType = 'Users';
          notEligible = false;
        }
      } else if (this.props.cardItem === 'schedule') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Schedules information viewing and\\or Editing';
          exportTitle = 'Export previously configured Schedules in a  .csv file';
          templateTitle = 'Download a .csv import template for Schedules';
          objectType = 'Schedules';
          notEligible = false;
        } else if (this.props.currentSlide === 1) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Schedule Groups information viewing and\\or Editing';
          exportTitle = 'Export previously configured Schedule Groups in a  .csv file';
          templateTitle = 'Download a .csv import template for Schedule Groups';
          objectType = 'Schedule Groups';
          notEligible = false;
        } else if (this.props.currentSlide === 2) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed IVR Routing information viewing and\\or Editing';
          exportTitle = 'Export previously configured IVR Routings in a  .csv file';
          templateTitle = 'Download a .csv import template for IVR Routing';
          objectType = 'IVR Routing';
          notEligible = false;
        } else if (this.props.currentSlide === 3) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Widgets information viewing and\\or Editing';
          exportTitle = 'Export previously configured Widgets in a  .csv file';
          templateTitle = 'Download a .csv import template for IVR Routing';
          objectType = 'Widgets';
          notEligible = false;
        }
      } else if (this.props.cardItem === 'callFlows') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Design Flows information viewing and\\or Editing';
          exportTitle = 'Export previously configured Design Flows in a  .csv file';
          templateTitle = 'Download a .csv import template for Design Flows';
          objectType = 'Design Flows';
          notEligible = true;
        } else if (this.props.currentSlide === 1) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Callflows information viewing and\\or Editing';
          exportTitle = 'Export previously configured Callflows in a  .csv file';
          templateTitle = 'Download a .csv import template for Callflows';
          objectType = 'Callflows';
          notEligible = true;
        }
      } else if (this.props.cardItem === 'announcements') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Prompts information viewing and\\or Editing';
          exportTitle = 'Export previously configured Prompts in a  .csv file';
          templateTitle = 'Download a .csv import template for Prompts';
          objectType = 'Prompts';
          notEligible = false;
        }
      } else if (this.props.cardItem === 'outbound') {
        if (this.props.currentSlide === 0) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Campaigns information viewing and\\or Editing';
          exportTitle = 'Export previously configured Campaigns in a  .csv file';
          templateTitle = 'Download a .csv import template for Campagins';
          objectType = 'Campagins';
          notEligible = true;
        } else if (this.props.currentSlide === 1) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Contact Lists information viewing and\\or Editing';
          exportTitle = 'Export previously configured Contact Lists in a  .csv file';
          templateTitle = 'Download a .csv import template for Contact Lists';
          objectType = 'Contact Lists';
          notEligible = true;
        } else if (this.props.currentSlide === 2) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Queues information viewing and\\or Editing';
          exportTitle = 'Export previously configured Queues in a  .csv file';
          templateTitle = 'Download a .csv import template for Queues';
          objectType = 'Queues';
          notEligible = true;
        } else if (this.props.currentSlide === 3) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed WrapCode Mappings information viewing and\\or Editing';
          exportTitle = 'Export previously configured WrapCode Mappings in a  .csv file';
          templateTitle = 'Download a .csv import template for WrapCode Mappings';
          objectType = 'WrapCode Mappings';
          notEligible = true;
        } else if (this.props.currentSlide === 4) {
          manageGcTitle = 'Navigate to Genesys Cloud for detailed Campaigns information viewing and\\or Editing';
          exportTitle = 'Export previously configured Campaigns in a  .csv file';
          templateTitle = 'Download a .csv import template for Campagins';
          objectType = 'Campagins';
          notEligible = true;
        }
      }
    }
    //----------------------------------------------
    if (this.props.modeProp === 'authorized') {
      return (
        <div>
          {/* <event list viewer> */}
          <EventListViewer title="Export Error" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
          {/* </event list viewer>  */}

          {/* <preloader> */}
          <Preloader show={this.state.preloader} text={this.state.preloaderMessage} />
          {/* </preloader> */}

          {/* <Organization Information> */}
          <OrgInformation regionName={this.state.regionName} orgName={this.state.orgMe.name} userName={this.state.userMe.name} isOpen={this.state.isOrgOpen} moreFunction={this.moreFunction} closeFunction={() => { this.setState({ isOrgOpen: false }) }} />
          {/* <Organization Information> */}

          {/* <Organization Properties> */}
          <OrgProperties orgFeatures={this.state.orgFeatures} orgProperties={this.state.orgProperties} isOpen={this.state.isOrgPropsOpen} closeFunction={() => { this.setState({ isOrgPropsOpen: false }) }} />
          {/* <Organization Properties> */}

          {
            this.state.isBulkImportOpen &&
            <BulkImport isOpen={this.state.isBulkImportOpen} closeFunction={() => { this.setState({ isBulkImportOpen: false }) }} />
          }

          {
            this.state.isBulkExportOpen &&
            <BulkExport isOpen={this.state.isBulkExportOpen}
              isExtendedExport={this.state.isExtendedExport}
              handleInputChange={this.handleInputChange}
              exportFunction={this.exportFunction}
              closeFunction={() => { this.setState({ isBulkExportOpen: false, isExtendedExport: false }) }} />
          }

          <Navbar dark expand="md" className="navbar">
            <NavbarBrand href="/" className="navbrand">
              <img src="/images/genesys_name.png" alt="Genesys Logo"></img>
            </NavbarBrand>
            <Nav className="ml-auto" navbar>
              <Dropdown nav isOpen={this.state.isToolsDropdownOpen} toggle={() => { this.setState({ isToolsDropdownOpen: !this.state.isToolsDropdownOpen }) }}>
                <DropdownToggle nav className='tools' >
                  <img src="/images/Tools.png" alt="Tools Image"></img>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={() => { this.handleOrgInfo() }}>Organization Information</DropdownItem>
                  <DropdownItem onClick={() => { this.handleManageGC() }}>Manage in Genesys Cloud</DropdownItem>
                  {
                    this.props.currPage === 0 ?
                      <DropdownItem onClick={() => { this.handleBulkExport(objectType) }}>Export All</DropdownItem> :
                      <DropdownItem disabled={notEligible} onClick={() => { this.handleBulkExport(objectType) }}>Export {objectType}</DropdownItem>
                  }
                  {
                    this.props.currPage === 0
                    &&
                    <DropdownItem onClick={() => { this.handleImport() }}>Import</DropdownItem>
                  }
                  {
                    <DropdownItem onClick={() => { this.handleDownloadOffTemplate() }}>Download Offline Template</DropdownItem>
                  }
                </DropdownMenu>
              </Dropdown>
              <Dropdown nav isOpen={this.state.isUserDropdownOpen} toggle={() => { this.setState({ isUserDropdownOpen: !this.state.isUserDropdownOpen }) }}>
                <DropdownToggle nav className='menu'>
                  ...
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem disabled><FaGlobeAmericas /> {this.state.regionName}</DropdownItem>
                  <DropdownItem disabled><FaBuilding /> {this.state.orgMe.name}</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem title="About Genesys Cloud Ignite" onClick={() => { this.aboutPureCloud() }}><FaAnchor /> About {this.state.version.prod ? `(Build ${this.state.version.version})` : '(dev)'}</DropdownItem>
                  <DropdownItem title="Genesys Cloud Ignite Users Guide" onClick={() => { this.PureCloudUsersGuide() }}><FaBook /> Users Guide</DropdownItem>
                  <DropdownItem onClick={() => { pureCloudLogout() }}><FaSignOutAlt /> Logout {this.state.userMe.name}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <img alt="user" hidden={this.state.userPicture === ''} src={this.state.userPicture} className="user-picture" />
            </Nav>
          </Navbar>
        </div>
      )
    }
    //----------------------------------------------
    if (this.props.modeProp === 'unauthorized') {
      return (
        <div>
          <Navbar dark expand="md" className="navbar">
            <NavbarBrand href="/" className="navbrand">
              <img src="/images/genesys_name.png" alt="Genesys Logo"></img>
            </NavbarBrand>
            <Nav className="ml-auto" navbar>
              <Dropdown nav isOpen={this.state.isRegionDropdownOpen} toggle={() => { this.setState({ isRegionDropdownOpen: !this.state.isRegionDropdownOpen }) }}>
                <DropdownToggle nav caret>
                  <FaGlobeAmericas />
                  {getSelectedRegionDisplay()}
                </DropdownToggle>
                <DropdownMenu right>
                  {
                    Object.values(this.getPureCloudRegionList()).map((e) => {
                      return <DropdownItem id={e.name} key={e.name} onClick={this.setPureCloudRegion(e.name, e.display)}>{e.display}</DropdownItem>;
                    })
                  }
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Navbar>
        </div>
      )
    }
    //----------------------------------------------
    return (
      <div>
        <Navbar dark expand="md" className="navbar">
          <NavbarBrand href="/" className="navbrand">
            <img src="/images/genesys_name.png" alt="Genesys Logo"></img>
          </NavbarBrand>
        </Navbar>
      </div>
    )
    //----------------------------------------------
  }
}