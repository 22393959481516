import './phonesInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, FormGroup, Col, Label, Input, Row } from 'reactstrap'

export default class PhonesInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.phonesName !== '' && this.props.phoneBaseSettings !== '' && this.props.site !== '' && this.props.lineBaseSettings !== '') {
            isButtonEnabled = true;
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="phonesModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label for="phonesName">Name:<span className="text-danger font-weight-bold">*</span></Label>
                                        <Input invalid={this.props.phonesName === '' ? true : false} value={this.props.phonesName} onChange={this.props.handleInputChange('phonesName')} type="text" id="phonesName" placeholder="Enter the Phones name" />
                                    </Col>
                                    <Col md={6}>
                                        <Label for="remoteAddress">Remote Address:</Label>
                                        <Input value={this.props.remoteAddress} onChange={this.props.handleInputChange('remoteAddress')} type="text" id="remoteAddress" placeholder="Remote Address" />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Phone Base Settings:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.phoneBaseSettings} onChange={this.props.handleInputChange('phoneBaseSettings')}>
                                            <option key="PB1" hidden>None Selected</option>
                                            {
                                                this.props.phoneBaseSettingsList.map((phoneBase) => (
                                                    <option key={phoneBase.id}>{phoneBase.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label>Line Base Settings:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.lineBaseSettings} onChange={this.props.handleInputChange('lineBaseSettings')}>
                                            <option key="LB1" hidden>None Selected</option>
                                            {
                                                this.props.lineBaseSettingsList.map((lineBase) => (
                                                    <option key={lineBase.id}>{lineBase.name}</option>
                                                ))
                                            }
                                        </select>

                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <Label>Site:<span className="text-danger font-weight-bold">*</span></Label>
                                        <select className="form-control selectpicker" value={this.props.site} onChange={this.props.handleInputChange('site')}>
                                            <option key="S1" hidden>Select a Site</option>
                                            {
                                                this.props.phonesSites.map((site) => (
                                                    <option key={site.id}>{site.name}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                    <Col md={6}>
                                        <Label>Person:</Label>
                                        <select className="form-control selectpicker" value={this.props.person} onChange={this.props.handleInputChange('person')}>
                                            <option key="U1" hidden>Select a User</option>
                                            {
                                                this.props.usersList.map((user) => (
                                                    <option key={user.id}>{user.name}</option>
                                                ))
                                            }
                                        </select>

                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
