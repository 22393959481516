import './schedulesInfo.css'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Button, ModalFooter, Form, Col, Label, Input } from 'reactstrap'
import { FaMinus } from 'react-icons/fa';

export default class SchedulesInfo extends React.Component {
    render() {

        let isButtonEnabled = false;
        if (this.props.scheduleName !== '' && this.props.startDate !== '') {
            isButtonEnabled = true;
        }

        if (this.props.noEndDate === false && this.props.endDate === '') {
            isButtonEnabled = false;
        }

        if (this.props.repeatingEvent) {
            if (this.props.interval === '' || this.props.recurrenceType === '') {
                isButtonEnabled = false;
            }
            if (this.props.recurrenceType === 'MONTHLY' && this.props.monthlyRecurrenceType === 'On' && this.props.byMonthDay === '') {
                isButtonEnabled = false;
            } else if (this.props.recurrenceType === 'YEARLY' && this.props.yearlyRecurrenceType === 'On' && this.props.byYearDay === '') {
                isButtonEnabled = false;
            }
        }

        return (
            <div>
                <Modal isOpen={this.props.isOpen} className="schedulesModal">
                    <ModalHeader>{this.props.title + ' Information'}</ModalHeader>
                    <ModalBody>
                        <Form className="modal-body form-horizontal" id="modal-form">
                            <div className="form-group">
                                <div className="form-group row">
                                    <Label sm={3} for="scheduleName">Schedule Name:<span className="text-danger font-weight-bold">*</span></Label>
                                    <Col sm={9}>
                                        <Input invalid={this.props.scheduleName === '' ? true : false} value={this.props.scheduleName} onChange={this.props.handleInputChange('scheduleName')} type="text" id="scheduleName" placeholder="Enter the name of this schedule (e.g. Open Hours Weekday)" />
                                    </Col>
                                </div>
                                <div className="form-check row">
                                    <div className="form-group col-md-12">
                                        <Input className="form-check-input" type="checkbox" name="check" id="repeatingEvent" checked={this.props.repeatingEvent} onChange={this.props.handleInputChange('repeatingEvent')} />
                                        <Label className="form-check-label" for="repeatingEvent" check>Repeating Event</Label>
                                    </div>
                                </div>
                                {/* Repeating Event */}
                                {
                                    this.props.repeatingEvent
                                    &&
                                    <div id="repeatingEventDiv">
                                        <div className="form-group row">
                                            <label for="interval" className="col-sm-3 col-form-label">Repeats every:<span className="text-danger font-weight-bold">*</span></label>
                                            <div className="col-sm-9 row">
                                                <input className="form-control col-md-3" value={this.props.interval} onChange={this.props.handleInputChange('interval')} type="number" id="interval" min="0" />
                                                <select className="form-control col-md-3 ml-1" value={this.props.recurrenceType} id="recurrenceType" name="recurrenceType" onChange={this.props.handleInputChange('recurrenceType')}>
                                                    <option key="R1" hidden>Frequency</option>
                                                    <option key="R2">DAILY</option>
                                                    <option key="R3">WEEKLY</option>
                                                    <option key="R4">MONTHLY</option>
                                                    <option key="R5">YEARLY</option>
                                                </select>
                                                {
                                                    this.props.recurrenceType === 'YEARLY'
                                                    &&
                                                    <div id="yearMonth" className="col-md-3">
                                                        <select className="form-control selectpicker" name="yearMonthSelect" id="yearMonthSelect" value={this.props.yearMonthSelect} onChange={this.props.handleInputChange('yearMonthSelect')} required>
                                                            <option key="M0" hidden>Month</option>
                                                            <option key="M1">January</option>
                                                            <option key="M2">February</option>
                                                            <option key="M3">March</option>
                                                            <option key="M4">April</option>
                                                            <option key="M5">May</option>
                                                            <option key="M6">June</option>
                                                            <option key="M7">July</option>
                                                            <option key="M8">August</option>
                                                            <option key="M9">September</option>
                                                            <option key="M10">October</option>
                                                            <option key="M11">November</option>
                                                            <option key="M12">December</option>
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {/* Weekly */}
                                        {
                                            this.props.recurrenceType === 'WEEKLY'
                                            &&
                                            <div className="form-group row" id="weeklyControlsDiv">
                                                <label for="weeklyDays" className="col-sm-2 offset-sm-3 col-form-label">Every</label>
                                                <div className="btn-group" id="weeklyDays">
                                                    <label className="btn btn-primary"><input type="checkbox" id="weeklyMonday" checked={this.props.weeklyMonday} onChange={this.props.handleInputChange('weeklyMonday')} />Mo</label>
                                                    <label className="btn btn-primary"><input type="checkbox" id="weeklyTuesday" checked={this.props.weeklyTuesday} onChange={this.props.handleInputChange('weeklyTuesday')} />Tu</label>
                                                    <label className="btn btn-primary"><input type="checkbox" id="weeklyWednesday" checked={this.props.weeklyWednesday} onChange={this.props.handleInputChange('weeklyWednesday')} />We</label>
                                                    <label className="btn btn-primary"><input type="checkbox" id="weeklyThursday" checked={this.props.weeklyThursday} onChange={this.props.handleInputChange('weeklyThursday')} />Th</label>
                                                    <label className="btn btn-primary"><input type="checkbox" id="weeklyFriday" checked={this.props.weeklyFriday} onChange={this.props.handleInputChange('weeklyFriday')} />Fr</label>
                                                    <label className="btn btn-primary"><input type="checkbox" id="weeklySaturday" checked={this.props.weeklySaturday} onChange={this.props.handleInputChange('weeklySaturday')} />Sa</label>
                                                    <label className="btn btn-primary"><input type="checkbox" id="weeklySunday" checked={this.props.weeklySunday} onChange={this.props.handleInputChange('weeklySunday')} />Su</label>
                                                </div>
                                            </div>
                                        }
                                        {/* Monthly */}
                                        {
                                            this.props.recurrenceType === 'MONTHLY'
                                            &&
                                            <div className="form-group row" id="monthlyControlsDiv">
                                                <div className="col-md-3"></div>
                                                <select value={this.props.monthlyRecurrenceType} onChange={this.props.handleInputChange('monthlyRecurrenceType')} name="monthlyRecurrenceType" id="monthlyRecurrenceType" className="form-control col-md-2 mr-1" required>
                                                    <optgroup key="MR1" label="Date">
                                                        <option>On</option>
                                                    </optgroup>
                                                    <optgroup key="MR2" label="Day of the Week">
                                                        <option key="IfDayIs">If Day is</option>
                                                        <option key="First">First</option>
                                                        <option key="Second">Second</option>
                                                        <option key="Third">Third</option>
                                                        <option key="Fourth">Fourth</option>
                                                        <option key="Last">Last</option>
                                                    </optgroup>
                                                </select>

                                                {
                                                    this.props.monthlyRecurrenceType === 'On'
                                                        ?
                                                        <div className="col-md-6 row" id="byMonthDayDiv">
                                                            <Input invalid={this.props.byMonthDay === '' ? true : false} value={this.props.byMonthDay} onChange={this.props.handleInputChange('byMonthDay')} type="text" className="form-control col-md-3" id="byMonthDay" name="byMonthDay" required data-toggle="tooltip" data-placement="top" title="Add a single day (e.g. 24) or mutliple days (e.g. 24,25,26)" />
                                                        </div>
                                                        :
                                                        <div className="col-md-6 row" id="byDayDiv">
                                                            <div className="btn-group" id="byDay">
                                                                <label className="btn btn-primary"><input type="checkbox" id="monthlyMonday" checked={this.props.monthlyMonday} onChange={this.props.handleInputChange('monthlyMonday')} />Mo</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="monthlyTuesday" checked={this.props.monthlyTuesday} onChange={this.props.handleInputChange('monthlyTuesday')} /> Tu</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="monthlyWednesday" checked={this.props.monthlyWednesday} onChange={this.props.handleInputChange('monthlyWednesday')} />We</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="monthlyThursday" checked={this.props.monthlyThursday} onChange={this.props.handleInputChange('monthlyThursday')} /> Th</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="monthlyFriday" checked={this.props.monthlyFriday} onChange={this.props.handleInputChange('monthlyFriday')} /> Fr</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="monthlySaturday" checked={this.props.monthlySaturday} onChange={this.props.handleInputChange('monthlySaturday')} /> Sa</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="monthlySunday" checked={this.props.monthlySunday} onChange={this.props.handleInputChange('monthlySunday')} /> Su</label>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        }
                                        {/* Yearly */}
                                        {
                                            this.props.recurrenceType === 'YEARLY'
                                            &&
                                            <div className="form-group row" id="yearlyControlsDiv">
                                                <div className="col-md-3"></div>
                                                <select value={this.props.yearlyRecurrenceType} onChange={this.props.handleInputChange('yearlyRecurrenceType')} name="yearlyRecurrenceType" id="yearlyRecurrenceType" className="form-control col-md-2 mr-1" required>
                                                    <optgroup key="YR1" label="Date">
                                                        <option>On</option>
                                                    </optgroup>
                                                    <optgroup key="YR2" label="Day of the Week">
                                                        <option key="IfDayIs">If Day is</option>
                                                        <option key="First">First</option>
                                                        <option key="Second">Second</option>
                                                        <option key="Third">Third</option>
                                                        <option key="Fourth">Fourth</option>
                                                        <option key="Last">Last</option>
                                                    </optgroup>
                                                </select>

                                                {
                                                    this.props.yearlyRecurrenceType === 'On'
                                                        ?
                                                        <div className="col-md-6 row" id="byYearDayDiv">
                                                            <Input invalid={this.props.byYearDay === '' ? true : false} value={this.props.byYearDay} onChange={this.props.handleInputChange('byYearDay')} type="text" className="form-control col-md-3" id="byYearDay" name="byYearDay" required data-toggle="tooltip" data-placement="top" title="Add a single day (e.g. 24) or mutliple days (e.g. 24,25,26)" />
                                                        </div>
                                                        :
                                                        <div className="col-md-6 row" id="byDayDiv">
                                                            <div className="btn-group" id="byYearDay">
                                                                <label className="btn btn-primary"><input type="checkbox" id="yearlyMonday" checked={this.props.yearlyMonday} onChange={this.props.handleInputChange('yearlyMonday')} />Mo</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="yearlyTuesday" checked={this.props.yearlyTuesday} onChange={this.props.handleInputChange('yearlyTuesday')} /> Tu</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="yearlyWednesday" checked={this.props.yearlyWednesday} onChange={this.props.handleInputChange('yearlyWednesday')} />We</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="yearlyThursday" checked={this.props.yearlyThursday} onChange={this.props.handleInputChange('yearlyThursday')} /> Th</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="yearlyFriday" checked={this.props.yearlyFriday} onChange={this.props.handleInputChange('yearlyFriday')} /> Fr</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="yearlySaturday" checked={this.props.yearlySaturday} onChange={this.props.handleInputChange('yearlySaturday')} /> Sa</label>
                                                                <label className="btn btn-primary"><input type="checkbox" id="yearlySunday" checked={this.props.yearlySunday} onChange={this.props.handleInputChange('yearlySunday')} /> Su</label>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {/* No End Date */}
                                {
                                    this.props.repeatingEvent
                                    &&
                                    <div className="form-check row noEndRow">
                                        <div className="form-group col-md-12">
                                            <input className="form-check-input" type="checkbox" id="noEndDate" checked={this.props.noEndDate} onChange={this.props.handleInputChange('noEndDate')} />
                                            <label className="form-check-label" for="noEndDate">No End Date</label>
                                        </div>
                                    </div>
                                }
                                {/* All Day */}
                                <div className="form-check row">
                                    <div className="form-group col-md-12">
                                        <input className="form-check-input" type="checkbox" id="allDay" checked={this.props.allDay} onChange={this.props.handleInputChange('allDay')} />
                                        <label className="form-check-label" for="allDay">All Day</label>
                                    </div>
                                </div>

                                {
                                    !this.props.allDay
                                    &&
                                    <div className="form-group row">
                                        <label className="control-label col-md-1">Time:</label>
                                        <div style={{ marginRight: "5px" }}>
                                            <input value={this.props.startTime} onChange={this.props.handleInputChange('startTime')} type="time" id="startTime" name="startTime"></input>
                                        </div>
                                        <div style={{ marginRight: "5px" }}>
                                            <FaMinus />
                                        </div>
                                        <div>
                                            <input value={this.props.endTime} onChange={this.props.handleInputChange('endTime')} type="time" id="endTime" name="endTime"></input>
                                        </div>
                                    </div>
                                }

                                {/* Start Date */}
                                <div className="form-group row">
                                    <label className="col-sm-1 col-form-label">Start:<span className="text-danger font-weight-bold">*</span></label>
                                    <input value={this.props.startDate} onChange={this.props.handleInputChange('startDate')} className="col-sm-3 datetimepicker-input" type="date" id="startDate" name="startDate"></input>
                                </div>

                                {/* End Date */}
                                {
                                    !this.props.noEndDate
                                    &&
                                    <div className="form-group row">
                                        <label className="col-sm-1 col-form-label">End:<span className="text-danger font-weight-bold">*</span></label>
                                        <input value={this.props.endDate} onChange={this.props.handleInputChange('endDate')} className="col-sm-3 datetimepicker-input" type="date" id="endDate" name="endDate"></input>
                                    </div>
                                }
                            </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.props.isSaveLocale
                                ?
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.updateFunction} disabled={!isButtonEnabled}>Update</Button>
                                :
                                <Button style={{backgroundColor:"#061e45" }} onClick={this.props.submitFunction} disabled={!isButtonEnabled}>Save</Button>
                        }
                        <Button style={{backgroundColor:"#061e45" }} onClick={this.props.closeFunction}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
