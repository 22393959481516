import './cardbar.css';
import React from 'react';
import { Container, Row, Col, Card, CardImg, CardTitle, CardBody, CardText } from 'reactstrap';

export default class Cardbar extends React.Component {

    render() {
        return (
            <div>
            <h4 className = 'badge'>Hello. <span className = 'badgetext'>Select from the Tiles to start your Genesys Cloud set-up</span></h4>
            <Container fluid  className="containerCard">
                <Row xs="1" sm="2" md="4" className="firstrowCard">
                    <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('Org')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('Org')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number1.png" alt="loading"></img>
                                    </span>
                                }

                                <CardImg className="cardImage" src="/images/org.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">Organizational Settings</CardTitle>
                                    <CardText className = 'cardText'>Create common settings such as Divisions, Skills, Wrap Code and Roles</CardText>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                    <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('locale')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('locale')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number2.png" alt="loading"></img>
                                    </span>
                                }
                                <CardImg className="cardImage" src="/images/locale.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">Locations</CardTitle>
                                    <CardText className = 'cardText'>Locale information for your Sites</CardText>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('people')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('people')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number3.png" alt="loading"></img>
                                    </span>
                                }
                                <CardImg className="cardImage" src="/images/people.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">People</CardTitle>
                                    <CardText className = 'cardText'>User settings, Queues</CardText>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('telephony')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('telephony')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number4.png" alt="loading"></img>
                                    </span>
                                }
                                <CardImg className="cardImage" src="/images/telephony.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">Telephony</CardTitle>
                                    <CardText className = 'cardText'>Phone settings, number ranges and DIDs </CardText>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                </Row>
                <Row xs="1" sm="2" md="4" className = 'secondrowCard'>
                <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('announcements')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('announcements')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number5.png" alt="loading"></img>
                                    </span>
                                }
                                <CardImg className="cardImage" src="/images/announcements.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">Announcements</CardTitle>
                                    <CardText className = 'cardText'>Upload your prompts or use TTS</CardText>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('callFlows')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('callFlows')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number6.png" alt="loading"></img>
                                    </span>
                                }
                                <CardImg className="cardImage" src="/images/callflows.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">Call Flows</CardTitle>
                                    <CardText className = 'cardText'>Design your Call Flow Experience</CardText>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('schedule')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('schedule')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number7.png" alt="loading"></img>
                                    </span>
                                }
                                <CardImg className="cardImage" src="/images/sch.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">Schedules and Routing</CardTitle>
                                    <CardText className = 'cardText'>Set-up Schedules, including Operating Hours and Holidays</CardText>
                                </CardBody>
                            </Card>
                        </div>

                    </Col>
                    <Col>
                        <div className="cardBarDiv" onClick={() => this.props.cardOnSelect('outbound')}>
                            <Card className="cardBar">
                                {
                                    this.props.finishedCards.includes('outbound')
                                    ?
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/tickMark.ico" alt="loading"></img>
                                    </span>
                                    :
                                    <span className="checkmark">
                                        <img className="tickMarkImage" src="/images/number8.png" alt="loading"></img>
                                    </span>
                                }
                                <CardImg className="cardImage" src="/images/outbound.png" alt="Card image cap" />
                                <CardBody className="cardBody">
                                    <CardTitle tag="h6">Outbound</CardTitle>
                                    <CardText className = 'cardText'>Campaigns, campaign groups and treatments</CardText>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
        )
    }
}