// *******************************************************************************************
//
//    code from:
//    https://medium.com/@650egor/simple-drag-and-drop-file-upload-in-react-2cb409d88929
//
// *******************************************************************************************

import './dragAndDrop.css'
import React, { Component } from 'react'

class DragAndDrop extends Component {

  state = {
    drag: false
  }

  dropRef = React.createRef()

  dragCounter = 0

  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  handleDragIn = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: true })
    // this.dragCounter++
    // if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
    //   this.setState({ drag: true })
    // }
  }

  handleDragOut = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    // this.dragCounter--
    // if (this.dragCounter === 0) {
    //   this.setState({ drag: false })
    // }
  }

  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ drag: false })
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
      this.dragCounter = 0
    }
  }

  componentDidMount() {
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }

  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }

  render() {
    return (

      <div ref={this.dropRef} className={this.state.drag ? 'draganddrop-dropping ' : 'draganddrop-no-dropping ' }>
        {this.props.children}
      </div>

    )
  }
}

export default DragAndDrop