import './campTypes.css'
import React from 'react'
import { FaCog, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, Label, FormGroup, Input, Badge, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, getUserMe, getOrgMe, logActionData } from '../../../../services/session/session';
import { downloadSubsFile, publishCallFlows, checkProgress } from '../../../../services/purecloud/flows'
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import { getEdgeGroups, getAgentScripts, postAgentScripts, publishAgentScripts, getCallResponseSets, getSmsPhoneNumbers, getOutboundFlows, postCallResponseAnalysisSets, postOutboundFlow } from '../../../../services/purecloud/outbound';
import moment from 'moment';
import ImageMapper from 'react-image-mapper';
import AddNewItem from './AddNewItem/addNewItem'
import exportfile from '../../../../services/misc/fileDownload';
import Misc from '../../../../services/misc/misc';

export default class CampTypes extends React.Component {

    state = {
        env: '',
        token: '',
        userMe: {},
        orgMe: {},
        campType: '',
        preloaderLocalShow: false,
        isInfoOpen: false,
        disableScriptSelector: false,
        edgeGroups: [],
        responseSets: [],
        scripts: [],
        scriptsList: [],
        selectedEdgeGroup: undefined,
        selectedResponseSet: undefined,
        edgeGroupsList: [],
        responseSetsList: [],
        selectedScript: undefined,
        scriptName: '',
        eventListIsOpen: false,
        eventList: [],
        selectedSmsPhoneNumber: undefined,
        smsPhoneNumbers: [],
        smsPhoneNumbersList: [],
        noAnswerTimeout: undefined,
        isModalOpen: false,
        callResponseName: '',
        outboundFlows: [],
        outboundFlowsList: [],
        selectedoutboundFlows: undefined,
        flowName: '',
        disableOutboundRow: false,
        subsFileName: '',
        subsFileContent: {},
        preloaderShow: false,
        preloaderText: 'Loading',
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }
    eventList = [];
    campaign = {};
    isPreviewCampType = false;

    logEvent = (message, isError = false) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }
    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    componentDidMount = async () => {
        await this.setState({ preloaderLocalShow: true });
        await this.loadUser();
        await this.loadItems();
        await this.setState({ preloaderLocalShow: false });
    }

    loadItems = async () => {
        await this.loadEdgeGroups();
        await this.loadAgentScripts();
        await this.loadCallResponseSets();
        await this.loadSmsPhoneNumbers();
        await this.loadOutboundFlows();
    }

    loadEdgeGroups = async () => {
        try {
            let response = await getEdgeGroups(this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (response.messages.length === 0) {
                if (response.result.entities !== null && response.result.entities !== undefined) {
                    let edgeAroupArray = [];
                    for (const edgeGroup in response.result.entities) {
                        const element = response.result.entities[edgeGroup];
                        edgeAroupArray.push(element.name)
                    }
                    this.setState({
                        edgeGroups: edgeAroupArray,
                        edgeGroupsList: response.result.entities
                    })
                }
            } else {
                response.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            this.logEvent(err, true);
        }
    }

    loadAgentScripts = async () => {
        try {
            let scriptResponse = await getAgentScripts(this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (scriptResponse.messages.length === 0) {
                if (scriptResponse.result.entities !== null && scriptResponse.result.entities !== undefined) {
                    let scriptsArray = [];
                    for (const script in scriptResponse.result.entities) {
                        const element = scriptResponse.result.entities[script];
                        scriptsArray.push(element.name)
                    }
                    this.setState({
                        scripts: scriptsArray,
                        scriptsList: scriptResponse.result.entities
                    })
                }
            } else {
                scriptResponse.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            console.log(err);
            this.logEvent(err, true);
        }
    }

    loadCallResponseSets = async () => {
        try {
            let responseSets = await getCallResponseSets(this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (responseSets.messages.length === 0) {
                if (responseSets.result !== null && responseSets.result !== undefined) {
                    let responseSetsArray = [];
                    for (const responseSet in responseSets.result.entities) {
                        const element = responseSets.result.entities[responseSet];
                        responseSetsArray.push(element.name)
                    }
                    this.setState({
                        responseSets: responseSetsArray,
                        responseSetsList: responseSets.result.entities
                    })
                }
            } else {
                responseSets.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            console.log(err);
            this.logEvent(err, true);
        }
    }

    loadSmsPhoneNumbers = async () => {
        try {
            let response = await getSmsPhoneNumbers(this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (response.messages.length === 0) {
                if (response.result.entities !== null && response.result.entities !== undefined) {
                    let smsPhoneNumbers = [];
                    for (const smsPhoneNumber in response.result.entities) {
                        const element = response.result.entities[smsPhoneNumber];
                        smsPhoneNumbers.push(element.phoneNumber)
                    }
                    this.setState({
                        smsPhoneNumbers: smsPhoneNumbers,
                        smsPhoneNumbersList: response.result.entities
                    })
                }
            } else {
                response.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            this.logEvent(err, true);
        }
    }

    loadOutboundFlows = async () => {
        await this.setState({ preloaderLocalShow: true });
        try {
            let response = await getOutboundFlows(this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (response.messages.length === 0) {
                if (response.result.entities !== null && response.result.entities !== undefined) {
                    let outboundFlowsArray = [];
                    for (const outboundFlow in response.result.entities) {
                        const element = response.result.entities[outboundFlow];
                        outboundFlowsArray.push(element.name)
                    }
                    this.setState({
                        outboundFlows: outboundFlowsArray,
                        outboundFlowsList: response.result.entities
                    })
                }
            } else {
                response.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            this.logEvent(err, true);
        }
        await this.setState({ preloaderLocalShow: false });
    }

    loadUser = async () => {
        try {
            const userMe = await getUserMe();
            const orgMe = await getOrgMe();
            await this.setState({ userMe: userMe });
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            throw error;
        }
    }


    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'campType') {
            this.setState({
                [fieldName]: event.target.value,
                disableScriptSelector: false,
                selectedScript: '',
                scriptName: ''
            });
        } else {
            this.setState({
                [fieldName]: event.target.value,
            });
        }
    }

    handleInputText = (event) => {
        this.setState({
            scriptName: event.target.value
        });
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }
    handleAddNewScript = () => {
        this.setState({
            disableScriptSelector: true,
            scriptName: '',
            selectedScript: ''
        })
    }

    handleAddNewResponseSet = () => {
        this.setState({ isModalOpen: true })
    }

    postNewCallResponse = async () => {
        this.eventList = [];
        let body = {}, selectedFlowId = '';
        let selectOutboundFlow = this.state.outboundFlowsList.find(el => el.name == this.state.selectedoutboundFlows)
        if (selectOutboundFlow != undefined) {
            selectedFlowId = selectOutboundFlow.id;
        }
        body.name = this.state.callResponseName;
        body.beepDetectionEnabled = false;
        body.responses = {
            "disposition.classification.callable.machine": {
                "id": "hangup",
                "reactionType": "hangup",
                "reactionName": "Hangup"
            },
            "disposition.classification.callable.person": {
                "id": "transfer_flow",
                "reactionType": "transfer_flow",
                "reactionName": "Transfer to Outbound Flow",
                "name": this.state.selectedoutboundFlows,
                "data": selectedFlowId
            }
        };

        try {
            let response = await postCallResponseAnalysisSets(body, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
            if (response.messages.length === 0) {
                await logActionData('Import', `Importing Call Response Set`, 1, 'CallResponseSet');
                await this.loadCallResponseSets();
            } else {
                response.messages.map((message) => {
                    this.logEvent(message.content, true);
                });
            }
        } catch (err) {
            this.logEvent(err, true);
        }
        await this.setState({ isModalOpen: false })
        if (this.eventList.length > 0) {
            this.showEventList(this.eventList);
        }
    }

    publishOutboundFlow = async () => {
        await this.setState({ preloaderShow: true });
        try {
            const subContent = await this.createDocReaderPromise(this.state.subsFileContent);
            let token = await publishCallFlows(this.state.userMe.email, this.state.orgMe.name, 'TestAgent.yaml', subContent, this.state.subsFileName, this.state.token, this.state.env);
            let response = await this.callFlowProgress(token);
            console.log(response);
            await Misc.sleep(500);  // just for better user expirience - to showw 100% completed progressbar
            await this.setState({ subsFileName: '', subsFileContent: {} });
            this.displayEventList(response);
        } catch (err) {
            this.logEvent(err, true);
        }
        await this.setState({ preloaderShow: false });
    }

    createDocReaderPromise = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file,
                };
                resolve(fileInfo.base64.split(',')[1]);
            };
            reader.onerror = () => {
                reject();
            };
        });
    }

    callFlowProgress = async (token) => {
        if (token) {
            while (true) {
                let response = await checkProgress(this.state.userMe.email, this.state.orgMe.name, token);
                if (response.progress !== undefined) {
                    await this.setState({ preloaderShow: true, preloaderText: response.progress + '% ' + response.text });
                    await Misc.sleep(500);
                } else {
                    await this.setState({ preloaderShow: false, preloaderText: '' });
                    return response;
                }
            }
        }
    }

    displayEventList = async (response) => {
        this.eventList = [];
        if (response) {
            if (response.status === undefined) {
                if (response === 'Unauthorized') {
                    this.logEvent(response, true);
                } else {
                    this.logEvent("Something went wrong with archy command. Verify callflow/substitution file.", true);
                }
            } else {
                if (response.status === 'success') {
                    await this.logEvent(`Outbound Flow Creation ${response.status}`, false);
                    if (response.output) {
                        await this.logEvent(`Outbound Flow Published - ${response.output.flowName}`, false);
                        await this.loadOutboundFlows();
                        await this.setState({ selectedoutboundFlows: response.output.flowName });
                    }
                } else {
                    this.logEvent(response.status, true);
                    if (response.output) {
                        this.logEvent(`Outbound Flow Failure - ${response.output.flowName}`, false);
                        if (response.output.traces && response.output.traces.length > 0) {
                            response.output.traces.map((trace) => {
                                this.logEvent(trace.text, true);
                            });
                        }
                    }
                }
            }
        }
        if (this.eventList.length > 0) {
            this.showEventList(this.eventList);
        }
    }


    handleAddNewOutboundFlow = async () => {
        this.setState({ disableOutboundRow: true });
    }

    AddCampTypes = async () => {
        this.eventList = [];
        this.isPreviewCampType = this.state.campType === 'Preview' ? true : false;
        this.campaign['dialingMode'] = this.state.campType === 'Agent-less' ? 'agentless' : this.state.campType.toLowerCase();
        if (this.state.campType !== 'Agent-less' && this.state.campType !== 'SMS') {
            if (this.state.selectedScript !== undefined && this.state.selectedScript !== '') {
                this.campaign['script'] = this.state.scriptsList.find(el => el['name'] === this.state.selectedScript);
            } else {
                if ((this.state.scripts.find(el => el.toLowerCase() === this.state.scriptName.toLowerCase()) === undefined)) {
                    try {
                        let name = { "name": this.state.scriptName }
                        let response = await postAgentScripts(name, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
                        if (response.messages.length === 0) {
                            this.logEvent(` ${this.state.scriptName} Script Created successfully`, false);
                            await logActionData('Import', `Importing Agent Scripts`, 1, 'AgentScripts');
                            let publishRespone = await publishAgentScripts(response.result.id, this.state.env, this.state.userMe.email, this.state.token, this.state.orgMe.name);
                            if (publishRespone.messages.length === 0) {
                                this.logEvent(` ${this.state.scriptName} Script Published successfully`, false);
                                await this.loadAgentScripts();
                                this.campaign['script'] = this.state.scriptsList.find(el => el['name'] === this.state.scriptName);
                            } else {
                                publishRespone.messages.map((message) => {
                                    this.logEvent(message.content, true);
                                });
                            }
                        }
                        else {
                            response.messages.map((message) => {
                                this.logEvent(message.content, true);
                            });
                        }
                    } catch (err) {
                        this.logEvent(err, true);
                    }
                } else {
                    this.logEvent('Script Name already exists', true);
                }
            }
        }
        if (this.state.campType !== 'Preview' && this.state.campType !== 'SMS') {
            if (this.state.selectedEdgeGroup !== undefined && this.state.selectedEdgeGroup !== '') {
                this.campaign['edgeGroup'] = this.state.edgeGroupsList.find(el => el['name'] === this.state.selectedEdgeGroup);
            }
            if (this.state.selectedResponseSet !== undefined && this.state.selectedResponseSet !== '') {
                let selectedValue = this.state.responseSetsList.find(el => el['name'] === this.state.selectedResponseSet);
                this.campaign['callAnalysisResponseSet'] = { "id": selectedValue['id'], "name": selectedValue['name'], "selfUri": "" };
            }
        }
        if (this.state.campType === 'Agent-less') {
            this.campaign['outboundLineCount'] = "1";
        }
        if (this.state.campType == 'SMS') {
            this.campaign['phoneNumber'] = this.state.selectedSmsPhoneNumber;
        }
        console.log(JSON.stringify(this.campaign));
        if (this.eventList.length > 0) {
            this.showEventList(this.eventList);
        } else {
            let moveToListsSlide = true;
            this.resetState();
            this.props.handleCampType(this.campaign, moveToListsSlide, this.isPreviewCampType)
        }
    }

    closeEventViewer = () => {
        let moveToListsSlide = ((this.eventList.find(evnt => evnt.isError === true)) === undefined);
        let outboundEvent = ((this.eventList.find(evnt => evnt.message.contains('Outbound Flow Creation'))) === undefined);
        if (moveToListsSlide && outboundEvent) {
            this.resetState();
            this.setState({ eventListIsOpen: false, eventList: [] });
            this.props.handleCampType(this.campaign, moveToListsSlide, this.isPreviewCampType)
        } else {
            this.setState({ eventListIsOpen: false, eventList: [] });
        }
    }

    resetState = async () => {
        await this.setState({
            campType: '',
            disableScriptSelector: false,
            selectedEdgeGroup: undefined,
            selectedResponseSet: undefined,
            selectedScript: undefined,
            scriptName: '',
            selectedSmsPhoneNumber: undefined,
            flowName: '',
            disableOutboundRow: false,
            subsFileName: '',
            subsFileContent: {},
            callResponseName: '',
            selectOutboundFlow: ''
        });
    }

    handleDownloadSubs = async () => {
        this.eventList = [];
        await this.setState({ preloaderShow: true, preloaderText: 'Downloading Subs File' });
        let response = await downloadSubsFile(this.state.userMe.email, this.state.orgMe.name, 'OutboundFlow_sub.yaml');
        if (response) {
            if (response.isSuccess) {
                this.base64ToBlob(response.response);
            } else {
                this.logEvent(response.response, true);
            }
        }
        await this.setState({ preloaderShow: false, preloaderText: '' });
        if (this.eventList.length > 0) {
            this.showEventList(this.eventList);
        }
    }

    handleSubsFile = async (e) => {
        try {
            e.persist();
            if (e.target.files[0] != null && e.target.files[0].name != 'OutboundFlow_sub.yaml') {
                console.error('Please do not change file name other than OutboundFlow_sub.yaml');
                return;
            }
            this.setState({
                subsFileName: e.target.files[0].name,
                subsFileContent: e.target.files[0],
            });
            this.publishOutboundFlow();
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.yaml')) {
                console.error('Only YAML files are allowed');
                return;
            }
            if (file != null && file.name != 'OutboundFlow_sub.yaml') {
                console.error('Please do not change file name other than OutboundFlow_sub.yaml');
                return;
            }
            this.publishOutboundFlow();
            this.setState({
                subsFileName: file.name,
                subsFileContent: file,
            });
        } catch (err) {
            console.log(err);
        }
    }

    base64ToBlob = async (response) => {
        var b64toBlob = require('b64-to-blob');
        var contentType = 'application/json';
        var b64Data = response;
        var blob = b64toBlob(b64Data, contentType);
        exportfile(blob, 'OutboundFlow_sub.yaml');
    }

    render() {

        let campTypes = ['Preview', 'Progressive', 'Predictive', 'Agent-less', 'SMS'];
        let MAP = {
            name: "c-map",
            areas: []
        }
        let showWarning = (this.state.smsPhoneNumbersList != null && this.state.smsPhoneNumbersList.length == 0) ? false : true;

        return (
            <div className="campTypeCard">
                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={this.closeEventViewer} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} type={this.state.campType} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <AddNewItem
                    isOpen={this.state.isModalOpen}
                    callResponseName={this.state.callResponseName}
                    selectedoutboundFlows={this.state.selectedoutboundFlows}
                    outboundFlows={this.state.outboundFlows}
                    handleInputChange={this.handleInputChange}
                    postNewCallResponse={this.postNewCallResponse}
                    handleAddNewOutboundFlow={this.handleAddNewOutboundFlow}
                    handleDownloadSubs={this.handleDownloadSubs}
                    handleDropFile={this.handleDropFile}
                    handleSubsFile={this.handleSubsFile}
                    subsFileName={this.state.subsFileName}
                    disableOutboundRow={this.state.disableOutboundRow}
                    preloaderShow={this.state.preloaderShow}
                    preloaderText={this.state.preloaderText}
                    closeFunction={() => { this.setState({ isModalOpen: false, disableOutboundRow: false }) }} />

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 campTypeCardBody">
                        <CardTitle className="m-0 campTypeTitle">
                            <div className="divCampTypes">
                                <Badge className="titleBadgeCampType">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    {
                                        this.state.campType != 'SMS' ?
                                            <ImageMapper src="/images/pCampTypes.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />
                                            :
                                            <ImageMapper src="/images/smsCampType.png" map={MAP} width={200} onClick={area => this.clickedArea(area)} />
                                    }
                                </div>
                            </div>
                        </CardTitle>
                        <p style={{ fontSize: "2.5vmin", paddingLeft: "12px", marginLeft: "-20px" }}>From this page, Select  New Campaign Type to be Created </p>
                    </CardBody>
                    <CardBody className="p-3 CardBodyCampType">
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} />
                            <div className="campType-wrap">
                                <FormGroup row className="rowAttr">
                                    <Col md={4}>
                                        <Label>Type:<span className="text-danger font-weight-bold">*</span></Label>
                                    </Col>
                                    <Col md={5}>
                                        <select className="form-control selectpicker" value={this.state.campType} onChange={this.handleInputChange('campType')}>
                                            <option key="1">Select a Type</option>
                                            {
                                                campTypes.map((type, index) => (
                                                    <option key={index}>{type}</option>
                                                ))
                                            }
                                        </select>
                                    </Col>
                                </FormGroup>
                                {
                                    (this.state.campType === 'Preview' || this.state.campType === 'Predictive' || this.state.campType === 'Progressive') &&
                                    <div>
                                        <FormGroup row className="rowAttr">
                                            <Col md={4}>
                                                <Label>Agent Script:<span className="text-danger font-weight-bold">*</span></Label>
                                            </Col>
                                            <Col md={5}>
                                                <select className="form-control selectpicker" value={this.state.selectedScript} disabled={this.state.disableScriptSelector} onChange={this.handleInputChange('selectedScript')}>
                                                    <option key="1" hidden>Select a Script</option>
                                                    {
                                                        this.state.scripts.map((name, id) => (
                                                            <option key={id}>{name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>
                                            <Col md={3}>
                                                <Button className="AddButtonCampType" disabled={this.state.campType === '' || this.state.disableScriptSelector} onClick={this.handleAddNewScript}>Add New</Button>
                                            </Col>
                                        </FormGroup>
                                        {
                                            this.state.disableScriptSelector &&
                                            <FormGroup row className="rowAttr">
                                                <Col md={4}>
                                                    <Label>Script Name:<span className="text-danger font-weight-bold">*</span></Label>
                                                </Col>
                                                <Col md={5}>
                                                    <Input onChange={this.handleInputText} value={this.state.scriptName} type="text" id="scriptName" placeholder="Enter Script Name" />
                                                </Col>
                                            </FormGroup>
                                        }
                                    </div>
                                }

                                {
                                    (this.state.campType === 'Agent-less' || this.state.campType === 'Predictive' || this.state.campType === 'Progressive') &&
                                    <div>
                                        <FormGroup row className="rowAttr">
                                            <Col md={4}>
                                                <Label>Dialing Group:</Label>
                                            </Col>
                                            <Col md={5}>
                                                <select className="form-control selectpicker" value={this.state.campType} disabled={true} >
                                                    <option key="1">Edge Group</option>
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="rowAttr">
                                            <Col md={4}>
                                                <Label>Edge Group:<span className="text-danger font-weight-bold">*</span></Label>
                                            </Col>
                                            <Col md={5} class="wrapper">
                                                <select className="form-control selectpicker" onfocus='this.size=3' value={this.state.selectedEdgeGroup} onChange={this.handleInputChange('selectedEdgeGroup')}>
                                                    <option key="1" hidden>Select a Edge Group</option>
                                                    {
                                                        this.state.edgeGroups.map((name, id) => (
                                                            <option key={id}>{name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="rowAttr">
                                            <Col md={4}>
                                                <Label>No. of Outbound Lines:</Label>
                                            </Col>
                                            <Col md={5}>
                                                <input className="form-control" value={1} type="number" id="outboundLine" disabled={true} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row className="rowAttr">
                                            <Col md={4}>
                                                <Label>Call Response: <span className="text-danger font-weight-bold">*</span></Label>
                                            </Col>
                                            <Col md={5} class="wrapper">
                                                <select className="form-control selectpicker" onfocus='this.size=3' value={this.state.selectedResponseSet} onChange={this.handleInputChange('selectedResponseSet')}>
                                                    <option key="1" hidden>Select a Call Analysis Response Set</option>
                                                    {
                                                        this.state.responseSets.map((name, id) => (
                                                            <option key={id}>{name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </Col>
                                            {
                                                this.state.campType == 'Agent-less' &&
                                                <Col md={3}>
                                                    <Button className="AddButtonCampType" onClick={this.handleAddNewResponseSet}>Add New</Button>
                                                </Col>
                                            }
                                        </FormGroup>
                                    </div>
                                }
                                {
                                    this.state.campType === 'Agent-less' &&
                                    <div>
                                        <FormGroup row className="rowAttrCamp">
                                            <Label md={4} for="noAnswerTimeout">No-answer Timeout:</Label>
                                            <Col md={5}>
                                                <input className="form-control" value={this.state.noAnswerTimeout} onChange={this.handleInputChange('noAnswerTimeout')} type="number" id="noAnswerTimeout" min="15" defaultValue="15" />
                                            </Col>
                                        </FormGroup>
                                    </div>
                                }
                                {
                                    this.state.campType == 'SMS' &&
                                    <div>
                                        <FormGroup row className="rowAttr">
                                            <Col md={4}>
                                                <Label>Sender SMS Phone Number:<span className="text-danger font-weight-bold">*</span></Label>
                                            </Col>
                                            <Col md={5} class="wrapper">
                                                <select className="form-control selectpicker" onfocus='this.size=3' value={this.state.selectedSmsPhoneNumber} onChange={this.handleInputChange('selectedSmsPhoneNumber')}>
                                                    <option key="1" hidden>Select a Phone Number</option>
                                                    {
                                                        this.state.smsPhoneNumbers.map((phoneNumber) => (
                                                            <option >{phoneNumber}</option>
                                                        ))
                                                    }
                                                </select>
                                                <span hidden={showWarning} style={{ color: "red", fontSize: "2vmin" }}>Please purchase SMS Phone numbers for this Org to be used at Messaging Campaign</span>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                }
                            </div>

                            <Row className="mt-3">
                                <Col>
                                    <div>
                                        <Button className="SubmitButtonCampType" disabled={this.state.campType === ''} onClick={this.AddCampTypes}>Add</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}
