import './locale.css'
import React from 'react'
import { FaGlobeAmericas, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';

import { getSelectedRegion } from '../../../global/localStorage';
import { getToken, logActionData } from '../../../services/session/session';
import LocationInfo from '../Locale/locationInfo';
import DragAndDrop from '../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../services/misc/csvReader';
import PreloaderLocal from '../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../Misc/informationMessage/informationMessage';
import { locationsPostLocations } from "../../../services/purecloud/locations";
import { countries } from '../../../global/dictionary'
import BulkDelete from '../../Misc/bulkDeleteModal/bulkDelete'
import ImageMapper from 'react-image-mapper';

const uuidv1 = require('uuid/v1');
const locationsDataFields = [
  'name',
  'street1',
  'street2',
  'city',
  'state',
  'zip',
  'countryAbbreviation',
  'country',
  'notes',
  'emergencyNumber'
];

export default class Locale extends React.Component {

  state = {
    env: '',
    token: '',
    localeList: [],
    isLocaleInfoOpen: false,
    locationName: '',
    streetAddress: '',
    address2: '',
    cityLoc: '',
    stateLoc: '',
    zipLoc: '',
    countryLoc: '',
    codeLoc: '',
    notesLoc: '',
    emergencyLoc: '',
    rowID: null,
    isSaveLocale: false,
    preloaderLocalShow: false,
    preloaderLocalMessage: 'Loading',
    eventList: [],
    eventListIsOpen: false,
    isInfoOpen: false,
    selected: {},
    selectAll: 0,
    isBulkDeleteModalOpen: false
  }

  eventList = [];

  logEvent = (message, isError = false /*bool*/) => {
    const event = {
      isError: isError,
      time: moment().format('HH:mm:ss'),
      message: message
    }
    this.eventList.push(event);
  }

  showEventList = (eventList) => {
    this.setState({
      eventList: eventList,
      eventListIsOpen: true
    });
  }

  constructor(props) {
    super(props);
    this.state.env = getSelectedRegion();
    this.state.token = getToken();
    if (!this.state.token) {
      window.location.href = '/authorization';
      return;
    }
  }

  removeRowClicked = async (rowid, e) => {
    if (e) {
      e.stopPropagation();
    }
    var localeList = [...this.state.localeList];
    var selectedList = this.state.selected;
    for (const i in localeList) {
      if (localeList[i].rowid === rowid) {
        localeList.splice(i, 1);
        selectedList[rowid] = false;
        break;
      }
    }
    if ((Object.values(selectedList).find(el => el == true)) == undefined) {
      await this.setState({ selectAll: 0 })
    }
    await this.setState({ localeList: localeList, selected: selectedList });
  }

  removeAllClicked = async (e) => {
    if (e) { e.stopPropagation(); }
    if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
      this.setState({ isBulkDeleteModalOpen: true })
    }
  }

  sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

  handleInfo = () => {
    this.clearLocaleInfo();
    this.setState({
      isLocaleInfoOpen: true,
      isSaveLocale: false,
    });
  }

  clearLocaleInfo = () => {
    this.setState({
      locationName: '',
      streetAddress: '',
      address2: '',
      cityLoc: '',
      stateLoc: '',
      zipLoc: '',
      countryLoc: '',
      codeLoc: '',
      notesLoc: '',
      emergencyLoc: '',
      rowID: null,
    })
  }

  handleSubmitFunction = () => {
    let localeList = [...this.state.localeList]
    var newRow = { 'rowid': uuidv1() };
    for (const i in locationsDataFields) {
      if (locationsDataFields[i] === 'name') {
        newRow[locationsDataFields[i]] = this.state.locationName;
      } else if (locationsDataFields[i] === 'street1') {
        newRow[locationsDataFields[i]] = this.state.streetAddress;
      } else if (locationsDataFields[i] === 'street2') {
        newRow[locationsDataFields[i]] = this.state.address2;
      } else if (locationsDataFields[i] === 'city') {
        newRow[locationsDataFields[i]] = this.state.cityLoc;
      } else if (locationsDataFields[i] === 'state') {
        newRow[locationsDataFields[i]] = this.state.stateLoc;
      } else if (locationsDataFields[i] === 'zip') {
        newRow[locationsDataFields[i]] = this.state.zipLoc;
      } else if (locationsDataFields[i] === 'country') {
        newRow[locationsDataFields[i]] = this.state.countryLoc;
      } else if (locationsDataFields[i] === 'countryAbbreviation') {
        newRow[locationsDataFields[i]] = this.state.codeLoc;
      } else if (locationsDataFields[i] === 'notes') {
        newRow[locationsDataFields[i]] = this.state.notesLoc;
      } else if (locationsDataFields[i] === 'emergencyNumber') {
        newRow[locationsDataFields[i]] = this.state.emergencyLoc;
      }
    }

    localeList.push(newRow);

    this.setState({
      isLocaleInfoOpen: false,
      localeList: localeList,
    });
  }

  handleInputChange = (fieldName) => (event) => {
    if (fieldName === 'countryLoc') {
      const countryCode = this.getCountryCodeName(event.target.value);
      this.setState({
        [fieldName]: event.target.value,
        ['codeLoc']: countryCode,
      });
    } else {
      this.setState({
        [fieldName]: event.target.value
      });
    }
  }

  getCountryCodeName = (countryName) => {
    let countryCode = '';
    countries.map((country) => {
      if (country.name === countryName) {
        countryCode = country.code;
      }
    })
    return countryCode;
  }

  updateState = (rowInfo) => {
    this.setState({
      locationName: rowInfo.name,
      streetAddress: rowInfo.street1,
      address2: rowInfo.street2,
      cityLoc: rowInfo.city,
      stateLoc: rowInfo.state,
      zipLoc: rowInfo.zip,
      countryLoc: rowInfo.country,
      codeLoc: rowInfo.countryAbbreviation,
      notesLoc: rowInfo.notes,
      emergencyLoc: rowInfo.emergencyNumber,
      rowID: rowInfo.rowid,
      isSaveLocale: true,
      isLocaleInfoOpen: true,
    });
  }

  updateFunction = () => {
    let localeList = [...this.state.localeList];
    for (let locale of localeList) {
      if (locale.rowid === this.state.rowID) {
        locale.name = this.state.locationName;
        locale.street1 = this.state.streetAddress;
        locale.street2 = this.state.address2;
        locale.city = this.state.cityLoc;
        locale.state = this.state.stateLoc;
        locale.zip = this.state.zipLoc;
        locale.country = this.state.countryLoc;
        locale.countryAbbreviation = this.state.codeLoc;
        locale.notes = this.state.notesLoc;
        locale.emergencyNumber = this.state.emergencyLoc;
      }
    }

    this.setState({
      localeList: localeList,
      isLocaleInfoOpen: false,
    })
  }

  importCsv = async (file) => {
    await this.setState({ preloaderLocalShow: true });
    const fileContent = await CsvReader.readFile(file);
    const locationsData = await CsvReader.parseCsvFileToLocationsData(fileContent);
    await this.setState({ localeList: [...this.state.localeList, ...locationsData], preloaderLocalShow: false });
  }

  handleImportFile = async (e) => {
    try {
      const file = e.target.files[0];
      if (file) {
        e.persist();
        this.importCsv(e.target.files[0]);
      }
    } catch (err) {
      console.log(err);
    }
  }

  handleDropFile = async (e) => {
    try {
      const file = e[0];
      if (!file || !file.name.toLowerCase().endsWith('.csv')) {
        console.error('Only CSV files are allowed');
        return;
      }
      if (file) {
        this.importCsv(file);
      }
    } catch (err) {
      console.log(err);
    }
  }

  importLocale = async () => {
    this.eventList = [];
    const localeList = this.state.localeList;
    for (const i in localeList) {
      const locationData = localeList[i];
      await this.setState({ preloaderLocalShow: true });
      this.logEvent(`Processing location [${locationData.name}]`);
      if (await this.dataValidated(locationData, i, localeList)) {
        try {
          // <create location>
          const location = await locationsPostLocations(this.state.env, this.state.token, locationData.name, locationData.street1, locationData.street2, locationData.city, locationData.state, locationData.zip, locationData.countryAbbreviation, locationData.country, locationData.notes, locationData.emergencyNumber);
          console.log(`location created: ${JSON.stringify(location)}`);
          // </create location>

          this.logEvent(`Location [${locationData.name}] successfully processed`);
          this.removeRowClicked(locationData.rowid);
        } catch (err) {
          this.logEvent(`${err.message}`, true);
        }
      }
    }
    let count = 0;
    this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
    if (count > 0) {
      await logActionData('Import', `Importing ${this.props.cardTitle}`, count, this.props.cardTitle);
    }
    await this.setState({ preloaderLocalShow: false });
    this.showEventList(this.eventList);
  }

  dataValidated = async (location, i, locationsData) => {
    let isValidated = true;
    // <validate fields that can't be empty>
    if (!location.name || !location.name.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isValidated = false; }
    if (!location.street1 || !location.street1.trim()) { this.logEvent(`[Index ${i}] Street 1 can't be empty.`, true); isValidated = false; }
    if (!location.city || !location.city.trim()) { this.logEvent(`[Index ${i}] City can't be empty.`, true); isValidated = false; }
    if (!location.zip || !location.zip.trim()) { this.logEvent(`[Index ${i}] Zip can't be empty.`, true); isValidated = false; }
    if (!location.country || !location.country.trim()) { this.logEvent(`[Index ${i}] Country can't be empty.`, true); isValidated = false; }
    if (!location.countryAbbreviation || !location.countryAbbreviation.trim()) { this.logEvent(`[Index ${i}] Country abbreviation can't be empty.`, true); isValidated = false; }
    if (!location.emergencyNumber || !location.emergencyNumber.trim()) { this.logEvent(`[Index ${i}] Emergency Number can't be empty.`, true); isValidated = false; }
    // </validate fields that can't be empty>

    // <validate if location name is unique>
    for (const j in locationsData) {
      const location1 = locationsData[j];
      if (i === j) { continue; }
      if (location.name.trim().toLowerCase() === location1.name.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
    }
    // </validate if location name is unique>
    return isValidated;
  }

  handleInformation = () => {
    this.setState({
      isInfoOpen: true,
    });
  }

  toggleRow = (name, e) => {
    if (e) { e.stopPropagation(); }
    const newSelected = Object.assign({}, this.state.selected);
    newSelected[name] = !this.state.selected[name];
    let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
    this.setState({ selected: newSelected, selectAll: selectAllValue });
  }

  updateRow = async (row, e) => {
    if (e) { e.stopPropagation(); }
    this.updateState(row.original);
  }

  toggleSelectAll = () => {
    let newSelected = {};
    if (this.state.selectAll === 0) {
      this.state.localeList.forEach(x => {
        newSelected[x.rowid] = true;
      });
    }
    this.setState({
      selected: newSelected,
      selectAll: this.state.selectAll === 0 ? 1 : 0
    });
  }

  deleteAllFunction = async () => {
    await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderLocalMessage: "Deleting Selected Locations...." });
    let selected = this.state.selected;
    for (let rowid of Object.keys(selected)) {
      if (selected[rowid] === true) {
        this.removeRowClicked(rowid);
        await this.sleep(3000);
      }
    }
    await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderLocalMessage: 'Loading' })
  }

  clickedArea = (area) => {
    if (area != null) {
      if (area.name === '1') {
        this.props.navigateToHorizontalSlide('1');
      }
    }
  }

  render() {
    let MAP = {
      name: "loc-map",
      areas: [
        { name: "1", shape: "circle", coords: [101, 15, 13] }
      ]
    }
    return (
      <div className="localeCard">

        {/* <event list viewer> */}
        <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
        {/* </event list viewer>  */}

        {/* <Information viewer> */}
        <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
        {/* <Information viewer> */}

        <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

        {/* <Information viewer> */}
        <LocationInfo
          title={this.props.cardTitle}
          isOpen={this.state.isLocaleInfoOpen}
          locationName={this.state.locationName}
          streetAddress={this.state.streetAddress}
          address2={this.state.address2}
          cityLoc={this.state.cityLoc}
          stateLoc={this.state.stateLoc}
          zipLoc={this.state.zipLoc}
          countryLoc={this.state.countryLoc}
          codeLoc={this.state.codeLoc}
          notesLoc={this.state.notesLoc}
          emergencyLoc={this.state.emergencyLoc}
          isSaveLocale={this.state.isSaveLocale}
          submitFunction={this.handleSubmitFunction}
          updateFunction={this.updateFunction}
          handleInputChange={this.handleInputChange}
          closeFunction={() => { this.setState({ isLocaleInfoOpen: false }) }} />
        {/* <Information viewer> */}

        <Card className="mb-4 cardDesign">
          <CardBody className="p-3 localeCardBody">
            <CardTitle className="m-0 localeTitle">
              <div className="divLocale">
                <Badge className="titleBadgeLocale">{this.props.cardTitle}</Badge>
                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                <sup className="supText">  Learn More</sup>
                <div className="ImageMapper">
                  <ImageMapper src="/images/pLocations.png" map={MAP} width={125} onClick={area => this.clickedArea(area)} />
                </div>
              </div>
            </CardTitle>
            <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
              <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/location-import-template.csv" ><u>Here</u></a>
              </p>
            </div>
          </CardBody>
          <CardBody className="p-3 CardBodyLocale">
            <Row className="mt-3">
              <Col className="fileDropCol">
                <div style={{ width: "20%"}}>
                  <Button className="AddButtonLocale" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                </div>
                <div style={{ width: "60%", paddingLeft: "25px" }}>
                  <DragAndDrop handleDrop={this.handleDropFile}>
                    <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                  </DragAndDrop>
                </div>
                <div className="BrowseButtonLocale">
                  <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                    <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                    <FaCloudUploadAlt /> Browse
                  </label>
                </div>
              </Col>
            </Row>
            <div className="single-field-editor-card-wrap">
              <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage}/>
              <div className="locale-wrap">
                <ReactTable
                  data={this.state.localeList}
                  columns={[
                    {
                      id: "checkbox",
                      accessor: "",
                      Cell: ({ original }) => {
                        return (
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selected[original.rowid] === true}
                            onChange={(e) => this.toggleRow(original.rowid, e)}
                          />
                        );
                      },
                      Header: x => {
                        return (
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={this.state.selectAll === 1}
                            disabled={this.state.localeList.length === 0 ? "disabled" : ""}
                            ref={input => {
                              if (input) {
                                input.indeterminate = this.state.selectAll === 2;
                              }
                            }}
                            onChange={() => this.toggleSelectAll()}
                          />
                        );
                      },
                      sortable: false,
                      width: 45
                    },
                    {
                      Header: "Name",
                      accessor: "name",
                      Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                    },
                    {
                      Header: "City",
                      accessor: "city",
                      Cell: row => (<span style={{ width: "150px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                    },
                    {
                      Header: "State",
                      accessor: "state",
                      Cell: row => (<span style={{ width: "150px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                    },
                    {
                      Header: "Country",
                      accessor: "country",
                      Cell: row => (<span style={{ width: "150px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                    },
                    {
                      Header: row => (<span style={{ width: "150px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" 
                      ,backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius:  (this.state.selectAll != 0) ? "25px" : "0px"
                    }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                      accessor: "rowid",
                      Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                    }
                  ]}
                  showPagination={false}
                  style={{ height: "35vmin" }}
                  noDataText="No Locations loaded"
                  className="-striped -highlight"
                />
              </div>
              <Row className="mt-3">
                <Col>
                  <div>
                    <Button className="SubmitButtonLocale" onClick={this.importLocale} disabled={this.state.localeList.length === 0}>Submit</Button>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>

      </div>
    )
  }
}
