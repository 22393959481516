import './authorization.css'
import React from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppHeader from '../header/appHeader';
import { getSelectedRegion, getUserAuthorizationKey, setUserAuthorizationKey } from '../../global/localStorage'
import config from '../../config';

import {
    Container,
    Card,
    CardHeader,
    CardBody
} from 'reactstrap'

class Authorization extends React.Component {
    state = {
        loginPageUrl: 'about:blank',
    }

    constructor(props) {
        super(props);
        this.setLoginUrl();
    }

    setLoginUrl = async () => {
        const selectedRegion = getSelectedRegion();
        console.log('%o', config);
        const region = config.purecloud.regions.filter(r => r.name === selectedRegion)[0];
        this.state.loginPageUrl = 'login.html?en=' + region.name + '&ci=' + config.purecloud.client;
        console.log(this.state.loginPageUrl);
    }

    iframeOnLoad = (data) => {
        const purecloud_token = sessionStorage.getItem('purecloud_token');
        console.log(`purecloud_token: ${purecloud_token}`);
        if (purecloud_token) {
            window.location.href = '/';
        }
        const userAuthorizationkey = getUserAuthorizationKey();
        if (userAuthorizationkey !== 'true') {
            toast.error(userAuthorizationkey, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setUserAuthorizationKey('true');
    }

    render() {
        return (
            <div>
                <AppHeader modeProp="unauthorized" />
                <Container>
                    <Card className="border-secondary mb-3">
                        <CardHeader>Login to GenesysCloud</CardHeader>
                        <CardBody className="text-secondary purecloud-login-card">
                            <iframe title="GenesysCloud" className="purecloud-login-iframe" src={this.state.loginPageUrl} onLoad={this.iframeOnLoad}>
                            </iframe>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        )
    }
}

export default Authorization