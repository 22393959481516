import './sites.css'
import React from 'react'
import { FaMap, FaPlus, FaTrash, FaCloudUploadAlt, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, FormGroup, Input, Badge } from 'reactstrap';
import ReactTable from "react-table";
import moment from 'moment';
import 'moment-timezone';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, logActionData } from '../../../../services/session/session';
import { getlocations } from '../../../../services/purecloud/locations';
import { telephonyPostSites, telephonyGetTimeZones } from '../../../../services/purecloud/telephony';
import DragAndDrop from '../../../Misc/dragAndDrop/dragAndDrop';
import CsvReader from '../../../../services/misc/csvReader';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import SitesInfo from './sitesInfo';
import ImageMapper from 'react-image-mapper';
import BulkDelete from '../../../Misc/bulkDeleteModal/bulkDelete'

const uuidv1 = require('uuid/v1');
const sitesDataFields = [
    'SiteName',
    'Description',
    'Location',
    'Recurrence Type',
    'Time Zone',
    'Start Time',
    'End Time',
    'StartMoment',
    'EndMoment',
    'allDayCheck'
];

export default class Site extends React.Component {

    state = {
        env: '',
        token: '',
        sitesList: [],
        isSitesInfoOpen: false,
        siteName: '',
        description: '',
        location: '',
        Recurrence_Type: '',
        recurrType: '',
        timeZone: '',
        startTime: '09:00',
        endTime: '18:00',
        rowID: null,
        allDayCheck: false,
        StartMoment: '2021-02-10T09:00',
        EndMoment: '2021-02-10T18:00',
        weeklyMonday: false,
        weeklyTuesday: false,
        weeklyWednesday: false,
        weeklyThursday: false,
        weeklyFriday: false,
        weeklySaturday: false,
        weeklySunday: false,
        locationList: [],
        zonesList: [],
        isSaveLocale: false,
        preloaderLocalShow: false,
        preloaderMessage: 'Loading',
        eventList: [],
        eventListIsOpen: false,
        isInfoOpen: false,
        selected: {},
        selectAll: 0,
        isBulkDeleteModalOpen: false
    }

    eventList = [];

    logEvent = (message, isError = false /*bool*/) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        await this.setState({ preloaderLocalShow: true });
        this.state.locationList = await getlocations(this.state.env, this.state.token);
        this.state.zonesList = await telephonyGetTimeZones(this.state.env, this.state.token);
        await this.setState({ preloaderLocalShow: false });
    }

    updateLocations = async () => {
        this.state.locationList = await getlocations(this.state.env, this.state.token);
    }

    removeRowClicked = async (rowid, e) => {
        if (e) {
            e.stopPropagation();
        }
        var sitesList = [...this.state.sitesList];
        var selectedList = this.state.selected;
        for (const i in sitesList) {
            if (sitesList[i].rowid === rowid) {
                sitesList.splice(i, 1);
                selectedList[rowid] = false;
                break;
            }
        }
        if ((Object.values(selectedList).find(el => el == true)) == undefined) {
            await this.setState({ selectAll: 0 })
        }
        await this.setState({ sitesList: sitesList, selected: selectedList });
    }

    removeAllClicked = async (e) => {
        if (e) { e.stopPropagation(); }
        if (this.state.selected != null && ((Object.values(this.state.selected).find(el => el == true)) != undefined) && this.state.selectAll != 0) {
            this.setState({ isBulkDeleteModalOpen: true })
        }
    }

    sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

    handleInfo = () => {
        this.clearLocaleInfo();
        this.updateLocations();
        this.setState({
            isSitesInfoOpen: true,
            isSaveLocale: false,
        });
    }

    clearLocaleInfo = () => {
        this.setState({
            siteName: '',
            description: '',
            location: '',
            Recurrence_Type: '',
            recurrType: '',
            timeZone: '',
            startTime: '09:00',
            endTime: '18:00',
            StartMoment: '2021-02-10T09:00',
            EndMoment: '2021-02-10T18:00',
            allDayCheck: false,
            rowID: null,
            weeklyMonday: false,
            weeklyTuesday: false,
            weeklyWednesday: false,
            weeklyThursday: false,
            weeklyFriday: false,
            weeklySaturday: false,
            weeklySunday: false,
        })
    }

    generateRecurrType = async () => {
        let Recurrence_Type = 'FREQ=' + this.state.recurrType;
        let byWeekDay = 'BYDAY=';
        if (this.state.recurrType === 'WEEKLY') {
            if (this.state.weeklyMonday) { byWeekDay = byWeekDay + 'MO,'; }
            if (this.state.weeklyTuesday) { byWeekDay = byWeekDay + 'TU,'; }
            if (this.state.weeklyWednesday) { byWeekDay = byWeekDay + 'WE,'; }
            if (this.state.weeklyThursday) { byWeekDay = byWeekDay + 'TH,'; }
            if (this.state.weeklyFriday) { byWeekDay = byWeekDay + 'FR,'; }
            if (this.state.weeklySaturday) { byWeekDay = byWeekDay + 'SA,'; }
            if (this.state.weeklySunday) { byWeekDay = byWeekDay + 'SU,'; }
            byWeekDay = byWeekDay.replace(/,\s*$/, "");
            Recurrence_Type = Recurrence_Type + ';' + byWeekDay;
        }
        return Recurrence_Type;
    }

    handleSubmitFunction = async () => {
        let sitesList = [...this.state.sitesList];
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Generating Recurrence' });
        let Recurrence_Type = await this.generateRecurrType();
        await this.setState({ preloaderLocalShow: false, preloaderMessage: 'Loading' });
        var newRow = { 'rowid': uuidv1() };
        for (const i in sitesDataFields) {
            if (sitesDataFields[i] === 'SiteName') {
                newRow[sitesDataFields[i]] = this.state.siteName;
            } else if (sitesDataFields[i] === 'Description') {
                newRow[sitesDataFields[i]] = this.state.description;
            } else if (sitesDataFields[i] === 'Location') {
                newRow[sitesDataFields[i]] = this.state.location;
            } else if (sitesDataFields[i] === 'Recurrence Type') {
                newRow[sitesDataFields[i]] = Recurrence_Type;
            } else if (sitesDataFields[i] === 'Time Zone') {
                newRow[sitesDataFields[i]] = this.state.timeZone;
            } else if (sitesDataFields[i] === 'Start Time') {
                newRow[sitesDataFields[i]] = this.state.startTime;
            } else if (sitesDataFields[i] === 'End Time') {
                newRow[sitesDataFields[i]] = this.state.endTime;
            } else if (sitesDataFields[i] === 'allDayCheck') {
                newRow[sitesDataFields[i]] = this.state.allDayCheck;
            } else if (sitesDataFields[i] === 'StartMoment') {
                newRow[sitesDataFields[i]] = this.state.StartMoment;
            } else if (sitesDataFields[i] === 'EndMoment') {
                newRow[sitesDataFields[i]] = this.state.EndMoment;
            }
        }

        sitesList.push(newRow);

        this.setState({
            isSitesInfoOpen: false,
            sitesList: sitesList,
        });
    }

    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'allDayCheck') {
            const ele = document.getElementById(fieldName);
            let StartMoment = this.state.StartMoment;
            let EndMoment = this.state.EndMoment;
            const currStartMoment = StartMoment.slice(0, StartMoment.indexOf('T') + 1);
            let updatedMoment = null;
            if (ele.checked) {
                if (this.checkDaysDiff(StartMoment, EndMoment) === 0) {
                    var allDayMoment = moment(EndMoment, 'yyyy-MM-DDTHH:mm').add(1, 'days').format('yyyy-MM-DDTHH:mm');
                    updatedMoment = allDayMoment.slice(0, allDayMoment.indexOf('T') + 1);
                } else {
                    updatedMoment = EndMoment.slice(0, EndMoment.indexOf('T') + 1);
                }
                this.setState({
                    [fieldName]: ele.checked,
                    startTime: '00:00',
                    endTime: '00:00',
                    StartMoment: currStartMoment + '00:00',
                    EndMoment: updatedMoment + '00:00',
                });
            } else {
                if (this.checkDaysDiff(StartMoment, EndMoment) === 1) {
                    var allDayMoment = moment(EndMoment, 'yyyy-MM-DDTHH:mm').subtract(1, 'days').format('yyyy-MM-DDTHH:mm');
                    updatedMoment = allDayMoment.slice(0, allDayMoment.indexOf('T') + 1);
                } else {
                    updatedMoment = EndMoment.slice(0, EndMoment.indexOf('T') + 1);
                }
                this.setState({
                    [fieldName]: ele.checked,
                    startTime: '09:00',
                    endTime: '18:00',
                    StartMoment: currStartMoment + '09:00',
                    EndMoment: updatedMoment + '18:00',
                });
            }
        } else if (fieldName === 'startTime') {
            let StartMoment = this.state.StartMoment;
            const currStartMoment = StartMoment.slice(0, StartMoment.indexOf('T') + 1);
            this.setState({
                [fieldName]: event.target.value,
                StartMoment: currStartMoment + event.target.value,
            });
        } else if (fieldName === 'endTime') {
            let EndMoment = this.state.EndMoment;
            const currEndMoment = EndMoment.slice(0, EndMoment.indexOf('T') + 1);
            this.setState({
                [fieldName]: event.target.value,
                EndMoment: currEndMoment + event.target.value,
            });
        } else if (fieldName === 'weeklyMonday' || fieldName === 'weeklyTuesday' || fieldName === 'weeklyWednesday' || fieldName === 'weeklyThursday' || fieldName === 'weeklyFriday' || fieldName === 'weeklySaturday' || fieldName === 'weeklySunday') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked
            });
        } else {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    }

    checkDaysDiff = (start, end) => {
        var updatedStartMoment = moment(start, 'yyyy-MM-DDTHH:mm');
        var updatedEndMoment = moment(end, 'yyyy-MM-DDTHH:mm');
        var diffDays = Math.abs(updatedEndMoment.diff(updatedStartMoment, 'days'));
        return diffDays;
    }

    decoderRule = async (rowInfo) => {
        let Recurrence_Type = rowInfo['Recurrence Type'];
        let decoder = {
            recurrType: '',
            weeklyMonday: false,
            weeklyTuesday: false,
            weeklyWednesday: false,
            weeklyThursday: false,
            weeklyFriday: false,
            weeklySaturday: false,
            weeklySunday: false,
        }
        if (Recurrence_Type.includes('DAILY')) {
            decoder.recurrType = 'DAILY';
        } else if (Recurrence_Type.includes('WEEKLY')) {
            decoder.recurrType = 'WEEKLY';
            const byDays = Recurrence_Type.slice(Recurrence_Type.lastIndexOf('=') + 1, Recurrence_Type.length);
            if (byDays.includes('MO')) {
                decoder.weeklyMonday = true;
            }
            if (byDays.includes('TU')) {
                decoder.weeklyTuesday = true;
            }
            if (byDays.includes('WE')) {
                decoder.weeklyWednesday = true;
            }
            if (byDays.includes('TH')) {
                decoder.weeklyThursday = true;
            }
            if (byDays.includes('FR')) {
                decoder.weeklyFriday = true;
            }
            if (byDays.includes('SA')) {
                decoder.weeklySaturday = true;
            }
            if (byDays.includes('SU')) {
                decoder.weeklySunday = true;
            }
        }
        return decoder;
    }

    updateState = async (rowInfo) => {
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Loading Current State' });
        const decodedRule = await this.decoderRule(rowInfo);
        await this.setState({ preloaderLocalShow: false, preloaderMessage: 'Loading' });
        this.setState({
            siteName: rowInfo.SiteName,
            description: rowInfo.Description,
            location: rowInfo.Location,
            Recurrence_Type: rowInfo['Recurrence Type'],
            recurrType: decodedRule.recurrType,
            timeZone: rowInfo['Time Zone'],
            startTime: rowInfo['Start Time'],
            endTime: rowInfo['End Time'],
            rowID: rowInfo.rowid,
            StartMoment: rowInfo.StartMoment,
            EndMoment: rowInfo.EndMoment,
            allDayCheck: rowInfo.allDayCheck,
            isSaveLocale: true,
            isSitesInfoOpen: true,
            weeklyMonday: decodedRule.weeklyMonday,
            weeklyTuesday: decodedRule.weeklyTuesday,
            weeklyWednesday: decodedRule.weeklyWednesday,
            weeklyThursday: decodedRule.weeklyThursday,
            weeklyFriday: decodedRule.weeklyFriday,
            weeklySaturday: decodedRule.weeklySaturday,
            weeklySunday: decodedRule.weeklySunday,
        });
    }

    updateFunction = async () => {
        let sitesList = [...this.state.sitesList];
        await this.setState({ preloaderLocalShow: true, preloaderMessage: 'Updating Recurrence' });
        let Recurrence_Type = await this.generateRecurrType();
        await this.setState({ preloaderLocalShow: false, preloaderMessage: 'Loading' });
        for (let locale of sitesList) {
            if (locale.rowid === this.state.rowID) {
                locale.SiteName = this.state.siteName;
                locale.Description = this.state.description;
                locale.Location = this.state.location;
                locale['Recurrence Type'] = Recurrence_Type;
                locale['Time Zone'] = this.state.timeZone;
                locale['Start Time'] = this.state.startTime;
                locale['End Time'] = this.state.endTime;
                locale.allDayCheck = this.state.allDayCheck;
                locale.StartMoment = this.state.StartMoment;
                locale.EndMoment = this.state.EndMoment;
            }
        }

        this.setState({
            sitesList: sitesList,
            isSitesInfoOpen: false,
        })
    }

    importCsv = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        await this.updateLocations();
        const fileContent = await CsvReader.readFile(file);
        const sitesData = await CsvReader.parseCsvFileToSitesData(fileContent);
        await this.setState({ sitesList: [...this.state.sitesList, ...sitesData], preloaderLocalShow: false });
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                this.importCsv(e.target.files[0]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                this.importCsv(file);
            }
        } catch (err) {
            console.log(err);
        }
    }

    importSites = async () => {
        this.eventList = [];
        const sitesList = this.state.sitesList;
        for (const i in sitesList) {
            const siteData = sitesList[i];
            await this.setState({ preloaderLocalShow: true });
            this.logEvent(`Processing site [${siteData.SiteName}]`);
            if (await this.dataValidated(siteData, i, sitesList)) {
                try {
                    // <create site>
                    const location = this.getLocation(siteData.Location);
                    const site = await telephonyPostSites(this.state.env, this.state.token, siteData.SiteName, siteData.Description, location.id, siteData["Recurrence Type"], siteData["Time Zone"], siteData.StartMoment, siteData.EndMoment);
                    console.log(`site created: ${JSON.stringify(site)}`);
                    // </create site>

                    this.logEvent(`Site [${siteData.SiteName}] successfully processed`);
                    this.removeRowClicked(siteData.rowid);
                } catch (err) {
                    this.logEvent(`${err.message}`, true);
                }
            }
        }
        let count = 0;
        this.eventList.forEach(el => { if (el.isError === false && el.message.contains('successfully processed')) { count = count + 1; } });
        if (count > 0) {
            await logActionData('Import', `Importing ${this.props.cardTitle}`, count, this.props.cardTitle);
        }
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    getLocation = (name) => {
        const location = this.state.locationList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (location) { return location; }
    }

    dataValidated = async (site, i, sitesData) => {
        let isValidated = true;
        // <validate fields that can't be empty>
        if (!site.SiteName.trim()) { this.logEvent(`[Index ${i}] Name can't be empty.`, true); isValidated = false; }
        if (!site.Location.trim()) { this.logEvent(`[Index ${i}] Location name can't be empty.`, true); isValidated = false; }
        if (!site["Recurrence Type"].trim()) { this.logEvent(`[Index ${i}] Recurrence type can't be empty.`, true); isValidated = false; }
        if (!site["Time Zone"].trim()) { this.logEvent(`[Index ${i}] Time zone can't be empty.`, true); isValidated = false; }
        if (!site.StartMoment) { this.logEvent(`[Index ${i}] Start time can't be empty.`, true); isValidated = false; }
        if (!site.EndMoment) { this.logEvent(`[Index ${i}] End time can't be empty.`, true); isValidated = false; }
        // </validate fields that can't be empty>

        // <validate if location exist>
        var locationNameValid = false;
        for (const location of this.state.locationList) {
            if (site.Location.toLowerCase() === location.name.toLowerCase()) {
                locationNameValid = true;
            }
        }
        if (!locationNameValid) { this.logEvent(`[Index ${i}] Location '${site.Location}' doesn't exist`, true); isValidated = false; }
        // </validate if location exist>

        // <validate if site name is unique>
        for (const j in sitesData) {
            const site1 = sitesData[j];
            if (i === j) { continue; }
            if (site.SiteName.trim().toLowerCase() === site1.SiteName.trim().toLowerCase()) { this.logEvent(`[Index ${i}][Index ${j}] Name should be unique.`, true); isValidated = false; }
        }
        // </validate if site name is unique>
        return isValidated;
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    toggleRow = (name, e) => {
        if (e) { e.stopPropagation(); }
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[name] = !this.state.selected[name];
        let selectAllValue = (Object.values(newSelected).find(el => el == true) != undefined) ? 2 : 0;
        this.setState({ selected: newSelected, selectAll: selectAllValue });
    }

    updateRow = async (row, e) => {
        if (e) { e.stopPropagation(); }
        this.updateState(row.original);
    }

    toggleSelectAll = () => {
        let newSelected = {};
        if (this.state.selectAll === 0) {
            this.state.sitesList.forEach(x => {
                newSelected[x.rowid] = true;
            });
        }
        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        });
    }

    deleteAllFunction = async () => {
        await this.setState({ preloaderLocalShow: true, isBulkDeleteModalOpen: false, preloaderMessage: "Deleting Selected Sites...." });
        let selected = this.state.selected;
        for (let rowid of Object.keys(selected)) {
            if (selected[rowid] === true) {
                this.removeRowClicked(rowid);
                await this.sleep(3000);
            }
        }
        await this.setState({ selectAll: 0, preloaderLocalShow: false, preloaderMessage: 'Loading' })
    }

    clickedArea = (area) => {
        if (area != null) {
            if (area.name === '0') {
                this.props.navigateToHorizontalSlide('0');
            }
        }
    }

    render() {
        let MAP = {
            name: "site-map",
            areas: [
                { name: "0", shape: "circle", coords: [28, 16, 13] }
            ]
        }

        return (
            <div className="sitesCard">

                {/* <event list viewer> */}
                <EventListViewer title="Import completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={() => { this.setState({ eventListIsOpen: false, eventList: [] }) }} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <BulkDelete isBulkDeleteModalOpen={this.state.isBulkDeleteModalOpen} deleteAllFunction={this.deleteAllFunction} closeFunction={() => { this.setState({ isBulkDeleteModalOpen: false }) }} />

                {/* <Information viewer> */}
                <SitesInfo
                    title={this.props.cardTitle}
                    isOpen={this.state.isSitesInfoOpen}
                    siteName={this.state.siteName}
                    description={this.state.description}
                    location={this.state.location}
                    recurrType={this.state.recurrType}
                    timeZone={this.state.timeZone}
                    startTime={this.state.startTime}
                    endTime={this.state.endTime}
                    locationList={this.state.locationList}
                    zonesList={this.state.zonesList}
                    allDayCheck={this.state.allDayCheck}
                    weeklyMonday={this.state.weeklyMonday}
                    weeklyTuesday={this.state.weeklyTuesday}
                    weeklyWednesday={this.state.weeklyWednesday}
                    weeklyThursday={this.state.weeklyThursday}
                    weeklyFriday={this.state.weeklyFriday}
                    weeklySaturday={this.state.weeklySaturday}
                    weeklySunday={this.state.weeklySunday}
                    isSaveLocale={this.state.isSaveLocale}
                    submitFunction={this.handleSubmitFunction}
                    updateFunction={this.updateFunction}
                    handleInputChange={this.handleInputChange}
                    closeFunction={() => { this.setState({ isSitesInfoOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 sitesCardBody">
                        <CardTitle className="m-0 sitesTitle">
                            <div className="divSites">
                                <Badge className="titleBadgeSites">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    <ImageMapper src="/images/pSites.png" map={MAP} width={125} onClick={area => this.clickedArea(area)} />
                                </div>
                            </div>
                        </CardTitle>
                        <div style={{ paddingLeft: "12px", marginLeft: "-20px"}}>
                            <p style={{ fontSize: "2.5vmin" }}>Add new {this.props.cardTitle} individually, or import multiple {this.props.cardTitle} from a .csv file.
                                A template .csv file can be downloaded <a style={{ color: "orangered" }} href="./templates/site-import-template.csv" ><u>Here</u></a>
                            </p>
                        </div>
                    </CardBody>
                    <CardBody className="p-3 CardBodySites">
                        <Row className="mt-3">
                            <Col className="fileDropCol">
                                <div style={{ width: "20%" }}>
                                    <Button className="AddButtonSites" onClick={this.handleInfo} disabled={this.state.preloaderLocalShow}><FaPlus /> Add New</Button>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <DragAndDrop handleDrop={this.handleDropFile}>
                                        <Input type="text" style={{ fontWeight: "900" }} disabled placeholder="Drop Import file here" />
                                    </DragAndDrop>
                                </div>
                                <div className="BrowseButtonSites">
                                    <label className="custom-file-upload" style={{ fontSize: "2vmin" }}>
                                        <input style={{ display: "none" }} type="file" accept=".csv" onChange={this.handleImportFile} />
                                        <FaCloudUploadAlt /> Browse
                                    </label>
                                </div>
                            </Col>
                        </Row>
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderMessage} />
                            <div className="sites-wrap">
                                <ReactTable
                                    data={this.state.sitesList}
                                    columns={[
                                        {
                                            id: "checkbox",
                                            accessor: "",
                                            Cell: ({ original }) => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selected[original.rowid] === true}
                                                        onChange={(e) => this.toggleRow(original.rowid, e)}
                                                    />
                                                );
                                            },
                                            Header: x => {
                                                return (
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={this.state.selectAll === 1}
                                                        disabled={this.state.sitesList.length === 0 ? "disabled" : ""}
                                                        ref={input => {
                                                            if (input) {
                                                                input.indeterminate = this.state.selectAll === 2;
                                                            }
                                                        }}
                                                        onChange={() => this.toggleSelectAll()}
                                                    />
                                                );
                                            },
                                            sortable: false,
                                            width: 45
                                        },
                                        {
                                            Header: "Name",
                                            accessor: "SiteName",
                                            Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: "Location name",
                                            accessor: "Location",
                                            Cell: row => (<span style={{ width: "200px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px" }} onClick={(e) => { this.updateRow(row, e) }}>{row.value}</span>)
                                        },
                                        {
                                            Header: row => (<span style={{
                                                width: "200px", height: "25px", display: "block", cursor: "pointer", paddingLeft: "0px"
                                                , backgroundColor: (this.state.selectAll != 0) ? "#007bff" : "#061e45", borderRadius: (this.state.selectAll != 0) ? "25px" : "0px"
                                            }} onClick={(e) => { this.removeAllClicked() }}>Remove</span>),
                                            accessor: "rowid",
                                            Cell: row => (<FaTrash style={{ color: "red", cursor: "pointer" }} key={row.value} onClick={(e) => { this.removeRowClicked(row.value, e) }} />)
                                        }
                                    ]}
                                    showPagination={false}
                                    style={{ height: "35vmin" }}
                                    noDataText="No Sites loaded"
                                    className="-striped -highlight"
                                />
                            </div>

                            <Row>
                                <Col>
                                    <Button className="SubmitButtonSites" onClick={this.importSites} disabled={this.state.sitesList.length === 0}>Submit</Button>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }
}
