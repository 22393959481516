import './outboundLists.css'
import React from 'react'
import { FaCog, FaInfoCircle } from 'react-icons/fa';
import { Card, CardBody, CardTitle, Button, Row, Col, Badge } from 'reactstrap';
import moment from 'moment';

import { getSelectedRegion } from '../../../../global/localStorage';
import { getToken, getUserMe, getOrgMe, logActionData } from '../../../../services/session/session';
import PreloaderLocal from '../../../Misc/preloaderLocal/preloaderLocal';
import EventListViewer from '../../../Misc/eventListViewer/eventListViewer';
import InformationMessage from '../../../Misc/informationMessage/informationMessage';
import { getAuthorizationDivisions } from '../../../../services/purecloud/authorization';
import ContactListInfo from '../outboundLists/contactListInfo';
import DNClistInfo from '../outboundLists/dncListInfo';
import CsvReader from '../../../../services/misc/csvReader';
import { createContactLists, uploadContactLists } from '../../../../services/purecloud/outbound';
import ImageMapper from 'react-image-mapper';

export default class OutboundLists extends React.Component {

    state = {
        env: '',
        token: '',
        userMe: {},
        orgMe: {},
        timezone: false,
        preloaderLocalShow: false,
        isInfoOpen: false,
        divisionList: [],
        contactListName: '',
        division: '',
        clFileName: '',
        clContent: null,
        clColumns: [],
        isContactListsOpen: false,
        DNCname: '',
        DNCdivision: '',
        dclFileName: '',
        dncListType: 'Internal',
        dclContent: null,
        dclColumns: [],
        isDNClistOpen: false,
        isSubmitEnabled: false,
        outboundContactList: [],
        divisionDetails: undefined,
        columnNames: [],
        outboundDncLists: [],
        clEncodedFileContent: null,
        dclEncodedFileContent: null,
        eventListIsOpen: false,
        eventList: []
    }

    contactListsUrl = `/outbound/contactlists`;
    contactListsUploadUrl = `/outbound/uploads/contactlist`;
    dncListsUrl = `/outbound/dnclists`;
    eventList = [];

    logEvent = (message, isError = false) => {
        const event = {
            isError: isError,
            time: moment().format('HH:mm:ss'),
            message: message
        }
        this.eventList.push(event);
    }

    showEventList = (eventList) => {
        this.setState({
            eventList: eventList,
            eventListIsOpen: true
        });
    }

    constructor(props) {
        super(props);
        this.state.env = getSelectedRegion();
        this.state.token = getToken();
        if (!this.state.token) {
            window.location.href = '/authorization';
            return;
        }
    }

    async componentDidMount() {
        this.setState({ preloaderLocalShow: true });
        this.loadItems();
        await this.loadUser();
    }

    loadItems = async () => {
        this.setState({ preloaderLocalShow: false, divisionList: await getAuthorizationDivisions(this.state.env, this.state.token) });
    }

    loadUser = async () => {
        try {
            const userMe = await getUserMe();
            const orgMe = await getOrgMe();
            await this.setState({ userMe: userMe });
            await this.setState({ orgMe: orgMe });
        } catch (error) {
            throw error;
        }
    }

    handleInputChange = (fieldName) => (event) => {
        if (fieldName === 'timezone') {
            const ele = document.getElementById(fieldName);
            this.setState({
                [fieldName]: ele.checked,
            });
        } else {
            this.setState({
                [fieldName]: event.target.value
            });
        }
    }

    uploadContacts = async (id, EncodedFileContent, fileType, phoneColumnsKey, phoneColumnsValue) => {
        let contactListRows = {};
        contactListRows['id'] = id;
        contactListRows['file'] = EncodedFileContent;
        contactListRows['fileType'] = fileType;
        contactListRows[phoneColumnsKey] = phoneColumnsValue;

        const uploadResponse = await uploadContactLists(JSON.stringify(contactListRows), this.state.env, this.state.userMe.email, this.state.token, this.contactListsUploadUrl, this.state.orgMe.name);
        if (uploadResponse.messages.length === 0) {
            (fileType === 'contactlist') ? this.logEvent(`Contact List "${this.state.contactListName}" processed successfully`, false) : this.logEvent(`Do Not Contact List "${this.state.DNCname}" processed successfully`, false);
        }
        else {
            uploadResponse.messages.map((message) => {
                this.logEvent(message.content, true);
            });
        }
    }

    importContactLists = async () => {
        let contactList = {};
        try {
            if (this.state.contactListName !== '') {
                const divisionDetails = await this.getDivision(this.state.division);
                contactList['name'] = this.state.contactListName;
                contactList['version'] = 0;
                contactList['phoneColumns'] = [{ "columnName": "PhoneNumber", "type": "Cell" }];
                contactList['division'] = { "id": divisionDetails.id, "name": divisionDetails.name, "selfUri": "" };
                contactList['columnNames'] = this.state.clColumns;
                contactList['automaticTimeZoneMapping'] = this.state.timezone;
                contactList['previewModeColumnName'] = "";
                contactList['previewModeAcceptedValues'] = [];
                contactList['zipCodeColumnName'] = "";
                contactList['attemptLimits'] = { "id": "", "name": "", "selfUri": "" };

                const response = await createContactLists(JSON.stringify(contactList), this.state.env, this.state.userMe.email, this.state.token, this.contactListsUrl, this.state.orgMe.name);
                if (response) {
                    if (response.messages.length === 0) {
                        await logActionData('Import', `Importing Outbound Contact Lists`, 1, 'ContactLists');
                        await this.uploadContacts(response.result.id, this.state.clEncodedFileContent, 'contactlist', 'contactIdName', 'PhoneNumber');
                        let contactListResult = [response.result.id, this.state.contactListName];
                        this.setState({ outboundContactList: contactListResult, divisionDetails: contactList['division'], columnNames: response.result.columnNames });
                    } else {
                        response.messages.map((message) => {
                            this.logEvent(message.content, true);
                        });
                    }
                }
            }
        } catch (err) {
            this.logEvent(err, true);
        }
    }

    importDNCLists = async () => {
        let dncList = {};
        if (this.state.DNCname !== '') {
            const divisionDetails = await this.getDivision(this.state.DNCdivision);
            dncList['name'] = this.state.DNCname;
            dncList['version'] = 1;
            dncList['dateCreated'] = moment();
            dncList['dncSourceType'] = 'RDS';
            dncList['division'] = { "id": divisionDetails.id, "name": divisionDetails.name, "selfUri": "" };

            const response = await createContactLists(JSON.stringify(dncList), this.state.env, this.state.userMe.email, this.state.token, this.dncListsUrl, this.state.orgMe.name);
            if (response) {
                if (response.messages.length === 0) {
                    await logActionData('Import', `Importing Outbound Don Not Contact Lists`, 1, 'DoNotContactLists');
                    await this.uploadContacts(response.result.id, this.state.dclEncodedFileContent, 'dnclist', 'phoneColumns', ['PhoneNumber']);
                    let dncListResult = [response.result.id, this.state.DNCname];
                    this.setState({ outboundDncLists: dncListResult });
                } else {
                    response.messages.map((message) => {
                        this.logEvent(message.content, true);
                    });
                }
            }
        }
    }

    handleSubmit = async () => {
        this.eventList = [];
        await this.setState({ preloaderLocalShow: true });
        await this.importContactLists();
        await this.importDNCLists();
        await this.setState({ preloaderLocalShow: false });
        this.showEventList(this.eventList);
    }

    closeEventViewer = () => {
        let moveToQueueSlide = ((this.eventList.find(evnt => evnt.isError === true)) === undefined);
        if (moveToQueueSlide) {
            this.resetState();
            this.setState({ eventListIsOpen: false, eventList: [] });
            this.props.handleLists(this.state.outboundContactList, this.state.outboundDncLists, this.state.divisionDetails, moveToQueueSlide, this.state.columnNames);
        } else {
            this.setState({ eventListIsOpen: false, eventList: [] });
        }
    }

    resetState = async () => {
        await this.setState({
            timezone: false,
            contactListName: '',
            division: '',
            clFileName: '',
            clContent: null,
            clColumns: [],
            isContactListsOpen: false,
            DNCname: '',
            DNCdivision: '',
            dclFileName: '',
            dncListType: 'Internal',
            dclContent: null,
            dclColumns: [],
            isDNClistOpen: false,
            isSubmitEnabled: false,
            outboundContactList: [],
            outboundDncLists: [],
            clEncodedFileContent: null,
            dclEncodedFileContent: null,
        });
    }

    saveContactLists = async () => {
        this.setState({
            isContactListsOpen: false,
            isSubmitEnabled: true,
        });
    }

    saveDNClists = () => {
        this.setState({
            isDNClistOpen: false,
        });
    }

    createDocReaderPromise = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: Math.round(file.size / 1000) + ' kB',
                    base64: reader.result,
                    file,
                };
                resolve(fileInfo.base64.split(',')[1]);
            };
            reader.onerror = () => {
                reject();
            };
        });
    }

    uploadContactLists = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const columns = fileContent.split(/\r\n|\n/);
        const columnsArray = columns[0].split(',');
        const EncodedFileContent = await this.createDocReaderPromise(file);
        await this.setState({ preloaderLocalShow: false, clFileName: file.name, clContent: fileContent, clColumns: columnsArray, clEncodedFileContent: EncodedFileContent });
    }

    uploadDNC = async (file) => {
        await this.setState({ preloaderLocalShow: true });
        const fileContent = await CsvReader.readFile(file);
        const columns = fileContent.split(/\r\n|\n/);
        const columnsArray = columns[0].split(',');
        const EncodedFileContent = await this.createDocReaderPromise(file);
        await this.setState({ preloaderLocalShow: false, dclFileName: file.name, dclContent: fileContent, dclColumns: columnsArray, dclEncodedFileContent: EncodedFileContent });
    }

    getDivision = async (name) => {
        const division = this.state.divisionList.filter(r => r.name.toLowerCase() === name.toLowerCase())[0];
        if (division) { return division; }
    }

    handleImportFile = async (e) => {
        try {
            const file = e.target.files[0];
            if (file) {
                e.persist();
                if (this.state.isContactListsOpen) {
                    this.uploadContactLists(e.target.files[0]);
                } else if (this.state.isDNClistOpen) {
                    this.uploadDNC(e.target.files[0]);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleDropFile = async (e) => {
        try {
            const file = e[0];
            if (!file || !file.name.toLowerCase().endsWith('.csv')) {
                console.error('Only CSV files are allowed');
                return;
            }
            if (file) {
                if (this.state.isContactListsOpen) {
                    this.uploadContactLists(file);
                } else if (this.state.isDNClistOpen) {
                    this.uploadDNC(file);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    handleContactListsInfo = () => {
        this.setState({
            isContactListsOpen: true,
        });
    }

    handleDNClistsInfo = () => {
        this.setState({
            isDNClistOpen: true,
        });
    }

    handleInformation = () => {
        this.setState({
            isInfoOpen: true,
        });
    }

    render() {
        let MAP = {
            name: "c-map",
            areas: []
        }

        return (
            <div className="listCard">
                {/* <event list viewer> */}
                <EventListViewer title="Upload completed" isOpen={this.state.eventListIsOpen} eventList={this.state.eventList} closeFunction={this.closeEventViewer} />
                {/* </event list viewer>  */}

                {/* <Information viewer> */}
                <InformationMessage title={this.props.cardTitle} isOpen={this.state.isInfoOpen} closeFunction={() => { this.setState({ isInfoOpen: false }) }} />
                {/* <Information viewer> */}

                {/* <Information viewer> */}
                <ContactListInfo
                    isOpen={this.state.isContactListsOpen}
                    contactListName={this.state.contactListName}
                    division={this.state.division}
                    divisionList={this.state.divisionList}
                    clFileName={this.state.clFileName}
                    saveContactListsFunction={this.saveContactLists}
                    handleInputChange={this.handleInputChange}
                    handleImportFile={this.handleImportFile}
                    handleDropFile={this.handleDropFile}
                    closeFunction={() => { this.setState({ isContactListsOpen: false }) }} />
                {/* <Information viewer> */}

                {/* <Information viewer> */}
                <DNClistInfo
                    isOpen={this.state.isDNClistOpen}
                    DNCname={this.state.DNCname}
                    DNCdivision={this.state.DNCdivision}
                    divisionList={this.state.divisionList}
                    dncListType={this.state.dncListType}
                    dclFileName={this.state.dclFileName}
                    timezone={this.state.timezone}
                    saveDNClistsFunction={this.saveDNClists}
                    handleInputChange={this.handleInputChange}
                    handleImportFile={this.handleImportFile}
                    handleDropFile={this.handleDropFile}
                    closeFunction={() => { this.setState({ isDNClistOpen: false }) }} />
                {/* <Information viewer> */}

                <Card className="mb-4 cardDesign">
                    <CardBody className="p-3 listCardBody">
                        <CardTitle className="m-0 listTitle">
                            <div className="divLists">
                                <Badge className="titleBadgeList">{this.props.cardTitle}</Badge>
                                <FaInfoCircle style={{ cursor: "pointer", fontSize: "2.5vmin", paddingBottom: "4px" }} title={this.props.cardTitle + ' Information'} onClick={this.handleInformation} />
                                <sup className="supText">  Learn More</sup>
                                <div className="ImageMapper">
                                    {
                                        this.props.isSMSCampType ?
                                            <ImageMapper src="/images/smsList.png" map={MAP} width={200} onClick={area => this.clickedArea(area)} />
                                            :
                                            <ImageMapper src="/images/pLists.png" map={MAP} width={300} onClick={area => this.clickedArea(area)} />

                                    }

                                </div>
                            </div>
                        </CardTitle>
                        <p style={{ paddingLeft: "12px", fontSize: "2.5vmin", marginLeft: "-20px" }}>From this page, add new {this.props.cardTitle} individually </p>
                    </CardBody>
                    <CardBody className="p-3 CardBodyList">
                        <div className="single-field-editor-card-wrap">
                            <PreloaderLocal show={this.state.preloaderLocalShow} text={this.state.preloaderLocalMessage} />
                            <div className="list-wrap">
                                <Row style={{ paddingTop: "15px" }}>
                                    <Col md={7}>
                                        {
                                            this.props.dialingMode === 'SMS'
                                                ?
                                                <p>Download Recommended <a href="./templates/sms-contactList-template.csv">Contact List Template</a></p>
                                                :
                                                <p>Download Recommended <a href="./templates/contactList-template.csv">Contact List Template</a></p>
                                        }
                                    </Col>
                                    <Col md={4}>
                                        <Button className="ImportButtonList" onClick={this.handleContactListsInfo}>Import File here</Button>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "10px", paddingBottom: "15px" }}>
                                    <Col md={7}>
                                        <p>Download Recommended <a href="./templates/doNotContactList-template.csv">Do Not Contact List Template</a></p>
                                    </Col>
                                    <Col md={4}>
                                        <Button className="ImportButtonList" onClick={this.handleDNClistsInfo}>Import File here</Button>
                                    </Col>
                                </Row>
                            </div>

                            <Row className="mt-3">
                                <Col>
                                    <div>
                                        <Button className="SubmitButtonList" onClick={this.handleSubmit} disabled={!this.state.isSubmitEnabled}>Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>

            </div>
        )
    }
}
